import { default as api_45legacymCqrFnPOqbMeta } from "/codebuild/output/src2964691665/src/horizon/pages/api-legacy.vue?macro=true";
import { default as awardsLQCludMypLMeta } from "/codebuild/output/src2964691665/src/horizon/pages/awards.vue?macro=true";
import { default as programszW4yyJZxUcMeta } from "/codebuild/output/src2964691665/src/horizon/pages/benefits/programs.vue?macro=true";
import { default as _91slug_93AExRuz2TFHMeta } from "/codebuild/output/src2964691665/src/horizon/pages/blog/[slug].vue?macro=true";
import { default as _91slug_93TgSrJ9ZLXCMeta } from "/codebuild/output/src2964691665/src/horizon/pages/blog/category/[slug].vue?macro=true";
import { default as index8vd1tqQ80EMeta } from "/codebuild/output/src2964691665/src/horizon/pages/blog/index.vue?macro=true";
import { default as call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta } from "/codebuild/output/src2964691665/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue?macro=true";
import { default as _91slug_93wiDMqpU21qMeta } from "/codebuild/output/src2964691665/src/horizon/pages/company/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93FqnxcAq4dAMeta } from "/codebuild/output/src2964691665/src/horizon/pages/company/[slug].vue?macro=true";
import { default as abouttAPkKYKhiTMeta } from "/codebuild/output/src2964691665/src/horizon/pages/company/about.vue?macro=true";
import { default as indexML68DbFBfrMeta } from "/codebuild/output/src2964691665/src/horizon/pages/company/careers/benefits/index.vue?macro=true";
import { default as engineering_45and_45productCSqXLl08iDMeta } from "/codebuild/output/src2964691665/src/horizon/pages/company/careers/departments/engineering-and-product.vue?macro=true";
import { default as index438BPMyKZGMeta } from "/codebuild/output/src2964691665/src/horizon/pages/company/careers/departments/index.vue?macro=true";
import { default as salesUJBOw3vYzCMeta } from "/codebuild/output/src2964691665/src/horizon/pages/company/careers/departments/sales.vue?macro=true";
import { default as emerging_45talent_45archive2K8B8A0UfBMeta } from "/codebuild/output/src2964691665/src/horizon/pages/company/careers/emerging-talent-archive.vue?macro=true";
import { default as indexqhowhWcUgrMeta } from "/codebuild/output/src2964691665/src/horizon/pages/company/careers/index.vue?macro=true";
import { default as indexOOPcaLeoaXMeta } from "/codebuild/output/src2964691665/src/horizon/pages/company/careers/locations/index.vue?macro=true";
import { default as _91slug_93tgUBVzcGz6Meta } from "/codebuild/output/src2964691665/src/horizon/pages/company/careers/roles/[slug].vue?macro=true";
import { default as indexMoCEFCW2OZMeta } from "/codebuild/output/src2964691665/src/horizon/pages/company/careers/roles/index.vue?macro=true";
import { default as contactmSJ7exPVuwMeta } from "/codebuild/output/src2964691665/src/horizon/pages/company/contact.vue?macro=true";
import { default as diversityx3gdFflCsAMeta } from "/codebuild/output/src2964691665/src/horizon/pages/company/diversity.vue?macro=true";
import { default as index6BIYoA8fdTMeta } from "/codebuild/output/src2964691665/src/horizon/pages/company/index.vue?macro=true";
import { default as efficiency4sReWqrMTqMeta } from "/codebuild/output/src2964691665/src/horizon/pages/company/mission/efficiency.vue?macro=true";
import { default as safetygu2yWdlisZMeta } from "/codebuild/output/src2964691665/src/horizon/pages/company/mission/safety.vue?macro=true";
import { default as sustainabilitybTP87K9ka2Meta } from "/codebuild/output/src2964691665/src/horizon/pages/company/mission/sustainability.vue?macro=true";
import { default as in_45the_45newspdUPys4XjYMeta } from "/codebuild/output/src2964691665/src/horizon/pages/company/news/in-the-news.vue?macro=true";
import { default as indexlA4GroH8NtMeta } from "/codebuild/output/src2964691665/src/horizon/pages/company/news/index.vue?macro=true";
import { default as press_45releasesHJlhxrTIb3Meta } from "/codebuild/output/src2964691665/src/horizon/pages/company/news/press-releases.vue?macro=true";
import { default as privacyY4Mxej5R9dMeta } from "/codebuild/output/src2964691665/src/horizon/pages/company/privacy.vue?macro=true";
import { default as cost_45assessment_45toolVohx40TmBuMeta } from "/codebuild/output/src2964691665/src/horizon/pages/cost-assessment-tool.vue?macro=true";
import { default as _91slug_93fNaIzkOEs0Meta } from "/codebuild/output/src2964691665/src/horizon/pages/customers/[slug].vue?macro=true";
import { default as _91slug_93XAfcP6ofK9Meta } from "/codebuild/output/src2964691665/src/horizon/pages/customers/category/[slug].vue?macro=true";
import { default as indexVW6ylkO5xUMeta } from "/codebuild/output/src2964691665/src/horizon/pages/customers/index.vue?macro=true";
import { default as _91slug_93Eb0EUTUKZtMeta } from "/codebuild/output/src2964691665/src/horizon/pages/customers/tips/[slug].vue?macro=true";
import { default as _91slug_93HOhs0496ahMeta } from "/codebuild/output/src2964691665/src/horizon/pages/customers/tips/category/[slug].vue?macro=true";
import { default as indexM5CeiYWkQXMeta } from "/codebuild/output/src2964691665/src/horizon/pages/customers/tips/index.vue?macro=true";
import { default as _91_46_46_46slug_93DfAv9rUHA4Meta } from "/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue?macro=true";
import { default as above_45beyondabKcrpwsPeMeta } from "/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/above-beyond.vue?macro=true";
import { default as excellence_45performanceGSIlx1iV8VMeta } from "/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue?macro=true";
import { default as excellence_45serviceEyos5bZJWCMeta } from "/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/excellence-service.vue?macro=true";
import { default as indexm6td9yGT9CMeta } from "/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/index.vue?macro=true";
import { default as safest_45fleetUZZgEGqlRNMeta } from "/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue?macro=true";
import { default as driver_45performance4gO8DizCYuMeta } from "/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/driver-performance.vue?macro=true";
import { default as excellence_45performancegeQiyyGvnuMeta } from "/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue?macro=true";
import { default as fleet_45innovatorhJEbsCp4VUMeta } from "/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue?macro=true";
import { default as index5rPqLx6tCgMeta } from "/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/index.vue?macro=true";
import { default as public_45fleetVL2g58bijeMeta } from "/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/public-fleet.vue?macro=true";
import { default as safest_45fleetnxKDOcqx92Meta } from "/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue?macro=true";
import { default as indexnm1qelVdyOMeta } from "/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/index.vue?macro=true";
import { default as _91slug_93lUJkJ8Fg14Meta } from "/codebuild/output/src2964691665/src/horizon/pages/events/[slug].vue?macro=true";
import { default as indexkFFpAYnVopMeta } from "/codebuild/output/src2964691665/src/horizon/pages/events/index.vue?macro=true";
import { default as fleet_45appxAh58vdQ0YMeta } from "/codebuild/output/src2964691665/src/horizon/pages/fleet-app.vue?macro=true";
import { default as _91slug_93zX7UVNDZRoMeta } from "/codebuild/output/src2964691665/src/horizon/pages/fleet/[prefix]/[slug].vue?macro=true";
import { default as _91slug_931vFeSRQVIoMeta } from "/codebuild/output/src2964691665/src/horizon/pages/fleet/[slug].vue?macro=true";
import { default as _91slug_93fxU8k5RBsrMeta } from "/codebuild/output/src2964691665/src/horizon/pages/fleets/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93Lk61ENg1jeMeta } from "/codebuild/output/src2964691665/src/horizon/pages/fleets/[slug].vue?macro=true";
import { default as _91slug_93ckNd3jhw3JMeta } from "/codebuild/output/src2964691665/src/horizon/pages/guides/[slug].vue?macro=true";
import { default as attract_45retain_45talentZ3Bk9idIdFMeta } from "/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue?macro=true";
import { default as increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta } from "/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue?macro=true";
import { default as indexjhCVxg7FzMMeta } from "/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/index.vue?macro=true";
import { default as protect_45optimize_45assetsWEV1t7oceHMeta } from "/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue?macro=true";
import { default as best_45dash_45camswpmPLMVimCMeta } from "/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue?macro=true";
import { default as indexWsoZPUCCp6Meta } from "/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/index.vue?macro=true";
import { default as safety_45programcWkKJGUzGMMeta } from "/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/safety-program.vue?macro=true";
import { default as safety_45roiK8UDYUSoqzMeta } from "/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/safety-roi.vue?macro=true";
import { default as indexFR987Qwf3iMeta } from "/codebuild/output/src2964691665/src/horizon/pages/guides/index.vue?macro=true";
import { default as build_45for_45the_45futureal7bny4gZ0Meta } from "/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue?macro=true";
import { default as empower_45your_45teamsyUNzgmZLVCMeta } from "/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue?macro=true";
import { default as indexkJuAaxlHVMMeta } from "/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/index.vue?macro=true";
import { default as take_45action_45with_45datavKEvn0njrRMeta } from "/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue?macro=true";
import { default as _91slug_93SsekXyBkGpMeta } from "/codebuild/output/src2964691665/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue?macro=true";
import { default as indexEoGHcHCGu3Meta } from "/codebuild/output/src2964691665/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue?macro=true";
import { default as indexvqHvWWnZHdMeta } from "/codebuild/output/src2964691665/src/horizon/pages/index.vue?macro=true";
import { default as _91slug_93x3G8BraYYLMeta } from "/codebuild/output/src2964691665/src/horizon/pages/industrial/[slug].vue?macro=true";
import { default as _91slug_93o8KwTHu4hlMeta } from "/codebuild/output/src2964691665/src/horizon/pages/industries/[slug].vue?macro=true";
import { default as indexKWGXMC3CTJMeta } from "/codebuild/output/src2964691665/src/horizon/pages/industries/index.vue?macro=true";
import { default as citiesqssk38TEOmMeta } from "/codebuild/output/src2964691665/src/horizon/pages/insights/cities.vue?macro=true";
import { default as index9fDeovbYyWMeta } from "/codebuild/output/src2964691665/src/horizon/pages/integrations/index.vue?macro=true";
import { default as indexKoCywAAe57Meta } from "/codebuild/output/src2964691665/src/horizon/pages/invite/index.vue?macro=true";
import { default as learn_45moreOGCS6JKdfjMeta } from "/codebuild/output/src2964691665/src/horizon/pages/learn-more.vue?macro=true";
import { default as candidate_45privacy_45noticexBDyb4Sr1oMeta } from "/codebuild/output/src2964691665/src/horizon/pages/legal/candidate-privacy-notice.vue?macro=true";
import { default as cookie_45consentBgzDrsIydxMeta } from "/codebuild/output/src2964691665/src/horizon/pages/legal/cookie-consent.vue?macro=true";
import { default as cookie_45policyH4PjFfHQ79Meta } from "/codebuild/output/src2964691665/src/horizon/pages/legal/cookie-policy.vue?macro=true";
import { default as data_45protection_45addenduml2TIK7YfybMeta } from "/codebuild/output/src2964691665/src/horizon/pages/legal/data-protection-addendum.vue?macro=true";
import { default as express_45order_45termsCUWM2mQ9jSMeta } from "/codebuild/output/src2964691665/src/horizon/pages/legal/express-order-terms.vue?macro=true";
import { default as hardware_45warrantySbsUhqGDfKMeta } from "/codebuild/output/src2964691665/src/horizon/pages/legal/hardware-warranty.vue?macro=true";
import { default as hosted_45software_45slavZqWQXrRGoMeta } from "/codebuild/output/src2964691665/src/horizon/pages/legal/hosted-software-sla.vue?macro=true";
import { default as partner_45code_45of_45conducti0ViBXP0A9Meta } from "/codebuild/output/src2964691665/src/horizon/pages/legal/partner-code-of-conduct.vue?macro=true";
import { default as platform_45terms_45of_45serviceqzVAZPExxYMeta } from "/codebuild/output/src2964691665/src/horizon/pages/legal/platform-terms-of-service.vue?macro=true";
import { default as privacy5vGHmEkb53Meta } from "/codebuild/output/src2964691665/src/horizon/pages/legal/privacy.vue?macro=true";
import { default as public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta } from "/codebuild/output/src2964691665/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue?macro=true";
import { default as requestingcustomerdataLuF7SVQdkgMeta } from "/codebuild/output/src2964691665/src/horizon/pages/legal/requestingcustomerdata.vue?macro=true";
import { default as security0fJQm14lR2Meta } from "/codebuild/output/src2964691665/src/horizon/pages/legal/security.vue?macro=true";
import { default as transparencyreport2uCJz8ZmztMeta } from "/codebuild/output/src2964691665/src/horizon/pages/legal/transparencyreport.vue?macro=true";
import { default as website_45terms_45of_45serviceLNcWlNSHPjMeta } from "/codebuild/output/src2964691665/src/horizon/pages/legal/website-terms-of-service.vue?macro=true";
import { default as your_45privacy_45choicesbduqLwNYKcMeta } from "/codebuild/output/src2964691665/src/horizon/pages/legal/your-privacy-choices.vue?macro=true";
import { default as pricingT19BNP0CwEMeta } from "/codebuild/output/src2964691665/src/horizon/pages/page/pricing.vue?macro=true";
import { default as _91slug_93tbtWcAvohAMeta } from "/codebuild/output/src2964691665/src/horizon/pages/pages/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93YWqFwnMYgQMeta } from "/codebuild/output/src2964691665/src/horizon/pages/pages/[slug].vue?macro=true";
import { default as asset_45tracking3izzWlAZRKMeta } from "/codebuild/output/src2964691665/src/horizon/pages/pages/asset-tracking.vue?macro=true";
import { default as best_45dash_45camsjGOPuMS49wMeta } from "/codebuild/output/src2964691665/src/horizon/pages/pages/best-dash-cams.vue?macro=true";
import { default as constructiongcPXfYUPaMMeta } from "/codebuild/output/src2964691665/src/horizon/pages/pages/construction.vue?macro=true";
import { default as fleet_45managementCnbN9RKe6CMeta } from "/codebuild/output/src2964691665/src/horizon/pages/pages/fleet-management.vue?macro=true";
import { default as nationwideMrtlC15pwYMeta } from "/codebuild/output/src2964691665/src/horizon/pages/pages/nationwide.vue?macro=true";
import { default as safety_45coaching2T6h5Iurp6Meta } from "/codebuild/output/src2964691665/src/horizon/pages/pages/safety-coaching.vue?macro=true";
import { default as unifirstysHLWjUV9UMeta } from "/codebuild/output/src2964691665/src/horizon/pages/pages/unifirst.vue?macro=true";
import { default as pricingcD0QoPpd7TMeta } from "/codebuild/output/src2964691665/src/horizon/pages/pricing.vue?macro=true";
import { default as _91slug_93Etpl9pIBgHMeta } from "/codebuild/output/src2964691665/src/horizon/pages/products/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93myYOEZT9YiMeta } from "/codebuild/output/src2964691665/src/horizon/pages/products/[slug].vue?macro=true";
import { default as asset_45trackingAPHX9J5TS3Meta } from "/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue?macro=true";
import { default as equipment_45trackingxx2tspZwoYMeta } from "/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue?macro=true";
import { default as reefer_45monitoring9tMBkgMNvKMeta } from "/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue?macro=true";
import { default as smart_45trailerstFvzlMgqkkMeta } from "/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue?macro=true";
import { default as trailer_45trackingrHxkpdepdhMeta } from "/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue?macro=true";
import { default as indexNLMWZ67fWhMeta } from "/codebuild/output/src2964691665/src/horizon/pages/products/index.vue?macro=true";
import { default as _91slug_93C80Tzk4TpwMeta } from "/codebuild/output/src2964691665/src/horizon/pages/products/models/[slug].vue?macro=true";
import { default as indexv0GspYyO1KMeta } from "/codebuild/output/src2964691665/src/horizon/pages/products/models/index.vue?macro=true";
import { default as gps_45fleet_45trackingi5Amfph1LbMeta } from "/codebuild/output/src2964691665/src/horizon/pages/products/telematics/gps-fleet-tracking.vue?macro=true";
import { default as indexqUyUTR3XEfMeta } from "/codebuild/output/src2964691665/src/horizon/pages/products/telematics/index.vue?macro=true";
import { default as referralsIB8LnsZJqtMeta } from "/codebuild/output/src2964691665/src/horizon/pages/referrals.vue?macro=true";
import { default as _91slug_93XwIbcQ7G8GMeta } from "/codebuild/output/src2964691665/src/horizon/pages/resources/[slug].vue?macro=true";
import { default as brand_45assets2CrUbMkpB5Meta } from "/codebuild/output/src2964691665/src/horizon/pages/resources/brand-assets.vue?macro=true";
import { default as _91slug_93ZUsCpf1s1rMeta } from "/codebuild/output/src2964691665/src/horizon/pages/resources/content/[slug].vue?macro=true";
import { default as email_45preferences_45centerL4v0R6EFuOMeta } from "/codebuild/output/src2964691665/src/horizon/pages/resources/content/email-preferences-center.vue?macro=true";
import { default as _91slug_93865XW1S5VuMeta } from "/codebuild/output/src2964691665/src/horizon/pages/resources/experts/[slug].vue?macro=true";
import { default as _91slug_93k9L8tTSzFIMeta } from "/codebuild/output/src2964691665/src/horizon/pages/resources/experts/category/[slug].vue?macro=true";
import { default as indexv09paYUT59Meta } from "/codebuild/output/src2964691665/src/horizon/pages/resources/experts/index.vue?macro=true";
import { default as indexDaVrFESPHwMeta } from "/codebuild/output/src2964691665/src/horizon/pages/resources/index.vue?macro=true";
import { default as _91slug_93ywekjdCWHgMeta } from "/codebuild/output/src2964691665/src/horizon/pages/resources/marketplace/[slug].vue?macro=true";
import { default as _91slug_93M2QWfDTfhDMeta } from "/codebuild/output/src2964691665/src/horizon/pages/resources/marketplace/category/[slug].vue?macro=true";
import { default as index0N7CTDvv30Meta } from "/codebuild/output/src2964691665/src/horizon/pages/resources/marketplace/index.vue?macro=true";
import { default as build_45with_45samsara7w6j0pFMfxMeta } from "/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/build-with-samsara.vue?macro=true";
import { default as evolve_45insurance_45programsLxoIGtdXjCMeta } from "/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue?macro=true";
import { default as indexSluW3nDWHvMeta } from "/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/index.vue?macro=true";
import { default as provide_45servicesf2FcrLE5X8Meta } from "/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/provide-services.vue?macro=true";
import { default as sell_45samsaraDVmUqTvZ9SMeta } from "/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/sell-samsara.vue?macro=true";
import { default as tourbk9dRmRaQEMeta } from "/codebuild/output/src2964691665/src/horizon/pages/resources/tour.vue?macro=true";
import { default as videosgx9YYsGeaZMeta } from "/codebuild/output/src2964691665/src/horizon/pages/resources/videos.vue?macro=true";
import { default as indexkJdUfzF9U1Meta } from "/codebuild/output/src2964691665/src/horizon/pages/roi-calculator/index.vue?macro=true";
import { default as samsara_45venturesSbQknknTBDMeta } from "/codebuild/output/src2964691665/src/horizon/pages/samsara-ventures.vue?macro=true";
import { default as security_45summit7XN8t40EB9Meta } from "/codebuild/output/src2964691665/src/horizon/pages/security-summit.vue?macro=true";
import { default as _91slug_93EwZrfKYUtiMeta } from "/codebuild/output/src2964691665/src/horizon/pages/solutions/[slug].vue?macro=true";
import { default as indexBu0jp0EM5jMeta } from "/codebuild/output/src2964691665/src/horizon/pages/solutions/index.vue?macro=true";
import { default as securityURWb31nu2xMeta } from "/codebuild/output/src2964691665/src/horizon/pages/solutions/security.vue?macro=true";
import { default as space8vynKz2GwqMeta } from "/codebuild/output/src2964691665/src/horizon/pages/space.vue?macro=true";
import { default as sparkF6uU47j19sMeta } from "/codebuild/output/src2964691665/src/horizon/pages/spark.vue?macro=true";
import { default as _91slug_93z0ICenCrNEMeta } from "/codebuild/output/src2964691665/src/horizon/pages/styles/[slug].vue?macro=true";
import { default as _91slug_93rviAkGUlWEMeta } from "/codebuild/output/src2964691665/src/horizon/pages/support/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93ic9x24khsgMeta } from "/codebuild/output/src2964691665/src/horizon/pages/support/[slug].vue?macro=true";
import { default as apps_45privacy_45policyYYjBWOkWEEMeta } from "/codebuild/output/src2964691665/src/horizon/pages/support/apps-privacy-policy.vue?macro=true";
import { default as customer_45referrals_45faqyGwUiJGMMcMeta } from "/codebuild/output/src2964691665/src/horizon/pages/support/customer-referrals-faq.vue?macro=true";
import { default as driver_45training_45spanish2uuM0qPCPWMeta } from "/codebuild/output/src2964691665/src/horizon/pages/support/driver-training-spanish.vue?macro=true";
import { default as driver_45trainingilKR58QBHRMeta } from "/codebuild/output/src2964691665/src/horizon/pages/support/driver-training.vue?macro=true";
import { default as indexzNFmc5BrqhMeta } from "/codebuild/output/src2964691665/src/horizon/pages/support/index.vue?macro=true";
import { default as industrialTWgZEp1uj1Meta } from "/codebuild/output/src2964691665/src/horizon/pages/support/industrial.vue?macro=true";
import { default as modern_45slavery_45statement4TNMOz4HtIMeta } from "/codebuild/output/src2964691665/src/horizon/pages/support/modern-slavery-statement.vue?macro=true";
import { default as apps_45privacy_45policyGE979YShNJMeta } from "/codebuild/output/src2964691665/src/horizon/pages/support/privacy/apps-privacy-policy.vue?macro=true";
import { default as indexrqcvbeoe2KMeta } from "/codebuild/output/src2964691665/src/horizon/pages/support/privacy/index.vue?macro=true";
import { default as special_45featuresoSgXkFlwWzMeta } from "/codebuild/output/src2964691665/src/horizon/pages/support/privacy/special-features.vue?macro=true";
import { default as subprocessor_45listPc598awrhIMeta } from "/codebuild/output/src2964691665/src/horizon/pages/support/privacy/subprocessor-list.vue?macro=true";
import { default as support_45kb_45articlesU9jqRf37VoMeta } from "/codebuild/output/src2964691665/src/horizon/pages/support/support-kb-articles.vue?macro=true";
import { default as thank_45you_45pagensUAr9uEsFMeta } from "/codebuild/output/src2964691665/src/horizon/pages/thank-you-page.vue?macro=true";
import { default as thankyou_45recruitinggPXFvzTEgRMeta } from "/codebuild/output/src2964691665/src/horizon/pages/thankyou-recruiting.vue?macro=true";
import { default as thankyou_45referralKXkdOVYLAHMeta } from "/codebuild/output/src2964691665/src/horizon/pages/thankyou-referral.vue?macro=true";
import { default as thankyouF4fZx7KasyMeta } from "/codebuild/output/src2964691665/src/horizon/pages/thankyou.vue?macro=true";
import { default as elitejilMufutQPMeta } from "/codebuild/output/src2964691665/src/horizon/pages/tiers/elite.vue?macro=true";
import { default as plusVbSStCmBOcMeta } from "/codebuild/output/src2964691665/src/horizon/pages/tiers/plus.vue?macro=true";
import { default as premierlbT2V8Hv5FMeta } from "/codebuild/output/src2964691665/src/horizon/pages/tiers/premier.vue?macro=true";
import { default as starter4c4mJaQyhjMeta } from "/codebuild/output/src2964691665/src/horizon/pages/tiers/starter.vue?macro=true";
import { default as processklUdMwEI3BMeta } from "/codebuild/output/src2964691665/src/horizon/pages/trial/process.vue?macro=true";
import { default as supportb055D007icMeta } from "/codebuild/output/src2964691665/src/horizon/pages/warranty/support.vue?macro=true";
import { default as _91slug_93ghmeDDZsJNMeta } from "/codebuild/output/src2964691665/src/horizon/pages/webinars/[slug].vue?macro=true";
import { default as indexjl5eGSP63DMeta } from "/codebuild/output/src2964691665/src/horizon/pages/webinars/index.vue?macro=true";
import { default as _91slug_9393pan9yVxZMeta } from "/codebuild/output/src2964691665/src/horizon/pages/webinars/thank-you/[slug].vue?macro=true";
export default [
  {
    name: api_45legacymCqrFnPOqbMeta?.name ?? "api-legacy___en-US",
    path: api_45legacymCqrFnPOqbMeta?.path ?? "/api-legacy",
    meta: api_45legacymCqrFnPOqbMeta || {},
    alias: api_45legacymCqrFnPOqbMeta?.alias || [],
    redirect: api_45legacymCqrFnPOqbMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacymCqrFnPOqbMeta?.name ?? "api-legacy___ca",
    path: api_45legacymCqrFnPOqbMeta?.path ?? "/ca/api-legacy",
    meta: api_45legacymCqrFnPOqbMeta || {},
    alias: api_45legacymCqrFnPOqbMeta?.alias || [],
    redirect: api_45legacymCqrFnPOqbMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacymCqrFnPOqbMeta?.name ?? "api-legacy___nl",
    path: api_45legacymCqrFnPOqbMeta?.path ?? "/nl/api-legacy",
    meta: api_45legacymCqrFnPOqbMeta || {},
    alias: api_45legacymCqrFnPOqbMeta?.alias || [],
    redirect: api_45legacymCqrFnPOqbMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacymCqrFnPOqbMeta?.name ?? "api-legacy___uk",
    path: api_45legacymCqrFnPOqbMeta?.path ?? "/uk/api-legacy",
    meta: api_45legacymCqrFnPOqbMeta || {},
    alias: api_45legacymCqrFnPOqbMeta?.alias || [],
    redirect: api_45legacymCqrFnPOqbMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacymCqrFnPOqbMeta?.name ?? "api-legacy___de",
    path: api_45legacymCqrFnPOqbMeta?.path ?? "/de/api-legacy",
    meta: api_45legacymCqrFnPOqbMeta || {},
    alias: api_45legacymCqrFnPOqbMeta?.alias || [],
    redirect: api_45legacymCqrFnPOqbMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacymCqrFnPOqbMeta?.name ?? "api-legacy___mx",
    path: api_45legacymCqrFnPOqbMeta?.path ?? "/mx/api-legacy",
    meta: api_45legacymCqrFnPOqbMeta || {},
    alias: api_45legacymCqrFnPOqbMeta?.alias || [],
    redirect: api_45legacymCqrFnPOqbMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacymCqrFnPOqbMeta?.name ?? "api-legacy___fr",
    path: api_45legacymCqrFnPOqbMeta?.path ?? "/fr/api-legacy",
    meta: api_45legacymCqrFnPOqbMeta || {},
    alias: api_45legacymCqrFnPOqbMeta?.alias || [],
    redirect: api_45legacymCqrFnPOqbMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacymCqrFnPOqbMeta?.name ?? "api-legacy___fr-ca",
    path: api_45legacymCqrFnPOqbMeta?.path ?? "/fr-ca/api-legacy",
    meta: api_45legacymCqrFnPOqbMeta || {},
    alias: api_45legacymCqrFnPOqbMeta?.alias || [],
    redirect: api_45legacymCqrFnPOqbMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: awardsLQCludMypLMeta?.name ?? "awards___en-US",
    path: awardsLQCludMypLMeta?.path ?? "/awards",
    meta: awardsLQCludMypLMeta || {},
    alias: awardsLQCludMypLMeta?.alias || [],
    redirect: awardsLQCludMypLMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsLQCludMypLMeta?.name ?? "awards___ca",
    path: awardsLQCludMypLMeta?.path ?? "/ca/awards",
    meta: awardsLQCludMypLMeta || {},
    alias: awardsLQCludMypLMeta?.alias || [],
    redirect: awardsLQCludMypLMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsLQCludMypLMeta?.name ?? "awards___nl",
    path: awardsLQCludMypLMeta?.path ?? "/nl/awards",
    meta: awardsLQCludMypLMeta || {},
    alias: awardsLQCludMypLMeta?.alias || [],
    redirect: awardsLQCludMypLMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsLQCludMypLMeta?.name ?? "awards___uk",
    path: awardsLQCludMypLMeta?.path ?? "/uk/awards",
    meta: awardsLQCludMypLMeta || {},
    alias: awardsLQCludMypLMeta?.alias || [],
    redirect: awardsLQCludMypLMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsLQCludMypLMeta?.name ?? "awards___de",
    path: awardsLQCludMypLMeta?.path ?? "/de/awards",
    meta: awardsLQCludMypLMeta || {},
    alias: awardsLQCludMypLMeta?.alias || [],
    redirect: awardsLQCludMypLMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsLQCludMypLMeta?.name ?? "awards___mx",
    path: awardsLQCludMypLMeta?.path ?? "/mx/awards",
    meta: awardsLQCludMypLMeta || {},
    alias: awardsLQCludMypLMeta?.alias || [],
    redirect: awardsLQCludMypLMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsLQCludMypLMeta?.name ?? "awards___fr",
    path: awardsLQCludMypLMeta?.path ?? "/fr/awards",
    meta: awardsLQCludMypLMeta || {},
    alias: awardsLQCludMypLMeta?.alias || [],
    redirect: awardsLQCludMypLMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsLQCludMypLMeta?.name ?? "awards___fr-ca",
    path: awardsLQCludMypLMeta?.path ?? "/fr-ca/awards",
    meta: awardsLQCludMypLMeta || {},
    alias: awardsLQCludMypLMeta?.alias || [],
    redirect: awardsLQCludMypLMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: programszW4yyJZxUcMeta?.name ?? "benefits-programs___en-US",
    path: programszW4yyJZxUcMeta?.path ?? "/benefits/programs",
    meta: programszW4yyJZxUcMeta || {},
    alias: programszW4yyJZxUcMeta?.alias || [],
    redirect: programszW4yyJZxUcMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programszW4yyJZxUcMeta?.name ?? "benefits-programs___ca",
    path: programszW4yyJZxUcMeta?.path ?? "/ca/benefits/programs",
    meta: programszW4yyJZxUcMeta || {},
    alias: programszW4yyJZxUcMeta?.alias || [],
    redirect: programszW4yyJZxUcMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programszW4yyJZxUcMeta?.name ?? "benefits-programs___nl",
    path: programszW4yyJZxUcMeta?.path ?? "/nl/benefits/programs",
    meta: programszW4yyJZxUcMeta || {},
    alias: programszW4yyJZxUcMeta?.alias || [],
    redirect: programszW4yyJZxUcMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programszW4yyJZxUcMeta?.name ?? "benefits-programs___uk",
    path: programszW4yyJZxUcMeta?.path ?? "/uk/benefits/programs",
    meta: programszW4yyJZxUcMeta || {},
    alias: programszW4yyJZxUcMeta?.alias || [],
    redirect: programszW4yyJZxUcMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programszW4yyJZxUcMeta?.name ?? "benefits-programs___de",
    path: programszW4yyJZxUcMeta?.path ?? "/de/benefits/programs",
    meta: programszW4yyJZxUcMeta || {},
    alias: programszW4yyJZxUcMeta?.alias || [],
    redirect: programszW4yyJZxUcMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programszW4yyJZxUcMeta?.name ?? "benefits-programs___mx",
    path: programszW4yyJZxUcMeta?.path ?? "/mx/benefits/programs",
    meta: programszW4yyJZxUcMeta || {},
    alias: programszW4yyJZxUcMeta?.alias || [],
    redirect: programszW4yyJZxUcMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programszW4yyJZxUcMeta?.name ?? "benefits-programs___fr",
    path: programszW4yyJZxUcMeta?.path ?? "/fr/benefits/programs",
    meta: programszW4yyJZxUcMeta || {},
    alias: programszW4yyJZxUcMeta?.alias || [],
    redirect: programszW4yyJZxUcMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programszW4yyJZxUcMeta?.name ?? "benefits-programs___fr-ca",
    path: programszW4yyJZxUcMeta?.path ?? "/fr-ca/benefits/programs",
    meta: programszW4yyJZxUcMeta || {},
    alias: programszW4yyJZxUcMeta?.alias || [],
    redirect: programszW4yyJZxUcMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AExRuz2TFHMeta?.name ?? "blog-slug___en-US",
    path: _91slug_93AExRuz2TFHMeta?.path ?? "/blog/:slug()",
    meta: _91slug_93AExRuz2TFHMeta || {},
    alias: _91slug_93AExRuz2TFHMeta?.alias || [],
    redirect: _91slug_93AExRuz2TFHMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AExRuz2TFHMeta?.name ?? "blog-slug___ca",
    path: _91slug_93AExRuz2TFHMeta?.path ?? "/ca/blog/:slug()",
    meta: _91slug_93AExRuz2TFHMeta || {},
    alias: _91slug_93AExRuz2TFHMeta?.alias || [],
    redirect: _91slug_93AExRuz2TFHMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AExRuz2TFHMeta?.name ?? "blog-slug___mx",
    path: _91slug_93AExRuz2TFHMeta?.path ?? "/mx/blog/:slug()",
    meta: _91slug_93AExRuz2TFHMeta || {},
    alias: _91slug_93AExRuz2TFHMeta?.alias || [],
    redirect: _91slug_93AExRuz2TFHMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AExRuz2TFHMeta?.name ?? "blog-slug___uk",
    path: _91slug_93AExRuz2TFHMeta?.path ?? "/uk/blog/:slug()",
    meta: _91slug_93AExRuz2TFHMeta || {},
    alias: _91slug_93AExRuz2TFHMeta?.alias || [],
    redirect: _91slug_93AExRuz2TFHMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AExRuz2TFHMeta?.name ?? "blog-slug___fr",
    path: _91slug_93AExRuz2TFHMeta?.path ?? "/fr/blog/:slug()",
    meta: _91slug_93AExRuz2TFHMeta || {},
    alias: _91slug_93AExRuz2TFHMeta?.alias || [],
    redirect: _91slug_93AExRuz2TFHMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AExRuz2TFHMeta?.name ?? "blog-slug___de",
    path: _91slug_93AExRuz2TFHMeta?.path ?? "/de/blog/:slug()",
    meta: _91slug_93AExRuz2TFHMeta || {},
    alias: _91slug_93AExRuz2TFHMeta?.alias || [],
    redirect: _91slug_93AExRuz2TFHMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93TgSrJ9ZLXCMeta?.name ?? "blog-category-slug___en-US",
    path: _91slug_93TgSrJ9ZLXCMeta?.path ?? "/blog/category/:slug()",
    meta: _91slug_93TgSrJ9ZLXCMeta || {},
    alias: _91slug_93TgSrJ9ZLXCMeta?.alias || [],
    redirect: _91slug_93TgSrJ9ZLXCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93TgSrJ9ZLXCMeta?.name ?? "blog-category-slug___ca",
    path: _91slug_93TgSrJ9ZLXCMeta?.path ?? "/ca/blog/category/:slug()",
    meta: _91slug_93TgSrJ9ZLXCMeta || {},
    alias: _91slug_93TgSrJ9ZLXCMeta?.alias || [],
    redirect: _91slug_93TgSrJ9ZLXCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93TgSrJ9ZLXCMeta?.name ?? "blog-category-slug___mx",
    path: _91slug_93TgSrJ9ZLXCMeta?.path ?? "/mx/blog/category/:slug()",
    meta: _91slug_93TgSrJ9ZLXCMeta || {},
    alias: _91slug_93TgSrJ9ZLXCMeta?.alias || [],
    redirect: _91slug_93TgSrJ9ZLXCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93TgSrJ9ZLXCMeta?.name ?? "blog-category-slug___uk",
    path: _91slug_93TgSrJ9ZLXCMeta?.path ?? "/uk/blog/category/:slug()",
    meta: _91slug_93TgSrJ9ZLXCMeta || {},
    alias: _91slug_93TgSrJ9ZLXCMeta?.alias || [],
    redirect: _91slug_93TgSrJ9ZLXCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93TgSrJ9ZLXCMeta?.name ?? "blog-category-slug___fr",
    path: _91slug_93TgSrJ9ZLXCMeta?.path ?? "/fr/blog/category/:slug()",
    meta: _91slug_93TgSrJ9ZLXCMeta || {},
    alias: _91slug_93TgSrJ9ZLXCMeta?.alias || [],
    redirect: _91slug_93TgSrJ9ZLXCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93TgSrJ9ZLXCMeta?.name ?? "blog-category-slug___de",
    path: _91slug_93TgSrJ9ZLXCMeta?.path ?? "/de/blog/category/:slug()",
    meta: _91slug_93TgSrJ9ZLXCMeta || {},
    alias: _91slug_93TgSrJ9ZLXCMeta?.alias || [],
    redirect: _91slug_93TgSrJ9ZLXCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: index8vd1tqQ80EMeta?.name ?? "blog___en-US",
    path: index8vd1tqQ80EMeta?.path ?? "/blog",
    meta: index8vd1tqQ80EMeta || {},
    alias: index8vd1tqQ80EMeta?.alias || [],
    redirect: index8vd1tqQ80EMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: index8vd1tqQ80EMeta?.name ?? "blog___ca",
    path: index8vd1tqQ80EMeta?.path ?? "/ca/blog",
    meta: index8vd1tqQ80EMeta || {},
    alias: index8vd1tqQ80EMeta?.alias || [],
    redirect: index8vd1tqQ80EMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: index8vd1tqQ80EMeta?.name ?? "blog___mx",
    path: index8vd1tqQ80EMeta?.path ?? "/mx/blog",
    meta: index8vd1tqQ80EMeta || {},
    alias: index8vd1tqQ80EMeta?.alias || [],
    redirect: index8vd1tqQ80EMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: index8vd1tqQ80EMeta?.name ?? "blog___uk",
    path: index8vd1tqQ80EMeta?.path ?? "/uk/blog",
    meta: index8vd1tqQ80EMeta || {},
    alias: index8vd1tqQ80EMeta?.alias || [],
    redirect: index8vd1tqQ80EMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: index8vd1tqQ80EMeta?.name ?? "blog___fr",
    path: index8vd1tqQ80EMeta?.path ?? "/fr/blog",
    meta: index8vd1tqQ80EMeta || {},
    alias: index8vd1tqQ80EMeta?.alias || [],
    redirect: index8vd1tqQ80EMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: index8vd1tqQ80EMeta?.name ?? "blog___de",
    path: index8vd1tqQ80EMeta?.path ?? "/de/blog",
    meta: index8vd1tqQ80EMeta || {},
    alias: index8vd1tqQ80EMeta?.alias || [],
    redirect: index8vd1tqQ80EMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta?.name ?? "call-for-speakers-at-samsara-beyond___en-US",
    path: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta?.path ?? "/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta?.name ?? "call-for-speakers-at-samsara-beyond___ca",
    path: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta?.path ?? "/ca/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta?.name ?? "call-for-speakers-at-samsara-beyond___nl",
    path: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta?.path ?? "/nl/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta?.name ?? "call-for-speakers-at-samsara-beyond___uk",
    path: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta?.path ?? "/uk/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta?.name ?? "call-for-speakers-at-samsara-beyond___de",
    path: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta?.path ?? "/de/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta?.name ?? "call-for-speakers-at-samsara-beyond___mx",
    path: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta?.path ?? "/mx/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta?.name ?? "call-for-speakers-at-samsara-beyond___fr",
    path: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta?.path ?? "/fr/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta?.name ?? "call-for-speakers-at-samsara-beyond___fr-ca",
    path: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta?.path ?? "/fr-ca/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondTqN31PMimlMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wiDMqpU21qMeta?.name ?? "company-prefix-slug___en-US",
    path: _91slug_93wiDMqpU21qMeta?.path ?? "/company/:prefix()/:slug()",
    meta: _91slug_93wiDMqpU21qMeta || {},
    alias: _91slug_93wiDMqpU21qMeta?.alias || [],
    redirect: _91slug_93wiDMqpU21qMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wiDMqpU21qMeta?.name ?? "company-prefix-slug___ca",
    path: _91slug_93wiDMqpU21qMeta?.path ?? "/ca/company/:prefix()/:slug()",
    meta: _91slug_93wiDMqpU21qMeta || {},
    alias: _91slug_93wiDMqpU21qMeta?.alias || [],
    redirect: _91slug_93wiDMqpU21qMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wiDMqpU21qMeta?.name ?? "company-prefix-slug___nl",
    path: _91slug_93wiDMqpU21qMeta?.path ?? "/nl/company/:prefix()/:slug()",
    meta: _91slug_93wiDMqpU21qMeta || {},
    alias: _91slug_93wiDMqpU21qMeta?.alias || [],
    redirect: _91slug_93wiDMqpU21qMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wiDMqpU21qMeta?.name ?? "company-prefix-slug___uk",
    path: _91slug_93wiDMqpU21qMeta?.path ?? "/uk/company/:prefix()/:slug()",
    meta: _91slug_93wiDMqpU21qMeta || {},
    alias: _91slug_93wiDMqpU21qMeta?.alias || [],
    redirect: _91slug_93wiDMqpU21qMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wiDMqpU21qMeta?.name ?? "company-prefix-slug___de",
    path: _91slug_93wiDMqpU21qMeta?.path ?? "/de/company/:prefix()/:slug()",
    meta: _91slug_93wiDMqpU21qMeta || {},
    alias: _91slug_93wiDMqpU21qMeta?.alias || [],
    redirect: _91slug_93wiDMqpU21qMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wiDMqpU21qMeta?.name ?? "company-prefix-slug___mx",
    path: _91slug_93wiDMqpU21qMeta?.path ?? "/mx/company/:prefix()/:slug()",
    meta: _91slug_93wiDMqpU21qMeta || {},
    alias: _91slug_93wiDMqpU21qMeta?.alias || [],
    redirect: _91slug_93wiDMqpU21qMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wiDMqpU21qMeta?.name ?? "company-prefix-slug___fr",
    path: _91slug_93wiDMqpU21qMeta?.path ?? "/fr/company/:prefix()/:slug()",
    meta: _91slug_93wiDMqpU21qMeta || {},
    alias: _91slug_93wiDMqpU21qMeta?.alias || [],
    redirect: _91slug_93wiDMqpU21qMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wiDMqpU21qMeta?.name ?? "company-prefix-slug___fr-ca",
    path: _91slug_93wiDMqpU21qMeta?.path ?? "/fr-ca/company/:prefix()/:slug()",
    meta: _91slug_93wiDMqpU21qMeta || {},
    alias: _91slug_93wiDMqpU21qMeta?.alias || [],
    redirect: _91slug_93wiDMqpU21qMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FqnxcAq4dAMeta?.name ?? "company-slug___en-US",
    path: _91slug_93FqnxcAq4dAMeta?.path ?? "/company/:slug()",
    meta: _91slug_93FqnxcAq4dAMeta || {},
    alias: _91slug_93FqnxcAq4dAMeta?.alias || [],
    redirect: _91slug_93FqnxcAq4dAMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FqnxcAq4dAMeta?.name ?? "company-slug___ca",
    path: _91slug_93FqnxcAq4dAMeta?.path ?? "/ca/company/:slug()",
    meta: _91slug_93FqnxcAq4dAMeta || {},
    alias: _91slug_93FqnxcAq4dAMeta?.alias || [],
    redirect: _91slug_93FqnxcAq4dAMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FqnxcAq4dAMeta?.name ?? "company-slug___nl",
    path: _91slug_93FqnxcAq4dAMeta?.path ?? "/nl/company/:slug()",
    meta: _91slug_93FqnxcAq4dAMeta || {},
    alias: _91slug_93FqnxcAq4dAMeta?.alias || [],
    redirect: _91slug_93FqnxcAq4dAMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FqnxcAq4dAMeta?.name ?? "company-slug___uk",
    path: _91slug_93FqnxcAq4dAMeta?.path ?? "/uk/company/:slug()",
    meta: _91slug_93FqnxcAq4dAMeta || {},
    alias: _91slug_93FqnxcAq4dAMeta?.alias || [],
    redirect: _91slug_93FqnxcAq4dAMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FqnxcAq4dAMeta?.name ?? "company-slug___de",
    path: _91slug_93FqnxcAq4dAMeta?.path ?? "/de/company/:slug()",
    meta: _91slug_93FqnxcAq4dAMeta || {},
    alias: _91slug_93FqnxcAq4dAMeta?.alias || [],
    redirect: _91slug_93FqnxcAq4dAMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FqnxcAq4dAMeta?.name ?? "company-slug___mx",
    path: _91slug_93FqnxcAq4dAMeta?.path ?? "/mx/company/:slug()",
    meta: _91slug_93FqnxcAq4dAMeta || {},
    alias: _91slug_93FqnxcAq4dAMeta?.alias || [],
    redirect: _91slug_93FqnxcAq4dAMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FqnxcAq4dAMeta?.name ?? "company-slug___fr",
    path: _91slug_93FqnxcAq4dAMeta?.path ?? "/fr/company/:slug()",
    meta: _91slug_93FqnxcAq4dAMeta || {},
    alias: _91slug_93FqnxcAq4dAMeta?.alias || [],
    redirect: _91slug_93FqnxcAq4dAMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FqnxcAq4dAMeta?.name ?? "company-slug___fr-ca",
    path: _91slug_93FqnxcAq4dAMeta?.path ?? "/fr-ca/company/:slug()",
    meta: _91slug_93FqnxcAq4dAMeta || {},
    alias: _91slug_93FqnxcAq4dAMeta?.alias || [],
    redirect: _91slug_93FqnxcAq4dAMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: abouttAPkKYKhiTMeta?.name ?? "company-about___en-US",
    path: abouttAPkKYKhiTMeta?.path ?? "/company/about",
    meta: abouttAPkKYKhiTMeta || {},
    alias: abouttAPkKYKhiTMeta?.alias || [],
    redirect: abouttAPkKYKhiTMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: abouttAPkKYKhiTMeta?.name ?? "company-about___ca",
    path: abouttAPkKYKhiTMeta?.path ?? "/ca/company/about",
    meta: abouttAPkKYKhiTMeta || {},
    alias: abouttAPkKYKhiTMeta?.alias || [],
    redirect: abouttAPkKYKhiTMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: abouttAPkKYKhiTMeta?.name ?? "company-about___nl",
    path: abouttAPkKYKhiTMeta?.path ?? "/nl/company/about",
    meta: abouttAPkKYKhiTMeta || {},
    alias: abouttAPkKYKhiTMeta?.alias || [],
    redirect: abouttAPkKYKhiTMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: abouttAPkKYKhiTMeta?.name ?? "company-about___uk",
    path: abouttAPkKYKhiTMeta?.path ?? "/uk/company/about",
    meta: abouttAPkKYKhiTMeta || {},
    alias: abouttAPkKYKhiTMeta?.alias || [],
    redirect: abouttAPkKYKhiTMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: abouttAPkKYKhiTMeta?.name ?? "company-about___de",
    path: abouttAPkKYKhiTMeta?.path ?? "/de/company/about",
    meta: abouttAPkKYKhiTMeta || {},
    alias: abouttAPkKYKhiTMeta?.alias || [],
    redirect: abouttAPkKYKhiTMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: abouttAPkKYKhiTMeta?.name ?? "company-about___mx",
    path: abouttAPkKYKhiTMeta?.path ?? "/mx/company/about",
    meta: abouttAPkKYKhiTMeta || {},
    alias: abouttAPkKYKhiTMeta?.alias || [],
    redirect: abouttAPkKYKhiTMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: abouttAPkKYKhiTMeta?.name ?? "company-about___fr",
    path: abouttAPkKYKhiTMeta?.path ?? "/fr/company/about",
    meta: abouttAPkKYKhiTMeta || {},
    alias: abouttAPkKYKhiTMeta?.alias || [],
    redirect: abouttAPkKYKhiTMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: abouttAPkKYKhiTMeta?.name ?? "company-about___fr-ca",
    path: abouttAPkKYKhiTMeta?.path ?? "/fr-ca/company/about",
    meta: abouttAPkKYKhiTMeta || {},
    alias: abouttAPkKYKhiTMeta?.alias || [],
    redirect: abouttAPkKYKhiTMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: indexML68DbFBfrMeta?.name ?? "company-careers-benefits___en-US",
    path: indexML68DbFBfrMeta?.path ?? "/company/careers/benefits",
    meta: indexML68DbFBfrMeta || {},
    alias: indexML68DbFBfrMeta?.alias || [],
    redirect: indexML68DbFBfrMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexML68DbFBfrMeta?.name ?? "company-careers-benefits___ca",
    path: indexML68DbFBfrMeta?.path ?? "/ca/company/careers/benefits",
    meta: indexML68DbFBfrMeta || {},
    alias: indexML68DbFBfrMeta?.alias || [],
    redirect: indexML68DbFBfrMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexML68DbFBfrMeta?.name ?? "company-careers-benefits___nl",
    path: indexML68DbFBfrMeta?.path ?? "/nl/company/careers/benefits",
    meta: indexML68DbFBfrMeta || {},
    alias: indexML68DbFBfrMeta?.alias || [],
    redirect: indexML68DbFBfrMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexML68DbFBfrMeta?.name ?? "company-careers-benefits___uk",
    path: indexML68DbFBfrMeta?.path ?? "/uk/company/careers/benefits",
    meta: indexML68DbFBfrMeta || {},
    alias: indexML68DbFBfrMeta?.alias || [],
    redirect: indexML68DbFBfrMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexML68DbFBfrMeta?.name ?? "company-careers-benefits___de",
    path: indexML68DbFBfrMeta?.path ?? "/de/company/careers/benefits",
    meta: indexML68DbFBfrMeta || {},
    alias: indexML68DbFBfrMeta?.alias || [],
    redirect: indexML68DbFBfrMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexML68DbFBfrMeta?.name ?? "company-careers-benefits___mx",
    path: indexML68DbFBfrMeta?.path ?? "/mx/company/careers/benefits",
    meta: indexML68DbFBfrMeta || {},
    alias: indexML68DbFBfrMeta?.alias || [],
    redirect: indexML68DbFBfrMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexML68DbFBfrMeta?.name ?? "company-careers-benefits___fr",
    path: indexML68DbFBfrMeta?.path ?? "/fr/company/careers/benefits",
    meta: indexML68DbFBfrMeta || {},
    alias: indexML68DbFBfrMeta?.alias || [],
    redirect: indexML68DbFBfrMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexML68DbFBfrMeta?.name ?? "company-careers-benefits___fr-ca",
    path: indexML68DbFBfrMeta?.path ?? "/fr-ca/company/careers/benefits",
    meta: indexML68DbFBfrMeta || {},
    alias: indexML68DbFBfrMeta?.alias || [],
    redirect: indexML68DbFBfrMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productCSqXLl08iDMeta?.name ?? "company-careers-departments-engineering-and-product___en-US",
    path: engineering_45and_45productCSqXLl08iDMeta?.path ?? "/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productCSqXLl08iDMeta || {},
    alias: engineering_45and_45productCSqXLl08iDMeta?.alias || [],
    redirect: engineering_45and_45productCSqXLl08iDMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productCSqXLl08iDMeta?.name ?? "company-careers-departments-engineering-and-product___ca",
    path: engineering_45and_45productCSqXLl08iDMeta?.path ?? "/ca/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productCSqXLl08iDMeta || {},
    alias: engineering_45and_45productCSqXLl08iDMeta?.alias || [],
    redirect: engineering_45and_45productCSqXLl08iDMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productCSqXLl08iDMeta?.name ?? "company-careers-departments-engineering-and-product___nl",
    path: engineering_45and_45productCSqXLl08iDMeta?.path ?? "/nl/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productCSqXLl08iDMeta || {},
    alias: engineering_45and_45productCSqXLl08iDMeta?.alias || [],
    redirect: engineering_45and_45productCSqXLl08iDMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productCSqXLl08iDMeta?.name ?? "company-careers-departments-engineering-and-product___uk",
    path: engineering_45and_45productCSqXLl08iDMeta?.path ?? "/uk/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productCSqXLl08iDMeta || {},
    alias: engineering_45and_45productCSqXLl08iDMeta?.alias || [],
    redirect: engineering_45and_45productCSqXLl08iDMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productCSqXLl08iDMeta?.name ?? "company-careers-departments-engineering-and-product___de",
    path: engineering_45and_45productCSqXLl08iDMeta?.path ?? "/de/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productCSqXLl08iDMeta || {},
    alias: engineering_45and_45productCSqXLl08iDMeta?.alias || [],
    redirect: engineering_45and_45productCSqXLl08iDMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productCSqXLl08iDMeta?.name ?? "company-careers-departments-engineering-and-product___mx",
    path: engineering_45and_45productCSqXLl08iDMeta?.path ?? "/mx/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productCSqXLl08iDMeta || {},
    alias: engineering_45and_45productCSqXLl08iDMeta?.alias || [],
    redirect: engineering_45and_45productCSqXLl08iDMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productCSqXLl08iDMeta?.name ?? "company-careers-departments-engineering-and-product___fr",
    path: engineering_45and_45productCSqXLl08iDMeta?.path ?? "/fr/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productCSqXLl08iDMeta || {},
    alias: engineering_45and_45productCSqXLl08iDMeta?.alias || [],
    redirect: engineering_45and_45productCSqXLl08iDMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productCSqXLl08iDMeta?.name ?? "company-careers-departments-engineering-and-product___fr-ca",
    path: engineering_45and_45productCSqXLl08iDMeta?.path ?? "/fr-ca/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productCSqXLl08iDMeta || {},
    alias: engineering_45and_45productCSqXLl08iDMeta?.alias || [],
    redirect: engineering_45and_45productCSqXLl08iDMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: index438BPMyKZGMeta?.name ?? "company-careers-departments___en-US",
    path: index438BPMyKZGMeta?.path ?? "/company/careers/departments",
    meta: index438BPMyKZGMeta || {},
    alias: index438BPMyKZGMeta?.alias || [],
    redirect: index438BPMyKZGMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: index438BPMyKZGMeta?.name ?? "company-careers-departments___ca",
    path: index438BPMyKZGMeta?.path ?? "/ca/company/careers/departments",
    meta: index438BPMyKZGMeta || {},
    alias: index438BPMyKZGMeta?.alias || [],
    redirect: index438BPMyKZGMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: index438BPMyKZGMeta?.name ?? "company-careers-departments___nl",
    path: index438BPMyKZGMeta?.path ?? "/nl/company/careers/departments",
    meta: index438BPMyKZGMeta || {},
    alias: index438BPMyKZGMeta?.alias || [],
    redirect: index438BPMyKZGMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: index438BPMyKZGMeta?.name ?? "company-careers-departments___uk",
    path: index438BPMyKZGMeta?.path ?? "/uk/company/careers/departments",
    meta: index438BPMyKZGMeta || {},
    alias: index438BPMyKZGMeta?.alias || [],
    redirect: index438BPMyKZGMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: index438BPMyKZGMeta?.name ?? "company-careers-departments___de",
    path: index438BPMyKZGMeta?.path ?? "/de/company/careers/departments",
    meta: index438BPMyKZGMeta || {},
    alias: index438BPMyKZGMeta?.alias || [],
    redirect: index438BPMyKZGMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: index438BPMyKZGMeta?.name ?? "company-careers-departments___mx",
    path: index438BPMyKZGMeta?.path ?? "/mx/company/careers/departments",
    meta: index438BPMyKZGMeta || {},
    alias: index438BPMyKZGMeta?.alias || [],
    redirect: index438BPMyKZGMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: index438BPMyKZGMeta?.name ?? "company-careers-departments___fr",
    path: index438BPMyKZGMeta?.path ?? "/fr/company/careers/departments",
    meta: index438BPMyKZGMeta || {},
    alias: index438BPMyKZGMeta?.alias || [],
    redirect: index438BPMyKZGMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: index438BPMyKZGMeta?.name ?? "company-careers-departments___fr-ca",
    path: index438BPMyKZGMeta?.path ?? "/fr-ca/company/careers/departments",
    meta: index438BPMyKZGMeta || {},
    alias: index438BPMyKZGMeta?.alias || [],
    redirect: index438BPMyKZGMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: salesUJBOw3vYzCMeta?.name ?? "company-careers-departments-sales___en-US",
    path: salesUJBOw3vYzCMeta?.path ?? "/company/careers/departments/sales",
    meta: salesUJBOw3vYzCMeta || {},
    alias: salesUJBOw3vYzCMeta?.alias || [],
    redirect: salesUJBOw3vYzCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesUJBOw3vYzCMeta?.name ?? "company-careers-departments-sales___ca",
    path: salesUJBOw3vYzCMeta?.path ?? "/ca/company/careers/departments/sales",
    meta: salesUJBOw3vYzCMeta || {},
    alias: salesUJBOw3vYzCMeta?.alias || [],
    redirect: salesUJBOw3vYzCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesUJBOw3vYzCMeta?.name ?? "company-careers-departments-sales___nl",
    path: salesUJBOw3vYzCMeta?.path ?? "/nl/company/careers/departments/sales",
    meta: salesUJBOw3vYzCMeta || {},
    alias: salesUJBOw3vYzCMeta?.alias || [],
    redirect: salesUJBOw3vYzCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesUJBOw3vYzCMeta?.name ?? "company-careers-departments-sales___uk",
    path: salesUJBOw3vYzCMeta?.path ?? "/uk/company/careers/departments/sales",
    meta: salesUJBOw3vYzCMeta || {},
    alias: salesUJBOw3vYzCMeta?.alias || [],
    redirect: salesUJBOw3vYzCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesUJBOw3vYzCMeta?.name ?? "company-careers-departments-sales___de",
    path: salesUJBOw3vYzCMeta?.path ?? "/de/company/careers/departments/sales",
    meta: salesUJBOw3vYzCMeta || {},
    alias: salesUJBOw3vYzCMeta?.alias || [],
    redirect: salesUJBOw3vYzCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesUJBOw3vYzCMeta?.name ?? "company-careers-departments-sales___mx",
    path: salesUJBOw3vYzCMeta?.path ?? "/mx/company/careers/departments/sales",
    meta: salesUJBOw3vYzCMeta || {},
    alias: salesUJBOw3vYzCMeta?.alias || [],
    redirect: salesUJBOw3vYzCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesUJBOw3vYzCMeta?.name ?? "company-careers-departments-sales___fr",
    path: salesUJBOw3vYzCMeta?.path ?? "/fr/company/careers/departments/sales",
    meta: salesUJBOw3vYzCMeta || {},
    alias: salesUJBOw3vYzCMeta?.alias || [],
    redirect: salesUJBOw3vYzCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesUJBOw3vYzCMeta?.name ?? "company-careers-departments-sales___fr-ca",
    path: salesUJBOw3vYzCMeta?.path ?? "/fr-ca/company/careers/departments/sales",
    meta: salesUJBOw3vYzCMeta || {},
    alias: salesUJBOw3vYzCMeta?.alias || [],
    redirect: salesUJBOw3vYzCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archive2K8B8A0UfBMeta?.name ?? "company-careers-emerging-talent-archive___en-US",
    path: emerging_45talent_45archive2K8B8A0UfBMeta?.path ?? "/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archive2K8B8A0UfBMeta || {},
    alias: emerging_45talent_45archive2K8B8A0UfBMeta?.alias || [],
    redirect: emerging_45talent_45archive2K8B8A0UfBMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archive2K8B8A0UfBMeta?.name ?? "company-careers-emerging-talent-archive___ca",
    path: emerging_45talent_45archive2K8B8A0UfBMeta?.path ?? "/ca/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archive2K8B8A0UfBMeta || {},
    alias: emerging_45talent_45archive2K8B8A0UfBMeta?.alias || [],
    redirect: emerging_45talent_45archive2K8B8A0UfBMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archive2K8B8A0UfBMeta?.name ?? "company-careers-emerging-talent-archive___nl",
    path: emerging_45talent_45archive2K8B8A0UfBMeta?.path ?? "/nl/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archive2K8B8A0UfBMeta || {},
    alias: emerging_45talent_45archive2K8B8A0UfBMeta?.alias || [],
    redirect: emerging_45talent_45archive2K8B8A0UfBMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archive2K8B8A0UfBMeta?.name ?? "company-careers-emerging-talent-archive___uk",
    path: emerging_45talent_45archive2K8B8A0UfBMeta?.path ?? "/uk/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archive2K8B8A0UfBMeta || {},
    alias: emerging_45talent_45archive2K8B8A0UfBMeta?.alias || [],
    redirect: emerging_45talent_45archive2K8B8A0UfBMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archive2K8B8A0UfBMeta?.name ?? "company-careers-emerging-talent-archive___de",
    path: emerging_45talent_45archive2K8B8A0UfBMeta?.path ?? "/de/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archive2K8B8A0UfBMeta || {},
    alias: emerging_45talent_45archive2K8B8A0UfBMeta?.alias || [],
    redirect: emerging_45talent_45archive2K8B8A0UfBMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archive2K8B8A0UfBMeta?.name ?? "company-careers-emerging-talent-archive___mx",
    path: emerging_45talent_45archive2K8B8A0UfBMeta?.path ?? "/mx/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archive2K8B8A0UfBMeta || {},
    alias: emerging_45talent_45archive2K8B8A0UfBMeta?.alias || [],
    redirect: emerging_45talent_45archive2K8B8A0UfBMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archive2K8B8A0UfBMeta?.name ?? "company-careers-emerging-talent-archive___fr",
    path: emerging_45talent_45archive2K8B8A0UfBMeta?.path ?? "/fr/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archive2K8B8A0UfBMeta || {},
    alias: emerging_45talent_45archive2K8B8A0UfBMeta?.alias || [],
    redirect: emerging_45talent_45archive2K8B8A0UfBMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archive2K8B8A0UfBMeta?.name ?? "company-careers-emerging-talent-archive___fr-ca",
    path: emerging_45talent_45archive2K8B8A0UfBMeta?.path ?? "/fr-ca/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archive2K8B8A0UfBMeta || {},
    alias: emerging_45talent_45archive2K8B8A0UfBMeta?.alias || [],
    redirect: emerging_45talent_45archive2K8B8A0UfBMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: indexqhowhWcUgrMeta?.name ?? "company-careers___en-US",
    path: indexqhowhWcUgrMeta?.path ?? "/company/careers",
    meta: indexqhowhWcUgrMeta || {},
    alias: indexqhowhWcUgrMeta?.alias || [],
    redirect: indexqhowhWcUgrMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexqhowhWcUgrMeta?.name ?? "company-careers___ca",
    path: indexqhowhWcUgrMeta?.path ?? "/ca/company/careers",
    meta: indexqhowhWcUgrMeta || {},
    alias: indexqhowhWcUgrMeta?.alias || [],
    redirect: indexqhowhWcUgrMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexqhowhWcUgrMeta?.name ?? "company-careers___nl",
    path: indexqhowhWcUgrMeta?.path ?? "/nl/company/careers",
    meta: indexqhowhWcUgrMeta || {},
    alias: indexqhowhWcUgrMeta?.alias || [],
    redirect: indexqhowhWcUgrMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexqhowhWcUgrMeta?.name ?? "company-careers___uk",
    path: indexqhowhWcUgrMeta?.path ?? "/uk/company/careers",
    meta: indexqhowhWcUgrMeta || {},
    alias: indexqhowhWcUgrMeta?.alias || [],
    redirect: indexqhowhWcUgrMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexqhowhWcUgrMeta?.name ?? "company-careers___de",
    path: indexqhowhWcUgrMeta?.path ?? "/de/company/careers",
    meta: indexqhowhWcUgrMeta || {},
    alias: indexqhowhWcUgrMeta?.alias || [],
    redirect: indexqhowhWcUgrMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexqhowhWcUgrMeta?.name ?? "company-careers___mx",
    path: indexqhowhWcUgrMeta?.path ?? "/mx/company/careers",
    meta: indexqhowhWcUgrMeta || {},
    alias: indexqhowhWcUgrMeta?.alias || [],
    redirect: indexqhowhWcUgrMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexqhowhWcUgrMeta?.name ?? "company-careers___fr",
    path: indexqhowhWcUgrMeta?.path ?? "/fr/company/careers",
    meta: indexqhowhWcUgrMeta || {},
    alias: indexqhowhWcUgrMeta?.alias || [],
    redirect: indexqhowhWcUgrMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexqhowhWcUgrMeta?.name ?? "company-careers___fr-ca",
    path: indexqhowhWcUgrMeta?.path ?? "/fr-ca/company/careers",
    meta: indexqhowhWcUgrMeta || {},
    alias: indexqhowhWcUgrMeta?.alias || [],
    redirect: indexqhowhWcUgrMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexOOPcaLeoaXMeta?.name ?? "company-careers-locations___en-US",
    path: indexOOPcaLeoaXMeta?.path ?? "/company/careers/locations",
    meta: indexOOPcaLeoaXMeta || {},
    alias: indexOOPcaLeoaXMeta?.alias || [],
    redirect: indexOOPcaLeoaXMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexOOPcaLeoaXMeta?.name ?? "company-careers-locations___ca",
    path: indexOOPcaLeoaXMeta?.path ?? "/ca/company/careers/locations",
    meta: indexOOPcaLeoaXMeta || {},
    alias: indexOOPcaLeoaXMeta?.alias || [],
    redirect: indexOOPcaLeoaXMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexOOPcaLeoaXMeta?.name ?? "company-careers-locations___nl",
    path: indexOOPcaLeoaXMeta?.path ?? "/nl/company/careers/locations",
    meta: indexOOPcaLeoaXMeta || {},
    alias: indexOOPcaLeoaXMeta?.alias || [],
    redirect: indexOOPcaLeoaXMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexOOPcaLeoaXMeta?.name ?? "company-careers-locations___uk",
    path: indexOOPcaLeoaXMeta?.path ?? "/uk/company/careers/locations",
    meta: indexOOPcaLeoaXMeta || {},
    alias: indexOOPcaLeoaXMeta?.alias || [],
    redirect: indexOOPcaLeoaXMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexOOPcaLeoaXMeta?.name ?? "company-careers-locations___de",
    path: indexOOPcaLeoaXMeta?.path ?? "/de/company/careers/locations",
    meta: indexOOPcaLeoaXMeta || {},
    alias: indexOOPcaLeoaXMeta?.alias || [],
    redirect: indexOOPcaLeoaXMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexOOPcaLeoaXMeta?.name ?? "company-careers-locations___mx",
    path: indexOOPcaLeoaXMeta?.path ?? "/mx/company/careers/locations",
    meta: indexOOPcaLeoaXMeta || {},
    alias: indexOOPcaLeoaXMeta?.alias || [],
    redirect: indexOOPcaLeoaXMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexOOPcaLeoaXMeta?.name ?? "company-careers-locations___fr",
    path: indexOOPcaLeoaXMeta?.path ?? "/fr/company/careers/locations",
    meta: indexOOPcaLeoaXMeta || {},
    alias: indexOOPcaLeoaXMeta?.alias || [],
    redirect: indexOOPcaLeoaXMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexOOPcaLeoaXMeta?.name ?? "company-careers-locations___fr-ca",
    path: indexOOPcaLeoaXMeta?.path ?? "/fr-ca/company/careers/locations",
    meta: indexOOPcaLeoaXMeta || {},
    alias: indexOOPcaLeoaXMeta?.alias || [],
    redirect: indexOOPcaLeoaXMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tgUBVzcGz6Meta?.name ?? "company-careers-roles-slug___en-US",
    path: _91slug_93tgUBVzcGz6Meta?.path ?? "/company/careers/roles/:slug()",
    meta: _91slug_93tgUBVzcGz6Meta || {},
    alias: _91slug_93tgUBVzcGz6Meta?.alias || [],
    redirect: _91slug_93tgUBVzcGz6Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tgUBVzcGz6Meta?.name ?? "company-careers-roles-slug___ca",
    path: _91slug_93tgUBVzcGz6Meta?.path ?? "/ca/company/careers/roles/:slug()",
    meta: _91slug_93tgUBVzcGz6Meta || {},
    alias: _91slug_93tgUBVzcGz6Meta?.alias || [],
    redirect: _91slug_93tgUBVzcGz6Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tgUBVzcGz6Meta?.name ?? "company-careers-roles-slug___nl",
    path: _91slug_93tgUBVzcGz6Meta?.path ?? "/nl/company/careers/roles/:slug()",
    meta: _91slug_93tgUBVzcGz6Meta || {},
    alias: _91slug_93tgUBVzcGz6Meta?.alias || [],
    redirect: _91slug_93tgUBVzcGz6Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tgUBVzcGz6Meta?.name ?? "company-careers-roles-slug___uk",
    path: _91slug_93tgUBVzcGz6Meta?.path ?? "/uk/company/careers/roles/:slug()",
    meta: _91slug_93tgUBVzcGz6Meta || {},
    alias: _91slug_93tgUBVzcGz6Meta?.alias || [],
    redirect: _91slug_93tgUBVzcGz6Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tgUBVzcGz6Meta?.name ?? "company-careers-roles-slug___de",
    path: _91slug_93tgUBVzcGz6Meta?.path ?? "/de/company/careers/roles/:slug()",
    meta: _91slug_93tgUBVzcGz6Meta || {},
    alias: _91slug_93tgUBVzcGz6Meta?.alias || [],
    redirect: _91slug_93tgUBVzcGz6Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tgUBVzcGz6Meta?.name ?? "company-careers-roles-slug___mx",
    path: _91slug_93tgUBVzcGz6Meta?.path ?? "/mx/company/careers/roles/:slug()",
    meta: _91slug_93tgUBVzcGz6Meta || {},
    alias: _91slug_93tgUBVzcGz6Meta?.alias || [],
    redirect: _91slug_93tgUBVzcGz6Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tgUBVzcGz6Meta?.name ?? "company-careers-roles-slug___fr",
    path: _91slug_93tgUBVzcGz6Meta?.path ?? "/fr/company/careers/roles/:slug()",
    meta: _91slug_93tgUBVzcGz6Meta || {},
    alias: _91slug_93tgUBVzcGz6Meta?.alias || [],
    redirect: _91slug_93tgUBVzcGz6Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tgUBVzcGz6Meta?.name ?? "company-careers-roles-slug___fr-ca",
    path: _91slug_93tgUBVzcGz6Meta?.path ?? "/fr-ca/company/careers/roles/:slug()",
    meta: _91slug_93tgUBVzcGz6Meta || {},
    alias: _91slug_93tgUBVzcGz6Meta?.alias || [],
    redirect: _91slug_93tgUBVzcGz6Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexMoCEFCW2OZMeta?.name ?? "company-careers-roles___en-US",
    path: indexMoCEFCW2OZMeta?.path ?? "/company/careers/roles",
    meta: indexMoCEFCW2OZMeta || {},
    alias: indexMoCEFCW2OZMeta?.alias || [],
    redirect: indexMoCEFCW2OZMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexMoCEFCW2OZMeta?.name ?? "company-careers-roles___ca",
    path: indexMoCEFCW2OZMeta?.path ?? "/ca/company/careers/roles",
    meta: indexMoCEFCW2OZMeta || {},
    alias: indexMoCEFCW2OZMeta?.alias || [],
    redirect: indexMoCEFCW2OZMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexMoCEFCW2OZMeta?.name ?? "company-careers-roles___nl",
    path: indexMoCEFCW2OZMeta?.path ?? "/nl/company/careers/roles",
    meta: indexMoCEFCW2OZMeta || {},
    alias: indexMoCEFCW2OZMeta?.alias || [],
    redirect: indexMoCEFCW2OZMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexMoCEFCW2OZMeta?.name ?? "company-careers-roles___uk",
    path: indexMoCEFCW2OZMeta?.path ?? "/uk/company/careers/roles",
    meta: indexMoCEFCW2OZMeta || {},
    alias: indexMoCEFCW2OZMeta?.alias || [],
    redirect: indexMoCEFCW2OZMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexMoCEFCW2OZMeta?.name ?? "company-careers-roles___de",
    path: indexMoCEFCW2OZMeta?.path ?? "/de/company/careers/roles",
    meta: indexMoCEFCW2OZMeta || {},
    alias: indexMoCEFCW2OZMeta?.alias || [],
    redirect: indexMoCEFCW2OZMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexMoCEFCW2OZMeta?.name ?? "company-careers-roles___mx",
    path: indexMoCEFCW2OZMeta?.path ?? "/mx/company/careers/roles",
    meta: indexMoCEFCW2OZMeta || {},
    alias: indexMoCEFCW2OZMeta?.alias || [],
    redirect: indexMoCEFCW2OZMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexMoCEFCW2OZMeta?.name ?? "company-careers-roles___fr",
    path: indexMoCEFCW2OZMeta?.path ?? "/fr/company/careers/roles",
    meta: indexMoCEFCW2OZMeta || {},
    alias: indexMoCEFCW2OZMeta?.alias || [],
    redirect: indexMoCEFCW2OZMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexMoCEFCW2OZMeta?.name ?? "company-careers-roles___fr-ca",
    path: indexMoCEFCW2OZMeta?.path ?? "/fr-ca/company/careers/roles",
    meta: indexMoCEFCW2OZMeta || {},
    alias: indexMoCEFCW2OZMeta?.alias || [],
    redirect: indexMoCEFCW2OZMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: contactmSJ7exPVuwMeta?.name ?? "company-contact___en-US",
    path: contactmSJ7exPVuwMeta?.path ?? "/company/contact",
    meta: contactmSJ7exPVuwMeta || {},
    alias: contactmSJ7exPVuwMeta?.alias || [],
    redirect: contactmSJ7exPVuwMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contactmSJ7exPVuwMeta?.name ?? "company-contact___ca",
    path: contactmSJ7exPVuwMeta?.path ?? "/ca/company/contact",
    meta: contactmSJ7exPVuwMeta || {},
    alias: contactmSJ7exPVuwMeta?.alias || [],
    redirect: contactmSJ7exPVuwMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contactmSJ7exPVuwMeta?.name ?? "company-contact___nl",
    path: contactmSJ7exPVuwMeta?.path ?? "/nl/company/contact",
    meta: contactmSJ7exPVuwMeta || {},
    alias: contactmSJ7exPVuwMeta?.alias || [],
    redirect: contactmSJ7exPVuwMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contactmSJ7exPVuwMeta?.name ?? "company-contact___uk",
    path: contactmSJ7exPVuwMeta?.path ?? "/uk/company/contact",
    meta: contactmSJ7exPVuwMeta || {},
    alias: contactmSJ7exPVuwMeta?.alias || [],
    redirect: contactmSJ7exPVuwMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contactmSJ7exPVuwMeta?.name ?? "company-contact___de",
    path: contactmSJ7exPVuwMeta?.path ?? "/de/company/contact",
    meta: contactmSJ7exPVuwMeta || {},
    alias: contactmSJ7exPVuwMeta?.alias || [],
    redirect: contactmSJ7exPVuwMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contactmSJ7exPVuwMeta?.name ?? "company-contact___mx",
    path: contactmSJ7exPVuwMeta?.path ?? "/mx/company/contact",
    meta: contactmSJ7exPVuwMeta || {},
    alias: contactmSJ7exPVuwMeta?.alias || [],
    redirect: contactmSJ7exPVuwMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contactmSJ7exPVuwMeta?.name ?? "company-contact___fr",
    path: contactmSJ7exPVuwMeta?.path ?? "/fr/company/contact",
    meta: contactmSJ7exPVuwMeta || {},
    alias: contactmSJ7exPVuwMeta?.alias || [],
    redirect: contactmSJ7exPVuwMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contactmSJ7exPVuwMeta?.name ?? "company-contact___fr-ca",
    path: contactmSJ7exPVuwMeta?.path ?? "/fr-ca/company/contact",
    meta: contactmSJ7exPVuwMeta || {},
    alias: contactmSJ7exPVuwMeta?.alias || [],
    redirect: contactmSJ7exPVuwMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: diversityx3gdFflCsAMeta?.name ?? "company-diversity___en-US",
    path: diversityx3gdFflCsAMeta?.path ?? "/company/diversity",
    meta: diversityx3gdFflCsAMeta || {},
    alias: diversityx3gdFflCsAMeta?.alias || [],
    redirect: diversityx3gdFflCsAMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityx3gdFflCsAMeta?.name ?? "company-diversity___ca",
    path: diversityx3gdFflCsAMeta?.path ?? "/ca/company/diversity",
    meta: diversityx3gdFflCsAMeta || {},
    alias: diversityx3gdFflCsAMeta?.alias || [],
    redirect: diversityx3gdFflCsAMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityx3gdFflCsAMeta?.name ?? "company-diversity___nl",
    path: diversityx3gdFflCsAMeta?.path ?? "/nl/company/diversity",
    meta: diversityx3gdFflCsAMeta || {},
    alias: diversityx3gdFflCsAMeta?.alias || [],
    redirect: diversityx3gdFflCsAMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityx3gdFflCsAMeta?.name ?? "company-diversity___uk",
    path: diversityx3gdFflCsAMeta?.path ?? "/uk/company/diversity",
    meta: diversityx3gdFflCsAMeta || {},
    alias: diversityx3gdFflCsAMeta?.alias || [],
    redirect: diversityx3gdFflCsAMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityx3gdFflCsAMeta?.name ?? "company-diversity___de",
    path: diversityx3gdFflCsAMeta?.path ?? "/de/company/diversity",
    meta: diversityx3gdFflCsAMeta || {},
    alias: diversityx3gdFflCsAMeta?.alias || [],
    redirect: diversityx3gdFflCsAMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityx3gdFflCsAMeta?.name ?? "company-diversity___mx",
    path: diversityx3gdFflCsAMeta?.path ?? "/mx/company/diversity",
    meta: diversityx3gdFflCsAMeta || {},
    alias: diversityx3gdFflCsAMeta?.alias || [],
    redirect: diversityx3gdFflCsAMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityx3gdFflCsAMeta?.name ?? "company-diversity___fr",
    path: diversityx3gdFflCsAMeta?.path ?? "/fr/company/diversity",
    meta: diversityx3gdFflCsAMeta || {},
    alias: diversityx3gdFflCsAMeta?.alias || [],
    redirect: diversityx3gdFflCsAMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityx3gdFflCsAMeta?.name ?? "company-diversity___fr-ca",
    path: diversityx3gdFflCsAMeta?.path ?? "/fr-ca/company/diversity",
    meta: diversityx3gdFflCsAMeta || {},
    alias: diversityx3gdFflCsAMeta?.alias || [],
    redirect: diversityx3gdFflCsAMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: index6BIYoA8fdTMeta?.name ?? "company___en-US",
    path: index6BIYoA8fdTMeta?.path ?? "/company",
    meta: index6BIYoA8fdTMeta || {},
    alias: index6BIYoA8fdTMeta?.alias || [],
    redirect: index6BIYoA8fdTMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index6BIYoA8fdTMeta?.name ?? "company___ca",
    path: index6BIYoA8fdTMeta?.path ?? "/ca/company",
    meta: index6BIYoA8fdTMeta || {},
    alias: index6BIYoA8fdTMeta?.alias || [],
    redirect: index6BIYoA8fdTMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index6BIYoA8fdTMeta?.name ?? "company___nl",
    path: index6BIYoA8fdTMeta?.path ?? "/nl/company",
    meta: index6BIYoA8fdTMeta || {},
    alias: index6BIYoA8fdTMeta?.alias || [],
    redirect: index6BIYoA8fdTMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index6BIYoA8fdTMeta?.name ?? "company___uk",
    path: index6BIYoA8fdTMeta?.path ?? "/uk/company",
    meta: index6BIYoA8fdTMeta || {},
    alias: index6BIYoA8fdTMeta?.alias || [],
    redirect: index6BIYoA8fdTMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index6BIYoA8fdTMeta?.name ?? "company___de",
    path: index6BIYoA8fdTMeta?.path ?? "/de/company",
    meta: index6BIYoA8fdTMeta || {},
    alias: index6BIYoA8fdTMeta?.alias || [],
    redirect: index6BIYoA8fdTMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index6BIYoA8fdTMeta?.name ?? "company___mx",
    path: index6BIYoA8fdTMeta?.path ?? "/mx/company",
    meta: index6BIYoA8fdTMeta || {},
    alias: index6BIYoA8fdTMeta?.alias || [],
    redirect: index6BIYoA8fdTMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index6BIYoA8fdTMeta?.name ?? "company___fr",
    path: index6BIYoA8fdTMeta?.path ?? "/fr/company",
    meta: index6BIYoA8fdTMeta || {},
    alias: index6BIYoA8fdTMeta?.alias || [],
    redirect: index6BIYoA8fdTMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index6BIYoA8fdTMeta?.name ?? "company___fr-ca",
    path: index6BIYoA8fdTMeta?.path ?? "/fr-ca/company",
    meta: index6BIYoA8fdTMeta || {},
    alias: index6BIYoA8fdTMeta?.alias || [],
    redirect: index6BIYoA8fdTMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: efficiency4sReWqrMTqMeta?.name ?? "company-mission-efficiency___en-US",
    path: efficiency4sReWqrMTqMeta?.path ?? "/company/mission/efficiency",
    meta: efficiency4sReWqrMTqMeta || {},
    alias: efficiency4sReWqrMTqMeta?.alias || [],
    redirect: efficiency4sReWqrMTqMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiency4sReWqrMTqMeta?.name ?? "company-mission-efficiency___ca",
    path: efficiency4sReWqrMTqMeta?.path ?? "/ca/company/mission/efficiency",
    meta: efficiency4sReWqrMTqMeta || {},
    alias: efficiency4sReWqrMTqMeta?.alias || [],
    redirect: efficiency4sReWqrMTqMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiency4sReWqrMTqMeta?.name ?? "company-mission-efficiency___nl",
    path: efficiency4sReWqrMTqMeta?.path ?? "/nl/company/mission/efficiency",
    meta: efficiency4sReWqrMTqMeta || {},
    alias: efficiency4sReWqrMTqMeta?.alias || [],
    redirect: efficiency4sReWqrMTqMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiency4sReWqrMTqMeta?.name ?? "company-mission-efficiency___uk",
    path: efficiency4sReWqrMTqMeta?.path ?? "/uk/company/mission/efficiency",
    meta: efficiency4sReWqrMTqMeta || {},
    alias: efficiency4sReWqrMTqMeta?.alias || [],
    redirect: efficiency4sReWqrMTqMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiency4sReWqrMTqMeta?.name ?? "company-mission-efficiency___de",
    path: efficiency4sReWqrMTqMeta?.path ?? "/de/company/mission/efficiency",
    meta: efficiency4sReWqrMTqMeta || {},
    alias: efficiency4sReWqrMTqMeta?.alias || [],
    redirect: efficiency4sReWqrMTqMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiency4sReWqrMTqMeta?.name ?? "company-mission-efficiency___mx",
    path: efficiency4sReWqrMTqMeta?.path ?? "/mx/company/mission/efficiency",
    meta: efficiency4sReWqrMTqMeta || {},
    alias: efficiency4sReWqrMTqMeta?.alias || [],
    redirect: efficiency4sReWqrMTqMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiency4sReWqrMTqMeta?.name ?? "company-mission-efficiency___fr",
    path: efficiency4sReWqrMTqMeta?.path ?? "/fr/company/mission/efficiency",
    meta: efficiency4sReWqrMTqMeta || {},
    alias: efficiency4sReWqrMTqMeta?.alias || [],
    redirect: efficiency4sReWqrMTqMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiency4sReWqrMTqMeta?.name ?? "company-mission-efficiency___fr-ca",
    path: efficiency4sReWqrMTqMeta?.path ?? "/fr-ca/company/mission/efficiency",
    meta: efficiency4sReWqrMTqMeta || {},
    alias: efficiency4sReWqrMTqMeta?.alias || [],
    redirect: efficiency4sReWqrMTqMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: safetygu2yWdlisZMeta?.name ?? "company-mission-safety___en-US",
    path: safetygu2yWdlisZMeta?.path ?? "/company/mission/safety",
    meta: safetygu2yWdlisZMeta || {},
    alias: safetygu2yWdlisZMeta?.alias || [],
    redirect: safetygu2yWdlisZMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetygu2yWdlisZMeta?.name ?? "company-mission-safety___ca",
    path: safetygu2yWdlisZMeta?.path ?? "/ca/company/mission/safety",
    meta: safetygu2yWdlisZMeta || {},
    alias: safetygu2yWdlisZMeta?.alias || [],
    redirect: safetygu2yWdlisZMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetygu2yWdlisZMeta?.name ?? "company-mission-safety___nl",
    path: safetygu2yWdlisZMeta?.path ?? "/nl/company/mission/safety",
    meta: safetygu2yWdlisZMeta || {},
    alias: safetygu2yWdlisZMeta?.alias || [],
    redirect: safetygu2yWdlisZMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetygu2yWdlisZMeta?.name ?? "company-mission-safety___uk",
    path: safetygu2yWdlisZMeta?.path ?? "/uk/company/mission/safety",
    meta: safetygu2yWdlisZMeta || {},
    alias: safetygu2yWdlisZMeta?.alias || [],
    redirect: safetygu2yWdlisZMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetygu2yWdlisZMeta?.name ?? "company-mission-safety___de",
    path: safetygu2yWdlisZMeta?.path ?? "/de/company/mission/safety",
    meta: safetygu2yWdlisZMeta || {},
    alias: safetygu2yWdlisZMeta?.alias || [],
    redirect: safetygu2yWdlisZMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetygu2yWdlisZMeta?.name ?? "company-mission-safety___mx",
    path: safetygu2yWdlisZMeta?.path ?? "/mx/company/mission/safety",
    meta: safetygu2yWdlisZMeta || {},
    alias: safetygu2yWdlisZMeta?.alias || [],
    redirect: safetygu2yWdlisZMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetygu2yWdlisZMeta?.name ?? "company-mission-safety___fr",
    path: safetygu2yWdlisZMeta?.path ?? "/fr/company/mission/safety",
    meta: safetygu2yWdlisZMeta || {},
    alias: safetygu2yWdlisZMeta?.alias || [],
    redirect: safetygu2yWdlisZMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetygu2yWdlisZMeta?.name ?? "company-mission-safety___fr-ca",
    path: safetygu2yWdlisZMeta?.path ?? "/fr-ca/company/mission/safety",
    meta: safetygu2yWdlisZMeta || {},
    alias: safetygu2yWdlisZMeta?.alias || [],
    redirect: safetygu2yWdlisZMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitybTP87K9ka2Meta?.name ?? "company-mission-sustainability___en-US",
    path: sustainabilitybTP87K9ka2Meta?.path ?? "/company/mission/sustainability",
    meta: sustainabilitybTP87K9ka2Meta || {},
    alias: sustainabilitybTP87K9ka2Meta?.alias || [],
    redirect: sustainabilitybTP87K9ka2Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitybTP87K9ka2Meta?.name ?? "company-mission-sustainability___ca",
    path: sustainabilitybTP87K9ka2Meta?.path ?? "/ca/company/mission/sustainability",
    meta: sustainabilitybTP87K9ka2Meta || {},
    alias: sustainabilitybTP87K9ka2Meta?.alias || [],
    redirect: sustainabilitybTP87K9ka2Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitybTP87K9ka2Meta?.name ?? "company-mission-sustainability___nl",
    path: sustainabilitybTP87K9ka2Meta?.path ?? "/nl/company/mission/sustainability",
    meta: sustainabilitybTP87K9ka2Meta || {},
    alias: sustainabilitybTP87K9ka2Meta?.alias || [],
    redirect: sustainabilitybTP87K9ka2Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitybTP87K9ka2Meta?.name ?? "company-mission-sustainability___uk",
    path: sustainabilitybTP87K9ka2Meta?.path ?? "/uk/company/mission/sustainability",
    meta: sustainabilitybTP87K9ka2Meta || {},
    alias: sustainabilitybTP87K9ka2Meta?.alias || [],
    redirect: sustainabilitybTP87K9ka2Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitybTP87K9ka2Meta?.name ?? "company-mission-sustainability___de",
    path: sustainabilitybTP87K9ka2Meta?.path ?? "/de/company/mission/sustainability",
    meta: sustainabilitybTP87K9ka2Meta || {},
    alias: sustainabilitybTP87K9ka2Meta?.alias || [],
    redirect: sustainabilitybTP87K9ka2Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitybTP87K9ka2Meta?.name ?? "company-mission-sustainability___mx",
    path: sustainabilitybTP87K9ka2Meta?.path ?? "/mx/company/mission/sustainability",
    meta: sustainabilitybTP87K9ka2Meta || {},
    alias: sustainabilitybTP87K9ka2Meta?.alias || [],
    redirect: sustainabilitybTP87K9ka2Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitybTP87K9ka2Meta?.name ?? "company-mission-sustainability___fr",
    path: sustainabilitybTP87K9ka2Meta?.path ?? "/fr/company/mission/sustainability",
    meta: sustainabilitybTP87K9ka2Meta || {},
    alias: sustainabilitybTP87K9ka2Meta?.alias || [],
    redirect: sustainabilitybTP87K9ka2Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitybTP87K9ka2Meta?.name ?? "company-mission-sustainability___fr-ca",
    path: sustainabilitybTP87K9ka2Meta?.path ?? "/fr-ca/company/mission/sustainability",
    meta: sustainabilitybTP87K9ka2Meta || {},
    alias: sustainabilitybTP87K9ka2Meta?.alias || [],
    redirect: sustainabilitybTP87K9ka2Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newspdUPys4XjYMeta?.name ?? "company-news-in-the-news___en-US",
    path: in_45the_45newspdUPys4XjYMeta?.path ?? "/company/news/in-the-news",
    meta: in_45the_45newspdUPys4XjYMeta || {},
    alias: in_45the_45newspdUPys4XjYMeta?.alias || [],
    redirect: in_45the_45newspdUPys4XjYMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newspdUPys4XjYMeta?.name ?? "company-news-in-the-news___ca",
    path: in_45the_45newspdUPys4XjYMeta?.path ?? "/ca/company/news/in-the-news",
    meta: in_45the_45newspdUPys4XjYMeta || {},
    alias: in_45the_45newspdUPys4XjYMeta?.alias || [],
    redirect: in_45the_45newspdUPys4XjYMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newspdUPys4XjYMeta?.name ?? "company-news-in-the-news___nl",
    path: in_45the_45newspdUPys4XjYMeta?.path ?? "/nl/company/news/in-the-news",
    meta: in_45the_45newspdUPys4XjYMeta || {},
    alias: in_45the_45newspdUPys4XjYMeta?.alias || [],
    redirect: in_45the_45newspdUPys4XjYMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newspdUPys4XjYMeta?.name ?? "company-news-in-the-news___uk",
    path: in_45the_45newspdUPys4XjYMeta?.path ?? "/uk/company/news/in-the-news",
    meta: in_45the_45newspdUPys4XjYMeta || {},
    alias: in_45the_45newspdUPys4XjYMeta?.alias || [],
    redirect: in_45the_45newspdUPys4XjYMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newspdUPys4XjYMeta?.name ?? "company-news-in-the-news___de",
    path: in_45the_45newspdUPys4XjYMeta?.path ?? "/de/company/news/in-the-news",
    meta: in_45the_45newspdUPys4XjYMeta || {},
    alias: in_45the_45newspdUPys4XjYMeta?.alias || [],
    redirect: in_45the_45newspdUPys4XjYMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newspdUPys4XjYMeta?.name ?? "company-news-in-the-news___mx",
    path: in_45the_45newspdUPys4XjYMeta?.path ?? "/mx/company/news/in-the-news",
    meta: in_45the_45newspdUPys4XjYMeta || {},
    alias: in_45the_45newspdUPys4XjYMeta?.alias || [],
    redirect: in_45the_45newspdUPys4XjYMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newspdUPys4XjYMeta?.name ?? "company-news-in-the-news___fr",
    path: in_45the_45newspdUPys4XjYMeta?.path ?? "/fr/company/news/in-the-news",
    meta: in_45the_45newspdUPys4XjYMeta || {},
    alias: in_45the_45newspdUPys4XjYMeta?.alias || [],
    redirect: in_45the_45newspdUPys4XjYMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newspdUPys4XjYMeta?.name ?? "company-news-in-the-news___fr-ca",
    path: in_45the_45newspdUPys4XjYMeta?.path ?? "/fr-ca/company/news/in-the-news",
    meta: in_45the_45newspdUPys4XjYMeta || {},
    alias: in_45the_45newspdUPys4XjYMeta?.alias || [],
    redirect: in_45the_45newspdUPys4XjYMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: indexlA4GroH8NtMeta?.name ?? "company-news___en-US",
    path: indexlA4GroH8NtMeta?.path ?? "/company/news",
    meta: indexlA4GroH8NtMeta || {},
    alias: indexlA4GroH8NtMeta?.alias || [],
    redirect: indexlA4GroH8NtMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexlA4GroH8NtMeta?.name ?? "company-news___ca",
    path: indexlA4GroH8NtMeta?.path ?? "/ca/company/news",
    meta: indexlA4GroH8NtMeta || {},
    alias: indexlA4GroH8NtMeta?.alias || [],
    redirect: indexlA4GroH8NtMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexlA4GroH8NtMeta?.name ?? "company-news___mx",
    path: indexlA4GroH8NtMeta?.path ?? "/mx/company/news",
    meta: indexlA4GroH8NtMeta || {},
    alias: indexlA4GroH8NtMeta?.alias || [],
    redirect: indexlA4GroH8NtMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexlA4GroH8NtMeta?.name ?? "company-news___uk",
    path: indexlA4GroH8NtMeta?.path ?? "/uk/company/news",
    meta: indexlA4GroH8NtMeta || {},
    alias: indexlA4GroH8NtMeta?.alias || [],
    redirect: indexlA4GroH8NtMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesHJlhxrTIb3Meta?.name ?? "company-news-press-releases___en-US",
    path: press_45releasesHJlhxrTIb3Meta?.path ?? "/company/news/press-releases",
    meta: press_45releasesHJlhxrTIb3Meta || {},
    alias: press_45releasesHJlhxrTIb3Meta?.alias || [],
    redirect: press_45releasesHJlhxrTIb3Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesHJlhxrTIb3Meta?.name ?? "company-news-press-releases___ca",
    path: press_45releasesHJlhxrTIb3Meta?.path ?? "/ca/company/news/press-releases",
    meta: press_45releasesHJlhxrTIb3Meta || {},
    alias: press_45releasesHJlhxrTIb3Meta?.alias || [],
    redirect: press_45releasesHJlhxrTIb3Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesHJlhxrTIb3Meta?.name ?? "company-news-press-releases___nl",
    path: press_45releasesHJlhxrTIb3Meta?.path ?? "/nl/company/news/press-releases",
    meta: press_45releasesHJlhxrTIb3Meta || {},
    alias: press_45releasesHJlhxrTIb3Meta?.alias || [],
    redirect: press_45releasesHJlhxrTIb3Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesHJlhxrTIb3Meta?.name ?? "company-news-press-releases___uk",
    path: press_45releasesHJlhxrTIb3Meta?.path ?? "/uk/company/news/press-releases",
    meta: press_45releasesHJlhxrTIb3Meta || {},
    alias: press_45releasesHJlhxrTIb3Meta?.alias || [],
    redirect: press_45releasesHJlhxrTIb3Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesHJlhxrTIb3Meta?.name ?? "company-news-press-releases___de",
    path: press_45releasesHJlhxrTIb3Meta?.path ?? "/de/company/news/press-releases",
    meta: press_45releasesHJlhxrTIb3Meta || {},
    alias: press_45releasesHJlhxrTIb3Meta?.alias || [],
    redirect: press_45releasesHJlhxrTIb3Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesHJlhxrTIb3Meta?.name ?? "company-news-press-releases___mx",
    path: press_45releasesHJlhxrTIb3Meta?.path ?? "/mx/company/news/press-releases",
    meta: press_45releasesHJlhxrTIb3Meta || {},
    alias: press_45releasesHJlhxrTIb3Meta?.alias || [],
    redirect: press_45releasesHJlhxrTIb3Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesHJlhxrTIb3Meta?.name ?? "company-news-press-releases___fr",
    path: press_45releasesHJlhxrTIb3Meta?.path ?? "/fr/company/news/press-releases",
    meta: press_45releasesHJlhxrTIb3Meta || {},
    alias: press_45releasesHJlhxrTIb3Meta?.alias || [],
    redirect: press_45releasesHJlhxrTIb3Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesHJlhxrTIb3Meta?.name ?? "company-news-press-releases___fr-ca",
    path: press_45releasesHJlhxrTIb3Meta?.path ?? "/fr-ca/company/news/press-releases",
    meta: press_45releasesHJlhxrTIb3Meta || {},
    alias: press_45releasesHJlhxrTIb3Meta?.alias || [],
    redirect: press_45releasesHJlhxrTIb3Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: privacyY4Mxej5R9dMeta?.name ?? "company-privacy___en-US",
    path: privacyY4Mxej5R9dMeta?.path ?? "/company/privacy",
    meta: privacyY4Mxej5R9dMeta || {},
    alias: privacyY4Mxej5R9dMeta?.alias || [],
    redirect: privacyY4Mxej5R9dMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyY4Mxej5R9dMeta?.name ?? "company-privacy___ca",
    path: privacyY4Mxej5R9dMeta?.path ?? "/ca/company/privacy",
    meta: privacyY4Mxej5R9dMeta || {},
    alias: privacyY4Mxej5R9dMeta?.alias || [],
    redirect: privacyY4Mxej5R9dMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyY4Mxej5R9dMeta?.name ?? "company-privacy___nl",
    path: privacyY4Mxej5R9dMeta?.path ?? "/nl/company/privacy",
    meta: privacyY4Mxej5R9dMeta || {},
    alias: privacyY4Mxej5R9dMeta?.alias || [],
    redirect: privacyY4Mxej5R9dMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyY4Mxej5R9dMeta?.name ?? "company-privacy___uk",
    path: privacyY4Mxej5R9dMeta?.path ?? "/uk/company/privacy",
    meta: privacyY4Mxej5R9dMeta || {},
    alias: privacyY4Mxej5R9dMeta?.alias || [],
    redirect: privacyY4Mxej5R9dMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyY4Mxej5R9dMeta?.name ?? "company-privacy___de",
    path: privacyY4Mxej5R9dMeta?.path ?? "/de/company/privacy",
    meta: privacyY4Mxej5R9dMeta || {},
    alias: privacyY4Mxej5R9dMeta?.alias || [],
    redirect: privacyY4Mxej5R9dMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyY4Mxej5R9dMeta?.name ?? "company-privacy___mx",
    path: privacyY4Mxej5R9dMeta?.path ?? "/mx/company/privacy",
    meta: privacyY4Mxej5R9dMeta || {},
    alias: privacyY4Mxej5R9dMeta?.alias || [],
    redirect: privacyY4Mxej5R9dMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyY4Mxej5R9dMeta?.name ?? "company-privacy___fr",
    path: privacyY4Mxej5R9dMeta?.path ?? "/fr/company/privacy",
    meta: privacyY4Mxej5R9dMeta || {},
    alias: privacyY4Mxej5R9dMeta?.alias || [],
    redirect: privacyY4Mxej5R9dMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyY4Mxej5R9dMeta?.name ?? "company-privacy___fr-ca",
    path: privacyY4Mxej5R9dMeta?.path ?? "/fr-ca/company/privacy",
    meta: privacyY4Mxej5R9dMeta || {},
    alias: privacyY4Mxej5R9dMeta?.alias || [],
    redirect: privacyY4Mxej5R9dMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolVohx40TmBuMeta?.name ?? "cost-assessment-tool___en-US",
    path: cost_45assessment_45toolVohx40TmBuMeta?.path ?? "/cost-assessment-tool",
    meta: cost_45assessment_45toolVohx40TmBuMeta || {},
    alias: cost_45assessment_45toolVohx40TmBuMeta?.alias || [],
    redirect: cost_45assessment_45toolVohx40TmBuMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolVohx40TmBuMeta?.name ?? "cost-assessment-tool___ca",
    path: cost_45assessment_45toolVohx40TmBuMeta?.path ?? "/ca/cost-assessment-tool",
    meta: cost_45assessment_45toolVohx40TmBuMeta || {},
    alias: cost_45assessment_45toolVohx40TmBuMeta?.alias || [],
    redirect: cost_45assessment_45toolVohx40TmBuMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolVohx40TmBuMeta?.name ?? "cost-assessment-tool___nl",
    path: cost_45assessment_45toolVohx40TmBuMeta?.path ?? "/nl/cost-assessment-tool",
    meta: cost_45assessment_45toolVohx40TmBuMeta || {},
    alias: cost_45assessment_45toolVohx40TmBuMeta?.alias || [],
    redirect: cost_45assessment_45toolVohx40TmBuMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolVohx40TmBuMeta?.name ?? "cost-assessment-tool___uk",
    path: cost_45assessment_45toolVohx40TmBuMeta?.path ?? "/uk/cost-assessment-tool",
    meta: cost_45assessment_45toolVohx40TmBuMeta || {},
    alias: cost_45assessment_45toolVohx40TmBuMeta?.alias || [],
    redirect: cost_45assessment_45toolVohx40TmBuMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolVohx40TmBuMeta?.name ?? "cost-assessment-tool___de",
    path: cost_45assessment_45toolVohx40TmBuMeta?.path ?? "/de/cost-assessment-tool",
    meta: cost_45assessment_45toolVohx40TmBuMeta || {},
    alias: cost_45assessment_45toolVohx40TmBuMeta?.alias || [],
    redirect: cost_45assessment_45toolVohx40TmBuMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolVohx40TmBuMeta?.name ?? "cost-assessment-tool___mx",
    path: cost_45assessment_45toolVohx40TmBuMeta?.path ?? "/mx/cost-assessment-tool",
    meta: cost_45assessment_45toolVohx40TmBuMeta || {},
    alias: cost_45assessment_45toolVohx40TmBuMeta?.alias || [],
    redirect: cost_45assessment_45toolVohx40TmBuMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolVohx40TmBuMeta?.name ?? "cost-assessment-tool___fr",
    path: cost_45assessment_45toolVohx40TmBuMeta?.path ?? "/fr/cost-assessment-tool",
    meta: cost_45assessment_45toolVohx40TmBuMeta || {},
    alias: cost_45assessment_45toolVohx40TmBuMeta?.alias || [],
    redirect: cost_45assessment_45toolVohx40TmBuMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolVohx40TmBuMeta?.name ?? "cost-assessment-tool___fr-ca",
    path: cost_45assessment_45toolVohx40TmBuMeta?.path ?? "/fr-ca/cost-assessment-tool",
    meta: cost_45assessment_45toolVohx40TmBuMeta || {},
    alias: cost_45assessment_45toolVohx40TmBuMeta?.alias || [],
    redirect: cost_45assessment_45toolVohx40TmBuMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fNaIzkOEs0Meta?.name ?? "customers-slug___en-US",
    path: _91slug_93fNaIzkOEs0Meta?.path ?? "/customers/:slug()",
    meta: _91slug_93fNaIzkOEs0Meta || {},
    alias: _91slug_93fNaIzkOEs0Meta?.alias || [],
    redirect: _91slug_93fNaIzkOEs0Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fNaIzkOEs0Meta?.name ?? "customers-slug___ca",
    path: _91slug_93fNaIzkOEs0Meta?.path ?? "/ca/customers/:slug()",
    meta: _91slug_93fNaIzkOEs0Meta || {},
    alias: _91slug_93fNaIzkOEs0Meta?.alias || [],
    redirect: _91slug_93fNaIzkOEs0Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fNaIzkOEs0Meta?.name ?? "customers-slug___nl",
    path: _91slug_93fNaIzkOEs0Meta?.path ?? "/nl/customers/:slug()",
    meta: _91slug_93fNaIzkOEs0Meta || {},
    alias: _91slug_93fNaIzkOEs0Meta?.alias || [],
    redirect: _91slug_93fNaIzkOEs0Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fNaIzkOEs0Meta?.name ?? "customers-slug___uk",
    path: _91slug_93fNaIzkOEs0Meta?.path ?? "/uk/customers/:slug()",
    meta: _91slug_93fNaIzkOEs0Meta || {},
    alias: _91slug_93fNaIzkOEs0Meta?.alias || [],
    redirect: _91slug_93fNaIzkOEs0Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fNaIzkOEs0Meta?.name ?? "customers-slug___de",
    path: _91slug_93fNaIzkOEs0Meta?.path ?? "/de/customers/:slug()",
    meta: _91slug_93fNaIzkOEs0Meta || {},
    alias: _91slug_93fNaIzkOEs0Meta?.alias || [],
    redirect: _91slug_93fNaIzkOEs0Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fNaIzkOEs0Meta?.name ?? "customers-slug___mx",
    path: _91slug_93fNaIzkOEs0Meta?.path ?? "/mx/customers/:slug()",
    meta: _91slug_93fNaIzkOEs0Meta || {},
    alias: _91slug_93fNaIzkOEs0Meta?.alias || [],
    redirect: _91slug_93fNaIzkOEs0Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fNaIzkOEs0Meta?.name ?? "customers-slug___fr",
    path: _91slug_93fNaIzkOEs0Meta?.path ?? "/fr/customers/:slug()",
    meta: _91slug_93fNaIzkOEs0Meta || {},
    alias: _91slug_93fNaIzkOEs0Meta?.alias || [],
    redirect: _91slug_93fNaIzkOEs0Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fNaIzkOEs0Meta?.name ?? "customers-slug___fr-ca",
    path: _91slug_93fNaIzkOEs0Meta?.path ?? "/fr-ca/customers/:slug()",
    meta: _91slug_93fNaIzkOEs0Meta || {},
    alias: _91slug_93fNaIzkOEs0Meta?.alias || [],
    redirect: _91slug_93fNaIzkOEs0Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XAfcP6ofK9Meta?.name ?? "customers-category-slug___en-US",
    path: _91slug_93XAfcP6ofK9Meta?.path ?? "/customers/category/:slug()",
    meta: _91slug_93XAfcP6ofK9Meta || {},
    alias: _91slug_93XAfcP6ofK9Meta?.alias || [],
    redirect: _91slug_93XAfcP6ofK9Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XAfcP6ofK9Meta?.name ?? "customers-category-slug___ca",
    path: _91slug_93XAfcP6ofK9Meta?.path ?? "/ca/customers/category/:slug()",
    meta: _91slug_93XAfcP6ofK9Meta || {},
    alias: _91slug_93XAfcP6ofK9Meta?.alias || [],
    redirect: _91slug_93XAfcP6ofK9Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XAfcP6ofK9Meta?.name ?? "customers-category-slug___nl",
    path: _91slug_93XAfcP6ofK9Meta?.path ?? "/nl/customers/category/:slug()",
    meta: _91slug_93XAfcP6ofK9Meta || {},
    alias: _91slug_93XAfcP6ofK9Meta?.alias || [],
    redirect: _91slug_93XAfcP6ofK9Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XAfcP6ofK9Meta?.name ?? "customers-category-slug___uk",
    path: _91slug_93XAfcP6ofK9Meta?.path ?? "/uk/customers/category/:slug()",
    meta: _91slug_93XAfcP6ofK9Meta || {},
    alias: _91slug_93XAfcP6ofK9Meta?.alias || [],
    redirect: _91slug_93XAfcP6ofK9Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XAfcP6ofK9Meta?.name ?? "customers-category-slug___de",
    path: _91slug_93XAfcP6ofK9Meta?.path ?? "/de/customers/category/:slug()",
    meta: _91slug_93XAfcP6ofK9Meta || {},
    alias: _91slug_93XAfcP6ofK9Meta?.alias || [],
    redirect: _91slug_93XAfcP6ofK9Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XAfcP6ofK9Meta?.name ?? "customers-category-slug___mx",
    path: _91slug_93XAfcP6ofK9Meta?.path ?? "/mx/customers/category/:slug()",
    meta: _91slug_93XAfcP6ofK9Meta || {},
    alias: _91slug_93XAfcP6ofK9Meta?.alias || [],
    redirect: _91slug_93XAfcP6ofK9Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XAfcP6ofK9Meta?.name ?? "customers-category-slug___fr",
    path: _91slug_93XAfcP6ofK9Meta?.path ?? "/fr/customers/category/:slug()",
    meta: _91slug_93XAfcP6ofK9Meta || {},
    alias: _91slug_93XAfcP6ofK9Meta?.alias || [],
    redirect: _91slug_93XAfcP6ofK9Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XAfcP6ofK9Meta?.name ?? "customers-category-slug___fr-ca",
    path: _91slug_93XAfcP6ofK9Meta?.path ?? "/fr-ca/customers/category/:slug()",
    meta: _91slug_93XAfcP6ofK9Meta || {},
    alias: _91slug_93XAfcP6ofK9Meta?.alias || [],
    redirect: _91slug_93XAfcP6ofK9Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexVW6ylkO5xUMeta?.name ?? "customers___en-US",
    path: indexVW6ylkO5xUMeta?.path ?? "/customers",
    meta: indexVW6ylkO5xUMeta || {},
    alias: indexVW6ylkO5xUMeta?.alias || [],
    redirect: indexVW6ylkO5xUMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexVW6ylkO5xUMeta?.name ?? "customers___ca",
    path: indexVW6ylkO5xUMeta?.path ?? "/ca/customers",
    meta: indexVW6ylkO5xUMeta || {},
    alias: indexVW6ylkO5xUMeta?.alias || [],
    redirect: indexVW6ylkO5xUMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexVW6ylkO5xUMeta?.name ?? "customers___nl",
    path: indexVW6ylkO5xUMeta?.path ?? "/nl/customers",
    meta: indexVW6ylkO5xUMeta || {},
    alias: indexVW6ylkO5xUMeta?.alias || [],
    redirect: indexVW6ylkO5xUMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexVW6ylkO5xUMeta?.name ?? "customers___uk",
    path: indexVW6ylkO5xUMeta?.path ?? "/uk/customers",
    meta: indexVW6ylkO5xUMeta || {},
    alias: indexVW6ylkO5xUMeta?.alias || [],
    redirect: indexVW6ylkO5xUMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexVW6ylkO5xUMeta?.name ?? "customers___de",
    path: indexVW6ylkO5xUMeta?.path ?? "/de/customers",
    meta: indexVW6ylkO5xUMeta || {},
    alias: indexVW6ylkO5xUMeta?.alias || [],
    redirect: indexVW6ylkO5xUMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexVW6ylkO5xUMeta?.name ?? "customers___mx",
    path: indexVW6ylkO5xUMeta?.path ?? "/mx/customers",
    meta: indexVW6ylkO5xUMeta || {},
    alias: indexVW6ylkO5xUMeta?.alias || [],
    redirect: indexVW6ylkO5xUMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexVW6ylkO5xUMeta?.name ?? "customers___fr",
    path: indexVW6ylkO5xUMeta?.path ?? "/fr/customers",
    meta: indexVW6ylkO5xUMeta || {},
    alias: indexVW6ylkO5xUMeta?.alias || [],
    redirect: indexVW6ylkO5xUMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexVW6ylkO5xUMeta?.name ?? "customers___fr-ca",
    path: indexVW6ylkO5xUMeta?.path ?? "/fr-ca/customers",
    meta: indexVW6ylkO5xUMeta || {},
    alias: indexVW6ylkO5xUMeta?.alias || [],
    redirect: indexVW6ylkO5xUMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Eb0EUTUKZtMeta?.name ?? "customers-tips-slug___en-US",
    path: _91slug_93Eb0EUTUKZtMeta?.path ?? "/customers/tips/:slug()",
    meta: _91slug_93Eb0EUTUKZtMeta || {},
    alias: _91slug_93Eb0EUTUKZtMeta?.alias || [],
    redirect: _91slug_93Eb0EUTUKZtMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Eb0EUTUKZtMeta?.name ?? "customers-tips-slug___ca",
    path: _91slug_93Eb0EUTUKZtMeta?.path ?? "/ca/customers/tips/:slug()",
    meta: _91slug_93Eb0EUTUKZtMeta || {},
    alias: _91slug_93Eb0EUTUKZtMeta?.alias || [],
    redirect: _91slug_93Eb0EUTUKZtMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Eb0EUTUKZtMeta?.name ?? "customers-tips-slug___nl",
    path: _91slug_93Eb0EUTUKZtMeta?.path ?? "/nl/customers/tips/:slug()",
    meta: _91slug_93Eb0EUTUKZtMeta || {},
    alias: _91slug_93Eb0EUTUKZtMeta?.alias || [],
    redirect: _91slug_93Eb0EUTUKZtMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Eb0EUTUKZtMeta?.name ?? "customers-tips-slug___uk",
    path: _91slug_93Eb0EUTUKZtMeta?.path ?? "/uk/customers/tips/:slug()",
    meta: _91slug_93Eb0EUTUKZtMeta || {},
    alias: _91slug_93Eb0EUTUKZtMeta?.alias || [],
    redirect: _91slug_93Eb0EUTUKZtMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Eb0EUTUKZtMeta?.name ?? "customers-tips-slug___de",
    path: _91slug_93Eb0EUTUKZtMeta?.path ?? "/de/customers/tips/:slug()",
    meta: _91slug_93Eb0EUTUKZtMeta || {},
    alias: _91slug_93Eb0EUTUKZtMeta?.alias || [],
    redirect: _91slug_93Eb0EUTUKZtMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Eb0EUTUKZtMeta?.name ?? "customers-tips-slug___mx",
    path: _91slug_93Eb0EUTUKZtMeta?.path ?? "/mx/customers/tips/:slug()",
    meta: _91slug_93Eb0EUTUKZtMeta || {},
    alias: _91slug_93Eb0EUTUKZtMeta?.alias || [],
    redirect: _91slug_93Eb0EUTUKZtMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Eb0EUTUKZtMeta?.name ?? "customers-tips-slug___fr",
    path: _91slug_93Eb0EUTUKZtMeta?.path ?? "/fr/customers/tips/:slug()",
    meta: _91slug_93Eb0EUTUKZtMeta || {},
    alias: _91slug_93Eb0EUTUKZtMeta?.alias || [],
    redirect: _91slug_93Eb0EUTUKZtMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Eb0EUTUKZtMeta?.name ?? "customers-tips-slug___fr-ca",
    path: _91slug_93Eb0EUTUKZtMeta?.path ?? "/fr-ca/customers/tips/:slug()",
    meta: _91slug_93Eb0EUTUKZtMeta || {},
    alias: _91slug_93Eb0EUTUKZtMeta?.alias || [],
    redirect: _91slug_93Eb0EUTUKZtMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HOhs0496ahMeta?.name ?? "customers-tips-category-slug___en-US",
    path: _91slug_93HOhs0496ahMeta?.path ?? "/customers/tips/category/:slug()",
    meta: _91slug_93HOhs0496ahMeta || {},
    alias: _91slug_93HOhs0496ahMeta?.alias || [],
    redirect: _91slug_93HOhs0496ahMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HOhs0496ahMeta?.name ?? "customers-tips-category-slug___ca",
    path: _91slug_93HOhs0496ahMeta?.path ?? "/ca/customers/tips/category/:slug()",
    meta: _91slug_93HOhs0496ahMeta || {},
    alias: _91slug_93HOhs0496ahMeta?.alias || [],
    redirect: _91slug_93HOhs0496ahMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HOhs0496ahMeta?.name ?? "customers-tips-category-slug___nl",
    path: _91slug_93HOhs0496ahMeta?.path ?? "/nl/customers/tips/category/:slug()",
    meta: _91slug_93HOhs0496ahMeta || {},
    alias: _91slug_93HOhs0496ahMeta?.alias || [],
    redirect: _91slug_93HOhs0496ahMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HOhs0496ahMeta?.name ?? "customers-tips-category-slug___uk",
    path: _91slug_93HOhs0496ahMeta?.path ?? "/uk/customers/tips/category/:slug()",
    meta: _91slug_93HOhs0496ahMeta || {},
    alias: _91slug_93HOhs0496ahMeta?.alias || [],
    redirect: _91slug_93HOhs0496ahMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HOhs0496ahMeta?.name ?? "customers-tips-category-slug___de",
    path: _91slug_93HOhs0496ahMeta?.path ?? "/de/customers/tips/category/:slug()",
    meta: _91slug_93HOhs0496ahMeta || {},
    alias: _91slug_93HOhs0496ahMeta?.alias || [],
    redirect: _91slug_93HOhs0496ahMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HOhs0496ahMeta?.name ?? "customers-tips-category-slug___mx",
    path: _91slug_93HOhs0496ahMeta?.path ?? "/mx/customers/tips/category/:slug()",
    meta: _91slug_93HOhs0496ahMeta || {},
    alias: _91slug_93HOhs0496ahMeta?.alias || [],
    redirect: _91slug_93HOhs0496ahMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HOhs0496ahMeta?.name ?? "customers-tips-category-slug___fr",
    path: _91slug_93HOhs0496ahMeta?.path ?? "/fr/customers/tips/category/:slug()",
    meta: _91slug_93HOhs0496ahMeta || {},
    alias: _91slug_93HOhs0496ahMeta?.alias || [],
    redirect: _91slug_93HOhs0496ahMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HOhs0496ahMeta?.name ?? "customers-tips-category-slug___fr-ca",
    path: _91slug_93HOhs0496ahMeta?.path ?? "/fr-ca/customers/tips/category/:slug()",
    meta: _91slug_93HOhs0496ahMeta || {},
    alias: _91slug_93HOhs0496ahMeta?.alias || [],
    redirect: _91slug_93HOhs0496ahMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexM5CeiYWkQXMeta?.name ?? "customers-tips___en-US",
    path: indexM5CeiYWkQXMeta?.path ?? "/customers/tips",
    meta: indexM5CeiYWkQXMeta || {},
    alias: indexM5CeiYWkQXMeta?.alias || [],
    redirect: indexM5CeiYWkQXMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexM5CeiYWkQXMeta?.name ?? "customers-tips___ca",
    path: indexM5CeiYWkQXMeta?.path ?? "/ca/customers/tips",
    meta: indexM5CeiYWkQXMeta || {},
    alias: indexM5CeiYWkQXMeta?.alias || [],
    redirect: indexM5CeiYWkQXMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexM5CeiYWkQXMeta?.name ?? "customers-tips___nl",
    path: indexM5CeiYWkQXMeta?.path ?? "/nl/customers/tips",
    meta: indexM5CeiYWkQXMeta || {},
    alias: indexM5CeiYWkQXMeta?.alias || [],
    redirect: indexM5CeiYWkQXMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexM5CeiYWkQXMeta?.name ?? "customers-tips___uk",
    path: indexM5CeiYWkQXMeta?.path ?? "/uk/customers/tips",
    meta: indexM5CeiYWkQXMeta || {},
    alias: indexM5CeiYWkQXMeta?.alias || [],
    redirect: indexM5CeiYWkQXMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexM5CeiYWkQXMeta?.name ?? "customers-tips___de",
    path: indexM5CeiYWkQXMeta?.path ?? "/de/customers/tips",
    meta: indexM5CeiYWkQXMeta || {},
    alias: indexM5CeiYWkQXMeta?.alias || [],
    redirect: indexM5CeiYWkQXMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexM5CeiYWkQXMeta?.name ?? "customers-tips___mx",
    path: indexM5CeiYWkQXMeta?.path ?? "/mx/customers/tips",
    meta: indexM5CeiYWkQXMeta || {},
    alias: indexM5CeiYWkQXMeta?.alias || [],
    redirect: indexM5CeiYWkQXMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexM5CeiYWkQXMeta?.name ?? "customers-tips___fr",
    path: indexM5CeiYWkQXMeta?.path ?? "/fr/customers/tips",
    meta: indexM5CeiYWkQXMeta || {},
    alias: indexM5CeiYWkQXMeta?.alias || [],
    redirect: indexM5CeiYWkQXMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexM5CeiYWkQXMeta?.name ?? "customers-tips___fr-ca",
    path: indexM5CeiYWkQXMeta?.path ?? "/fr-ca/customers/tips",
    meta: indexM5CeiYWkQXMeta || {},
    alias: indexM5CeiYWkQXMeta?.alias || [],
    redirect: indexM5CeiYWkQXMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93DfAv9rUHA4Meta?.name ?? "customers-topfleets-prefix-slug___en-US",
    path: _91_46_46_46slug_93DfAv9rUHA4Meta?.path ?? "/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93DfAv9rUHA4Meta || {},
    alias: _91_46_46_46slug_93DfAv9rUHA4Meta?.alias || [],
    redirect: _91_46_46_46slug_93DfAv9rUHA4Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93DfAv9rUHA4Meta?.name ?? "customers-topfleets-prefix-slug___ca",
    path: _91_46_46_46slug_93DfAv9rUHA4Meta?.path ?? "/ca/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93DfAv9rUHA4Meta || {},
    alias: _91_46_46_46slug_93DfAv9rUHA4Meta?.alias || [],
    redirect: _91_46_46_46slug_93DfAv9rUHA4Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93DfAv9rUHA4Meta?.name ?? "customers-topfleets-prefix-slug___nl",
    path: _91_46_46_46slug_93DfAv9rUHA4Meta?.path ?? "/nl/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93DfAv9rUHA4Meta || {},
    alias: _91_46_46_46slug_93DfAv9rUHA4Meta?.alias || [],
    redirect: _91_46_46_46slug_93DfAv9rUHA4Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93DfAv9rUHA4Meta?.name ?? "customers-topfleets-prefix-slug___uk",
    path: _91_46_46_46slug_93DfAv9rUHA4Meta?.path ?? "/uk/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93DfAv9rUHA4Meta || {},
    alias: _91_46_46_46slug_93DfAv9rUHA4Meta?.alias || [],
    redirect: _91_46_46_46slug_93DfAv9rUHA4Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93DfAv9rUHA4Meta?.name ?? "customers-topfleets-prefix-slug___de",
    path: _91_46_46_46slug_93DfAv9rUHA4Meta?.path ?? "/de/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93DfAv9rUHA4Meta || {},
    alias: _91_46_46_46slug_93DfAv9rUHA4Meta?.alias || [],
    redirect: _91_46_46_46slug_93DfAv9rUHA4Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93DfAv9rUHA4Meta?.name ?? "customers-topfleets-prefix-slug___mx",
    path: _91_46_46_46slug_93DfAv9rUHA4Meta?.path ?? "/mx/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93DfAv9rUHA4Meta || {},
    alias: _91_46_46_46slug_93DfAv9rUHA4Meta?.alias || [],
    redirect: _91_46_46_46slug_93DfAv9rUHA4Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93DfAv9rUHA4Meta?.name ?? "customers-topfleets-prefix-slug___fr",
    path: _91_46_46_46slug_93DfAv9rUHA4Meta?.path ?? "/fr/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93DfAv9rUHA4Meta || {},
    alias: _91_46_46_46slug_93DfAv9rUHA4Meta?.alias || [],
    redirect: _91_46_46_46slug_93DfAv9rUHA4Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93DfAv9rUHA4Meta?.name ?? "customers-topfleets-prefix-slug___fr-ca",
    path: _91_46_46_46slug_93DfAv9rUHA4Meta?.path ?? "/fr-ca/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93DfAv9rUHA4Meta || {},
    alias: _91_46_46_46slug_93DfAv9rUHA4Meta?.alias || [],
    redirect: _91_46_46_46slug_93DfAv9rUHA4Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: above_45beyondabKcrpwsPeMeta?.name ?? "customers-topfleets-2019-above-beyond___en-US",
    path: above_45beyondabKcrpwsPeMeta?.path ?? "/customers/topfleets/2019/above-beyond",
    meta: above_45beyondabKcrpwsPeMeta || {},
    alias: above_45beyondabKcrpwsPeMeta?.alias || [],
    redirect: above_45beyondabKcrpwsPeMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondabKcrpwsPeMeta?.name ?? "customers-topfleets-2019-above-beyond___ca",
    path: above_45beyondabKcrpwsPeMeta?.path ?? "/ca/customers/topfleets/2019/above-beyond",
    meta: above_45beyondabKcrpwsPeMeta || {},
    alias: above_45beyondabKcrpwsPeMeta?.alias || [],
    redirect: above_45beyondabKcrpwsPeMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondabKcrpwsPeMeta?.name ?? "customers-topfleets-2019-above-beyond___nl",
    path: above_45beyondabKcrpwsPeMeta?.path ?? "/nl/customers/topfleets/2019/above-beyond",
    meta: above_45beyondabKcrpwsPeMeta || {},
    alias: above_45beyondabKcrpwsPeMeta?.alias || [],
    redirect: above_45beyondabKcrpwsPeMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondabKcrpwsPeMeta?.name ?? "customers-topfleets-2019-above-beyond___uk",
    path: above_45beyondabKcrpwsPeMeta?.path ?? "/uk/customers/topfleets/2019/above-beyond",
    meta: above_45beyondabKcrpwsPeMeta || {},
    alias: above_45beyondabKcrpwsPeMeta?.alias || [],
    redirect: above_45beyondabKcrpwsPeMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondabKcrpwsPeMeta?.name ?? "customers-topfleets-2019-above-beyond___de",
    path: above_45beyondabKcrpwsPeMeta?.path ?? "/de/customers/topfleets/2019/above-beyond",
    meta: above_45beyondabKcrpwsPeMeta || {},
    alias: above_45beyondabKcrpwsPeMeta?.alias || [],
    redirect: above_45beyondabKcrpwsPeMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondabKcrpwsPeMeta?.name ?? "customers-topfleets-2019-above-beyond___mx",
    path: above_45beyondabKcrpwsPeMeta?.path ?? "/mx/customers/topfleets/2019/above-beyond",
    meta: above_45beyondabKcrpwsPeMeta || {},
    alias: above_45beyondabKcrpwsPeMeta?.alias || [],
    redirect: above_45beyondabKcrpwsPeMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondabKcrpwsPeMeta?.name ?? "customers-topfleets-2019-above-beyond___fr",
    path: above_45beyondabKcrpwsPeMeta?.path ?? "/fr/customers/topfleets/2019/above-beyond",
    meta: above_45beyondabKcrpwsPeMeta || {},
    alias: above_45beyondabKcrpwsPeMeta?.alias || [],
    redirect: above_45beyondabKcrpwsPeMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondabKcrpwsPeMeta?.name ?? "customers-topfleets-2019-above-beyond___fr-ca",
    path: above_45beyondabKcrpwsPeMeta?.path ?? "/fr-ca/customers/topfleets/2019/above-beyond",
    meta: above_45beyondabKcrpwsPeMeta || {},
    alias: above_45beyondabKcrpwsPeMeta?.alias || [],
    redirect: above_45beyondabKcrpwsPeMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceGSIlx1iV8VMeta?.name ?? "customers-topfleets-2019-excellence-performance___en-US",
    path: excellence_45performanceGSIlx1iV8VMeta?.path ?? "/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceGSIlx1iV8VMeta || {},
    alias: excellence_45performanceGSIlx1iV8VMeta?.alias || [],
    redirect: excellence_45performanceGSIlx1iV8VMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceGSIlx1iV8VMeta?.name ?? "customers-topfleets-2019-excellence-performance___ca",
    path: excellence_45performanceGSIlx1iV8VMeta?.path ?? "/ca/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceGSIlx1iV8VMeta || {},
    alias: excellence_45performanceGSIlx1iV8VMeta?.alias || [],
    redirect: excellence_45performanceGSIlx1iV8VMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceGSIlx1iV8VMeta?.name ?? "customers-topfleets-2019-excellence-performance___nl",
    path: excellence_45performanceGSIlx1iV8VMeta?.path ?? "/nl/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceGSIlx1iV8VMeta || {},
    alias: excellence_45performanceGSIlx1iV8VMeta?.alias || [],
    redirect: excellence_45performanceGSIlx1iV8VMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceGSIlx1iV8VMeta?.name ?? "customers-topfleets-2019-excellence-performance___uk",
    path: excellence_45performanceGSIlx1iV8VMeta?.path ?? "/uk/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceGSIlx1iV8VMeta || {},
    alias: excellence_45performanceGSIlx1iV8VMeta?.alias || [],
    redirect: excellence_45performanceGSIlx1iV8VMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceGSIlx1iV8VMeta?.name ?? "customers-topfleets-2019-excellence-performance___de",
    path: excellence_45performanceGSIlx1iV8VMeta?.path ?? "/de/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceGSIlx1iV8VMeta || {},
    alias: excellence_45performanceGSIlx1iV8VMeta?.alias || [],
    redirect: excellence_45performanceGSIlx1iV8VMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceGSIlx1iV8VMeta?.name ?? "customers-topfleets-2019-excellence-performance___mx",
    path: excellence_45performanceGSIlx1iV8VMeta?.path ?? "/mx/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceGSIlx1iV8VMeta || {},
    alias: excellence_45performanceGSIlx1iV8VMeta?.alias || [],
    redirect: excellence_45performanceGSIlx1iV8VMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceGSIlx1iV8VMeta?.name ?? "customers-topfleets-2019-excellence-performance___fr",
    path: excellence_45performanceGSIlx1iV8VMeta?.path ?? "/fr/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceGSIlx1iV8VMeta || {},
    alias: excellence_45performanceGSIlx1iV8VMeta?.alias || [],
    redirect: excellence_45performanceGSIlx1iV8VMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceGSIlx1iV8VMeta?.name ?? "customers-topfleets-2019-excellence-performance___fr-ca",
    path: excellence_45performanceGSIlx1iV8VMeta?.path ?? "/fr-ca/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceGSIlx1iV8VMeta || {},
    alias: excellence_45performanceGSIlx1iV8VMeta?.alias || [],
    redirect: excellence_45performanceGSIlx1iV8VMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceEyos5bZJWCMeta?.name ?? "customers-topfleets-2019-excellence-service___en-US",
    path: excellence_45serviceEyos5bZJWCMeta?.path ?? "/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceEyos5bZJWCMeta || {},
    alias: excellence_45serviceEyos5bZJWCMeta?.alias || [],
    redirect: excellence_45serviceEyos5bZJWCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceEyos5bZJWCMeta?.name ?? "customers-topfleets-2019-excellence-service___ca",
    path: excellence_45serviceEyos5bZJWCMeta?.path ?? "/ca/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceEyos5bZJWCMeta || {},
    alias: excellence_45serviceEyos5bZJWCMeta?.alias || [],
    redirect: excellence_45serviceEyos5bZJWCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceEyos5bZJWCMeta?.name ?? "customers-topfleets-2019-excellence-service___nl",
    path: excellence_45serviceEyos5bZJWCMeta?.path ?? "/nl/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceEyos5bZJWCMeta || {},
    alias: excellence_45serviceEyos5bZJWCMeta?.alias || [],
    redirect: excellence_45serviceEyos5bZJWCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceEyos5bZJWCMeta?.name ?? "customers-topfleets-2019-excellence-service___uk",
    path: excellence_45serviceEyos5bZJWCMeta?.path ?? "/uk/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceEyos5bZJWCMeta || {},
    alias: excellence_45serviceEyos5bZJWCMeta?.alias || [],
    redirect: excellence_45serviceEyos5bZJWCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceEyos5bZJWCMeta?.name ?? "customers-topfleets-2019-excellence-service___de",
    path: excellence_45serviceEyos5bZJWCMeta?.path ?? "/de/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceEyos5bZJWCMeta || {},
    alias: excellence_45serviceEyos5bZJWCMeta?.alias || [],
    redirect: excellence_45serviceEyos5bZJWCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceEyos5bZJWCMeta?.name ?? "customers-topfleets-2019-excellence-service___mx",
    path: excellence_45serviceEyos5bZJWCMeta?.path ?? "/mx/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceEyos5bZJWCMeta || {},
    alias: excellence_45serviceEyos5bZJWCMeta?.alias || [],
    redirect: excellence_45serviceEyos5bZJWCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceEyos5bZJWCMeta?.name ?? "customers-topfleets-2019-excellence-service___fr",
    path: excellence_45serviceEyos5bZJWCMeta?.path ?? "/fr/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceEyos5bZJWCMeta || {},
    alias: excellence_45serviceEyos5bZJWCMeta?.alias || [],
    redirect: excellence_45serviceEyos5bZJWCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceEyos5bZJWCMeta?.name ?? "customers-topfleets-2019-excellence-service___fr-ca",
    path: excellence_45serviceEyos5bZJWCMeta?.path ?? "/fr-ca/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceEyos5bZJWCMeta || {},
    alias: excellence_45serviceEyos5bZJWCMeta?.alias || [],
    redirect: excellence_45serviceEyos5bZJWCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: indexm6td9yGT9CMeta?.name ?? "customers-topfleets-2019___en-US",
    path: indexm6td9yGT9CMeta?.path ?? "/customers/topfleets/2019",
    meta: indexm6td9yGT9CMeta || {},
    alias: indexm6td9yGT9CMeta?.alias || [],
    redirect: indexm6td9yGT9CMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexm6td9yGT9CMeta?.name ?? "customers-topfleets-2019___ca",
    path: indexm6td9yGT9CMeta?.path ?? "/ca/customers/topfleets/2019",
    meta: indexm6td9yGT9CMeta || {},
    alias: indexm6td9yGT9CMeta?.alias || [],
    redirect: indexm6td9yGT9CMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexm6td9yGT9CMeta?.name ?? "customers-topfleets-2019___nl",
    path: indexm6td9yGT9CMeta?.path ?? "/nl/customers/topfleets/2019",
    meta: indexm6td9yGT9CMeta || {},
    alias: indexm6td9yGT9CMeta?.alias || [],
    redirect: indexm6td9yGT9CMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexm6td9yGT9CMeta?.name ?? "customers-topfleets-2019___uk",
    path: indexm6td9yGT9CMeta?.path ?? "/uk/customers/topfleets/2019",
    meta: indexm6td9yGT9CMeta || {},
    alias: indexm6td9yGT9CMeta?.alias || [],
    redirect: indexm6td9yGT9CMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexm6td9yGT9CMeta?.name ?? "customers-topfleets-2019___de",
    path: indexm6td9yGT9CMeta?.path ?? "/de/customers/topfleets/2019",
    meta: indexm6td9yGT9CMeta || {},
    alias: indexm6td9yGT9CMeta?.alias || [],
    redirect: indexm6td9yGT9CMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexm6td9yGT9CMeta?.name ?? "customers-topfleets-2019___mx",
    path: indexm6td9yGT9CMeta?.path ?? "/mx/customers/topfleets/2019",
    meta: indexm6td9yGT9CMeta || {},
    alias: indexm6td9yGT9CMeta?.alias || [],
    redirect: indexm6td9yGT9CMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexm6td9yGT9CMeta?.name ?? "customers-topfleets-2019___fr",
    path: indexm6td9yGT9CMeta?.path ?? "/fr/customers/topfleets/2019",
    meta: indexm6td9yGT9CMeta || {},
    alias: indexm6td9yGT9CMeta?.alias || [],
    redirect: indexm6td9yGT9CMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexm6td9yGT9CMeta?.name ?? "customers-topfleets-2019___fr-ca",
    path: indexm6td9yGT9CMeta?.path ?? "/fr-ca/customers/topfleets/2019",
    meta: indexm6td9yGT9CMeta || {},
    alias: indexm6td9yGT9CMeta?.alias || [],
    redirect: indexm6td9yGT9CMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetUZZgEGqlRNMeta?.name ?? "customers-topfleets-2019-safest-fleet___en-US",
    path: safest_45fleetUZZgEGqlRNMeta?.path ?? "/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetUZZgEGqlRNMeta || {},
    alias: safest_45fleetUZZgEGqlRNMeta?.alias || [],
    redirect: safest_45fleetUZZgEGqlRNMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetUZZgEGqlRNMeta?.name ?? "customers-topfleets-2019-safest-fleet___ca",
    path: safest_45fleetUZZgEGqlRNMeta?.path ?? "/ca/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetUZZgEGqlRNMeta || {},
    alias: safest_45fleetUZZgEGqlRNMeta?.alias || [],
    redirect: safest_45fleetUZZgEGqlRNMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetUZZgEGqlRNMeta?.name ?? "customers-topfleets-2019-safest-fleet___nl",
    path: safest_45fleetUZZgEGqlRNMeta?.path ?? "/nl/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetUZZgEGqlRNMeta || {},
    alias: safest_45fleetUZZgEGqlRNMeta?.alias || [],
    redirect: safest_45fleetUZZgEGqlRNMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetUZZgEGqlRNMeta?.name ?? "customers-topfleets-2019-safest-fleet___uk",
    path: safest_45fleetUZZgEGqlRNMeta?.path ?? "/uk/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetUZZgEGqlRNMeta || {},
    alias: safest_45fleetUZZgEGqlRNMeta?.alias || [],
    redirect: safest_45fleetUZZgEGqlRNMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetUZZgEGqlRNMeta?.name ?? "customers-topfleets-2019-safest-fleet___de",
    path: safest_45fleetUZZgEGqlRNMeta?.path ?? "/de/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetUZZgEGqlRNMeta || {},
    alias: safest_45fleetUZZgEGqlRNMeta?.alias || [],
    redirect: safest_45fleetUZZgEGqlRNMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetUZZgEGqlRNMeta?.name ?? "customers-topfleets-2019-safest-fleet___mx",
    path: safest_45fleetUZZgEGqlRNMeta?.path ?? "/mx/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetUZZgEGqlRNMeta || {},
    alias: safest_45fleetUZZgEGqlRNMeta?.alias || [],
    redirect: safest_45fleetUZZgEGqlRNMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetUZZgEGqlRNMeta?.name ?? "customers-topfleets-2019-safest-fleet___fr",
    path: safest_45fleetUZZgEGqlRNMeta?.path ?? "/fr/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetUZZgEGqlRNMeta || {},
    alias: safest_45fleetUZZgEGqlRNMeta?.alias || [],
    redirect: safest_45fleetUZZgEGqlRNMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetUZZgEGqlRNMeta?.name ?? "customers-topfleets-2019-safest-fleet___fr-ca",
    path: safest_45fleetUZZgEGqlRNMeta?.path ?? "/fr-ca/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetUZZgEGqlRNMeta || {},
    alias: safest_45fleetUZZgEGqlRNMeta?.alias || [],
    redirect: safest_45fleetUZZgEGqlRNMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: driver_45performance4gO8DizCYuMeta?.name ?? "customers-topfleets-2020-driver-performance___en-US",
    path: driver_45performance4gO8DizCYuMeta?.path ?? "/customers/topfleets/2020/driver-performance",
    meta: driver_45performance4gO8DizCYuMeta || {},
    alias: driver_45performance4gO8DizCYuMeta?.alias || [],
    redirect: driver_45performance4gO8DizCYuMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performance4gO8DizCYuMeta?.name ?? "customers-topfleets-2020-driver-performance___ca",
    path: driver_45performance4gO8DizCYuMeta?.path ?? "/ca/customers/topfleets/2020/driver-performance",
    meta: driver_45performance4gO8DizCYuMeta || {},
    alias: driver_45performance4gO8DizCYuMeta?.alias || [],
    redirect: driver_45performance4gO8DizCYuMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performance4gO8DizCYuMeta?.name ?? "customers-topfleets-2020-driver-performance___nl",
    path: driver_45performance4gO8DizCYuMeta?.path ?? "/nl/customers/topfleets/2020/driver-performance",
    meta: driver_45performance4gO8DizCYuMeta || {},
    alias: driver_45performance4gO8DizCYuMeta?.alias || [],
    redirect: driver_45performance4gO8DizCYuMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performance4gO8DizCYuMeta?.name ?? "customers-topfleets-2020-driver-performance___uk",
    path: driver_45performance4gO8DizCYuMeta?.path ?? "/uk/customers/topfleets/2020/driver-performance",
    meta: driver_45performance4gO8DizCYuMeta || {},
    alias: driver_45performance4gO8DizCYuMeta?.alias || [],
    redirect: driver_45performance4gO8DizCYuMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performance4gO8DizCYuMeta?.name ?? "customers-topfleets-2020-driver-performance___de",
    path: driver_45performance4gO8DizCYuMeta?.path ?? "/de/customers/topfleets/2020/driver-performance",
    meta: driver_45performance4gO8DizCYuMeta || {},
    alias: driver_45performance4gO8DizCYuMeta?.alias || [],
    redirect: driver_45performance4gO8DizCYuMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performance4gO8DizCYuMeta?.name ?? "customers-topfleets-2020-driver-performance___mx",
    path: driver_45performance4gO8DizCYuMeta?.path ?? "/mx/customers/topfleets/2020/driver-performance",
    meta: driver_45performance4gO8DizCYuMeta || {},
    alias: driver_45performance4gO8DizCYuMeta?.alias || [],
    redirect: driver_45performance4gO8DizCYuMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performance4gO8DizCYuMeta?.name ?? "customers-topfleets-2020-driver-performance___fr",
    path: driver_45performance4gO8DizCYuMeta?.path ?? "/fr/customers/topfleets/2020/driver-performance",
    meta: driver_45performance4gO8DizCYuMeta || {},
    alias: driver_45performance4gO8DizCYuMeta?.alias || [],
    redirect: driver_45performance4gO8DizCYuMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performance4gO8DizCYuMeta?.name ?? "customers-topfleets-2020-driver-performance___fr-ca",
    path: driver_45performance4gO8DizCYuMeta?.path ?? "/fr-ca/customers/topfleets/2020/driver-performance",
    meta: driver_45performance4gO8DizCYuMeta || {},
    alias: driver_45performance4gO8DizCYuMeta?.alias || [],
    redirect: driver_45performance4gO8DizCYuMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancegeQiyyGvnuMeta?.name ?? "customers-topfleets-2020-excellence-performance___en-US",
    path: excellence_45performancegeQiyyGvnuMeta?.path ?? "/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancegeQiyyGvnuMeta || {},
    alias: excellence_45performancegeQiyyGvnuMeta?.alias || [],
    redirect: excellence_45performancegeQiyyGvnuMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancegeQiyyGvnuMeta?.name ?? "customers-topfleets-2020-excellence-performance___ca",
    path: excellence_45performancegeQiyyGvnuMeta?.path ?? "/ca/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancegeQiyyGvnuMeta || {},
    alias: excellence_45performancegeQiyyGvnuMeta?.alias || [],
    redirect: excellence_45performancegeQiyyGvnuMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancegeQiyyGvnuMeta?.name ?? "customers-topfleets-2020-excellence-performance___nl",
    path: excellence_45performancegeQiyyGvnuMeta?.path ?? "/nl/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancegeQiyyGvnuMeta || {},
    alias: excellence_45performancegeQiyyGvnuMeta?.alias || [],
    redirect: excellence_45performancegeQiyyGvnuMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancegeQiyyGvnuMeta?.name ?? "customers-topfleets-2020-excellence-performance___uk",
    path: excellence_45performancegeQiyyGvnuMeta?.path ?? "/uk/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancegeQiyyGvnuMeta || {},
    alias: excellence_45performancegeQiyyGvnuMeta?.alias || [],
    redirect: excellence_45performancegeQiyyGvnuMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancegeQiyyGvnuMeta?.name ?? "customers-topfleets-2020-excellence-performance___de",
    path: excellence_45performancegeQiyyGvnuMeta?.path ?? "/de/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancegeQiyyGvnuMeta || {},
    alias: excellence_45performancegeQiyyGvnuMeta?.alias || [],
    redirect: excellence_45performancegeQiyyGvnuMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancegeQiyyGvnuMeta?.name ?? "customers-topfleets-2020-excellence-performance___mx",
    path: excellence_45performancegeQiyyGvnuMeta?.path ?? "/mx/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancegeQiyyGvnuMeta || {},
    alias: excellence_45performancegeQiyyGvnuMeta?.alias || [],
    redirect: excellence_45performancegeQiyyGvnuMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancegeQiyyGvnuMeta?.name ?? "customers-topfleets-2020-excellence-performance___fr",
    path: excellence_45performancegeQiyyGvnuMeta?.path ?? "/fr/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancegeQiyyGvnuMeta || {},
    alias: excellence_45performancegeQiyyGvnuMeta?.alias || [],
    redirect: excellence_45performancegeQiyyGvnuMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancegeQiyyGvnuMeta?.name ?? "customers-topfleets-2020-excellence-performance___fr-ca",
    path: excellence_45performancegeQiyyGvnuMeta?.path ?? "/fr-ca/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancegeQiyyGvnuMeta || {},
    alias: excellence_45performancegeQiyyGvnuMeta?.alias || [],
    redirect: excellence_45performancegeQiyyGvnuMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorhJEbsCp4VUMeta?.name ?? "customers-topfleets-2020-fleet-innovator___en-US",
    path: fleet_45innovatorhJEbsCp4VUMeta?.path ?? "/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorhJEbsCp4VUMeta || {},
    alias: fleet_45innovatorhJEbsCp4VUMeta?.alias || [],
    redirect: fleet_45innovatorhJEbsCp4VUMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorhJEbsCp4VUMeta?.name ?? "customers-topfleets-2020-fleet-innovator___ca",
    path: fleet_45innovatorhJEbsCp4VUMeta?.path ?? "/ca/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorhJEbsCp4VUMeta || {},
    alias: fleet_45innovatorhJEbsCp4VUMeta?.alias || [],
    redirect: fleet_45innovatorhJEbsCp4VUMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorhJEbsCp4VUMeta?.name ?? "customers-topfleets-2020-fleet-innovator___nl",
    path: fleet_45innovatorhJEbsCp4VUMeta?.path ?? "/nl/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorhJEbsCp4VUMeta || {},
    alias: fleet_45innovatorhJEbsCp4VUMeta?.alias || [],
    redirect: fleet_45innovatorhJEbsCp4VUMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorhJEbsCp4VUMeta?.name ?? "customers-topfleets-2020-fleet-innovator___uk",
    path: fleet_45innovatorhJEbsCp4VUMeta?.path ?? "/uk/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorhJEbsCp4VUMeta || {},
    alias: fleet_45innovatorhJEbsCp4VUMeta?.alias || [],
    redirect: fleet_45innovatorhJEbsCp4VUMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorhJEbsCp4VUMeta?.name ?? "customers-topfleets-2020-fleet-innovator___de",
    path: fleet_45innovatorhJEbsCp4VUMeta?.path ?? "/de/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorhJEbsCp4VUMeta || {},
    alias: fleet_45innovatorhJEbsCp4VUMeta?.alias || [],
    redirect: fleet_45innovatorhJEbsCp4VUMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorhJEbsCp4VUMeta?.name ?? "customers-topfleets-2020-fleet-innovator___mx",
    path: fleet_45innovatorhJEbsCp4VUMeta?.path ?? "/mx/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorhJEbsCp4VUMeta || {},
    alias: fleet_45innovatorhJEbsCp4VUMeta?.alias || [],
    redirect: fleet_45innovatorhJEbsCp4VUMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorhJEbsCp4VUMeta?.name ?? "customers-topfleets-2020-fleet-innovator___fr",
    path: fleet_45innovatorhJEbsCp4VUMeta?.path ?? "/fr/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorhJEbsCp4VUMeta || {},
    alias: fleet_45innovatorhJEbsCp4VUMeta?.alias || [],
    redirect: fleet_45innovatorhJEbsCp4VUMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorhJEbsCp4VUMeta?.name ?? "customers-topfleets-2020-fleet-innovator___fr-ca",
    path: fleet_45innovatorhJEbsCp4VUMeta?.path ?? "/fr-ca/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorhJEbsCp4VUMeta || {},
    alias: fleet_45innovatorhJEbsCp4VUMeta?.alias || [],
    redirect: fleet_45innovatorhJEbsCp4VUMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: index5rPqLx6tCgMeta?.name ?? "customers-topfleets-2020___en-US",
    path: index5rPqLx6tCgMeta?.path ?? "/customers/topfleets/2020",
    meta: index5rPqLx6tCgMeta || {},
    alias: index5rPqLx6tCgMeta?.alias || [],
    redirect: index5rPqLx6tCgMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: index5rPqLx6tCgMeta?.name ?? "customers-topfleets-2020___ca",
    path: index5rPqLx6tCgMeta?.path ?? "/ca/customers/topfleets/2020",
    meta: index5rPqLx6tCgMeta || {},
    alias: index5rPqLx6tCgMeta?.alias || [],
    redirect: index5rPqLx6tCgMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: index5rPqLx6tCgMeta?.name ?? "customers-topfleets-2020___nl",
    path: index5rPqLx6tCgMeta?.path ?? "/nl/customers/topfleets/2020",
    meta: index5rPqLx6tCgMeta || {},
    alias: index5rPqLx6tCgMeta?.alias || [],
    redirect: index5rPqLx6tCgMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: index5rPqLx6tCgMeta?.name ?? "customers-topfleets-2020___uk",
    path: index5rPqLx6tCgMeta?.path ?? "/uk/customers/topfleets/2020",
    meta: index5rPqLx6tCgMeta || {},
    alias: index5rPqLx6tCgMeta?.alias || [],
    redirect: index5rPqLx6tCgMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: index5rPqLx6tCgMeta?.name ?? "customers-topfleets-2020___de",
    path: index5rPqLx6tCgMeta?.path ?? "/de/customers/topfleets/2020",
    meta: index5rPqLx6tCgMeta || {},
    alias: index5rPqLx6tCgMeta?.alias || [],
    redirect: index5rPqLx6tCgMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: index5rPqLx6tCgMeta?.name ?? "customers-topfleets-2020___mx",
    path: index5rPqLx6tCgMeta?.path ?? "/mx/customers/topfleets/2020",
    meta: index5rPqLx6tCgMeta || {},
    alias: index5rPqLx6tCgMeta?.alias || [],
    redirect: index5rPqLx6tCgMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: index5rPqLx6tCgMeta?.name ?? "customers-topfleets-2020___fr",
    path: index5rPqLx6tCgMeta?.path ?? "/fr/customers/topfleets/2020",
    meta: index5rPqLx6tCgMeta || {},
    alias: index5rPqLx6tCgMeta?.alias || [],
    redirect: index5rPqLx6tCgMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: index5rPqLx6tCgMeta?.name ?? "customers-topfleets-2020___fr-ca",
    path: index5rPqLx6tCgMeta?.path ?? "/fr-ca/customers/topfleets/2020",
    meta: index5rPqLx6tCgMeta || {},
    alias: index5rPqLx6tCgMeta?.alias || [],
    redirect: index5rPqLx6tCgMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetVL2g58bijeMeta?.name ?? "customers-topfleets-2020-public-fleet___en-US",
    path: public_45fleetVL2g58bijeMeta?.path ?? "/customers/topfleets/2020/public-fleet",
    meta: public_45fleetVL2g58bijeMeta || {},
    alias: public_45fleetVL2g58bijeMeta?.alias || [],
    redirect: public_45fleetVL2g58bijeMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetVL2g58bijeMeta?.name ?? "customers-topfleets-2020-public-fleet___ca",
    path: public_45fleetVL2g58bijeMeta?.path ?? "/ca/customers/topfleets/2020/public-fleet",
    meta: public_45fleetVL2g58bijeMeta || {},
    alias: public_45fleetVL2g58bijeMeta?.alias || [],
    redirect: public_45fleetVL2g58bijeMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetVL2g58bijeMeta?.name ?? "customers-topfleets-2020-public-fleet___nl",
    path: public_45fleetVL2g58bijeMeta?.path ?? "/nl/customers/topfleets/2020/public-fleet",
    meta: public_45fleetVL2g58bijeMeta || {},
    alias: public_45fleetVL2g58bijeMeta?.alias || [],
    redirect: public_45fleetVL2g58bijeMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetVL2g58bijeMeta?.name ?? "customers-topfleets-2020-public-fleet___uk",
    path: public_45fleetVL2g58bijeMeta?.path ?? "/uk/customers/topfleets/2020/public-fleet",
    meta: public_45fleetVL2g58bijeMeta || {},
    alias: public_45fleetVL2g58bijeMeta?.alias || [],
    redirect: public_45fleetVL2g58bijeMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetVL2g58bijeMeta?.name ?? "customers-topfleets-2020-public-fleet___de",
    path: public_45fleetVL2g58bijeMeta?.path ?? "/de/customers/topfleets/2020/public-fleet",
    meta: public_45fleetVL2g58bijeMeta || {},
    alias: public_45fleetVL2g58bijeMeta?.alias || [],
    redirect: public_45fleetVL2g58bijeMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetVL2g58bijeMeta?.name ?? "customers-topfleets-2020-public-fleet___mx",
    path: public_45fleetVL2g58bijeMeta?.path ?? "/mx/customers/topfleets/2020/public-fleet",
    meta: public_45fleetVL2g58bijeMeta || {},
    alias: public_45fleetVL2g58bijeMeta?.alias || [],
    redirect: public_45fleetVL2g58bijeMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetVL2g58bijeMeta?.name ?? "customers-topfleets-2020-public-fleet___fr",
    path: public_45fleetVL2g58bijeMeta?.path ?? "/fr/customers/topfleets/2020/public-fleet",
    meta: public_45fleetVL2g58bijeMeta || {},
    alias: public_45fleetVL2g58bijeMeta?.alias || [],
    redirect: public_45fleetVL2g58bijeMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetVL2g58bijeMeta?.name ?? "customers-topfleets-2020-public-fleet___fr-ca",
    path: public_45fleetVL2g58bijeMeta?.path ?? "/fr-ca/customers/topfleets/2020/public-fleet",
    meta: public_45fleetVL2g58bijeMeta || {},
    alias: public_45fleetVL2g58bijeMeta?.alias || [],
    redirect: public_45fleetVL2g58bijeMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetnxKDOcqx92Meta?.name ?? "customers-topfleets-2020-safest-fleet___en-US",
    path: safest_45fleetnxKDOcqx92Meta?.path ?? "/customers/topfleets/2020/safest-fleet",
    meta: safest_45fleetnxKDOcqx92Meta || {},
    alias: safest_45fleetnxKDOcqx92Meta?.alias || [],
    redirect: safest_45fleetnxKDOcqx92Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetnxKDOcqx92Meta?.name ?? "customers-topfleets-2020-safest-fleet___ca",
    path: safest_45fleetnxKDOcqx92Meta?.path ?? "/ca/customers/topfleets/2020/safest-fleet",
    meta: safest_45fleetnxKDOcqx92Meta || {},
    alias: safest_45fleetnxKDOcqx92Meta?.alias || [],
    redirect: safest_45fleetnxKDOcqx92Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: indexnm1qelVdyOMeta?.name ?? "customers-topfleets___en-US",
    path: indexnm1qelVdyOMeta?.path ?? "/customers/topfleets",
    meta: indexnm1qelVdyOMeta || {},
    alias: indexnm1qelVdyOMeta?.alias || [],
    redirect: indexnm1qelVdyOMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexnm1qelVdyOMeta?.name ?? "customers-topfleets___ca",
    path: indexnm1qelVdyOMeta?.path ?? "/ca/customers/topfleets",
    meta: indexnm1qelVdyOMeta || {},
    alias: indexnm1qelVdyOMeta?.alias || [],
    redirect: indexnm1qelVdyOMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexnm1qelVdyOMeta?.name ?? "customers-topfleets___nl",
    path: indexnm1qelVdyOMeta?.path ?? "/nl/customers/topfleets",
    meta: indexnm1qelVdyOMeta || {},
    alias: indexnm1qelVdyOMeta?.alias || [],
    redirect: indexnm1qelVdyOMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexnm1qelVdyOMeta?.name ?? "customers-topfleets___uk",
    path: indexnm1qelVdyOMeta?.path ?? "/uk/customers/topfleets",
    meta: indexnm1qelVdyOMeta || {},
    alias: indexnm1qelVdyOMeta?.alias || [],
    redirect: indexnm1qelVdyOMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexnm1qelVdyOMeta?.name ?? "customers-topfleets___de",
    path: indexnm1qelVdyOMeta?.path ?? "/de/customers/topfleets",
    meta: indexnm1qelVdyOMeta || {},
    alias: indexnm1qelVdyOMeta?.alias || [],
    redirect: indexnm1qelVdyOMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexnm1qelVdyOMeta?.name ?? "customers-topfleets___mx",
    path: indexnm1qelVdyOMeta?.path ?? "/mx/customers/topfleets",
    meta: indexnm1qelVdyOMeta || {},
    alias: indexnm1qelVdyOMeta?.alias || [],
    redirect: indexnm1qelVdyOMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexnm1qelVdyOMeta?.name ?? "customers-topfleets___fr",
    path: indexnm1qelVdyOMeta?.path ?? "/fr/customers/topfleets",
    meta: indexnm1qelVdyOMeta || {},
    alias: indexnm1qelVdyOMeta?.alias || [],
    redirect: indexnm1qelVdyOMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexnm1qelVdyOMeta?.name ?? "customers-topfleets___fr-ca",
    path: indexnm1qelVdyOMeta?.path ?? "/fr-ca/customers/topfleets",
    meta: indexnm1qelVdyOMeta || {},
    alias: indexnm1qelVdyOMeta?.alias || [],
    redirect: indexnm1qelVdyOMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lUJkJ8Fg14Meta?.name ?? "events-slug___en-US",
    path: _91slug_93lUJkJ8Fg14Meta?.path ?? "/events/:slug()",
    meta: _91slug_93lUJkJ8Fg14Meta || {},
    alias: _91slug_93lUJkJ8Fg14Meta?.alias || [],
    redirect: _91slug_93lUJkJ8Fg14Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lUJkJ8Fg14Meta?.name ?? "events-slug___ca",
    path: _91slug_93lUJkJ8Fg14Meta?.path ?? "/ca/events/:slug()",
    meta: _91slug_93lUJkJ8Fg14Meta || {},
    alias: _91slug_93lUJkJ8Fg14Meta?.alias || [],
    redirect: _91slug_93lUJkJ8Fg14Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lUJkJ8Fg14Meta?.name ?? "events-slug___nl",
    path: _91slug_93lUJkJ8Fg14Meta?.path ?? "/nl/events/:slug()",
    meta: _91slug_93lUJkJ8Fg14Meta || {},
    alias: _91slug_93lUJkJ8Fg14Meta?.alias || [],
    redirect: _91slug_93lUJkJ8Fg14Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lUJkJ8Fg14Meta?.name ?? "events-slug___uk",
    path: _91slug_93lUJkJ8Fg14Meta?.path ?? "/uk/events/:slug()",
    meta: _91slug_93lUJkJ8Fg14Meta || {},
    alias: _91slug_93lUJkJ8Fg14Meta?.alias || [],
    redirect: _91slug_93lUJkJ8Fg14Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lUJkJ8Fg14Meta?.name ?? "events-slug___de",
    path: _91slug_93lUJkJ8Fg14Meta?.path ?? "/de/events/:slug()",
    meta: _91slug_93lUJkJ8Fg14Meta || {},
    alias: _91slug_93lUJkJ8Fg14Meta?.alias || [],
    redirect: _91slug_93lUJkJ8Fg14Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lUJkJ8Fg14Meta?.name ?? "events-slug___mx",
    path: _91slug_93lUJkJ8Fg14Meta?.path ?? "/mx/events/:slug()",
    meta: _91slug_93lUJkJ8Fg14Meta || {},
    alias: _91slug_93lUJkJ8Fg14Meta?.alias || [],
    redirect: _91slug_93lUJkJ8Fg14Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lUJkJ8Fg14Meta?.name ?? "events-slug___fr",
    path: _91slug_93lUJkJ8Fg14Meta?.path ?? "/fr/events/:slug()",
    meta: _91slug_93lUJkJ8Fg14Meta || {},
    alias: _91slug_93lUJkJ8Fg14Meta?.alias || [],
    redirect: _91slug_93lUJkJ8Fg14Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lUJkJ8Fg14Meta?.name ?? "events-slug___fr-ca",
    path: _91slug_93lUJkJ8Fg14Meta?.path ?? "/fr-ca/events/:slug()",
    meta: _91slug_93lUJkJ8Fg14Meta || {},
    alias: _91slug_93lUJkJ8Fg14Meta?.alias || [],
    redirect: _91slug_93lUJkJ8Fg14Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexkFFpAYnVopMeta?.name ?? "events___en-US",
    path: indexkFFpAYnVopMeta?.path ?? "/events",
    meta: indexkFFpAYnVopMeta || {},
    alias: indexkFFpAYnVopMeta?.alias || [],
    redirect: indexkFFpAYnVopMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexkFFpAYnVopMeta?.name ?? "events___ca",
    path: indexkFFpAYnVopMeta?.path ?? "/ca/events",
    meta: indexkFFpAYnVopMeta || {},
    alias: indexkFFpAYnVopMeta?.alias || [],
    redirect: indexkFFpAYnVopMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexkFFpAYnVopMeta?.name ?? "events___nl",
    path: indexkFFpAYnVopMeta?.path ?? "/nl/events",
    meta: indexkFFpAYnVopMeta || {},
    alias: indexkFFpAYnVopMeta?.alias || [],
    redirect: indexkFFpAYnVopMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexkFFpAYnVopMeta?.name ?? "events___uk",
    path: indexkFFpAYnVopMeta?.path ?? "/uk/events",
    meta: indexkFFpAYnVopMeta || {},
    alias: indexkFFpAYnVopMeta?.alias || [],
    redirect: indexkFFpAYnVopMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexkFFpAYnVopMeta?.name ?? "events___de",
    path: indexkFFpAYnVopMeta?.path ?? "/de/events",
    meta: indexkFFpAYnVopMeta || {},
    alias: indexkFFpAYnVopMeta?.alias || [],
    redirect: indexkFFpAYnVopMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexkFFpAYnVopMeta?.name ?? "events___mx",
    path: indexkFFpAYnVopMeta?.path ?? "/mx/events",
    meta: indexkFFpAYnVopMeta || {},
    alias: indexkFFpAYnVopMeta?.alias || [],
    redirect: indexkFFpAYnVopMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexkFFpAYnVopMeta?.name ?? "events___fr",
    path: indexkFFpAYnVopMeta?.path ?? "/fr/events",
    meta: indexkFFpAYnVopMeta || {},
    alias: indexkFFpAYnVopMeta?.alias || [],
    redirect: indexkFFpAYnVopMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexkFFpAYnVopMeta?.name ?? "events___fr-ca",
    path: indexkFFpAYnVopMeta?.path ?? "/fr-ca/events",
    meta: indexkFFpAYnVopMeta || {},
    alias: indexkFFpAYnVopMeta?.alias || [],
    redirect: indexkFFpAYnVopMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appxAh58vdQ0YMeta?.name ?? "fleet-app___en-US",
    path: fleet_45appxAh58vdQ0YMeta?.path ?? "/fleet-app",
    meta: fleet_45appxAh58vdQ0YMeta || {},
    alias: fleet_45appxAh58vdQ0YMeta?.alias || [],
    redirect: fleet_45appxAh58vdQ0YMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appxAh58vdQ0YMeta?.name ?? "fleet-app___ca",
    path: fleet_45appxAh58vdQ0YMeta?.path ?? "/ca/fleet-app",
    meta: fleet_45appxAh58vdQ0YMeta || {},
    alias: fleet_45appxAh58vdQ0YMeta?.alias || [],
    redirect: fleet_45appxAh58vdQ0YMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appxAh58vdQ0YMeta?.name ?? "fleet-app___nl",
    path: fleet_45appxAh58vdQ0YMeta?.path ?? "/nl/fleet-app",
    meta: fleet_45appxAh58vdQ0YMeta || {},
    alias: fleet_45appxAh58vdQ0YMeta?.alias || [],
    redirect: fleet_45appxAh58vdQ0YMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appxAh58vdQ0YMeta?.name ?? "fleet-app___uk",
    path: fleet_45appxAh58vdQ0YMeta?.path ?? "/uk/fleet-app",
    meta: fleet_45appxAh58vdQ0YMeta || {},
    alias: fleet_45appxAh58vdQ0YMeta?.alias || [],
    redirect: fleet_45appxAh58vdQ0YMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appxAh58vdQ0YMeta?.name ?? "fleet-app___de",
    path: fleet_45appxAh58vdQ0YMeta?.path ?? "/de/fleet-app",
    meta: fleet_45appxAh58vdQ0YMeta || {},
    alias: fleet_45appxAh58vdQ0YMeta?.alias || [],
    redirect: fleet_45appxAh58vdQ0YMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appxAh58vdQ0YMeta?.name ?? "fleet-app___mx",
    path: fleet_45appxAh58vdQ0YMeta?.path ?? "/mx/fleet-app",
    meta: fleet_45appxAh58vdQ0YMeta || {},
    alias: fleet_45appxAh58vdQ0YMeta?.alias || [],
    redirect: fleet_45appxAh58vdQ0YMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appxAh58vdQ0YMeta?.name ?? "fleet-app___fr",
    path: fleet_45appxAh58vdQ0YMeta?.path ?? "/fr/fleet-app",
    meta: fleet_45appxAh58vdQ0YMeta || {},
    alias: fleet_45appxAh58vdQ0YMeta?.alias || [],
    redirect: fleet_45appxAh58vdQ0YMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appxAh58vdQ0YMeta?.name ?? "fleet-app___fr-ca",
    path: fleet_45appxAh58vdQ0YMeta?.path ?? "/fr-ca/fleet-app",
    meta: fleet_45appxAh58vdQ0YMeta || {},
    alias: fleet_45appxAh58vdQ0YMeta?.alias || [],
    redirect: fleet_45appxAh58vdQ0YMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zX7UVNDZRoMeta?.name ?? "fleet-prefix-slug___en-US",
    path: _91slug_93zX7UVNDZRoMeta?.path ?? "/fleet/:prefix()/:slug()",
    meta: _91slug_93zX7UVNDZRoMeta || {},
    alias: _91slug_93zX7UVNDZRoMeta?.alias || [],
    redirect: _91slug_93zX7UVNDZRoMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zX7UVNDZRoMeta?.name ?? "fleet-prefix-slug___ca",
    path: _91slug_93zX7UVNDZRoMeta?.path ?? "/ca/fleet/:prefix()/:slug()",
    meta: _91slug_93zX7UVNDZRoMeta || {},
    alias: _91slug_93zX7UVNDZRoMeta?.alias || [],
    redirect: _91slug_93zX7UVNDZRoMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zX7UVNDZRoMeta?.name ?? "fleet-prefix-slug___nl",
    path: _91slug_93zX7UVNDZRoMeta?.path ?? "/nl/fleet/:prefix()/:slug()",
    meta: _91slug_93zX7UVNDZRoMeta || {},
    alias: _91slug_93zX7UVNDZRoMeta?.alias || [],
    redirect: _91slug_93zX7UVNDZRoMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zX7UVNDZRoMeta?.name ?? "fleet-prefix-slug___uk",
    path: _91slug_93zX7UVNDZRoMeta?.path ?? "/uk/fleet/:prefix()/:slug()",
    meta: _91slug_93zX7UVNDZRoMeta || {},
    alias: _91slug_93zX7UVNDZRoMeta?.alias || [],
    redirect: _91slug_93zX7UVNDZRoMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zX7UVNDZRoMeta?.name ?? "fleet-prefix-slug___de",
    path: _91slug_93zX7UVNDZRoMeta?.path ?? "/de/fleet/:prefix()/:slug()",
    meta: _91slug_93zX7UVNDZRoMeta || {},
    alias: _91slug_93zX7UVNDZRoMeta?.alias || [],
    redirect: _91slug_93zX7UVNDZRoMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zX7UVNDZRoMeta?.name ?? "fleet-prefix-slug___mx",
    path: _91slug_93zX7UVNDZRoMeta?.path ?? "/mx/fleet/:prefix()/:slug()",
    meta: _91slug_93zX7UVNDZRoMeta || {},
    alias: _91slug_93zX7UVNDZRoMeta?.alias || [],
    redirect: _91slug_93zX7UVNDZRoMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zX7UVNDZRoMeta?.name ?? "fleet-prefix-slug___fr",
    path: _91slug_93zX7UVNDZRoMeta?.path ?? "/fr/fleet/:prefix()/:slug()",
    meta: _91slug_93zX7UVNDZRoMeta || {},
    alias: _91slug_93zX7UVNDZRoMeta?.alias || [],
    redirect: _91slug_93zX7UVNDZRoMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zX7UVNDZRoMeta?.name ?? "fleet-prefix-slug___fr-ca",
    path: _91slug_93zX7UVNDZRoMeta?.path ?? "/fr-ca/fleet/:prefix()/:slug()",
    meta: _91slug_93zX7UVNDZRoMeta || {},
    alias: _91slug_93zX7UVNDZRoMeta?.alias || [],
    redirect: _91slug_93zX7UVNDZRoMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931vFeSRQVIoMeta?.name ?? "fleet-slug___en-US",
    path: _91slug_931vFeSRQVIoMeta?.path ?? "/fleet/:slug()",
    meta: _91slug_931vFeSRQVIoMeta || {},
    alias: _91slug_931vFeSRQVIoMeta?.alias || [],
    redirect: _91slug_931vFeSRQVIoMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931vFeSRQVIoMeta?.name ?? "fleet-slug___ca",
    path: _91slug_931vFeSRQVIoMeta?.path ?? "/ca/fleet/:slug()",
    meta: _91slug_931vFeSRQVIoMeta || {},
    alias: _91slug_931vFeSRQVIoMeta?.alias || [],
    redirect: _91slug_931vFeSRQVIoMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931vFeSRQVIoMeta?.name ?? "fleet-slug___nl",
    path: _91slug_931vFeSRQVIoMeta?.path ?? "/nl/fleet/:slug()",
    meta: _91slug_931vFeSRQVIoMeta || {},
    alias: _91slug_931vFeSRQVIoMeta?.alias || [],
    redirect: _91slug_931vFeSRQVIoMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931vFeSRQVIoMeta?.name ?? "fleet-slug___uk",
    path: _91slug_931vFeSRQVIoMeta?.path ?? "/uk/fleet/:slug()",
    meta: _91slug_931vFeSRQVIoMeta || {},
    alias: _91slug_931vFeSRQVIoMeta?.alias || [],
    redirect: _91slug_931vFeSRQVIoMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931vFeSRQVIoMeta?.name ?? "fleet-slug___de",
    path: _91slug_931vFeSRQVIoMeta?.path ?? "/de/fleet/:slug()",
    meta: _91slug_931vFeSRQVIoMeta || {},
    alias: _91slug_931vFeSRQVIoMeta?.alias || [],
    redirect: _91slug_931vFeSRQVIoMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931vFeSRQVIoMeta?.name ?? "fleet-slug___mx",
    path: _91slug_931vFeSRQVIoMeta?.path ?? "/mx/fleet/:slug()",
    meta: _91slug_931vFeSRQVIoMeta || {},
    alias: _91slug_931vFeSRQVIoMeta?.alias || [],
    redirect: _91slug_931vFeSRQVIoMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931vFeSRQVIoMeta?.name ?? "fleet-slug___fr",
    path: _91slug_931vFeSRQVIoMeta?.path ?? "/fr/fleet/:slug()",
    meta: _91slug_931vFeSRQVIoMeta || {},
    alias: _91slug_931vFeSRQVIoMeta?.alias || [],
    redirect: _91slug_931vFeSRQVIoMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931vFeSRQVIoMeta?.name ?? "fleet-slug___fr-ca",
    path: _91slug_931vFeSRQVIoMeta?.path ?? "/fr-ca/fleet/:slug()",
    meta: _91slug_931vFeSRQVIoMeta || {},
    alias: _91slug_931vFeSRQVIoMeta?.alias || [],
    redirect: _91slug_931vFeSRQVIoMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fxU8k5RBsrMeta?.name ?? "fleets-prefix-slug___en-US",
    path: _91slug_93fxU8k5RBsrMeta?.path ?? "/fleets/:prefix()/:slug()",
    meta: _91slug_93fxU8k5RBsrMeta || {},
    alias: _91slug_93fxU8k5RBsrMeta?.alias || [],
    redirect: _91slug_93fxU8k5RBsrMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fxU8k5RBsrMeta?.name ?? "fleets-prefix-slug___ca",
    path: _91slug_93fxU8k5RBsrMeta?.path ?? "/ca/fleets/:prefix()/:slug()",
    meta: _91slug_93fxU8k5RBsrMeta || {},
    alias: _91slug_93fxU8k5RBsrMeta?.alias || [],
    redirect: _91slug_93fxU8k5RBsrMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fxU8k5RBsrMeta?.name ?? "fleets-prefix-slug___nl",
    path: _91slug_93fxU8k5RBsrMeta?.path ?? "/nl/fleets/:prefix()/:slug()",
    meta: _91slug_93fxU8k5RBsrMeta || {},
    alias: _91slug_93fxU8k5RBsrMeta?.alias || [],
    redirect: _91slug_93fxU8k5RBsrMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fxU8k5RBsrMeta?.name ?? "fleets-prefix-slug___uk",
    path: _91slug_93fxU8k5RBsrMeta?.path ?? "/uk/fleets/:prefix()/:slug()",
    meta: _91slug_93fxU8k5RBsrMeta || {},
    alias: _91slug_93fxU8k5RBsrMeta?.alias || [],
    redirect: _91slug_93fxU8k5RBsrMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fxU8k5RBsrMeta?.name ?? "fleets-prefix-slug___de",
    path: _91slug_93fxU8k5RBsrMeta?.path ?? "/de/fleets/:prefix()/:slug()",
    meta: _91slug_93fxU8k5RBsrMeta || {},
    alias: _91slug_93fxU8k5RBsrMeta?.alias || [],
    redirect: _91slug_93fxU8k5RBsrMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fxU8k5RBsrMeta?.name ?? "fleets-prefix-slug___mx",
    path: _91slug_93fxU8k5RBsrMeta?.path ?? "/mx/fleets/:prefix()/:slug()",
    meta: _91slug_93fxU8k5RBsrMeta || {},
    alias: _91slug_93fxU8k5RBsrMeta?.alias || [],
    redirect: _91slug_93fxU8k5RBsrMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fxU8k5RBsrMeta?.name ?? "fleets-prefix-slug___fr",
    path: _91slug_93fxU8k5RBsrMeta?.path ?? "/fr/fleets/:prefix()/:slug()",
    meta: _91slug_93fxU8k5RBsrMeta || {},
    alias: _91slug_93fxU8k5RBsrMeta?.alias || [],
    redirect: _91slug_93fxU8k5RBsrMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fxU8k5RBsrMeta?.name ?? "fleets-prefix-slug___fr-ca",
    path: _91slug_93fxU8k5RBsrMeta?.path ?? "/fr-ca/fleets/:prefix()/:slug()",
    meta: _91slug_93fxU8k5RBsrMeta || {},
    alias: _91slug_93fxU8k5RBsrMeta?.alias || [],
    redirect: _91slug_93fxU8k5RBsrMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Lk61ENg1jeMeta?.name ?? "fleets-slug___en-US",
    path: _91slug_93Lk61ENg1jeMeta?.path ?? "/fleets/:slug()",
    meta: _91slug_93Lk61ENg1jeMeta || {},
    alias: _91slug_93Lk61ENg1jeMeta?.alias || [],
    redirect: _91slug_93Lk61ENg1jeMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Lk61ENg1jeMeta?.name ?? "fleets-slug___ca",
    path: _91slug_93Lk61ENg1jeMeta?.path ?? "/ca/fleets/:slug()",
    meta: _91slug_93Lk61ENg1jeMeta || {},
    alias: _91slug_93Lk61ENg1jeMeta?.alias || [],
    redirect: _91slug_93Lk61ENg1jeMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Lk61ENg1jeMeta?.name ?? "fleets-slug___nl",
    path: _91slug_93Lk61ENg1jeMeta?.path ?? "/nl/fleets/:slug()",
    meta: _91slug_93Lk61ENg1jeMeta || {},
    alias: _91slug_93Lk61ENg1jeMeta?.alias || [],
    redirect: _91slug_93Lk61ENg1jeMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Lk61ENg1jeMeta?.name ?? "fleets-slug___uk",
    path: _91slug_93Lk61ENg1jeMeta?.path ?? "/uk/fleets/:slug()",
    meta: _91slug_93Lk61ENg1jeMeta || {},
    alias: _91slug_93Lk61ENg1jeMeta?.alias || [],
    redirect: _91slug_93Lk61ENg1jeMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Lk61ENg1jeMeta?.name ?? "fleets-slug___de",
    path: _91slug_93Lk61ENg1jeMeta?.path ?? "/de/fleets/:slug()",
    meta: _91slug_93Lk61ENg1jeMeta || {},
    alias: _91slug_93Lk61ENg1jeMeta?.alias || [],
    redirect: _91slug_93Lk61ENg1jeMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Lk61ENg1jeMeta?.name ?? "fleets-slug___mx",
    path: _91slug_93Lk61ENg1jeMeta?.path ?? "/mx/fleets/:slug()",
    meta: _91slug_93Lk61ENg1jeMeta || {},
    alias: _91slug_93Lk61ENg1jeMeta?.alias || [],
    redirect: _91slug_93Lk61ENg1jeMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Lk61ENg1jeMeta?.name ?? "fleets-slug___fr",
    path: _91slug_93Lk61ENg1jeMeta?.path ?? "/fr/fleets/:slug()",
    meta: _91slug_93Lk61ENg1jeMeta || {},
    alias: _91slug_93Lk61ENg1jeMeta?.alias || [],
    redirect: _91slug_93Lk61ENg1jeMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Lk61ENg1jeMeta?.name ?? "fleets-slug___fr-ca",
    path: _91slug_93Lk61ENg1jeMeta?.path ?? "/fr-ca/fleets/:slug()",
    meta: _91slug_93Lk61ENg1jeMeta || {},
    alias: _91slug_93Lk61ENg1jeMeta?.alias || [],
    redirect: _91slug_93Lk61ENg1jeMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ckNd3jhw3JMeta?.name ?? "guides-slug___en-US",
    path: _91slug_93ckNd3jhw3JMeta?.path ?? "/guides/:slug()",
    meta: _91slug_93ckNd3jhw3JMeta || {},
    alias: _91slug_93ckNd3jhw3JMeta?.alias || [],
    redirect: _91slug_93ckNd3jhw3JMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ckNd3jhw3JMeta?.name ?? "guides-slug___ca",
    path: _91slug_93ckNd3jhw3JMeta?.path ?? "/ca/guides/:slug()",
    meta: _91slug_93ckNd3jhw3JMeta || {},
    alias: _91slug_93ckNd3jhw3JMeta?.alias || [],
    redirect: _91slug_93ckNd3jhw3JMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ckNd3jhw3JMeta?.name ?? "guides-slug___nl",
    path: _91slug_93ckNd3jhw3JMeta?.path ?? "/nl/guides/:slug()",
    meta: _91slug_93ckNd3jhw3JMeta || {},
    alias: _91slug_93ckNd3jhw3JMeta?.alias || [],
    redirect: _91slug_93ckNd3jhw3JMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ckNd3jhw3JMeta?.name ?? "guides-slug___uk",
    path: _91slug_93ckNd3jhw3JMeta?.path ?? "/uk/guides/:slug()",
    meta: _91slug_93ckNd3jhw3JMeta || {},
    alias: _91slug_93ckNd3jhw3JMeta?.alias || [],
    redirect: _91slug_93ckNd3jhw3JMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ckNd3jhw3JMeta?.name ?? "guides-slug___de",
    path: _91slug_93ckNd3jhw3JMeta?.path ?? "/de/guides/:slug()",
    meta: _91slug_93ckNd3jhw3JMeta || {},
    alias: _91slug_93ckNd3jhw3JMeta?.alias || [],
    redirect: _91slug_93ckNd3jhw3JMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ckNd3jhw3JMeta?.name ?? "guides-slug___mx",
    path: _91slug_93ckNd3jhw3JMeta?.path ?? "/mx/guides/:slug()",
    meta: _91slug_93ckNd3jhw3JMeta || {},
    alias: _91slug_93ckNd3jhw3JMeta?.alias || [],
    redirect: _91slug_93ckNd3jhw3JMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ckNd3jhw3JMeta?.name ?? "guides-slug___fr",
    path: _91slug_93ckNd3jhw3JMeta?.path ?? "/fr/guides/:slug()",
    meta: _91slug_93ckNd3jhw3JMeta || {},
    alias: _91slug_93ckNd3jhw3JMeta?.alias || [],
    redirect: _91slug_93ckNd3jhw3JMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ckNd3jhw3JMeta?.name ?? "guides-slug___fr-ca",
    path: _91slug_93ckNd3jhw3JMeta?.path ?? "/fr-ca/guides/:slug()",
    meta: _91slug_93ckNd3jhw3JMeta || {},
    alias: _91slug_93ckNd3jhw3JMeta?.alias || [],
    redirect: _91slug_93ckNd3jhw3JMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentZ3Bk9idIdFMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___en-US",
    path: attract_45retain_45talentZ3Bk9idIdFMeta?.path ?? "/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentZ3Bk9idIdFMeta || {},
    alias: attract_45retain_45talentZ3Bk9idIdFMeta?.alias || [],
    redirect: attract_45retain_45talentZ3Bk9idIdFMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentZ3Bk9idIdFMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___ca",
    path: attract_45retain_45talentZ3Bk9idIdFMeta?.path ?? "/ca/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentZ3Bk9idIdFMeta || {},
    alias: attract_45retain_45talentZ3Bk9idIdFMeta?.alias || [],
    redirect: attract_45retain_45talentZ3Bk9idIdFMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentZ3Bk9idIdFMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___nl",
    path: attract_45retain_45talentZ3Bk9idIdFMeta?.path ?? "/nl/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentZ3Bk9idIdFMeta || {},
    alias: attract_45retain_45talentZ3Bk9idIdFMeta?.alias || [],
    redirect: attract_45retain_45talentZ3Bk9idIdFMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentZ3Bk9idIdFMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___uk",
    path: attract_45retain_45talentZ3Bk9idIdFMeta?.path ?? "/uk/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentZ3Bk9idIdFMeta || {},
    alias: attract_45retain_45talentZ3Bk9idIdFMeta?.alias || [],
    redirect: attract_45retain_45talentZ3Bk9idIdFMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentZ3Bk9idIdFMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___de",
    path: attract_45retain_45talentZ3Bk9idIdFMeta?.path ?? "/de/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentZ3Bk9idIdFMeta || {},
    alias: attract_45retain_45talentZ3Bk9idIdFMeta?.alias || [],
    redirect: attract_45retain_45talentZ3Bk9idIdFMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentZ3Bk9idIdFMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___mx",
    path: attract_45retain_45talentZ3Bk9idIdFMeta?.path ?? "/mx/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentZ3Bk9idIdFMeta || {},
    alias: attract_45retain_45talentZ3Bk9idIdFMeta?.alias || [],
    redirect: attract_45retain_45talentZ3Bk9idIdFMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentZ3Bk9idIdFMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___fr",
    path: attract_45retain_45talentZ3Bk9idIdFMeta?.path ?? "/fr/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentZ3Bk9idIdFMeta || {},
    alias: attract_45retain_45talentZ3Bk9idIdFMeta?.alias || [],
    redirect: attract_45retain_45talentZ3Bk9idIdFMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentZ3Bk9idIdFMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___fr-ca",
    path: attract_45retain_45talentZ3Bk9idIdFMeta?.path ?? "/fr-ca/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentZ3Bk9idIdFMeta || {},
    alias: attract_45retain_45talentZ3Bk9idIdFMeta?.alias || [],
    redirect: attract_45retain_45talentZ3Bk9idIdFMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___en-US",
    path: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta?.path ?? "/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta || {},
    alias: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___ca",
    path: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta?.path ?? "/ca/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta || {},
    alias: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___nl",
    path: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta?.path ?? "/nl/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta || {},
    alias: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___uk",
    path: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta?.path ?? "/uk/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta || {},
    alias: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___de",
    path: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta?.path ?? "/de/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta || {},
    alias: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___mx",
    path: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta?.path ?? "/mx/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta || {},
    alias: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___fr",
    path: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta?.path ?? "/fr/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta || {},
    alias: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___fr-ca",
    path: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta?.path ?? "/fr-ca/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta || {},
    alias: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsUWT5joYgFLMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: indexjhCVxg7FzMMeta?.name ?? "guides-build-business-resilience___en-US",
    path: indexjhCVxg7FzMMeta?.path ?? "/guides/build-business-resilience",
    meta: indexjhCVxg7FzMMeta || {},
    alias: indexjhCVxg7FzMMeta?.alias || [],
    redirect: indexjhCVxg7FzMMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexjhCVxg7FzMMeta?.name ?? "guides-build-business-resilience___ca",
    path: indexjhCVxg7FzMMeta?.path ?? "/ca/guides/build-business-resilience",
    meta: indexjhCVxg7FzMMeta || {},
    alias: indexjhCVxg7FzMMeta?.alias || [],
    redirect: indexjhCVxg7FzMMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexjhCVxg7FzMMeta?.name ?? "guides-build-business-resilience___nl",
    path: indexjhCVxg7FzMMeta?.path ?? "/nl/guides/build-business-resilience",
    meta: indexjhCVxg7FzMMeta || {},
    alias: indexjhCVxg7FzMMeta?.alias || [],
    redirect: indexjhCVxg7FzMMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexjhCVxg7FzMMeta?.name ?? "guides-build-business-resilience___uk",
    path: indexjhCVxg7FzMMeta?.path ?? "/uk/guides/build-business-resilience",
    meta: indexjhCVxg7FzMMeta || {},
    alias: indexjhCVxg7FzMMeta?.alias || [],
    redirect: indexjhCVxg7FzMMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexjhCVxg7FzMMeta?.name ?? "guides-build-business-resilience___de",
    path: indexjhCVxg7FzMMeta?.path ?? "/de/guides/build-business-resilience",
    meta: indexjhCVxg7FzMMeta || {},
    alias: indexjhCVxg7FzMMeta?.alias || [],
    redirect: indexjhCVxg7FzMMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexjhCVxg7FzMMeta?.name ?? "guides-build-business-resilience___mx",
    path: indexjhCVxg7FzMMeta?.path ?? "/mx/guides/build-business-resilience",
    meta: indexjhCVxg7FzMMeta || {},
    alias: indexjhCVxg7FzMMeta?.alias || [],
    redirect: indexjhCVxg7FzMMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexjhCVxg7FzMMeta?.name ?? "guides-build-business-resilience___fr",
    path: indexjhCVxg7FzMMeta?.path ?? "/fr/guides/build-business-resilience",
    meta: indexjhCVxg7FzMMeta || {},
    alias: indexjhCVxg7FzMMeta?.alias || [],
    redirect: indexjhCVxg7FzMMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexjhCVxg7FzMMeta?.name ?? "guides-build-business-resilience___fr-ca",
    path: indexjhCVxg7FzMMeta?.path ?? "/fr-ca/guides/build-business-resilience",
    meta: indexjhCVxg7FzMMeta || {},
    alias: indexjhCVxg7FzMMeta?.alias || [],
    redirect: indexjhCVxg7FzMMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsWEV1t7oceHMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___en-US",
    path: protect_45optimize_45assetsWEV1t7oceHMeta?.path ?? "/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsWEV1t7oceHMeta || {},
    alias: protect_45optimize_45assetsWEV1t7oceHMeta?.alias || [],
    redirect: protect_45optimize_45assetsWEV1t7oceHMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsWEV1t7oceHMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___ca",
    path: protect_45optimize_45assetsWEV1t7oceHMeta?.path ?? "/ca/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsWEV1t7oceHMeta || {},
    alias: protect_45optimize_45assetsWEV1t7oceHMeta?.alias || [],
    redirect: protect_45optimize_45assetsWEV1t7oceHMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsWEV1t7oceHMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___nl",
    path: protect_45optimize_45assetsWEV1t7oceHMeta?.path ?? "/nl/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsWEV1t7oceHMeta || {},
    alias: protect_45optimize_45assetsWEV1t7oceHMeta?.alias || [],
    redirect: protect_45optimize_45assetsWEV1t7oceHMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsWEV1t7oceHMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___uk",
    path: protect_45optimize_45assetsWEV1t7oceHMeta?.path ?? "/uk/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsWEV1t7oceHMeta || {},
    alias: protect_45optimize_45assetsWEV1t7oceHMeta?.alias || [],
    redirect: protect_45optimize_45assetsWEV1t7oceHMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsWEV1t7oceHMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___de",
    path: protect_45optimize_45assetsWEV1t7oceHMeta?.path ?? "/de/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsWEV1t7oceHMeta || {},
    alias: protect_45optimize_45assetsWEV1t7oceHMeta?.alias || [],
    redirect: protect_45optimize_45assetsWEV1t7oceHMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsWEV1t7oceHMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___mx",
    path: protect_45optimize_45assetsWEV1t7oceHMeta?.path ?? "/mx/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsWEV1t7oceHMeta || {},
    alias: protect_45optimize_45assetsWEV1t7oceHMeta?.alias || [],
    redirect: protect_45optimize_45assetsWEV1t7oceHMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsWEV1t7oceHMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___fr",
    path: protect_45optimize_45assetsWEV1t7oceHMeta?.path ?? "/fr/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsWEV1t7oceHMeta || {},
    alias: protect_45optimize_45assetsWEV1t7oceHMeta?.alias || [],
    redirect: protect_45optimize_45assetsWEV1t7oceHMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsWEV1t7oceHMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___fr-ca",
    path: protect_45optimize_45assetsWEV1t7oceHMeta?.path ?? "/fr-ca/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsWEV1t7oceHMeta || {},
    alias: protect_45optimize_45assetsWEV1t7oceHMeta?.alias || [],
    redirect: protect_45optimize_45assetsWEV1t7oceHMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camswpmPLMVimCMeta?.name ?? "guides-fleet-safety-best-dash-cams___en-US",
    path: best_45dash_45camswpmPLMVimCMeta?.path ?? "/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camswpmPLMVimCMeta || {},
    alias: best_45dash_45camswpmPLMVimCMeta?.alias || [],
    redirect: best_45dash_45camswpmPLMVimCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camswpmPLMVimCMeta?.name ?? "guides-fleet-safety-best-dash-cams___ca",
    path: best_45dash_45camswpmPLMVimCMeta?.path ?? "/ca/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camswpmPLMVimCMeta || {},
    alias: best_45dash_45camswpmPLMVimCMeta?.alias || [],
    redirect: best_45dash_45camswpmPLMVimCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camswpmPLMVimCMeta?.name ?? "guides-fleet-safety-best-dash-cams___nl",
    path: best_45dash_45camswpmPLMVimCMeta?.path ?? "/nl/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camswpmPLMVimCMeta || {},
    alias: best_45dash_45camswpmPLMVimCMeta?.alias || [],
    redirect: best_45dash_45camswpmPLMVimCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camswpmPLMVimCMeta?.name ?? "guides-fleet-safety-best-dash-cams___uk",
    path: best_45dash_45camswpmPLMVimCMeta?.path ?? "/uk/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camswpmPLMVimCMeta || {},
    alias: best_45dash_45camswpmPLMVimCMeta?.alias || [],
    redirect: best_45dash_45camswpmPLMVimCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camswpmPLMVimCMeta?.name ?? "guides-fleet-safety-best-dash-cams___de",
    path: best_45dash_45camswpmPLMVimCMeta?.path ?? "/de/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camswpmPLMVimCMeta || {},
    alias: best_45dash_45camswpmPLMVimCMeta?.alias || [],
    redirect: best_45dash_45camswpmPLMVimCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camswpmPLMVimCMeta?.name ?? "guides-fleet-safety-best-dash-cams___mx",
    path: best_45dash_45camswpmPLMVimCMeta?.path ?? "/mx/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camswpmPLMVimCMeta || {},
    alias: best_45dash_45camswpmPLMVimCMeta?.alias || [],
    redirect: best_45dash_45camswpmPLMVimCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camswpmPLMVimCMeta?.name ?? "guides-fleet-safety-best-dash-cams___fr",
    path: best_45dash_45camswpmPLMVimCMeta?.path ?? "/fr/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camswpmPLMVimCMeta || {},
    alias: best_45dash_45camswpmPLMVimCMeta?.alias || [],
    redirect: best_45dash_45camswpmPLMVimCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camswpmPLMVimCMeta?.name ?? "guides-fleet-safety-best-dash-cams___fr-ca",
    path: best_45dash_45camswpmPLMVimCMeta?.path ?? "/fr-ca/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camswpmPLMVimCMeta || {},
    alias: best_45dash_45camswpmPLMVimCMeta?.alias || [],
    redirect: best_45dash_45camswpmPLMVimCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: indexWsoZPUCCp6Meta?.name ?? "guides-fleet-safety___en-US",
    path: indexWsoZPUCCp6Meta?.path ?? "/guides/fleet-safety",
    meta: indexWsoZPUCCp6Meta || {},
    alias: indexWsoZPUCCp6Meta?.alias || [],
    redirect: indexWsoZPUCCp6Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexWsoZPUCCp6Meta?.name ?? "guides-fleet-safety___ca",
    path: indexWsoZPUCCp6Meta?.path ?? "/ca/guides/fleet-safety",
    meta: indexWsoZPUCCp6Meta || {},
    alias: indexWsoZPUCCp6Meta?.alias || [],
    redirect: indexWsoZPUCCp6Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexWsoZPUCCp6Meta?.name ?? "guides-fleet-safety___nl",
    path: indexWsoZPUCCp6Meta?.path ?? "/nl/guides/fleet-safety",
    meta: indexWsoZPUCCp6Meta || {},
    alias: indexWsoZPUCCp6Meta?.alias || [],
    redirect: indexWsoZPUCCp6Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexWsoZPUCCp6Meta?.name ?? "guides-fleet-safety___uk",
    path: indexWsoZPUCCp6Meta?.path ?? "/uk/guides/fleet-safety",
    meta: indexWsoZPUCCp6Meta || {},
    alias: indexWsoZPUCCp6Meta?.alias || [],
    redirect: indexWsoZPUCCp6Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexWsoZPUCCp6Meta?.name ?? "guides-fleet-safety___de",
    path: indexWsoZPUCCp6Meta?.path ?? "/de/guides/fleet-safety",
    meta: indexWsoZPUCCp6Meta || {},
    alias: indexWsoZPUCCp6Meta?.alias || [],
    redirect: indexWsoZPUCCp6Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexWsoZPUCCp6Meta?.name ?? "guides-fleet-safety___mx",
    path: indexWsoZPUCCp6Meta?.path ?? "/mx/guias/seguridad-flotas",
    meta: indexWsoZPUCCp6Meta || {},
    alias: indexWsoZPUCCp6Meta?.alias || [],
    redirect: indexWsoZPUCCp6Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexWsoZPUCCp6Meta?.name ?? "guides-fleet-safety___fr",
    path: indexWsoZPUCCp6Meta?.path ?? "/fr/guides/fleet-safety",
    meta: indexWsoZPUCCp6Meta || {},
    alias: indexWsoZPUCCp6Meta?.alias || [],
    redirect: indexWsoZPUCCp6Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexWsoZPUCCp6Meta?.name ?? "guides-fleet-safety___fr-ca",
    path: indexWsoZPUCCp6Meta?.path ?? "/fr-ca/guides/fleet-safety",
    meta: indexWsoZPUCCp6Meta || {},
    alias: indexWsoZPUCCp6Meta?.alias || [],
    redirect: indexWsoZPUCCp6Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: safety_45programcWkKJGUzGMMeta?.name ?? "guides-fleet-safety-safety-program___en-US",
    path: safety_45programcWkKJGUzGMMeta?.path ?? "/guides/fleet-safety/safety-program",
    meta: safety_45programcWkKJGUzGMMeta || {},
    alias: safety_45programcWkKJGUzGMMeta?.alias || [],
    redirect: safety_45programcWkKJGUzGMMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programcWkKJGUzGMMeta?.name ?? "guides-fleet-safety-safety-program___ca",
    path: safety_45programcWkKJGUzGMMeta?.path ?? "/ca/guides/fleet-safety/safety-program",
    meta: safety_45programcWkKJGUzGMMeta || {},
    alias: safety_45programcWkKJGUzGMMeta?.alias || [],
    redirect: safety_45programcWkKJGUzGMMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programcWkKJGUzGMMeta?.name ?? "guides-fleet-safety-safety-program___nl",
    path: safety_45programcWkKJGUzGMMeta?.path ?? "/nl/guides/fleet-safety/safety-program",
    meta: safety_45programcWkKJGUzGMMeta || {},
    alias: safety_45programcWkKJGUzGMMeta?.alias || [],
    redirect: safety_45programcWkKJGUzGMMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programcWkKJGUzGMMeta?.name ?? "guides-fleet-safety-safety-program___uk",
    path: safety_45programcWkKJGUzGMMeta?.path ?? "/uk/guides/fleet-safety/safety-program",
    meta: safety_45programcWkKJGUzGMMeta || {},
    alias: safety_45programcWkKJGUzGMMeta?.alias || [],
    redirect: safety_45programcWkKJGUzGMMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programcWkKJGUzGMMeta?.name ?? "guides-fleet-safety-safety-program___de",
    path: safety_45programcWkKJGUzGMMeta?.path ?? "/de/guides/fleet-safety/safety-program",
    meta: safety_45programcWkKJGUzGMMeta || {},
    alias: safety_45programcWkKJGUzGMMeta?.alias || [],
    redirect: safety_45programcWkKJGUzGMMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programcWkKJGUzGMMeta?.name ?? "guides-fleet-safety-safety-program___mx",
    path: safety_45programcWkKJGUzGMMeta?.path ?? "/mx/guides/fleet-safety/safety-program",
    meta: safety_45programcWkKJGUzGMMeta || {},
    alias: safety_45programcWkKJGUzGMMeta?.alias || [],
    redirect: safety_45programcWkKJGUzGMMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programcWkKJGUzGMMeta?.name ?? "guides-fleet-safety-safety-program___fr",
    path: safety_45programcWkKJGUzGMMeta?.path ?? "/fr/guides/fleet-safety/safety-program",
    meta: safety_45programcWkKJGUzGMMeta || {},
    alias: safety_45programcWkKJGUzGMMeta?.alias || [],
    redirect: safety_45programcWkKJGUzGMMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programcWkKJGUzGMMeta?.name ?? "guides-fleet-safety-safety-program___fr-ca",
    path: safety_45programcWkKJGUzGMMeta?.path ?? "/fr-ca/guides/fleet-safety/safety-program",
    meta: safety_45programcWkKJGUzGMMeta || {},
    alias: safety_45programcWkKJGUzGMMeta?.alias || [],
    redirect: safety_45programcWkKJGUzGMMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiK8UDYUSoqzMeta?.name ?? "guides-fleet-safety-safety-roi___en-US",
    path: safety_45roiK8UDYUSoqzMeta?.path ?? "/guides/fleet-safety/safety-roi",
    meta: safety_45roiK8UDYUSoqzMeta || {},
    alias: safety_45roiK8UDYUSoqzMeta?.alias || [],
    redirect: safety_45roiK8UDYUSoqzMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiK8UDYUSoqzMeta?.name ?? "guides-fleet-safety-safety-roi___ca",
    path: safety_45roiK8UDYUSoqzMeta?.path ?? "/ca/guides/fleet-safety/safety-roi",
    meta: safety_45roiK8UDYUSoqzMeta || {},
    alias: safety_45roiK8UDYUSoqzMeta?.alias || [],
    redirect: safety_45roiK8UDYUSoqzMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiK8UDYUSoqzMeta?.name ?? "guides-fleet-safety-safety-roi___nl",
    path: safety_45roiK8UDYUSoqzMeta?.path ?? "/nl/guides/fleet-safety/safety-roi",
    meta: safety_45roiK8UDYUSoqzMeta || {},
    alias: safety_45roiK8UDYUSoqzMeta?.alias || [],
    redirect: safety_45roiK8UDYUSoqzMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiK8UDYUSoqzMeta?.name ?? "guides-fleet-safety-safety-roi___uk",
    path: safety_45roiK8UDYUSoqzMeta?.path ?? "/uk/guides/fleet-safety/safety-roi",
    meta: safety_45roiK8UDYUSoqzMeta || {},
    alias: safety_45roiK8UDYUSoqzMeta?.alias || [],
    redirect: safety_45roiK8UDYUSoqzMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiK8UDYUSoqzMeta?.name ?? "guides-fleet-safety-safety-roi___de",
    path: safety_45roiK8UDYUSoqzMeta?.path ?? "/de/guides/fleet-safety/safety-roi",
    meta: safety_45roiK8UDYUSoqzMeta || {},
    alias: safety_45roiK8UDYUSoqzMeta?.alias || [],
    redirect: safety_45roiK8UDYUSoqzMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiK8UDYUSoqzMeta?.name ?? "guides-fleet-safety-safety-roi___mx",
    path: safety_45roiK8UDYUSoqzMeta?.path ?? "/mx/guides/fleet-safety/safety-roi",
    meta: safety_45roiK8UDYUSoqzMeta || {},
    alias: safety_45roiK8UDYUSoqzMeta?.alias || [],
    redirect: safety_45roiK8UDYUSoqzMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiK8UDYUSoqzMeta?.name ?? "guides-fleet-safety-safety-roi___fr",
    path: safety_45roiK8UDYUSoqzMeta?.path ?? "/fr/guides/fleet-safety/safety-roi",
    meta: safety_45roiK8UDYUSoqzMeta || {},
    alias: safety_45roiK8UDYUSoqzMeta?.alias || [],
    redirect: safety_45roiK8UDYUSoqzMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiK8UDYUSoqzMeta?.name ?? "guides-fleet-safety-safety-roi___fr-ca",
    path: safety_45roiK8UDYUSoqzMeta?.path ?? "/fr-ca/guides/fleet-safety/safety-roi",
    meta: safety_45roiK8UDYUSoqzMeta || {},
    alias: safety_45roiK8UDYUSoqzMeta?.alias || [],
    redirect: safety_45roiK8UDYUSoqzMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: indexFR987Qwf3iMeta?.name ?? "guides___en-US",
    path: indexFR987Qwf3iMeta?.path ?? "/guides",
    meta: indexFR987Qwf3iMeta || {},
    alias: indexFR987Qwf3iMeta?.alias || [],
    redirect: indexFR987Qwf3iMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexFR987Qwf3iMeta?.name ?? "guides___ca",
    path: indexFR987Qwf3iMeta?.path ?? "/ca/guides",
    meta: indexFR987Qwf3iMeta || {},
    alias: indexFR987Qwf3iMeta?.alias || [],
    redirect: indexFR987Qwf3iMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexFR987Qwf3iMeta?.name ?? "guides___nl",
    path: indexFR987Qwf3iMeta?.path ?? "/nl/guides",
    meta: indexFR987Qwf3iMeta || {},
    alias: indexFR987Qwf3iMeta?.alias || [],
    redirect: indexFR987Qwf3iMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexFR987Qwf3iMeta?.name ?? "guides___uk",
    path: indexFR987Qwf3iMeta?.path ?? "/uk/guides",
    meta: indexFR987Qwf3iMeta || {},
    alias: indexFR987Qwf3iMeta?.alias || [],
    redirect: indexFR987Qwf3iMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexFR987Qwf3iMeta?.name ?? "guides___de",
    path: indexFR987Qwf3iMeta?.path ?? "/de/guides",
    meta: indexFR987Qwf3iMeta || {},
    alias: indexFR987Qwf3iMeta?.alias || [],
    redirect: indexFR987Qwf3iMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexFR987Qwf3iMeta?.name ?? "guides___mx",
    path: indexFR987Qwf3iMeta?.path ?? "/mx/guides",
    meta: indexFR987Qwf3iMeta || {},
    alias: indexFR987Qwf3iMeta?.alias || [],
    redirect: indexFR987Qwf3iMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexFR987Qwf3iMeta?.name ?? "guides___fr",
    path: indexFR987Qwf3iMeta?.path ?? "/fr/guides",
    meta: indexFR987Qwf3iMeta || {},
    alias: indexFR987Qwf3iMeta?.alias || [],
    redirect: indexFR987Qwf3iMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexFR987Qwf3iMeta?.name ?? "guides___fr-ca",
    path: indexFR987Qwf3iMeta?.path ?? "/fr-ca/guides",
    meta: indexFR987Qwf3iMeta || {},
    alias: indexFR987Qwf3iMeta?.alias || [],
    redirect: indexFR987Qwf3iMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futureal7bny4gZ0Meta?.name ?? "guides-make-more-possible-build-for-the-future___en-US",
    path: build_45for_45the_45futureal7bny4gZ0Meta?.path ?? "/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futureal7bny4gZ0Meta || {},
    alias: build_45for_45the_45futureal7bny4gZ0Meta?.alias || [],
    redirect: build_45for_45the_45futureal7bny4gZ0Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futureal7bny4gZ0Meta?.name ?? "guides-make-more-possible-build-for-the-future___ca",
    path: build_45for_45the_45futureal7bny4gZ0Meta?.path ?? "/ca/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futureal7bny4gZ0Meta || {},
    alias: build_45for_45the_45futureal7bny4gZ0Meta?.alias || [],
    redirect: build_45for_45the_45futureal7bny4gZ0Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futureal7bny4gZ0Meta?.name ?? "guides-make-more-possible-build-for-the-future___nl",
    path: build_45for_45the_45futureal7bny4gZ0Meta?.path ?? "/nl/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futureal7bny4gZ0Meta || {},
    alias: build_45for_45the_45futureal7bny4gZ0Meta?.alias || [],
    redirect: build_45for_45the_45futureal7bny4gZ0Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futureal7bny4gZ0Meta?.name ?? "guides-make-more-possible-build-for-the-future___uk",
    path: build_45for_45the_45futureal7bny4gZ0Meta?.path ?? "/uk/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futureal7bny4gZ0Meta || {},
    alias: build_45for_45the_45futureal7bny4gZ0Meta?.alias || [],
    redirect: build_45for_45the_45futureal7bny4gZ0Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futureal7bny4gZ0Meta?.name ?? "guides-make-more-possible-build-for-the-future___de",
    path: build_45for_45the_45futureal7bny4gZ0Meta?.path ?? "/de/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futureal7bny4gZ0Meta || {},
    alias: build_45for_45the_45futureal7bny4gZ0Meta?.alias || [],
    redirect: build_45for_45the_45futureal7bny4gZ0Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futureal7bny4gZ0Meta?.name ?? "guides-make-more-possible-build-for-the-future___mx",
    path: build_45for_45the_45futureal7bny4gZ0Meta?.path ?? "/mx/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futureal7bny4gZ0Meta || {},
    alias: build_45for_45the_45futureal7bny4gZ0Meta?.alias || [],
    redirect: build_45for_45the_45futureal7bny4gZ0Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futureal7bny4gZ0Meta?.name ?? "guides-make-more-possible-build-for-the-future___fr",
    path: build_45for_45the_45futureal7bny4gZ0Meta?.path ?? "/fr/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futureal7bny4gZ0Meta || {},
    alias: build_45for_45the_45futureal7bny4gZ0Meta?.alias || [],
    redirect: build_45for_45the_45futureal7bny4gZ0Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futureal7bny4gZ0Meta?.name ?? "guides-make-more-possible-build-for-the-future___fr-ca",
    path: build_45for_45the_45futureal7bny4gZ0Meta?.path ?? "/fr-ca/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futureal7bny4gZ0Meta || {},
    alias: build_45for_45the_45futureal7bny4gZ0Meta?.alias || [],
    redirect: build_45for_45the_45futureal7bny4gZ0Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsyUNzgmZLVCMeta?.name ?? "guides-make-more-possible-empower-your-teams___en-US",
    path: empower_45your_45teamsyUNzgmZLVCMeta?.path ?? "/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsyUNzgmZLVCMeta || {},
    alias: empower_45your_45teamsyUNzgmZLVCMeta?.alias || [],
    redirect: empower_45your_45teamsyUNzgmZLVCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsyUNzgmZLVCMeta?.name ?? "guides-make-more-possible-empower-your-teams___ca",
    path: empower_45your_45teamsyUNzgmZLVCMeta?.path ?? "/ca/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsyUNzgmZLVCMeta || {},
    alias: empower_45your_45teamsyUNzgmZLVCMeta?.alias || [],
    redirect: empower_45your_45teamsyUNzgmZLVCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsyUNzgmZLVCMeta?.name ?? "guides-make-more-possible-empower-your-teams___nl",
    path: empower_45your_45teamsyUNzgmZLVCMeta?.path ?? "/nl/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsyUNzgmZLVCMeta || {},
    alias: empower_45your_45teamsyUNzgmZLVCMeta?.alias || [],
    redirect: empower_45your_45teamsyUNzgmZLVCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsyUNzgmZLVCMeta?.name ?? "guides-make-more-possible-empower-your-teams___uk",
    path: empower_45your_45teamsyUNzgmZLVCMeta?.path ?? "/uk/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsyUNzgmZLVCMeta || {},
    alias: empower_45your_45teamsyUNzgmZLVCMeta?.alias || [],
    redirect: empower_45your_45teamsyUNzgmZLVCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsyUNzgmZLVCMeta?.name ?? "guides-make-more-possible-empower-your-teams___de",
    path: empower_45your_45teamsyUNzgmZLVCMeta?.path ?? "/de/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsyUNzgmZLVCMeta || {},
    alias: empower_45your_45teamsyUNzgmZLVCMeta?.alias || [],
    redirect: empower_45your_45teamsyUNzgmZLVCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsyUNzgmZLVCMeta?.name ?? "guides-make-more-possible-empower-your-teams___mx",
    path: empower_45your_45teamsyUNzgmZLVCMeta?.path ?? "/mx/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsyUNzgmZLVCMeta || {},
    alias: empower_45your_45teamsyUNzgmZLVCMeta?.alias || [],
    redirect: empower_45your_45teamsyUNzgmZLVCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsyUNzgmZLVCMeta?.name ?? "guides-make-more-possible-empower-your-teams___fr",
    path: empower_45your_45teamsyUNzgmZLVCMeta?.path ?? "/fr/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsyUNzgmZLVCMeta || {},
    alias: empower_45your_45teamsyUNzgmZLVCMeta?.alias || [],
    redirect: empower_45your_45teamsyUNzgmZLVCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsyUNzgmZLVCMeta?.name ?? "guides-make-more-possible-empower-your-teams___fr-ca",
    path: empower_45your_45teamsyUNzgmZLVCMeta?.path ?? "/fr-ca/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsyUNzgmZLVCMeta || {},
    alias: empower_45your_45teamsyUNzgmZLVCMeta?.alias || [],
    redirect: empower_45your_45teamsyUNzgmZLVCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: indexkJuAaxlHVMMeta?.name ?? "guides-make-more-possible___en-US",
    path: indexkJuAaxlHVMMeta?.path ?? "/guides/make-more-possible",
    meta: indexkJuAaxlHVMMeta || {},
    alias: indexkJuAaxlHVMMeta?.alias || [],
    redirect: indexkJuAaxlHVMMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexkJuAaxlHVMMeta?.name ?? "guides-make-more-possible___ca",
    path: indexkJuAaxlHVMMeta?.path ?? "/ca/guides/make-more-possible",
    meta: indexkJuAaxlHVMMeta || {},
    alias: indexkJuAaxlHVMMeta?.alias || [],
    redirect: indexkJuAaxlHVMMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexkJuAaxlHVMMeta?.name ?? "guides-make-more-possible___nl",
    path: indexkJuAaxlHVMMeta?.path ?? "/nl/guides/make-more-possible",
    meta: indexkJuAaxlHVMMeta || {},
    alias: indexkJuAaxlHVMMeta?.alias || [],
    redirect: indexkJuAaxlHVMMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexkJuAaxlHVMMeta?.name ?? "guides-make-more-possible___uk",
    path: indexkJuAaxlHVMMeta?.path ?? "/uk/guides/make-more-possible",
    meta: indexkJuAaxlHVMMeta || {},
    alias: indexkJuAaxlHVMMeta?.alias || [],
    redirect: indexkJuAaxlHVMMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexkJuAaxlHVMMeta?.name ?? "guides-make-more-possible___de",
    path: indexkJuAaxlHVMMeta?.path ?? "/de/guides/make-more-possible",
    meta: indexkJuAaxlHVMMeta || {},
    alias: indexkJuAaxlHVMMeta?.alias || [],
    redirect: indexkJuAaxlHVMMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexkJuAaxlHVMMeta?.name ?? "guides-make-more-possible___mx",
    path: indexkJuAaxlHVMMeta?.path ?? "/mx/guides/make-more-possible",
    meta: indexkJuAaxlHVMMeta || {},
    alias: indexkJuAaxlHVMMeta?.alias || [],
    redirect: indexkJuAaxlHVMMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexkJuAaxlHVMMeta?.name ?? "guides-make-more-possible___fr",
    path: indexkJuAaxlHVMMeta?.path ?? "/fr/guides/make-more-possible",
    meta: indexkJuAaxlHVMMeta || {},
    alias: indexkJuAaxlHVMMeta?.alias || [],
    redirect: indexkJuAaxlHVMMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexkJuAaxlHVMMeta?.name ?? "guides-make-more-possible___fr-ca",
    path: indexkJuAaxlHVMMeta?.path ?? "/fr-ca/guides/make-more-possible",
    meta: indexkJuAaxlHVMMeta || {},
    alias: indexkJuAaxlHVMMeta?.alias || [],
    redirect: indexkJuAaxlHVMMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45datavKEvn0njrRMeta?.name ?? "guides-make-more-possible-take-action-with-data___en-US",
    path: take_45action_45with_45datavKEvn0njrRMeta?.path ?? "/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45datavKEvn0njrRMeta || {},
    alias: take_45action_45with_45datavKEvn0njrRMeta?.alias || [],
    redirect: take_45action_45with_45datavKEvn0njrRMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45datavKEvn0njrRMeta?.name ?? "guides-make-more-possible-take-action-with-data___ca",
    path: take_45action_45with_45datavKEvn0njrRMeta?.path ?? "/ca/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45datavKEvn0njrRMeta || {},
    alias: take_45action_45with_45datavKEvn0njrRMeta?.alias || [],
    redirect: take_45action_45with_45datavKEvn0njrRMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45datavKEvn0njrRMeta?.name ?? "guides-make-more-possible-take-action-with-data___nl",
    path: take_45action_45with_45datavKEvn0njrRMeta?.path ?? "/nl/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45datavKEvn0njrRMeta || {},
    alias: take_45action_45with_45datavKEvn0njrRMeta?.alias || [],
    redirect: take_45action_45with_45datavKEvn0njrRMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45datavKEvn0njrRMeta?.name ?? "guides-make-more-possible-take-action-with-data___uk",
    path: take_45action_45with_45datavKEvn0njrRMeta?.path ?? "/uk/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45datavKEvn0njrRMeta || {},
    alias: take_45action_45with_45datavKEvn0njrRMeta?.alias || [],
    redirect: take_45action_45with_45datavKEvn0njrRMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45datavKEvn0njrRMeta?.name ?? "guides-make-more-possible-take-action-with-data___de",
    path: take_45action_45with_45datavKEvn0njrRMeta?.path ?? "/de/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45datavKEvn0njrRMeta || {},
    alias: take_45action_45with_45datavKEvn0njrRMeta?.alias || [],
    redirect: take_45action_45with_45datavKEvn0njrRMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45datavKEvn0njrRMeta?.name ?? "guides-make-more-possible-take-action-with-data___mx",
    path: take_45action_45with_45datavKEvn0njrRMeta?.path ?? "/mx/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45datavKEvn0njrRMeta || {},
    alias: take_45action_45with_45datavKEvn0njrRMeta?.alias || [],
    redirect: take_45action_45with_45datavKEvn0njrRMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45datavKEvn0njrRMeta?.name ?? "guides-make-more-possible-take-action-with-data___fr",
    path: take_45action_45with_45datavKEvn0njrRMeta?.path ?? "/fr/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45datavKEvn0njrRMeta || {},
    alias: take_45action_45with_45datavKEvn0njrRMeta?.alias || [],
    redirect: take_45action_45with_45datavKEvn0njrRMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45datavKEvn0njrRMeta?.name ?? "guides-make-more-possible-take-action-with-data___fr-ca",
    path: take_45action_45with_45datavKEvn0njrRMeta?.path ?? "/fr-ca/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45datavKEvn0njrRMeta || {},
    alias: take_45action_45with_45datavKEvn0njrRMeta?.alias || [],
    redirect: take_45action_45with_45datavKEvn0njrRMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SsekXyBkGpMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___en-US",
    path: _91slug_93SsekXyBkGpMeta?.path ?? "/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93SsekXyBkGpMeta || {},
    alias: _91slug_93SsekXyBkGpMeta?.alias || [],
    redirect: _91slug_93SsekXyBkGpMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SsekXyBkGpMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___ca",
    path: _91slug_93SsekXyBkGpMeta?.path ?? "/ca/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93SsekXyBkGpMeta || {},
    alias: _91slug_93SsekXyBkGpMeta?.alias || [],
    redirect: _91slug_93SsekXyBkGpMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SsekXyBkGpMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___nl",
    path: _91slug_93SsekXyBkGpMeta?.path ?? "/nl/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93SsekXyBkGpMeta || {},
    alias: _91slug_93SsekXyBkGpMeta?.alias || [],
    redirect: _91slug_93SsekXyBkGpMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SsekXyBkGpMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___uk",
    path: _91slug_93SsekXyBkGpMeta?.path ?? "/uk/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93SsekXyBkGpMeta || {},
    alias: _91slug_93SsekXyBkGpMeta?.alias || [],
    redirect: _91slug_93SsekXyBkGpMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SsekXyBkGpMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___de",
    path: _91slug_93SsekXyBkGpMeta?.path ?? "/de/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93SsekXyBkGpMeta || {},
    alias: _91slug_93SsekXyBkGpMeta?.alias || [],
    redirect: _91slug_93SsekXyBkGpMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SsekXyBkGpMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___mx",
    path: _91slug_93SsekXyBkGpMeta?.path ?? "/mx/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93SsekXyBkGpMeta || {},
    alias: _91slug_93SsekXyBkGpMeta?.alias || [],
    redirect: _91slug_93SsekXyBkGpMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SsekXyBkGpMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___fr",
    path: _91slug_93SsekXyBkGpMeta?.path ?? "/fr/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93SsekXyBkGpMeta || {},
    alias: _91slug_93SsekXyBkGpMeta?.alias || [],
    redirect: _91slug_93SsekXyBkGpMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SsekXyBkGpMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___fr-ca",
    path: _91slug_93SsekXyBkGpMeta?.path ?? "/fr-ca/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93SsekXyBkGpMeta || {},
    alias: _91slug_93SsekXyBkGpMeta?.alias || [],
    redirect: _91slug_93SsekXyBkGpMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexEoGHcHCGu3Meta?.name ?? "guides-your-partner-for-the-road-ahead___en-US",
    path: indexEoGHcHCGu3Meta?.path ?? "/guides/your-partner-for-the-road-ahead",
    meta: indexEoGHcHCGu3Meta || {},
    alias: indexEoGHcHCGu3Meta?.alias || [],
    redirect: indexEoGHcHCGu3Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexEoGHcHCGu3Meta?.name ?? "guides-your-partner-for-the-road-ahead___ca",
    path: indexEoGHcHCGu3Meta?.path ?? "/ca/guides/your-partner-for-the-road-ahead",
    meta: indexEoGHcHCGu3Meta || {},
    alias: indexEoGHcHCGu3Meta?.alias || [],
    redirect: indexEoGHcHCGu3Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexEoGHcHCGu3Meta?.name ?? "guides-your-partner-for-the-road-ahead___nl",
    path: indexEoGHcHCGu3Meta?.path ?? "/nl/guides/your-partner-for-the-road-ahead",
    meta: indexEoGHcHCGu3Meta || {},
    alias: indexEoGHcHCGu3Meta?.alias || [],
    redirect: indexEoGHcHCGu3Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexEoGHcHCGu3Meta?.name ?? "guides-your-partner-for-the-road-ahead___uk",
    path: indexEoGHcHCGu3Meta?.path ?? "/uk/guides/your-partner-for-the-road-ahead",
    meta: indexEoGHcHCGu3Meta || {},
    alias: indexEoGHcHCGu3Meta?.alias || [],
    redirect: indexEoGHcHCGu3Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexEoGHcHCGu3Meta?.name ?? "guides-your-partner-for-the-road-ahead___de",
    path: indexEoGHcHCGu3Meta?.path ?? "/de/guides/your-partner-for-the-road-ahead",
    meta: indexEoGHcHCGu3Meta || {},
    alias: indexEoGHcHCGu3Meta?.alias || [],
    redirect: indexEoGHcHCGu3Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexEoGHcHCGu3Meta?.name ?? "guides-your-partner-for-the-road-ahead___mx",
    path: indexEoGHcHCGu3Meta?.path ?? "/mx/guides/your-partner-for-the-road-ahead",
    meta: indexEoGHcHCGu3Meta || {},
    alias: indexEoGHcHCGu3Meta?.alias || [],
    redirect: indexEoGHcHCGu3Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexEoGHcHCGu3Meta?.name ?? "guides-your-partner-for-the-road-ahead___fr",
    path: indexEoGHcHCGu3Meta?.path ?? "/fr/guides/your-partner-for-the-road-ahead",
    meta: indexEoGHcHCGu3Meta || {},
    alias: indexEoGHcHCGu3Meta?.alias || [],
    redirect: indexEoGHcHCGu3Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexEoGHcHCGu3Meta?.name ?? "guides-your-partner-for-the-road-ahead___fr-ca",
    path: indexEoGHcHCGu3Meta?.path ?? "/fr-ca/guides/your-partner-for-the-road-ahead",
    meta: indexEoGHcHCGu3Meta || {},
    alias: indexEoGHcHCGu3Meta?.alias || [],
    redirect: indexEoGHcHCGu3Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexvqHvWWnZHdMeta?.name ?? "index___en-US",
    path: indexvqHvWWnZHdMeta?.path ?? "/",
    meta: indexvqHvWWnZHdMeta || {},
    alias: indexvqHvWWnZHdMeta?.alias || [],
    redirect: indexvqHvWWnZHdMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvqHvWWnZHdMeta?.name ?? "index___ca",
    path: indexvqHvWWnZHdMeta?.path ?? "/ca",
    meta: indexvqHvWWnZHdMeta || {},
    alias: indexvqHvWWnZHdMeta?.alias || [],
    redirect: indexvqHvWWnZHdMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvqHvWWnZHdMeta?.name ?? "index___nl",
    path: indexvqHvWWnZHdMeta?.path ?? "/nl",
    meta: indexvqHvWWnZHdMeta || {},
    alias: indexvqHvWWnZHdMeta?.alias || [],
    redirect: indexvqHvWWnZHdMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvqHvWWnZHdMeta?.name ?? "index___uk",
    path: indexvqHvWWnZHdMeta?.path ?? "/uk",
    meta: indexvqHvWWnZHdMeta || {},
    alias: indexvqHvWWnZHdMeta?.alias || [],
    redirect: indexvqHvWWnZHdMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvqHvWWnZHdMeta?.name ?? "index___de",
    path: indexvqHvWWnZHdMeta?.path ?? "/de",
    meta: indexvqHvWWnZHdMeta || {},
    alias: indexvqHvWWnZHdMeta?.alias || [],
    redirect: indexvqHvWWnZHdMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvqHvWWnZHdMeta?.name ?? "index___mx",
    path: indexvqHvWWnZHdMeta?.path ?? "/mx",
    meta: indexvqHvWWnZHdMeta || {},
    alias: indexvqHvWWnZHdMeta?.alias || [],
    redirect: indexvqHvWWnZHdMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvqHvWWnZHdMeta?.name ?? "index___fr",
    path: indexvqHvWWnZHdMeta?.path ?? "/fr",
    meta: indexvqHvWWnZHdMeta || {},
    alias: indexvqHvWWnZHdMeta?.alias || [],
    redirect: indexvqHvWWnZHdMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvqHvWWnZHdMeta?.name ?? "index___fr-ca",
    path: indexvqHvWWnZHdMeta?.path ?? "/fr-ca",
    meta: indexvqHvWWnZHdMeta || {},
    alias: indexvqHvWWnZHdMeta?.alias || [],
    redirect: indexvqHvWWnZHdMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93x3G8BraYYLMeta?.name ?? "industrial-slug___en-US",
    path: _91slug_93x3G8BraYYLMeta?.path ?? "/industrial/:slug()",
    meta: _91slug_93x3G8BraYYLMeta || {},
    alias: _91slug_93x3G8BraYYLMeta?.alias || [],
    redirect: _91slug_93x3G8BraYYLMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93x3G8BraYYLMeta?.name ?? "industrial-slug___ca",
    path: _91slug_93x3G8BraYYLMeta?.path ?? "/ca/industrial/:slug()",
    meta: _91slug_93x3G8BraYYLMeta || {},
    alias: _91slug_93x3G8BraYYLMeta?.alias || [],
    redirect: _91slug_93x3G8BraYYLMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93x3G8BraYYLMeta?.name ?? "industrial-slug___nl",
    path: _91slug_93x3G8BraYYLMeta?.path ?? "/nl/industrial/:slug()",
    meta: _91slug_93x3G8BraYYLMeta || {},
    alias: _91slug_93x3G8BraYYLMeta?.alias || [],
    redirect: _91slug_93x3G8BraYYLMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93x3G8BraYYLMeta?.name ?? "industrial-slug___uk",
    path: _91slug_93x3G8BraYYLMeta?.path ?? "/uk/industrial/:slug()",
    meta: _91slug_93x3G8BraYYLMeta || {},
    alias: _91slug_93x3G8BraYYLMeta?.alias || [],
    redirect: _91slug_93x3G8BraYYLMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93x3G8BraYYLMeta?.name ?? "industrial-slug___de",
    path: _91slug_93x3G8BraYYLMeta?.path ?? "/de/industrial/:slug()",
    meta: _91slug_93x3G8BraYYLMeta || {},
    alias: _91slug_93x3G8BraYYLMeta?.alias || [],
    redirect: _91slug_93x3G8BraYYLMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93x3G8BraYYLMeta?.name ?? "industrial-slug___mx",
    path: _91slug_93x3G8BraYYLMeta?.path ?? "/mx/industrial/:slug()",
    meta: _91slug_93x3G8BraYYLMeta || {},
    alias: _91slug_93x3G8BraYYLMeta?.alias || [],
    redirect: _91slug_93x3G8BraYYLMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93x3G8BraYYLMeta?.name ?? "industrial-slug___fr",
    path: _91slug_93x3G8BraYYLMeta?.path ?? "/fr/industrial/:slug()",
    meta: _91slug_93x3G8BraYYLMeta || {},
    alias: _91slug_93x3G8BraYYLMeta?.alias || [],
    redirect: _91slug_93x3G8BraYYLMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93x3G8BraYYLMeta?.name ?? "industrial-slug___fr-ca",
    path: _91slug_93x3G8BraYYLMeta?.path ?? "/fr-ca/industrial/:slug()",
    meta: _91slug_93x3G8BraYYLMeta || {},
    alias: _91slug_93x3G8BraYYLMeta?.alias || [],
    redirect: _91slug_93x3G8BraYYLMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93o8KwTHu4hlMeta?.name ?? "industries-slug___en-US",
    path: _91slug_93o8KwTHu4hlMeta?.path ?? "/industries/:slug()",
    meta: _91slug_93o8KwTHu4hlMeta || {},
    alias: _91slug_93o8KwTHu4hlMeta?.alias || [],
    redirect: _91slug_93o8KwTHu4hlMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93o8KwTHu4hlMeta?.name ?? "industries-slug___ca",
    path: _91slug_93o8KwTHu4hlMeta?.path ?? "/ca/industries/:slug()",
    meta: _91slug_93o8KwTHu4hlMeta || {},
    alias: _91slug_93o8KwTHu4hlMeta?.alias || [],
    redirect: _91slug_93o8KwTHu4hlMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93o8KwTHu4hlMeta?.name ?? "industries-slug___nl",
    path: _91slug_93o8KwTHu4hlMeta?.path ?? "/nl/industries/:slug()",
    meta: _91slug_93o8KwTHu4hlMeta || {},
    alias: _91slug_93o8KwTHu4hlMeta?.alias || [],
    redirect: _91slug_93o8KwTHu4hlMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93o8KwTHu4hlMeta?.name ?? "industries-slug___uk",
    path: _91slug_93o8KwTHu4hlMeta?.path ?? "/uk/industries/:slug()",
    meta: _91slug_93o8KwTHu4hlMeta || {},
    alias: _91slug_93o8KwTHu4hlMeta?.alias || [],
    redirect: _91slug_93o8KwTHu4hlMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93o8KwTHu4hlMeta?.name ?? "industries-slug___de",
    path: _91slug_93o8KwTHu4hlMeta?.path ?? "/de/industries/:slug()",
    meta: _91slug_93o8KwTHu4hlMeta || {},
    alias: _91slug_93o8KwTHu4hlMeta?.alias || [],
    redirect: _91slug_93o8KwTHu4hlMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93o8KwTHu4hlMeta?.name ?? "industries-slug___mx",
    path: _91slug_93o8KwTHu4hlMeta?.path ?? "/mx/industries/:slug()",
    meta: _91slug_93o8KwTHu4hlMeta || {},
    alias: _91slug_93o8KwTHu4hlMeta?.alias || [],
    redirect: _91slug_93o8KwTHu4hlMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93o8KwTHu4hlMeta?.name ?? "industries-slug___fr",
    path: _91slug_93o8KwTHu4hlMeta?.path ?? "/fr/industries/:slug()",
    meta: _91slug_93o8KwTHu4hlMeta || {},
    alias: _91slug_93o8KwTHu4hlMeta?.alias || [],
    redirect: _91slug_93o8KwTHu4hlMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93o8KwTHu4hlMeta?.name ?? "industries-slug___fr-ca",
    path: _91slug_93o8KwTHu4hlMeta?.path ?? "/fr-ca/industries/:slug()",
    meta: _91slug_93o8KwTHu4hlMeta || {},
    alias: _91slug_93o8KwTHu4hlMeta?.alias || [],
    redirect: _91slug_93o8KwTHu4hlMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexKWGXMC3CTJMeta?.name ?? "industries___en-US",
    path: indexKWGXMC3CTJMeta?.path ?? "/industries",
    meta: indexKWGXMC3CTJMeta || {},
    alias: indexKWGXMC3CTJMeta?.alias || [],
    redirect: indexKWGXMC3CTJMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexKWGXMC3CTJMeta?.name ?? "industries___ca",
    path: indexKWGXMC3CTJMeta?.path ?? "/ca/industries",
    meta: indexKWGXMC3CTJMeta || {},
    alias: indexKWGXMC3CTJMeta?.alias || [],
    redirect: indexKWGXMC3CTJMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexKWGXMC3CTJMeta?.name ?? "industries___nl",
    path: indexKWGXMC3CTJMeta?.path ?? "/nl/industries",
    meta: indexKWGXMC3CTJMeta || {},
    alias: indexKWGXMC3CTJMeta?.alias || [],
    redirect: indexKWGXMC3CTJMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexKWGXMC3CTJMeta?.name ?? "industries___uk",
    path: indexKWGXMC3CTJMeta?.path ?? "/uk/industries",
    meta: indexKWGXMC3CTJMeta || {},
    alias: indexKWGXMC3CTJMeta?.alias || [],
    redirect: indexKWGXMC3CTJMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexKWGXMC3CTJMeta?.name ?? "industries___de",
    path: indexKWGXMC3CTJMeta?.path ?? "/de/industries",
    meta: indexKWGXMC3CTJMeta || {},
    alias: indexKWGXMC3CTJMeta?.alias || [],
    redirect: indexKWGXMC3CTJMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexKWGXMC3CTJMeta?.name ?? "industries___mx",
    path: indexKWGXMC3CTJMeta?.path ?? "/mx/industries",
    meta: indexKWGXMC3CTJMeta || {},
    alias: indexKWGXMC3CTJMeta?.alias || [],
    redirect: indexKWGXMC3CTJMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexKWGXMC3CTJMeta?.name ?? "industries___fr",
    path: indexKWGXMC3CTJMeta?.path ?? "/fr/industries",
    meta: indexKWGXMC3CTJMeta || {},
    alias: indexKWGXMC3CTJMeta?.alias || [],
    redirect: indexKWGXMC3CTJMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexKWGXMC3CTJMeta?.name ?? "industries___fr-ca",
    path: indexKWGXMC3CTJMeta?.path ?? "/fr-ca/industries",
    meta: indexKWGXMC3CTJMeta || {},
    alias: indexKWGXMC3CTJMeta?.alias || [],
    redirect: indexKWGXMC3CTJMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: citiesqssk38TEOmMeta?.name ?? "insights-cities___en-US",
    path: citiesqssk38TEOmMeta?.path ?? "/insights/cities",
    meta: citiesqssk38TEOmMeta || {},
    alias: citiesqssk38TEOmMeta?.alias || [],
    redirect: citiesqssk38TEOmMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesqssk38TEOmMeta?.name ?? "insights-cities___ca",
    path: citiesqssk38TEOmMeta?.path ?? "/ca/insights/cities",
    meta: citiesqssk38TEOmMeta || {},
    alias: citiesqssk38TEOmMeta?.alias || [],
    redirect: citiesqssk38TEOmMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesqssk38TEOmMeta?.name ?? "insights-cities___nl",
    path: citiesqssk38TEOmMeta?.path ?? "/nl/insights/cities",
    meta: citiesqssk38TEOmMeta || {},
    alias: citiesqssk38TEOmMeta?.alias || [],
    redirect: citiesqssk38TEOmMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesqssk38TEOmMeta?.name ?? "insights-cities___uk",
    path: citiesqssk38TEOmMeta?.path ?? "/uk/insights/cities",
    meta: citiesqssk38TEOmMeta || {},
    alias: citiesqssk38TEOmMeta?.alias || [],
    redirect: citiesqssk38TEOmMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesqssk38TEOmMeta?.name ?? "insights-cities___de",
    path: citiesqssk38TEOmMeta?.path ?? "/de/insights/cities",
    meta: citiesqssk38TEOmMeta || {},
    alias: citiesqssk38TEOmMeta?.alias || [],
    redirect: citiesqssk38TEOmMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesqssk38TEOmMeta?.name ?? "insights-cities___mx",
    path: citiesqssk38TEOmMeta?.path ?? "/mx/insights/cities",
    meta: citiesqssk38TEOmMeta || {},
    alias: citiesqssk38TEOmMeta?.alias || [],
    redirect: citiesqssk38TEOmMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesqssk38TEOmMeta?.name ?? "insights-cities___fr",
    path: citiesqssk38TEOmMeta?.path ?? "/fr/insights/cities",
    meta: citiesqssk38TEOmMeta || {},
    alias: citiesqssk38TEOmMeta?.alias || [],
    redirect: citiesqssk38TEOmMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesqssk38TEOmMeta?.name ?? "insights-cities___fr-ca",
    path: citiesqssk38TEOmMeta?.path ?? "/fr-ca/insights/cities",
    meta: citiesqssk38TEOmMeta || {},
    alias: citiesqssk38TEOmMeta?.alias || [],
    redirect: citiesqssk38TEOmMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: index9fDeovbYyWMeta?.name ?? "integrations___en-US",
    path: index9fDeovbYyWMeta?.path ?? "/integrations",
    meta: index9fDeovbYyWMeta || {},
    alias: index9fDeovbYyWMeta?.alias || [],
    redirect: index9fDeovbYyWMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: index9fDeovbYyWMeta?.name ?? "integrations___ca",
    path: index9fDeovbYyWMeta?.path ?? "/ca/integrations",
    meta: index9fDeovbYyWMeta || {},
    alias: index9fDeovbYyWMeta?.alias || [],
    redirect: index9fDeovbYyWMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: index9fDeovbYyWMeta?.name ?? "integrations___nl",
    path: index9fDeovbYyWMeta?.path ?? "/nl/integrations",
    meta: index9fDeovbYyWMeta || {},
    alias: index9fDeovbYyWMeta?.alias || [],
    redirect: index9fDeovbYyWMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: index9fDeovbYyWMeta?.name ?? "integrations___uk",
    path: index9fDeovbYyWMeta?.path ?? "/uk/integrations",
    meta: index9fDeovbYyWMeta || {},
    alias: index9fDeovbYyWMeta?.alias || [],
    redirect: index9fDeovbYyWMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: index9fDeovbYyWMeta?.name ?? "integrations___de",
    path: index9fDeovbYyWMeta?.path ?? "/de/integrations",
    meta: index9fDeovbYyWMeta || {},
    alias: index9fDeovbYyWMeta?.alias || [],
    redirect: index9fDeovbYyWMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: index9fDeovbYyWMeta?.name ?? "integrations___mx",
    path: index9fDeovbYyWMeta?.path ?? "/mx/integrations",
    meta: index9fDeovbYyWMeta || {},
    alias: index9fDeovbYyWMeta?.alias || [],
    redirect: index9fDeovbYyWMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: index9fDeovbYyWMeta?.name ?? "integrations___fr",
    path: index9fDeovbYyWMeta?.path ?? "/fr/integrations",
    meta: index9fDeovbYyWMeta || {},
    alias: index9fDeovbYyWMeta?.alias || [],
    redirect: index9fDeovbYyWMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: index9fDeovbYyWMeta?.name ?? "integrations___fr-ca",
    path: index9fDeovbYyWMeta?.path ?? "/fr-ca/integrations",
    meta: index9fDeovbYyWMeta || {},
    alias: index9fDeovbYyWMeta?.alias || [],
    redirect: index9fDeovbYyWMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexKoCywAAe57Meta?.name ?? "invite___en-US",
    path: indexKoCywAAe57Meta?.path ?? "/invite",
    meta: indexKoCywAAe57Meta || {},
    alias: indexKoCywAAe57Meta?.alias || [],
    redirect: indexKoCywAAe57Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexKoCywAAe57Meta?.name ?? "invite___ca",
    path: indexKoCywAAe57Meta?.path ?? "/ca/invite",
    meta: indexKoCywAAe57Meta || {},
    alias: indexKoCywAAe57Meta?.alias || [],
    redirect: indexKoCywAAe57Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexKoCywAAe57Meta?.name ?? "invite___nl",
    path: indexKoCywAAe57Meta?.path ?? "/nl/invite",
    meta: indexKoCywAAe57Meta || {},
    alias: indexKoCywAAe57Meta?.alias || [],
    redirect: indexKoCywAAe57Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexKoCywAAe57Meta?.name ?? "invite___uk",
    path: indexKoCywAAe57Meta?.path ?? "/uk/invite",
    meta: indexKoCywAAe57Meta || {},
    alias: indexKoCywAAe57Meta?.alias || [],
    redirect: indexKoCywAAe57Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexKoCywAAe57Meta?.name ?? "invite___de",
    path: indexKoCywAAe57Meta?.path ?? "/de/invite",
    meta: indexKoCywAAe57Meta || {},
    alias: indexKoCywAAe57Meta?.alias || [],
    redirect: indexKoCywAAe57Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexKoCywAAe57Meta?.name ?? "invite___mx",
    path: indexKoCywAAe57Meta?.path ?? "/mx/invite",
    meta: indexKoCywAAe57Meta || {},
    alias: indexKoCywAAe57Meta?.alias || [],
    redirect: indexKoCywAAe57Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexKoCywAAe57Meta?.name ?? "invite___fr",
    path: indexKoCywAAe57Meta?.path ?? "/fr/invite",
    meta: indexKoCywAAe57Meta || {},
    alias: indexKoCywAAe57Meta?.alias || [],
    redirect: indexKoCywAAe57Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexKoCywAAe57Meta?.name ?? "invite___fr-ca",
    path: indexKoCywAAe57Meta?.path ?? "/fr-ca/invite",
    meta: indexKoCywAAe57Meta || {},
    alias: indexKoCywAAe57Meta?.alias || [],
    redirect: indexKoCywAAe57Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: learn_45moreOGCS6JKdfjMeta?.name ?? "learn-more___en-US",
    path: learn_45moreOGCS6JKdfjMeta?.path ?? "/learn-more",
    meta: learn_45moreOGCS6JKdfjMeta || {},
    alias: learn_45moreOGCS6JKdfjMeta?.alias || [],
    redirect: learn_45moreOGCS6JKdfjMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45moreOGCS6JKdfjMeta?.name ?? "learn-more___ca",
    path: learn_45moreOGCS6JKdfjMeta?.path ?? "/ca/learn-more",
    meta: learn_45moreOGCS6JKdfjMeta || {},
    alias: learn_45moreOGCS6JKdfjMeta?.alias || [],
    redirect: learn_45moreOGCS6JKdfjMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45moreOGCS6JKdfjMeta?.name ?? "learn-more___nl",
    path: learn_45moreOGCS6JKdfjMeta?.path ?? "/nl/learn-more",
    meta: learn_45moreOGCS6JKdfjMeta || {},
    alias: learn_45moreOGCS6JKdfjMeta?.alias || [],
    redirect: learn_45moreOGCS6JKdfjMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45moreOGCS6JKdfjMeta?.name ?? "learn-more___uk",
    path: learn_45moreOGCS6JKdfjMeta?.path ?? "/uk/learn-more",
    meta: learn_45moreOGCS6JKdfjMeta || {},
    alias: learn_45moreOGCS6JKdfjMeta?.alias || [],
    redirect: learn_45moreOGCS6JKdfjMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45moreOGCS6JKdfjMeta?.name ?? "learn-more___de",
    path: learn_45moreOGCS6JKdfjMeta?.path ?? "/de/learn-more",
    meta: learn_45moreOGCS6JKdfjMeta || {},
    alias: learn_45moreOGCS6JKdfjMeta?.alias || [],
    redirect: learn_45moreOGCS6JKdfjMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45moreOGCS6JKdfjMeta?.name ?? "learn-more___mx",
    path: learn_45moreOGCS6JKdfjMeta?.path ?? "/mx/learn-more",
    meta: learn_45moreOGCS6JKdfjMeta || {},
    alias: learn_45moreOGCS6JKdfjMeta?.alias || [],
    redirect: learn_45moreOGCS6JKdfjMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45moreOGCS6JKdfjMeta?.name ?? "learn-more___fr",
    path: learn_45moreOGCS6JKdfjMeta?.path ?? "/fr/learn-more",
    meta: learn_45moreOGCS6JKdfjMeta || {},
    alias: learn_45moreOGCS6JKdfjMeta?.alias || [],
    redirect: learn_45moreOGCS6JKdfjMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45moreOGCS6JKdfjMeta?.name ?? "learn-more___fr-ca",
    path: learn_45moreOGCS6JKdfjMeta?.path ?? "/fr-ca/learn-more",
    meta: learn_45moreOGCS6JKdfjMeta || {},
    alias: learn_45moreOGCS6JKdfjMeta?.alias || [],
    redirect: learn_45moreOGCS6JKdfjMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticexBDyb4Sr1oMeta?.name ?? "legal-candidate-privacy-notice___en-US",
    path: candidate_45privacy_45noticexBDyb4Sr1oMeta?.path ?? "/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticexBDyb4Sr1oMeta || {},
    alias: candidate_45privacy_45noticexBDyb4Sr1oMeta?.alias || [],
    redirect: candidate_45privacy_45noticexBDyb4Sr1oMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticexBDyb4Sr1oMeta?.name ?? "legal-candidate-privacy-notice___ca",
    path: candidate_45privacy_45noticexBDyb4Sr1oMeta?.path ?? "/ca/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticexBDyb4Sr1oMeta || {},
    alias: candidate_45privacy_45noticexBDyb4Sr1oMeta?.alias || [],
    redirect: candidate_45privacy_45noticexBDyb4Sr1oMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticexBDyb4Sr1oMeta?.name ?? "legal-candidate-privacy-notice___nl",
    path: candidate_45privacy_45noticexBDyb4Sr1oMeta?.path ?? "/nl/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticexBDyb4Sr1oMeta || {},
    alias: candidate_45privacy_45noticexBDyb4Sr1oMeta?.alias || [],
    redirect: candidate_45privacy_45noticexBDyb4Sr1oMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticexBDyb4Sr1oMeta?.name ?? "legal-candidate-privacy-notice___uk",
    path: candidate_45privacy_45noticexBDyb4Sr1oMeta?.path ?? "/uk/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticexBDyb4Sr1oMeta || {},
    alias: candidate_45privacy_45noticexBDyb4Sr1oMeta?.alias || [],
    redirect: candidate_45privacy_45noticexBDyb4Sr1oMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticexBDyb4Sr1oMeta?.name ?? "legal-candidate-privacy-notice___de",
    path: candidate_45privacy_45noticexBDyb4Sr1oMeta?.path ?? "/de/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticexBDyb4Sr1oMeta || {},
    alias: candidate_45privacy_45noticexBDyb4Sr1oMeta?.alias || [],
    redirect: candidate_45privacy_45noticexBDyb4Sr1oMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticexBDyb4Sr1oMeta?.name ?? "legal-candidate-privacy-notice___mx",
    path: candidate_45privacy_45noticexBDyb4Sr1oMeta?.path ?? "/mx/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticexBDyb4Sr1oMeta || {},
    alias: candidate_45privacy_45noticexBDyb4Sr1oMeta?.alias || [],
    redirect: candidate_45privacy_45noticexBDyb4Sr1oMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticexBDyb4Sr1oMeta?.name ?? "legal-candidate-privacy-notice___fr",
    path: candidate_45privacy_45noticexBDyb4Sr1oMeta?.path ?? "/fr/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticexBDyb4Sr1oMeta || {},
    alias: candidate_45privacy_45noticexBDyb4Sr1oMeta?.alias || [],
    redirect: candidate_45privacy_45noticexBDyb4Sr1oMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticexBDyb4Sr1oMeta?.name ?? "legal-candidate-privacy-notice___fr-ca",
    path: candidate_45privacy_45noticexBDyb4Sr1oMeta?.path ?? "/fr-ca/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticexBDyb4Sr1oMeta || {},
    alias: candidate_45privacy_45noticexBDyb4Sr1oMeta?.alias || [],
    redirect: candidate_45privacy_45noticexBDyb4Sr1oMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentBgzDrsIydxMeta?.name ?? "legal-cookie-consent___en-US",
    path: cookie_45consentBgzDrsIydxMeta?.path ?? "/legal/cookie-consent",
    meta: cookie_45consentBgzDrsIydxMeta || {},
    alias: cookie_45consentBgzDrsIydxMeta?.alias || [],
    redirect: cookie_45consentBgzDrsIydxMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentBgzDrsIydxMeta?.name ?? "legal-cookie-consent___ca",
    path: cookie_45consentBgzDrsIydxMeta?.path ?? "/ca/legal/cookie-consent",
    meta: cookie_45consentBgzDrsIydxMeta || {},
    alias: cookie_45consentBgzDrsIydxMeta?.alias || [],
    redirect: cookie_45consentBgzDrsIydxMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentBgzDrsIydxMeta?.name ?? "legal-cookie-consent___nl",
    path: cookie_45consentBgzDrsIydxMeta?.path ?? "/nl/legal/cookie-consent",
    meta: cookie_45consentBgzDrsIydxMeta || {},
    alias: cookie_45consentBgzDrsIydxMeta?.alias || [],
    redirect: cookie_45consentBgzDrsIydxMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentBgzDrsIydxMeta?.name ?? "legal-cookie-consent___uk",
    path: cookie_45consentBgzDrsIydxMeta?.path ?? "/uk/legal/cookie-consent",
    meta: cookie_45consentBgzDrsIydxMeta || {},
    alias: cookie_45consentBgzDrsIydxMeta?.alias || [],
    redirect: cookie_45consentBgzDrsIydxMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentBgzDrsIydxMeta?.name ?? "legal-cookie-consent___de",
    path: cookie_45consentBgzDrsIydxMeta?.path ?? "/de/legal/cookie-consent",
    meta: cookie_45consentBgzDrsIydxMeta || {},
    alias: cookie_45consentBgzDrsIydxMeta?.alias || [],
    redirect: cookie_45consentBgzDrsIydxMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentBgzDrsIydxMeta?.name ?? "legal-cookie-consent___mx",
    path: cookie_45consentBgzDrsIydxMeta?.path ?? "/mx/legal/cookie-consent",
    meta: cookie_45consentBgzDrsIydxMeta || {},
    alias: cookie_45consentBgzDrsIydxMeta?.alias || [],
    redirect: cookie_45consentBgzDrsIydxMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentBgzDrsIydxMeta?.name ?? "legal-cookie-consent___fr",
    path: cookie_45consentBgzDrsIydxMeta?.path ?? "/fr/legal/cookie-consent",
    meta: cookie_45consentBgzDrsIydxMeta || {},
    alias: cookie_45consentBgzDrsIydxMeta?.alias || [],
    redirect: cookie_45consentBgzDrsIydxMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentBgzDrsIydxMeta?.name ?? "legal-cookie-consent___fr-ca",
    path: cookie_45consentBgzDrsIydxMeta?.path ?? "/fr-ca/legal/cookie-consent",
    meta: cookie_45consentBgzDrsIydxMeta || {},
    alias: cookie_45consentBgzDrsIydxMeta?.alias || [],
    redirect: cookie_45consentBgzDrsIydxMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyH4PjFfHQ79Meta?.name ?? "legal-cookie-policy___en-US",
    path: cookie_45policyH4PjFfHQ79Meta?.path ?? "/legal/cookie-policy",
    meta: cookie_45policyH4PjFfHQ79Meta || {},
    alias: cookie_45policyH4PjFfHQ79Meta?.alias || [],
    redirect: cookie_45policyH4PjFfHQ79Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyH4PjFfHQ79Meta?.name ?? "legal-cookie-policy___ca",
    path: cookie_45policyH4PjFfHQ79Meta?.path ?? "/ca/legal/cookie-policy",
    meta: cookie_45policyH4PjFfHQ79Meta || {},
    alias: cookie_45policyH4PjFfHQ79Meta?.alias || [],
    redirect: cookie_45policyH4PjFfHQ79Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyH4PjFfHQ79Meta?.name ?? "legal-cookie-policy___nl",
    path: cookie_45policyH4PjFfHQ79Meta?.path ?? "/nl/legal/cookie-policy",
    meta: cookie_45policyH4PjFfHQ79Meta || {},
    alias: cookie_45policyH4PjFfHQ79Meta?.alias || [],
    redirect: cookie_45policyH4PjFfHQ79Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyH4PjFfHQ79Meta?.name ?? "legal-cookie-policy___uk",
    path: cookie_45policyH4PjFfHQ79Meta?.path ?? "/uk/legal/cookie-policy",
    meta: cookie_45policyH4PjFfHQ79Meta || {},
    alias: cookie_45policyH4PjFfHQ79Meta?.alias || [],
    redirect: cookie_45policyH4PjFfHQ79Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyH4PjFfHQ79Meta?.name ?? "legal-cookie-policy___de",
    path: cookie_45policyH4PjFfHQ79Meta?.path ?? "/de/legal/cookie-policy",
    meta: cookie_45policyH4PjFfHQ79Meta || {},
    alias: cookie_45policyH4PjFfHQ79Meta?.alias || [],
    redirect: cookie_45policyH4PjFfHQ79Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyH4PjFfHQ79Meta?.name ?? "legal-cookie-policy___mx",
    path: cookie_45policyH4PjFfHQ79Meta?.path ?? "/mx/legal/cookie-policy",
    meta: cookie_45policyH4PjFfHQ79Meta || {},
    alias: cookie_45policyH4PjFfHQ79Meta?.alias || [],
    redirect: cookie_45policyH4PjFfHQ79Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyH4PjFfHQ79Meta?.name ?? "legal-cookie-policy___fr",
    path: cookie_45policyH4PjFfHQ79Meta?.path ?? "/fr/legal/cookie-policy",
    meta: cookie_45policyH4PjFfHQ79Meta || {},
    alias: cookie_45policyH4PjFfHQ79Meta?.alias || [],
    redirect: cookie_45policyH4PjFfHQ79Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyH4PjFfHQ79Meta?.name ?? "legal-cookie-policy___fr-ca",
    path: cookie_45policyH4PjFfHQ79Meta?.path ?? "/fr-ca/legal/cookie-policy",
    meta: cookie_45policyH4PjFfHQ79Meta || {},
    alias: cookie_45policyH4PjFfHQ79Meta?.alias || [],
    redirect: cookie_45policyH4PjFfHQ79Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addenduml2TIK7YfybMeta?.name ?? "legal-data-protection-addendum___en-US",
    path: data_45protection_45addenduml2TIK7YfybMeta?.path ?? "/legal/data-protection-addendum",
    meta: data_45protection_45addenduml2TIK7YfybMeta || {},
    alias: data_45protection_45addenduml2TIK7YfybMeta?.alias || [],
    redirect: data_45protection_45addenduml2TIK7YfybMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addenduml2TIK7YfybMeta?.name ?? "legal-data-protection-addendum___ca",
    path: data_45protection_45addenduml2TIK7YfybMeta?.path ?? "/ca/legal/data-protection-addendum",
    meta: data_45protection_45addenduml2TIK7YfybMeta || {},
    alias: data_45protection_45addenduml2TIK7YfybMeta?.alias || [],
    redirect: data_45protection_45addenduml2TIK7YfybMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addenduml2TIK7YfybMeta?.name ?? "legal-data-protection-addendum___nl",
    path: data_45protection_45addenduml2TIK7YfybMeta?.path ?? "/nl/legal/data-protection-addendum",
    meta: data_45protection_45addenduml2TIK7YfybMeta || {},
    alias: data_45protection_45addenduml2TIK7YfybMeta?.alias || [],
    redirect: data_45protection_45addenduml2TIK7YfybMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addenduml2TIK7YfybMeta?.name ?? "legal-data-protection-addendum___uk",
    path: data_45protection_45addenduml2TIK7YfybMeta?.path ?? "/uk/legal/data-protection-addendum",
    meta: data_45protection_45addenduml2TIK7YfybMeta || {},
    alias: data_45protection_45addenduml2TIK7YfybMeta?.alias || [],
    redirect: data_45protection_45addenduml2TIK7YfybMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addenduml2TIK7YfybMeta?.name ?? "legal-data-protection-addendum___de",
    path: data_45protection_45addenduml2TIK7YfybMeta?.path ?? "/de/legal/data-protection-addendum",
    meta: data_45protection_45addenduml2TIK7YfybMeta || {},
    alias: data_45protection_45addenduml2TIK7YfybMeta?.alias || [],
    redirect: data_45protection_45addenduml2TIK7YfybMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addenduml2TIK7YfybMeta?.name ?? "legal-data-protection-addendum___mx",
    path: data_45protection_45addenduml2TIK7YfybMeta?.path ?? "/mx/legal/data-protection-addendum",
    meta: data_45protection_45addenduml2TIK7YfybMeta || {},
    alias: data_45protection_45addenduml2TIK7YfybMeta?.alias || [],
    redirect: data_45protection_45addenduml2TIK7YfybMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addenduml2TIK7YfybMeta?.name ?? "legal-data-protection-addendum___fr",
    path: data_45protection_45addenduml2TIK7YfybMeta?.path ?? "/fr/legal/data-protection-addendum",
    meta: data_45protection_45addenduml2TIK7YfybMeta || {},
    alias: data_45protection_45addenduml2TIK7YfybMeta?.alias || [],
    redirect: data_45protection_45addenduml2TIK7YfybMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addenduml2TIK7YfybMeta?.name ?? "legal-data-protection-addendum___fr-ca",
    path: data_45protection_45addenduml2TIK7YfybMeta?.path ?? "/fr-ca/legal/data-protection-addendum",
    meta: data_45protection_45addenduml2TIK7YfybMeta || {},
    alias: data_45protection_45addenduml2TIK7YfybMeta?.alias || [],
    redirect: data_45protection_45addenduml2TIK7YfybMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsCUWM2mQ9jSMeta?.name ?? "legal-express-order-terms___en-US",
    path: express_45order_45termsCUWM2mQ9jSMeta?.path ?? "/legal/express-order-terms",
    meta: express_45order_45termsCUWM2mQ9jSMeta || {},
    alias: express_45order_45termsCUWM2mQ9jSMeta?.alias || [],
    redirect: express_45order_45termsCUWM2mQ9jSMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsCUWM2mQ9jSMeta?.name ?? "legal-express-order-terms___ca",
    path: express_45order_45termsCUWM2mQ9jSMeta?.path ?? "/ca/legal/express-order-terms",
    meta: express_45order_45termsCUWM2mQ9jSMeta || {},
    alias: express_45order_45termsCUWM2mQ9jSMeta?.alias || [],
    redirect: express_45order_45termsCUWM2mQ9jSMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsCUWM2mQ9jSMeta?.name ?? "legal-express-order-terms___nl",
    path: express_45order_45termsCUWM2mQ9jSMeta?.path ?? "/nl/legal/express-order-terms",
    meta: express_45order_45termsCUWM2mQ9jSMeta || {},
    alias: express_45order_45termsCUWM2mQ9jSMeta?.alias || [],
    redirect: express_45order_45termsCUWM2mQ9jSMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsCUWM2mQ9jSMeta?.name ?? "legal-express-order-terms___uk",
    path: express_45order_45termsCUWM2mQ9jSMeta?.path ?? "/uk/legal/express-order-terms",
    meta: express_45order_45termsCUWM2mQ9jSMeta || {},
    alias: express_45order_45termsCUWM2mQ9jSMeta?.alias || [],
    redirect: express_45order_45termsCUWM2mQ9jSMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsCUWM2mQ9jSMeta?.name ?? "legal-express-order-terms___de",
    path: express_45order_45termsCUWM2mQ9jSMeta?.path ?? "/de/legal/express-order-terms",
    meta: express_45order_45termsCUWM2mQ9jSMeta || {},
    alias: express_45order_45termsCUWM2mQ9jSMeta?.alias || [],
    redirect: express_45order_45termsCUWM2mQ9jSMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsCUWM2mQ9jSMeta?.name ?? "legal-express-order-terms___mx",
    path: express_45order_45termsCUWM2mQ9jSMeta?.path ?? "/mx/legal/express-order-terms",
    meta: express_45order_45termsCUWM2mQ9jSMeta || {},
    alias: express_45order_45termsCUWM2mQ9jSMeta?.alias || [],
    redirect: express_45order_45termsCUWM2mQ9jSMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsCUWM2mQ9jSMeta?.name ?? "legal-express-order-terms___fr",
    path: express_45order_45termsCUWM2mQ9jSMeta?.path ?? "/fr/legal/express-order-terms",
    meta: express_45order_45termsCUWM2mQ9jSMeta || {},
    alias: express_45order_45termsCUWM2mQ9jSMeta?.alias || [],
    redirect: express_45order_45termsCUWM2mQ9jSMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsCUWM2mQ9jSMeta?.name ?? "legal-express-order-terms___fr-ca",
    path: express_45order_45termsCUWM2mQ9jSMeta?.path ?? "/fr-ca/legal/express-order-terms",
    meta: express_45order_45termsCUWM2mQ9jSMeta || {},
    alias: express_45order_45termsCUWM2mQ9jSMeta?.alias || [],
    redirect: express_45order_45termsCUWM2mQ9jSMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantySbsUhqGDfKMeta?.name ?? "legal-hardware-warranty___en-US",
    path: hardware_45warrantySbsUhqGDfKMeta?.path ?? "/legal/hardware-warranty",
    meta: hardware_45warrantySbsUhqGDfKMeta || {},
    alias: hardware_45warrantySbsUhqGDfKMeta?.alias || [],
    redirect: hardware_45warrantySbsUhqGDfKMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantySbsUhqGDfKMeta?.name ?? "legal-hardware-warranty___ca",
    path: hardware_45warrantySbsUhqGDfKMeta?.path ?? "/ca/legal/hardware-warranty",
    meta: hardware_45warrantySbsUhqGDfKMeta || {},
    alias: hardware_45warrantySbsUhqGDfKMeta?.alias || [],
    redirect: hardware_45warrantySbsUhqGDfKMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantySbsUhqGDfKMeta?.name ?? "legal-hardware-warranty___nl",
    path: hardware_45warrantySbsUhqGDfKMeta?.path ?? "/nl/legal/hardware-warranty",
    meta: hardware_45warrantySbsUhqGDfKMeta || {},
    alias: hardware_45warrantySbsUhqGDfKMeta?.alias || [],
    redirect: hardware_45warrantySbsUhqGDfKMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantySbsUhqGDfKMeta?.name ?? "legal-hardware-warranty___uk",
    path: hardware_45warrantySbsUhqGDfKMeta?.path ?? "/uk/legal/hardware-warranty",
    meta: hardware_45warrantySbsUhqGDfKMeta || {},
    alias: hardware_45warrantySbsUhqGDfKMeta?.alias || [],
    redirect: hardware_45warrantySbsUhqGDfKMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantySbsUhqGDfKMeta?.name ?? "legal-hardware-warranty___de",
    path: hardware_45warrantySbsUhqGDfKMeta?.path ?? "/de/legal/hardware-warranty",
    meta: hardware_45warrantySbsUhqGDfKMeta || {},
    alias: hardware_45warrantySbsUhqGDfKMeta?.alias || [],
    redirect: hardware_45warrantySbsUhqGDfKMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantySbsUhqGDfKMeta?.name ?? "legal-hardware-warranty___mx",
    path: hardware_45warrantySbsUhqGDfKMeta?.path ?? "/mx/legal/hardware-warranty",
    meta: hardware_45warrantySbsUhqGDfKMeta || {},
    alias: hardware_45warrantySbsUhqGDfKMeta?.alias || [],
    redirect: hardware_45warrantySbsUhqGDfKMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantySbsUhqGDfKMeta?.name ?? "legal-hardware-warranty___fr",
    path: hardware_45warrantySbsUhqGDfKMeta?.path ?? "/fr/legal/hardware-warranty",
    meta: hardware_45warrantySbsUhqGDfKMeta || {},
    alias: hardware_45warrantySbsUhqGDfKMeta?.alias || [],
    redirect: hardware_45warrantySbsUhqGDfKMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantySbsUhqGDfKMeta?.name ?? "legal-hardware-warranty___fr-ca",
    path: hardware_45warrantySbsUhqGDfKMeta?.path ?? "/fr-ca/legal/hardware-warranty",
    meta: hardware_45warrantySbsUhqGDfKMeta || {},
    alias: hardware_45warrantySbsUhqGDfKMeta?.alias || [],
    redirect: hardware_45warrantySbsUhqGDfKMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slavZqWQXrRGoMeta?.name ?? "legal-hosted-software-sla___en-US",
    path: hosted_45software_45slavZqWQXrRGoMeta?.path ?? "/legal/hosted-software-sla",
    meta: hosted_45software_45slavZqWQXrRGoMeta || {},
    alias: hosted_45software_45slavZqWQXrRGoMeta?.alias || [],
    redirect: hosted_45software_45slavZqWQXrRGoMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slavZqWQXrRGoMeta?.name ?? "legal-hosted-software-sla___ca",
    path: hosted_45software_45slavZqWQXrRGoMeta?.path ?? "/ca/legal/hosted-software-sla",
    meta: hosted_45software_45slavZqWQXrRGoMeta || {},
    alias: hosted_45software_45slavZqWQXrRGoMeta?.alias || [],
    redirect: hosted_45software_45slavZqWQXrRGoMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slavZqWQXrRGoMeta?.name ?? "legal-hosted-software-sla___nl",
    path: hosted_45software_45slavZqWQXrRGoMeta?.path ?? "/nl/legal/hosted-software-sla",
    meta: hosted_45software_45slavZqWQXrRGoMeta || {},
    alias: hosted_45software_45slavZqWQXrRGoMeta?.alias || [],
    redirect: hosted_45software_45slavZqWQXrRGoMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slavZqWQXrRGoMeta?.name ?? "legal-hosted-software-sla___uk",
    path: hosted_45software_45slavZqWQXrRGoMeta?.path ?? "/uk/legal/hosted-software-sla",
    meta: hosted_45software_45slavZqWQXrRGoMeta || {},
    alias: hosted_45software_45slavZqWQXrRGoMeta?.alias || [],
    redirect: hosted_45software_45slavZqWQXrRGoMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slavZqWQXrRGoMeta?.name ?? "legal-hosted-software-sla___de",
    path: hosted_45software_45slavZqWQXrRGoMeta?.path ?? "/de/legal/hosted-software-sla",
    meta: hosted_45software_45slavZqWQXrRGoMeta || {},
    alias: hosted_45software_45slavZqWQXrRGoMeta?.alias || [],
    redirect: hosted_45software_45slavZqWQXrRGoMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slavZqWQXrRGoMeta?.name ?? "legal-hosted-software-sla___mx",
    path: hosted_45software_45slavZqWQXrRGoMeta?.path ?? "/mx/legal/hosted-software-sla",
    meta: hosted_45software_45slavZqWQXrRGoMeta || {},
    alias: hosted_45software_45slavZqWQXrRGoMeta?.alias || [],
    redirect: hosted_45software_45slavZqWQXrRGoMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slavZqWQXrRGoMeta?.name ?? "legal-hosted-software-sla___fr",
    path: hosted_45software_45slavZqWQXrRGoMeta?.path ?? "/fr/legal/hosted-software-sla",
    meta: hosted_45software_45slavZqWQXrRGoMeta || {},
    alias: hosted_45software_45slavZqWQXrRGoMeta?.alias || [],
    redirect: hosted_45software_45slavZqWQXrRGoMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slavZqWQXrRGoMeta?.name ?? "legal-hosted-software-sla___fr-ca",
    path: hosted_45software_45slavZqWQXrRGoMeta?.path ?? "/fr-ca/legal/hosted-software-sla",
    meta: hosted_45software_45slavZqWQXrRGoMeta || {},
    alias: hosted_45software_45slavZqWQXrRGoMeta?.alias || [],
    redirect: hosted_45software_45slavZqWQXrRGoMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conducti0ViBXP0A9Meta?.name ?? "legal-partner-code-of-conduct___en-US",
    path: partner_45code_45of_45conducti0ViBXP0A9Meta?.path ?? "/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conducti0ViBXP0A9Meta || {},
    alias: partner_45code_45of_45conducti0ViBXP0A9Meta?.alias || [],
    redirect: partner_45code_45of_45conducti0ViBXP0A9Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conducti0ViBXP0A9Meta?.name ?? "legal-partner-code-of-conduct___ca",
    path: partner_45code_45of_45conducti0ViBXP0A9Meta?.path ?? "/ca/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conducti0ViBXP0A9Meta || {},
    alias: partner_45code_45of_45conducti0ViBXP0A9Meta?.alias || [],
    redirect: partner_45code_45of_45conducti0ViBXP0A9Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conducti0ViBXP0A9Meta?.name ?? "legal-partner-code-of-conduct___nl",
    path: partner_45code_45of_45conducti0ViBXP0A9Meta?.path ?? "/nl/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conducti0ViBXP0A9Meta || {},
    alias: partner_45code_45of_45conducti0ViBXP0A9Meta?.alias || [],
    redirect: partner_45code_45of_45conducti0ViBXP0A9Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conducti0ViBXP0A9Meta?.name ?? "legal-partner-code-of-conduct___uk",
    path: partner_45code_45of_45conducti0ViBXP0A9Meta?.path ?? "/uk/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conducti0ViBXP0A9Meta || {},
    alias: partner_45code_45of_45conducti0ViBXP0A9Meta?.alias || [],
    redirect: partner_45code_45of_45conducti0ViBXP0A9Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conducti0ViBXP0A9Meta?.name ?? "legal-partner-code-of-conduct___de",
    path: partner_45code_45of_45conducti0ViBXP0A9Meta?.path ?? "/de/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conducti0ViBXP0A9Meta || {},
    alias: partner_45code_45of_45conducti0ViBXP0A9Meta?.alias || [],
    redirect: partner_45code_45of_45conducti0ViBXP0A9Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conducti0ViBXP0A9Meta?.name ?? "legal-partner-code-of-conduct___mx",
    path: partner_45code_45of_45conducti0ViBXP0A9Meta?.path ?? "/mx/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conducti0ViBXP0A9Meta || {},
    alias: partner_45code_45of_45conducti0ViBXP0A9Meta?.alias || [],
    redirect: partner_45code_45of_45conducti0ViBXP0A9Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conducti0ViBXP0A9Meta?.name ?? "legal-partner-code-of-conduct___fr",
    path: partner_45code_45of_45conducti0ViBXP0A9Meta?.path ?? "/fr/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conducti0ViBXP0A9Meta || {},
    alias: partner_45code_45of_45conducti0ViBXP0A9Meta?.alias || [],
    redirect: partner_45code_45of_45conducti0ViBXP0A9Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conducti0ViBXP0A9Meta?.name ?? "legal-partner-code-of-conduct___fr-ca",
    path: partner_45code_45of_45conducti0ViBXP0A9Meta?.path ?? "/fr-ca/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conducti0ViBXP0A9Meta || {},
    alias: partner_45code_45of_45conducti0ViBXP0A9Meta?.alias || [],
    redirect: partner_45code_45of_45conducti0ViBXP0A9Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceqzVAZPExxYMeta?.name ?? "legal-platform-terms-of-service___en-US",
    path: platform_45terms_45of_45serviceqzVAZPExxYMeta?.path ?? "/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceqzVAZPExxYMeta || {},
    alias: platform_45terms_45of_45serviceqzVAZPExxYMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceqzVAZPExxYMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceqzVAZPExxYMeta?.name ?? "legal-platform-terms-of-service___ca",
    path: platform_45terms_45of_45serviceqzVAZPExxYMeta?.path ?? "/ca/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceqzVAZPExxYMeta || {},
    alias: platform_45terms_45of_45serviceqzVAZPExxYMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceqzVAZPExxYMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceqzVAZPExxYMeta?.name ?? "legal-platform-terms-of-service___nl",
    path: platform_45terms_45of_45serviceqzVAZPExxYMeta?.path ?? "/nl/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceqzVAZPExxYMeta || {},
    alias: platform_45terms_45of_45serviceqzVAZPExxYMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceqzVAZPExxYMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceqzVAZPExxYMeta?.name ?? "legal-platform-terms-of-service___uk",
    path: platform_45terms_45of_45serviceqzVAZPExxYMeta?.path ?? "/uk/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceqzVAZPExxYMeta || {},
    alias: platform_45terms_45of_45serviceqzVAZPExxYMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceqzVAZPExxYMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceqzVAZPExxYMeta?.name ?? "legal-platform-terms-of-service___de",
    path: platform_45terms_45of_45serviceqzVAZPExxYMeta?.path ?? "/de/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceqzVAZPExxYMeta || {},
    alias: platform_45terms_45of_45serviceqzVAZPExxYMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceqzVAZPExxYMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceqzVAZPExxYMeta?.name ?? "legal-platform-terms-of-service___mx",
    path: platform_45terms_45of_45serviceqzVAZPExxYMeta?.path ?? "/mx/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceqzVAZPExxYMeta || {},
    alias: platform_45terms_45of_45serviceqzVAZPExxYMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceqzVAZPExxYMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceqzVAZPExxYMeta?.name ?? "legal-platform-terms-of-service___fr",
    path: platform_45terms_45of_45serviceqzVAZPExxYMeta?.path ?? "/fr/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceqzVAZPExxYMeta || {},
    alias: platform_45terms_45of_45serviceqzVAZPExxYMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceqzVAZPExxYMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceqzVAZPExxYMeta?.name ?? "legal-platform-terms-of-service___fr-ca",
    path: platform_45terms_45of_45serviceqzVAZPExxYMeta?.path ?? "/fr-ca/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceqzVAZPExxYMeta || {},
    alias: platform_45terms_45of_45serviceqzVAZPExxYMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceqzVAZPExxYMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: privacy5vGHmEkb53Meta?.name ?? "legal-privacy___en-US",
    path: privacy5vGHmEkb53Meta?.path ?? "/legal/privacy",
    meta: privacy5vGHmEkb53Meta || {},
    alias: privacy5vGHmEkb53Meta?.alias || [],
    redirect: privacy5vGHmEkb53Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy5vGHmEkb53Meta?.name ?? "legal-privacy___ca",
    path: privacy5vGHmEkb53Meta?.path ?? "/ca/legal/privacy",
    meta: privacy5vGHmEkb53Meta || {},
    alias: privacy5vGHmEkb53Meta?.alias || [],
    redirect: privacy5vGHmEkb53Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy5vGHmEkb53Meta?.name ?? "legal-privacy___nl",
    path: privacy5vGHmEkb53Meta?.path ?? "/nl/legal/privacy",
    meta: privacy5vGHmEkb53Meta || {},
    alias: privacy5vGHmEkb53Meta?.alias || [],
    redirect: privacy5vGHmEkb53Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy5vGHmEkb53Meta?.name ?? "legal-privacy___uk",
    path: privacy5vGHmEkb53Meta?.path ?? "/uk/legal/privacy",
    meta: privacy5vGHmEkb53Meta || {},
    alias: privacy5vGHmEkb53Meta?.alias || [],
    redirect: privacy5vGHmEkb53Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy5vGHmEkb53Meta?.name ?? "legal-privacy___de",
    path: privacy5vGHmEkb53Meta?.path ?? "/de/legal/privacy",
    meta: privacy5vGHmEkb53Meta || {},
    alias: privacy5vGHmEkb53Meta?.alias || [],
    redirect: privacy5vGHmEkb53Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy5vGHmEkb53Meta?.name ?? "legal-privacy___mx",
    path: privacy5vGHmEkb53Meta?.path ?? "/mx/legal/privacy",
    meta: privacy5vGHmEkb53Meta || {},
    alias: privacy5vGHmEkb53Meta?.alias || [],
    redirect: privacy5vGHmEkb53Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy5vGHmEkb53Meta?.name ?? "legal-privacy___fr",
    path: privacy5vGHmEkb53Meta?.path ?? "/fr/legal/privacy",
    meta: privacy5vGHmEkb53Meta || {},
    alias: privacy5vGHmEkb53Meta?.alias || [],
    redirect: privacy5vGHmEkb53Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy5vGHmEkb53Meta?.name ?? "legal-privacy___fr-ca",
    path: privacy5vGHmEkb53Meta?.path ?? "/fr-ca/legal/privacy",
    meta: privacy5vGHmEkb53Meta || {},
    alias: privacy5vGHmEkb53Meta?.alias || [],
    redirect: privacy5vGHmEkb53Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta?.name ?? "legal-public-sector-customers-platform-terms-of-service___en-US",
    path: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta?.path ?? "/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta?.name ?? "legal-public-sector-customers-platform-terms-of-service___ca",
    path: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta?.path ?? "/ca/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta?.name ?? "legal-public-sector-customers-platform-terms-of-service___nl",
    path: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta?.path ?? "/nl/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta?.name ?? "legal-public-sector-customers-platform-terms-of-service___uk",
    path: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta?.path ?? "/uk/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta?.name ?? "legal-public-sector-customers-platform-terms-of-service___de",
    path: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta?.path ?? "/de/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta?.name ?? "legal-public-sector-customers-platform-terms-of-service___mx",
    path: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta?.path ?? "/mx/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta?.name ?? "legal-public-sector-customers-platform-terms-of-service___fr",
    path: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta?.path ?? "/fr/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta?.name ?? "legal-public-sector-customers-platform-terms-of-service___fr-ca",
    path: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta?.path ?? "/fr-ca/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45service7tQ0uLWvc3Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataLuF7SVQdkgMeta?.name ?? "legal-requestingcustomerdata___en-US",
    path: requestingcustomerdataLuF7SVQdkgMeta?.path ?? "/legal/requestingcustomerdata",
    meta: requestingcustomerdataLuF7SVQdkgMeta || {},
    alias: requestingcustomerdataLuF7SVQdkgMeta?.alias || [],
    redirect: requestingcustomerdataLuF7SVQdkgMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataLuF7SVQdkgMeta?.name ?? "legal-requestingcustomerdata___ca",
    path: requestingcustomerdataLuF7SVQdkgMeta?.path ?? "/ca/legal/requestingcustomerdata",
    meta: requestingcustomerdataLuF7SVQdkgMeta || {},
    alias: requestingcustomerdataLuF7SVQdkgMeta?.alias || [],
    redirect: requestingcustomerdataLuF7SVQdkgMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataLuF7SVQdkgMeta?.name ?? "legal-requestingcustomerdata___nl",
    path: requestingcustomerdataLuF7SVQdkgMeta?.path ?? "/nl/legal/requestingcustomerdata",
    meta: requestingcustomerdataLuF7SVQdkgMeta || {},
    alias: requestingcustomerdataLuF7SVQdkgMeta?.alias || [],
    redirect: requestingcustomerdataLuF7SVQdkgMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataLuF7SVQdkgMeta?.name ?? "legal-requestingcustomerdata___uk",
    path: requestingcustomerdataLuF7SVQdkgMeta?.path ?? "/uk/legal/requestingcustomerdata",
    meta: requestingcustomerdataLuF7SVQdkgMeta || {},
    alias: requestingcustomerdataLuF7SVQdkgMeta?.alias || [],
    redirect: requestingcustomerdataLuF7SVQdkgMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataLuF7SVQdkgMeta?.name ?? "legal-requestingcustomerdata___de",
    path: requestingcustomerdataLuF7SVQdkgMeta?.path ?? "/de/legal/requestingcustomerdata",
    meta: requestingcustomerdataLuF7SVQdkgMeta || {},
    alias: requestingcustomerdataLuF7SVQdkgMeta?.alias || [],
    redirect: requestingcustomerdataLuF7SVQdkgMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataLuF7SVQdkgMeta?.name ?? "legal-requestingcustomerdata___mx",
    path: requestingcustomerdataLuF7SVQdkgMeta?.path ?? "/mx/legal/requestingcustomerdata",
    meta: requestingcustomerdataLuF7SVQdkgMeta || {},
    alias: requestingcustomerdataLuF7SVQdkgMeta?.alias || [],
    redirect: requestingcustomerdataLuF7SVQdkgMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataLuF7SVQdkgMeta?.name ?? "legal-requestingcustomerdata___fr",
    path: requestingcustomerdataLuF7SVQdkgMeta?.path ?? "/fr/legal/requestingcustomerdata",
    meta: requestingcustomerdataLuF7SVQdkgMeta || {},
    alias: requestingcustomerdataLuF7SVQdkgMeta?.alias || [],
    redirect: requestingcustomerdataLuF7SVQdkgMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataLuF7SVQdkgMeta?.name ?? "legal-requestingcustomerdata___fr-ca",
    path: requestingcustomerdataLuF7SVQdkgMeta?.path ?? "/fr-ca/legal/requestingcustomerdata",
    meta: requestingcustomerdataLuF7SVQdkgMeta || {},
    alias: requestingcustomerdataLuF7SVQdkgMeta?.alias || [],
    redirect: requestingcustomerdataLuF7SVQdkgMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: security0fJQm14lR2Meta?.name ?? "legal-security___en-US",
    path: security0fJQm14lR2Meta?.path ?? "/legal/security",
    meta: security0fJQm14lR2Meta || {},
    alias: security0fJQm14lR2Meta?.alias || [],
    redirect: security0fJQm14lR2Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: security0fJQm14lR2Meta?.name ?? "legal-security___ca",
    path: security0fJQm14lR2Meta?.path ?? "/ca/legal/security",
    meta: security0fJQm14lR2Meta || {},
    alias: security0fJQm14lR2Meta?.alias || [],
    redirect: security0fJQm14lR2Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: security0fJQm14lR2Meta?.name ?? "legal-security___nl",
    path: security0fJQm14lR2Meta?.path ?? "/nl/legal/security",
    meta: security0fJQm14lR2Meta || {},
    alias: security0fJQm14lR2Meta?.alias || [],
    redirect: security0fJQm14lR2Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: security0fJQm14lR2Meta?.name ?? "legal-security___uk",
    path: security0fJQm14lR2Meta?.path ?? "/uk/legal/security",
    meta: security0fJQm14lR2Meta || {},
    alias: security0fJQm14lR2Meta?.alias || [],
    redirect: security0fJQm14lR2Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: security0fJQm14lR2Meta?.name ?? "legal-security___de",
    path: security0fJQm14lR2Meta?.path ?? "/de/legal/security",
    meta: security0fJQm14lR2Meta || {},
    alias: security0fJQm14lR2Meta?.alias || [],
    redirect: security0fJQm14lR2Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: security0fJQm14lR2Meta?.name ?? "legal-security___mx",
    path: security0fJQm14lR2Meta?.path ?? "/mx/legal/security",
    meta: security0fJQm14lR2Meta || {},
    alias: security0fJQm14lR2Meta?.alias || [],
    redirect: security0fJQm14lR2Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: security0fJQm14lR2Meta?.name ?? "legal-security___fr",
    path: security0fJQm14lR2Meta?.path ?? "/fr/legal/security",
    meta: security0fJQm14lR2Meta || {},
    alias: security0fJQm14lR2Meta?.alias || [],
    redirect: security0fJQm14lR2Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: security0fJQm14lR2Meta?.name ?? "legal-security___fr-ca",
    path: security0fJQm14lR2Meta?.path ?? "/fr-ca/legal/security",
    meta: security0fJQm14lR2Meta || {},
    alias: security0fJQm14lR2Meta?.alias || [],
    redirect: security0fJQm14lR2Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: transparencyreport2uCJz8ZmztMeta?.name ?? "legal-transparencyreport___en-US",
    path: transparencyreport2uCJz8ZmztMeta?.path ?? "/legal/transparencyreport",
    meta: transparencyreport2uCJz8ZmztMeta || {},
    alias: transparencyreport2uCJz8ZmztMeta?.alias || [],
    redirect: transparencyreport2uCJz8ZmztMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreport2uCJz8ZmztMeta?.name ?? "legal-transparencyreport___ca",
    path: transparencyreport2uCJz8ZmztMeta?.path ?? "/ca/legal/transparencyreport",
    meta: transparencyreport2uCJz8ZmztMeta || {},
    alias: transparencyreport2uCJz8ZmztMeta?.alias || [],
    redirect: transparencyreport2uCJz8ZmztMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreport2uCJz8ZmztMeta?.name ?? "legal-transparencyreport___nl",
    path: transparencyreport2uCJz8ZmztMeta?.path ?? "/nl/legal/transparencyreport",
    meta: transparencyreport2uCJz8ZmztMeta || {},
    alias: transparencyreport2uCJz8ZmztMeta?.alias || [],
    redirect: transparencyreport2uCJz8ZmztMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreport2uCJz8ZmztMeta?.name ?? "legal-transparencyreport___uk",
    path: transparencyreport2uCJz8ZmztMeta?.path ?? "/uk/legal/transparencyreport",
    meta: transparencyreport2uCJz8ZmztMeta || {},
    alias: transparencyreport2uCJz8ZmztMeta?.alias || [],
    redirect: transparencyreport2uCJz8ZmztMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreport2uCJz8ZmztMeta?.name ?? "legal-transparencyreport___de",
    path: transparencyreport2uCJz8ZmztMeta?.path ?? "/de/legal/transparencyreport",
    meta: transparencyreport2uCJz8ZmztMeta || {},
    alias: transparencyreport2uCJz8ZmztMeta?.alias || [],
    redirect: transparencyreport2uCJz8ZmztMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreport2uCJz8ZmztMeta?.name ?? "legal-transparencyreport___mx",
    path: transparencyreport2uCJz8ZmztMeta?.path ?? "/mx/legal/transparencyreport",
    meta: transparencyreport2uCJz8ZmztMeta || {},
    alias: transparencyreport2uCJz8ZmztMeta?.alias || [],
    redirect: transparencyreport2uCJz8ZmztMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreport2uCJz8ZmztMeta?.name ?? "legal-transparencyreport___fr",
    path: transparencyreport2uCJz8ZmztMeta?.path ?? "/fr/legal/transparencyreport",
    meta: transparencyreport2uCJz8ZmztMeta || {},
    alias: transparencyreport2uCJz8ZmztMeta?.alias || [],
    redirect: transparencyreport2uCJz8ZmztMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreport2uCJz8ZmztMeta?.name ?? "legal-transparencyreport___fr-ca",
    path: transparencyreport2uCJz8ZmztMeta?.path ?? "/fr-ca/legal/transparencyreport",
    meta: transparencyreport2uCJz8ZmztMeta || {},
    alias: transparencyreport2uCJz8ZmztMeta?.alias || [],
    redirect: transparencyreport2uCJz8ZmztMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceLNcWlNSHPjMeta?.name ?? "legal-website-terms-of-service___en-US",
    path: website_45terms_45of_45serviceLNcWlNSHPjMeta?.path ?? "/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceLNcWlNSHPjMeta || {},
    alias: website_45terms_45of_45serviceLNcWlNSHPjMeta?.alias || [],
    redirect: website_45terms_45of_45serviceLNcWlNSHPjMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceLNcWlNSHPjMeta?.name ?? "legal-website-terms-of-service___ca",
    path: website_45terms_45of_45serviceLNcWlNSHPjMeta?.path ?? "/ca/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceLNcWlNSHPjMeta || {},
    alias: website_45terms_45of_45serviceLNcWlNSHPjMeta?.alias || [],
    redirect: website_45terms_45of_45serviceLNcWlNSHPjMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceLNcWlNSHPjMeta?.name ?? "legal-website-terms-of-service___nl",
    path: website_45terms_45of_45serviceLNcWlNSHPjMeta?.path ?? "/nl/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceLNcWlNSHPjMeta || {},
    alias: website_45terms_45of_45serviceLNcWlNSHPjMeta?.alias || [],
    redirect: website_45terms_45of_45serviceLNcWlNSHPjMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceLNcWlNSHPjMeta?.name ?? "legal-website-terms-of-service___uk",
    path: website_45terms_45of_45serviceLNcWlNSHPjMeta?.path ?? "/uk/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceLNcWlNSHPjMeta || {},
    alias: website_45terms_45of_45serviceLNcWlNSHPjMeta?.alias || [],
    redirect: website_45terms_45of_45serviceLNcWlNSHPjMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceLNcWlNSHPjMeta?.name ?? "legal-website-terms-of-service___de",
    path: website_45terms_45of_45serviceLNcWlNSHPjMeta?.path ?? "/de/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceLNcWlNSHPjMeta || {},
    alias: website_45terms_45of_45serviceLNcWlNSHPjMeta?.alias || [],
    redirect: website_45terms_45of_45serviceLNcWlNSHPjMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceLNcWlNSHPjMeta?.name ?? "legal-website-terms-of-service___mx",
    path: website_45terms_45of_45serviceLNcWlNSHPjMeta?.path ?? "/mx/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceLNcWlNSHPjMeta || {},
    alias: website_45terms_45of_45serviceLNcWlNSHPjMeta?.alias || [],
    redirect: website_45terms_45of_45serviceLNcWlNSHPjMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceLNcWlNSHPjMeta?.name ?? "legal-website-terms-of-service___fr",
    path: website_45terms_45of_45serviceLNcWlNSHPjMeta?.path ?? "/fr/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceLNcWlNSHPjMeta || {},
    alias: website_45terms_45of_45serviceLNcWlNSHPjMeta?.alias || [],
    redirect: website_45terms_45of_45serviceLNcWlNSHPjMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceLNcWlNSHPjMeta?.name ?? "legal-website-terms-of-service___fr-ca",
    path: website_45terms_45of_45serviceLNcWlNSHPjMeta?.path ?? "/fr-ca/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceLNcWlNSHPjMeta || {},
    alias: website_45terms_45of_45serviceLNcWlNSHPjMeta?.alias || [],
    redirect: website_45terms_45of_45serviceLNcWlNSHPjMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesbduqLwNYKcMeta?.name ?? "legal-your-privacy-choices___en-US",
    path: your_45privacy_45choicesbduqLwNYKcMeta?.path ?? "/legal/your-privacy-choices",
    meta: your_45privacy_45choicesbduqLwNYKcMeta || {},
    alias: your_45privacy_45choicesbduqLwNYKcMeta?.alias || [],
    redirect: your_45privacy_45choicesbduqLwNYKcMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesbduqLwNYKcMeta?.name ?? "legal-your-privacy-choices___ca",
    path: your_45privacy_45choicesbduqLwNYKcMeta?.path ?? "/ca/legal/your-privacy-choices",
    meta: your_45privacy_45choicesbduqLwNYKcMeta || {},
    alias: your_45privacy_45choicesbduqLwNYKcMeta?.alias || [],
    redirect: your_45privacy_45choicesbduqLwNYKcMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesbduqLwNYKcMeta?.name ?? "legal-your-privacy-choices___nl",
    path: your_45privacy_45choicesbduqLwNYKcMeta?.path ?? "/nl/legal/your-privacy-choices",
    meta: your_45privacy_45choicesbduqLwNYKcMeta || {},
    alias: your_45privacy_45choicesbduqLwNYKcMeta?.alias || [],
    redirect: your_45privacy_45choicesbduqLwNYKcMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesbduqLwNYKcMeta?.name ?? "legal-your-privacy-choices___uk",
    path: your_45privacy_45choicesbduqLwNYKcMeta?.path ?? "/uk/legal/your-privacy-choices",
    meta: your_45privacy_45choicesbduqLwNYKcMeta || {},
    alias: your_45privacy_45choicesbduqLwNYKcMeta?.alias || [],
    redirect: your_45privacy_45choicesbduqLwNYKcMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesbduqLwNYKcMeta?.name ?? "legal-your-privacy-choices___de",
    path: your_45privacy_45choicesbduqLwNYKcMeta?.path ?? "/de/legal/your-privacy-choices",
    meta: your_45privacy_45choicesbduqLwNYKcMeta || {},
    alias: your_45privacy_45choicesbduqLwNYKcMeta?.alias || [],
    redirect: your_45privacy_45choicesbduqLwNYKcMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesbduqLwNYKcMeta?.name ?? "legal-your-privacy-choices___mx",
    path: your_45privacy_45choicesbduqLwNYKcMeta?.path ?? "/mx/legal/your-privacy-choices",
    meta: your_45privacy_45choicesbduqLwNYKcMeta || {},
    alias: your_45privacy_45choicesbduqLwNYKcMeta?.alias || [],
    redirect: your_45privacy_45choicesbduqLwNYKcMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesbduqLwNYKcMeta?.name ?? "legal-your-privacy-choices___fr",
    path: your_45privacy_45choicesbduqLwNYKcMeta?.path ?? "/fr/legal/your-privacy-choices",
    meta: your_45privacy_45choicesbduqLwNYKcMeta || {},
    alias: your_45privacy_45choicesbduqLwNYKcMeta?.alias || [],
    redirect: your_45privacy_45choicesbduqLwNYKcMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesbduqLwNYKcMeta?.name ?? "legal-your-privacy-choices___fr-ca",
    path: your_45privacy_45choicesbduqLwNYKcMeta?.path ?? "/fr-ca/legal/your-privacy-choices",
    meta: your_45privacy_45choicesbduqLwNYKcMeta || {},
    alias: your_45privacy_45choicesbduqLwNYKcMeta?.alias || [],
    redirect: your_45privacy_45choicesbduqLwNYKcMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: pricingT19BNP0CwEMeta?.name ?? "page-pricing___en-US",
    path: pricingT19BNP0CwEMeta?.path ?? "/page/pricing",
    meta: pricingT19BNP0CwEMeta || {},
    alias: pricingT19BNP0CwEMeta?.alias || [],
    redirect: pricingT19BNP0CwEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingT19BNP0CwEMeta?.name ?? "page-pricing___ca",
    path: pricingT19BNP0CwEMeta?.path ?? "/ca/page/pricing",
    meta: pricingT19BNP0CwEMeta || {},
    alias: pricingT19BNP0CwEMeta?.alias || [],
    redirect: pricingT19BNP0CwEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingT19BNP0CwEMeta?.name ?? "page-pricing___nl",
    path: pricingT19BNP0CwEMeta?.path ?? "/nl/page/pricing",
    meta: pricingT19BNP0CwEMeta || {},
    alias: pricingT19BNP0CwEMeta?.alias || [],
    redirect: pricingT19BNP0CwEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingT19BNP0CwEMeta?.name ?? "page-pricing___uk",
    path: pricingT19BNP0CwEMeta?.path ?? "/uk/page/pricing",
    meta: pricingT19BNP0CwEMeta || {},
    alias: pricingT19BNP0CwEMeta?.alias || [],
    redirect: pricingT19BNP0CwEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingT19BNP0CwEMeta?.name ?? "page-pricing___de",
    path: pricingT19BNP0CwEMeta?.path ?? "/de/page/pricing",
    meta: pricingT19BNP0CwEMeta || {},
    alias: pricingT19BNP0CwEMeta?.alias || [],
    redirect: pricingT19BNP0CwEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingT19BNP0CwEMeta?.name ?? "page-pricing___mx",
    path: pricingT19BNP0CwEMeta?.path ?? "/mx/page/pricing",
    meta: pricingT19BNP0CwEMeta || {},
    alias: pricingT19BNP0CwEMeta?.alias || [],
    redirect: pricingT19BNP0CwEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingT19BNP0CwEMeta?.name ?? "page-pricing___fr",
    path: pricingT19BNP0CwEMeta?.path ?? "/fr/page/pricing",
    meta: pricingT19BNP0CwEMeta || {},
    alias: pricingT19BNP0CwEMeta?.alias || [],
    redirect: pricingT19BNP0CwEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingT19BNP0CwEMeta?.name ?? "page-pricing___fr-ca",
    path: pricingT19BNP0CwEMeta?.path ?? "/fr-ca/page/pricing",
    meta: pricingT19BNP0CwEMeta || {},
    alias: pricingT19BNP0CwEMeta?.alias || [],
    redirect: pricingT19BNP0CwEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tbtWcAvohAMeta?.name ?? "pages-prefix-slug___en-US",
    path: _91slug_93tbtWcAvohAMeta?.path ?? "/pages/:prefix()/:slug()",
    meta: _91slug_93tbtWcAvohAMeta || {},
    alias: _91slug_93tbtWcAvohAMeta?.alias || [],
    redirect: _91slug_93tbtWcAvohAMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tbtWcAvohAMeta?.name ?? "pages-prefix-slug___ca",
    path: _91slug_93tbtWcAvohAMeta?.path ?? "/ca/pages/:prefix()/:slug()",
    meta: _91slug_93tbtWcAvohAMeta || {},
    alias: _91slug_93tbtWcAvohAMeta?.alias || [],
    redirect: _91slug_93tbtWcAvohAMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tbtWcAvohAMeta?.name ?? "pages-prefix-slug___nl",
    path: _91slug_93tbtWcAvohAMeta?.path ?? "/nl/pages/:prefix()/:slug()",
    meta: _91slug_93tbtWcAvohAMeta || {},
    alias: _91slug_93tbtWcAvohAMeta?.alias || [],
    redirect: _91slug_93tbtWcAvohAMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tbtWcAvohAMeta?.name ?? "pages-prefix-slug___uk",
    path: _91slug_93tbtWcAvohAMeta?.path ?? "/uk/pages/:prefix()/:slug()",
    meta: _91slug_93tbtWcAvohAMeta || {},
    alias: _91slug_93tbtWcAvohAMeta?.alias || [],
    redirect: _91slug_93tbtWcAvohAMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tbtWcAvohAMeta?.name ?? "pages-prefix-slug___de",
    path: _91slug_93tbtWcAvohAMeta?.path ?? "/de/pages/:prefix()/:slug()",
    meta: _91slug_93tbtWcAvohAMeta || {},
    alias: _91slug_93tbtWcAvohAMeta?.alias || [],
    redirect: _91slug_93tbtWcAvohAMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tbtWcAvohAMeta?.name ?? "pages-prefix-slug___mx",
    path: _91slug_93tbtWcAvohAMeta?.path ?? "/mx/pages/:prefix()/:slug()",
    meta: _91slug_93tbtWcAvohAMeta || {},
    alias: _91slug_93tbtWcAvohAMeta?.alias || [],
    redirect: _91slug_93tbtWcAvohAMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tbtWcAvohAMeta?.name ?? "pages-prefix-slug___fr",
    path: _91slug_93tbtWcAvohAMeta?.path ?? "/fr/pages/:prefix()/:slug()",
    meta: _91slug_93tbtWcAvohAMeta || {},
    alias: _91slug_93tbtWcAvohAMeta?.alias || [],
    redirect: _91slug_93tbtWcAvohAMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tbtWcAvohAMeta?.name ?? "pages-prefix-slug___fr-ca",
    path: _91slug_93tbtWcAvohAMeta?.path ?? "/fr-ca/pages/:prefix()/:slug()",
    meta: _91slug_93tbtWcAvohAMeta || {},
    alias: _91slug_93tbtWcAvohAMeta?.alias || [],
    redirect: _91slug_93tbtWcAvohAMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YWqFwnMYgQMeta?.name ?? "pages-slug___en-US",
    path: _91slug_93YWqFwnMYgQMeta?.path ?? "/pages/:slug()",
    meta: _91slug_93YWqFwnMYgQMeta || {},
    alias: _91slug_93YWqFwnMYgQMeta?.alias || [],
    redirect: _91slug_93YWqFwnMYgQMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YWqFwnMYgQMeta?.name ?? "pages-slug___ca",
    path: _91slug_93YWqFwnMYgQMeta?.path ?? "/ca/pages/:slug()",
    meta: _91slug_93YWqFwnMYgQMeta || {},
    alias: _91slug_93YWqFwnMYgQMeta?.alias || [],
    redirect: _91slug_93YWqFwnMYgQMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YWqFwnMYgQMeta?.name ?? "pages-slug___nl",
    path: _91slug_93YWqFwnMYgQMeta?.path ?? "/nl/pages/:slug()",
    meta: _91slug_93YWqFwnMYgQMeta || {},
    alias: _91slug_93YWqFwnMYgQMeta?.alias || [],
    redirect: _91slug_93YWqFwnMYgQMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YWqFwnMYgQMeta?.name ?? "pages-slug___uk",
    path: _91slug_93YWqFwnMYgQMeta?.path ?? "/uk/pages/:slug()",
    meta: _91slug_93YWqFwnMYgQMeta || {},
    alias: _91slug_93YWqFwnMYgQMeta?.alias || [],
    redirect: _91slug_93YWqFwnMYgQMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YWqFwnMYgQMeta?.name ?? "pages-slug___de",
    path: _91slug_93YWqFwnMYgQMeta?.path ?? "/de/pages/:slug()",
    meta: _91slug_93YWqFwnMYgQMeta || {},
    alias: _91slug_93YWqFwnMYgQMeta?.alias || [],
    redirect: _91slug_93YWqFwnMYgQMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YWqFwnMYgQMeta?.name ?? "pages-slug___mx",
    path: _91slug_93YWqFwnMYgQMeta?.path ?? "/mx/pages/:slug()",
    meta: _91slug_93YWqFwnMYgQMeta || {},
    alias: _91slug_93YWqFwnMYgQMeta?.alias || [],
    redirect: _91slug_93YWqFwnMYgQMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YWqFwnMYgQMeta?.name ?? "pages-slug___fr",
    path: _91slug_93YWqFwnMYgQMeta?.path ?? "/fr/pages/:slug()",
    meta: _91slug_93YWqFwnMYgQMeta || {},
    alias: _91slug_93YWqFwnMYgQMeta?.alias || [],
    redirect: _91slug_93YWqFwnMYgQMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YWqFwnMYgQMeta?.name ?? "pages-slug___fr-ca",
    path: _91slug_93YWqFwnMYgQMeta?.path ?? "/fr-ca/pages/:slug()",
    meta: _91slug_93YWqFwnMYgQMeta || {},
    alias: _91slug_93YWqFwnMYgQMeta?.alias || [],
    redirect: _91slug_93YWqFwnMYgQMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: asset_45tracking3izzWlAZRKMeta?.name ?? "pages-asset-tracking___en-US",
    path: asset_45tracking3izzWlAZRKMeta?.path ?? "/pages/asset-tracking",
    meta: asset_45tracking3izzWlAZRKMeta || {},
    alias: asset_45tracking3izzWlAZRKMeta?.alias || [],
    redirect: asset_45tracking3izzWlAZRKMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45tracking3izzWlAZRKMeta?.name ?? "pages-asset-tracking___ca",
    path: asset_45tracking3izzWlAZRKMeta?.path ?? "/ca/pages/asset-tracking",
    meta: asset_45tracking3izzWlAZRKMeta || {},
    alias: asset_45tracking3izzWlAZRKMeta?.alias || [],
    redirect: asset_45tracking3izzWlAZRKMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45tracking3izzWlAZRKMeta?.name ?? "pages-asset-tracking___nl",
    path: asset_45tracking3izzWlAZRKMeta?.path ?? "/nl/pages/asset-tracking",
    meta: asset_45tracking3izzWlAZRKMeta || {},
    alias: asset_45tracking3izzWlAZRKMeta?.alias || [],
    redirect: asset_45tracking3izzWlAZRKMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45tracking3izzWlAZRKMeta?.name ?? "pages-asset-tracking___uk",
    path: asset_45tracking3izzWlAZRKMeta?.path ?? "/uk/pages/asset-tracking",
    meta: asset_45tracking3izzWlAZRKMeta || {},
    alias: asset_45tracking3izzWlAZRKMeta?.alias || [],
    redirect: asset_45tracking3izzWlAZRKMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45tracking3izzWlAZRKMeta?.name ?? "pages-asset-tracking___de",
    path: asset_45tracking3izzWlAZRKMeta?.path ?? "/de/pages/asset-tracking",
    meta: asset_45tracking3izzWlAZRKMeta || {},
    alias: asset_45tracking3izzWlAZRKMeta?.alias || [],
    redirect: asset_45tracking3izzWlAZRKMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45tracking3izzWlAZRKMeta?.name ?? "pages-asset-tracking___mx",
    path: asset_45tracking3izzWlAZRKMeta?.path ?? "/mx/pages/asset-tracking",
    meta: asset_45tracking3izzWlAZRKMeta || {},
    alias: asset_45tracking3izzWlAZRKMeta?.alias || [],
    redirect: asset_45tracking3izzWlAZRKMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45tracking3izzWlAZRKMeta?.name ?? "pages-asset-tracking___fr",
    path: asset_45tracking3izzWlAZRKMeta?.path ?? "/fr/pages/asset-tracking",
    meta: asset_45tracking3izzWlAZRKMeta || {},
    alias: asset_45tracking3izzWlAZRKMeta?.alias || [],
    redirect: asset_45tracking3izzWlAZRKMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45tracking3izzWlAZRKMeta?.name ?? "pages-asset-tracking___fr-ca",
    path: asset_45tracking3izzWlAZRKMeta?.path ?? "/fr-ca/pages/asset-tracking",
    meta: asset_45tracking3izzWlAZRKMeta || {},
    alias: asset_45tracking3izzWlAZRKMeta?.alias || [],
    redirect: asset_45tracking3izzWlAZRKMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsjGOPuMS49wMeta?.name ?? "pages-best-dash-cams___en-US",
    path: best_45dash_45camsjGOPuMS49wMeta?.path ?? "/pages/best-dash-cams",
    meta: best_45dash_45camsjGOPuMS49wMeta || {},
    alias: best_45dash_45camsjGOPuMS49wMeta?.alias || [],
    redirect: best_45dash_45camsjGOPuMS49wMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsjGOPuMS49wMeta?.name ?? "pages-best-dash-cams___ca",
    path: best_45dash_45camsjGOPuMS49wMeta?.path ?? "/ca/pages/best-dash-cams",
    meta: best_45dash_45camsjGOPuMS49wMeta || {},
    alias: best_45dash_45camsjGOPuMS49wMeta?.alias || [],
    redirect: best_45dash_45camsjGOPuMS49wMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsjGOPuMS49wMeta?.name ?? "pages-best-dash-cams___nl",
    path: best_45dash_45camsjGOPuMS49wMeta?.path ?? "/nl/pages/best-dash-cams",
    meta: best_45dash_45camsjGOPuMS49wMeta || {},
    alias: best_45dash_45camsjGOPuMS49wMeta?.alias || [],
    redirect: best_45dash_45camsjGOPuMS49wMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsjGOPuMS49wMeta?.name ?? "pages-best-dash-cams___uk",
    path: best_45dash_45camsjGOPuMS49wMeta?.path ?? "/uk/pages/best-dash-cams",
    meta: best_45dash_45camsjGOPuMS49wMeta || {},
    alias: best_45dash_45camsjGOPuMS49wMeta?.alias || [],
    redirect: best_45dash_45camsjGOPuMS49wMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsjGOPuMS49wMeta?.name ?? "pages-best-dash-cams___de",
    path: best_45dash_45camsjGOPuMS49wMeta?.path ?? "/de/pages/best-dash-cams",
    meta: best_45dash_45camsjGOPuMS49wMeta || {},
    alias: best_45dash_45camsjGOPuMS49wMeta?.alias || [],
    redirect: best_45dash_45camsjGOPuMS49wMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsjGOPuMS49wMeta?.name ?? "pages-best-dash-cams___mx",
    path: best_45dash_45camsjGOPuMS49wMeta?.path ?? "/mx/pages/best-dash-cams",
    meta: best_45dash_45camsjGOPuMS49wMeta || {},
    alias: best_45dash_45camsjGOPuMS49wMeta?.alias || [],
    redirect: best_45dash_45camsjGOPuMS49wMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsjGOPuMS49wMeta?.name ?? "pages-best-dash-cams___fr",
    path: best_45dash_45camsjGOPuMS49wMeta?.path ?? "/fr/pages/best-dash-cams",
    meta: best_45dash_45camsjGOPuMS49wMeta || {},
    alias: best_45dash_45camsjGOPuMS49wMeta?.alias || [],
    redirect: best_45dash_45camsjGOPuMS49wMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsjGOPuMS49wMeta?.name ?? "pages-best-dash-cams___fr-ca",
    path: best_45dash_45camsjGOPuMS49wMeta?.path ?? "/fr-ca/pages/best-dash-cams",
    meta: best_45dash_45camsjGOPuMS49wMeta || {},
    alias: best_45dash_45camsjGOPuMS49wMeta?.alias || [],
    redirect: best_45dash_45camsjGOPuMS49wMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: constructiongcPXfYUPaMMeta?.name ?? "pages-construction___en-US",
    path: constructiongcPXfYUPaMMeta?.path ?? "/pages/construction",
    meta: constructiongcPXfYUPaMMeta || {},
    alias: constructiongcPXfYUPaMMeta?.alias || [],
    redirect: constructiongcPXfYUPaMMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructiongcPXfYUPaMMeta?.name ?? "pages-construction___ca",
    path: constructiongcPXfYUPaMMeta?.path ?? "/ca/pages/construction",
    meta: constructiongcPXfYUPaMMeta || {},
    alias: constructiongcPXfYUPaMMeta?.alias || [],
    redirect: constructiongcPXfYUPaMMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructiongcPXfYUPaMMeta?.name ?? "pages-construction___nl",
    path: constructiongcPXfYUPaMMeta?.path ?? "/nl/pages/construction",
    meta: constructiongcPXfYUPaMMeta || {},
    alias: constructiongcPXfYUPaMMeta?.alias || [],
    redirect: constructiongcPXfYUPaMMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructiongcPXfYUPaMMeta?.name ?? "pages-construction___uk",
    path: constructiongcPXfYUPaMMeta?.path ?? "/uk/pages/construction",
    meta: constructiongcPXfYUPaMMeta || {},
    alias: constructiongcPXfYUPaMMeta?.alias || [],
    redirect: constructiongcPXfYUPaMMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructiongcPXfYUPaMMeta?.name ?? "pages-construction___de",
    path: constructiongcPXfYUPaMMeta?.path ?? "/de/pages/construction",
    meta: constructiongcPXfYUPaMMeta || {},
    alias: constructiongcPXfYUPaMMeta?.alias || [],
    redirect: constructiongcPXfYUPaMMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructiongcPXfYUPaMMeta?.name ?? "pages-construction___mx",
    path: constructiongcPXfYUPaMMeta?.path ?? "/mx/pages/construction",
    meta: constructiongcPXfYUPaMMeta || {},
    alias: constructiongcPXfYUPaMMeta?.alias || [],
    redirect: constructiongcPXfYUPaMMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructiongcPXfYUPaMMeta?.name ?? "pages-construction___fr",
    path: constructiongcPXfYUPaMMeta?.path ?? "/fr/pages/construction",
    meta: constructiongcPXfYUPaMMeta || {},
    alias: constructiongcPXfYUPaMMeta?.alias || [],
    redirect: constructiongcPXfYUPaMMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructiongcPXfYUPaMMeta?.name ?? "pages-construction___fr-ca",
    path: constructiongcPXfYUPaMMeta?.path ?? "/fr-ca/pages/construction",
    meta: constructiongcPXfYUPaMMeta || {},
    alias: constructiongcPXfYUPaMMeta?.alias || [],
    redirect: constructiongcPXfYUPaMMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementCnbN9RKe6CMeta?.name ?? "pages-fleet-management___en-US",
    path: fleet_45managementCnbN9RKe6CMeta?.path ?? "/pages/fleet-management",
    meta: fleet_45managementCnbN9RKe6CMeta || {},
    alias: fleet_45managementCnbN9RKe6CMeta?.alias || [],
    redirect: fleet_45managementCnbN9RKe6CMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementCnbN9RKe6CMeta?.name ?? "pages-fleet-management___ca",
    path: fleet_45managementCnbN9RKe6CMeta?.path ?? "/ca/pages/fleet-management",
    meta: fleet_45managementCnbN9RKe6CMeta || {},
    alias: fleet_45managementCnbN9RKe6CMeta?.alias || [],
    redirect: fleet_45managementCnbN9RKe6CMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementCnbN9RKe6CMeta?.name ?? "pages-fleet-management___nl",
    path: fleet_45managementCnbN9RKe6CMeta?.path ?? "/nl/pages/fleet-management",
    meta: fleet_45managementCnbN9RKe6CMeta || {},
    alias: fleet_45managementCnbN9RKe6CMeta?.alias || [],
    redirect: fleet_45managementCnbN9RKe6CMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementCnbN9RKe6CMeta?.name ?? "pages-fleet-management___uk",
    path: fleet_45managementCnbN9RKe6CMeta?.path ?? "/uk/pages/fleet-management",
    meta: fleet_45managementCnbN9RKe6CMeta || {},
    alias: fleet_45managementCnbN9RKe6CMeta?.alias || [],
    redirect: fleet_45managementCnbN9RKe6CMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementCnbN9RKe6CMeta?.name ?? "pages-fleet-management___de",
    path: fleet_45managementCnbN9RKe6CMeta?.path ?? "/de/pages/fleet-management",
    meta: fleet_45managementCnbN9RKe6CMeta || {},
    alias: fleet_45managementCnbN9RKe6CMeta?.alias || [],
    redirect: fleet_45managementCnbN9RKe6CMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementCnbN9RKe6CMeta?.name ?? "pages-fleet-management___mx",
    path: fleet_45managementCnbN9RKe6CMeta?.path ?? "/mx/pages/fleet-management",
    meta: fleet_45managementCnbN9RKe6CMeta || {},
    alias: fleet_45managementCnbN9RKe6CMeta?.alias || [],
    redirect: fleet_45managementCnbN9RKe6CMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementCnbN9RKe6CMeta?.name ?? "pages-fleet-management___fr",
    path: fleet_45managementCnbN9RKe6CMeta?.path ?? "/fr/pages/fleet-management",
    meta: fleet_45managementCnbN9RKe6CMeta || {},
    alias: fleet_45managementCnbN9RKe6CMeta?.alias || [],
    redirect: fleet_45managementCnbN9RKe6CMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementCnbN9RKe6CMeta?.name ?? "pages-fleet-management___fr-ca",
    path: fleet_45managementCnbN9RKe6CMeta?.path ?? "/fr-ca/pages/fleet-management",
    meta: fleet_45managementCnbN9RKe6CMeta || {},
    alias: fleet_45managementCnbN9RKe6CMeta?.alias || [],
    redirect: fleet_45managementCnbN9RKe6CMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: nationwideMrtlC15pwYMeta?.name ?? "pages-nationwide___en-US",
    path: nationwideMrtlC15pwYMeta?.path ?? "/pages/nationwide",
    meta: nationwideMrtlC15pwYMeta || {},
    alias: nationwideMrtlC15pwYMeta?.alias || [],
    redirect: nationwideMrtlC15pwYMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideMrtlC15pwYMeta?.name ?? "pages-nationwide___ca",
    path: nationwideMrtlC15pwYMeta?.path ?? "/ca/pages/nationwide",
    meta: nationwideMrtlC15pwYMeta || {},
    alias: nationwideMrtlC15pwYMeta?.alias || [],
    redirect: nationwideMrtlC15pwYMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideMrtlC15pwYMeta?.name ?? "pages-nationwide___nl",
    path: nationwideMrtlC15pwYMeta?.path ?? "/nl/pages/nationwide",
    meta: nationwideMrtlC15pwYMeta || {},
    alias: nationwideMrtlC15pwYMeta?.alias || [],
    redirect: nationwideMrtlC15pwYMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideMrtlC15pwYMeta?.name ?? "pages-nationwide___uk",
    path: nationwideMrtlC15pwYMeta?.path ?? "/uk/pages/nationwide",
    meta: nationwideMrtlC15pwYMeta || {},
    alias: nationwideMrtlC15pwYMeta?.alias || [],
    redirect: nationwideMrtlC15pwYMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideMrtlC15pwYMeta?.name ?? "pages-nationwide___de",
    path: nationwideMrtlC15pwYMeta?.path ?? "/de/pages/nationwide",
    meta: nationwideMrtlC15pwYMeta || {},
    alias: nationwideMrtlC15pwYMeta?.alias || [],
    redirect: nationwideMrtlC15pwYMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideMrtlC15pwYMeta?.name ?? "pages-nationwide___mx",
    path: nationwideMrtlC15pwYMeta?.path ?? "/mx/pages/nationwide",
    meta: nationwideMrtlC15pwYMeta || {},
    alias: nationwideMrtlC15pwYMeta?.alias || [],
    redirect: nationwideMrtlC15pwYMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideMrtlC15pwYMeta?.name ?? "pages-nationwide___fr",
    path: nationwideMrtlC15pwYMeta?.path ?? "/fr/pages/nationwide",
    meta: nationwideMrtlC15pwYMeta || {},
    alias: nationwideMrtlC15pwYMeta?.alias || [],
    redirect: nationwideMrtlC15pwYMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideMrtlC15pwYMeta?.name ?? "pages-nationwide___fr-ca",
    path: nationwideMrtlC15pwYMeta?.path ?? "/fr-ca/pages/nationwide",
    meta: nationwideMrtlC15pwYMeta || {},
    alias: nationwideMrtlC15pwYMeta?.alias || [],
    redirect: nationwideMrtlC15pwYMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: safety_45coaching2T6h5Iurp6Meta?.name ?? "pages-safety-coaching___en-US",
    path: safety_45coaching2T6h5Iurp6Meta?.path ?? "/pages/safety-coaching",
    meta: safety_45coaching2T6h5Iurp6Meta || {},
    alias: safety_45coaching2T6h5Iurp6Meta?.alias || [],
    redirect: safety_45coaching2T6h5Iurp6Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coaching2T6h5Iurp6Meta?.name ?? "pages-safety-coaching___ca",
    path: safety_45coaching2T6h5Iurp6Meta?.path ?? "/ca/pages/safety-coaching",
    meta: safety_45coaching2T6h5Iurp6Meta || {},
    alias: safety_45coaching2T6h5Iurp6Meta?.alias || [],
    redirect: safety_45coaching2T6h5Iurp6Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coaching2T6h5Iurp6Meta?.name ?? "pages-safety-coaching___nl",
    path: safety_45coaching2T6h5Iurp6Meta?.path ?? "/nl/pages/safety-coaching",
    meta: safety_45coaching2T6h5Iurp6Meta || {},
    alias: safety_45coaching2T6h5Iurp6Meta?.alias || [],
    redirect: safety_45coaching2T6h5Iurp6Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coaching2T6h5Iurp6Meta?.name ?? "pages-safety-coaching___uk",
    path: safety_45coaching2T6h5Iurp6Meta?.path ?? "/uk/pages/safety-coaching",
    meta: safety_45coaching2T6h5Iurp6Meta || {},
    alias: safety_45coaching2T6h5Iurp6Meta?.alias || [],
    redirect: safety_45coaching2T6h5Iurp6Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coaching2T6h5Iurp6Meta?.name ?? "pages-safety-coaching___de",
    path: safety_45coaching2T6h5Iurp6Meta?.path ?? "/de/pages/safety-coaching",
    meta: safety_45coaching2T6h5Iurp6Meta || {},
    alias: safety_45coaching2T6h5Iurp6Meta?.alias || [],
    redirect: safety_45coaching2T6h5Iurp6Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coaching2T6h5Iurp6Meta?.name ?? "pages-safety-coaching___mx",
    path: safety_45coaching2T6h5Iurp6Meta?.path ?? "/mx/pages/safety-coaching",
    meta: safety_45coaching2T6h5Iurp6Meta || {},
    alias: safety_45coaching2T6h5Iurp6Meta?.alias || [],
    redirect: safety_45coaching2T6h5Iurp6Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coaching2T6h5Iurp6Meta?.name ?? "pages-safety-coaching___fr",
    path: safety_45coaching2T6h5Iurp6Meta?.path ?? "/fr/pages/safety-coaching",
    meta: safety_45coaching2T6h5Iurp6Meta || {},
    alias: safety_45coaching2T6h5Iurp6Meta?.alias || [],
    redirect: safety_45coaching2T6h5Iurp6Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coaching2T6h5Iurp6Meta?.name ?? "pages-safety-coaching___fr-ca",
    path: safety_45coaching2T6h5Iurp6Meta?.path ?? "/fr-ca/pages/safety-coaching",
    meta: safety_45coaching2T6h5Iurp6Meta || {},
    alias: safety_45coaching2T6h5Iurp6Meta?.alias || [],
    redirect: safety_45coaching2T6h5Iurp6Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: unifirstysHLWjUV9UMeta?.name ?? "pages-unifirst___en-US",
    path: unifirstysHLWjUV9UMeta?.path ?? "/pages/unifirst",
    meta: unifirstysHLWjUV9UMeta || {},
    alias: unifirstysHLWjUV9UMeta?.alias || [],
    redirect: unifirstysHLWjUV9UMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstysHLWjUV9UMeta?.name ?? "pages-unifirst___ca",
    path: unifirstysHLWjUV9UMeta?.path ?? "/ca/pages/unifirst",
    meta: unifirstysHLWjUV9UMeta || {},
    alias: unifirstysHLWjUV9UMeta?.alias || [],
    redirect: unifirstysHLWjUV9UMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstysHLWjUV9UMeta?.name ?? "pages-unifirst___nl",
    path: unifirstysHLWjUV9UMeta?.path ?? "/nl/pages/unifirst",
    meta: unifirstysHLWjUV9UMeta || {},
    alias: unifirstysHLWjUV9UMeta?.alias || [],
    redirect: unifirstysHLWjUV9UMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstysHLWjUV9UMeta?.name ?? "pages-unifirst___uk",
    path: unifirstysHLWjUV9UMeta?.path ?? "/uk/pages/unifirst",
    meta: unifirstysHLWjUV9UMeta || {},
    alias: unifirstysHLWjUV9UMeta?.alias || [],
    redirect: unifirstysHLWjUV9UMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstysHLWjUV9UMeta?.name ?? "pages-unifirst___de",
    path: unifirstysHLWjUV9UMeta?.path ?? "/de/pages/unifirst",
    meta: unifirstysHLWjUV9UMeta || {},
    alias: unifirstysHLWjUV9UMeta?.alias || [],
    redirect: unifirstysHLWjUV9UMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstysHLWjUV9UMeta?.name ?? "pages-unifirst___mx",
    path: unifirstysHLWjUV9UMeta?.path ?? "/mx/pages/unifirst",
    meta: unifirstysHLWjUV9UMeta || {},
    alias: unifirstysHLWjUV9UMeta?.alias || [],
    redirect: unifirstysHLWjUV9UMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstysHLWjUV9UMeta?.name ?? "pages-unifirst___fr",
    path: unifirstysHLWjUV9UMeta?.path ?? "/fr/pages/unifirst",
    meta: unifirstysHLWjUV9UMeta || {},
    alias: unifirstysHLWjUV9UMeta?.alias || [],
    redirect: unifirstysHLWjUV9UMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstysHLWjUV9UMeta?.name ?? "pages-unifirst___fr-ca",
    path: unifirstysHLWjUV9UMeta?.path ?? "/fr-ca/pages/unifirst",
    meta: unifirstysHLWjUV9UMeta || {},
    alias: unifirstysHLWjUV9UMeta?.alias || [],
    redirect: unifirstysHLWjUV9UMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: pricingcD0QoPpd7TMeta?.name ?? "pricing___en-US",
    path: pricingcD0QoPpd7TMeta?.path ?? "/pricing",
    meta: pricingcD0QoPpd7TMeta || {},
    alias: pricingcD0QoPpd7TMeta?.alias || [],
    redirect: pricingcD0QoPpd7TMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingcD0QoPpd7TMeta?.name ?? "pricing___ca",
    path: pricingcD0QoPpd7TMeta?.path ?? "/ca/pricing",
    meta: pricingcD0QoPpd7TMeta || {},
    alias: pricingcD0QoPpd7TMeta?.alias || [],
    redirect: pricingcD0QoPpd7TMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingcD0QoPpd7TMeta?.name ?? "pricing___nl",
    path: pricingcD0QoPpd7TMeta?.path ?? "/nl/pricing",
    meta: pricingcD0QoPpd7TMeta || {},
    alias: pricingcD0QoPpd7TMeta?.alias || [],
    redirect: pricingcD0QoPpd7TMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingcD0QoPpd7TMeta?.name ?? "pricing___uk",
    path: pricingcD0QoPpd7TMeta?.path ?? "/uk/pricing",
    meta: pricingcD0QoPpd7TMeta || {},
    alias: pricingcD0QoPpd7TMeta?.alias || [],
    redirect: pricingcD0QoPpd7TMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingcD0QoPpd7TMeta?.name ?? "pricing___de",
    path: pricingcD0QoPpd7TMeta?.path ?? "/de/pricing",
    meta: pricingcD0QoPpd7TMeta || {},
    alias: pricingcD0QoPpd7TMeta?.alias || [],
    redirect: pricingcD0QoPpd7TMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingcD0QoPpd7TMeta?.name ?? "pricing___mx",
    path: pricingcD0QoPpd7TMeta?.path ?? "/mx/prueba-gratis",
    meta: pricingcD0QoPpd7TMeta || {},
    alias: pricingcD0QoPpd7TMeta?.alias || [],
    redirect: pricingcD0QoPpd7TMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingcD0QoPpd7TMeta?.name ?? "pricing___fr",
    path: pricingcD0QoPpd7TMeta?.path ?? "/fr/pricing",
    meta: pricingcD0QoPpd7TMeta || {},
    alias: pricingcD0QoPpd7TMeta?.alias || [],
    redirect: pricingcD0QoPpd7TMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingcD0QoPpd7TMeta?.name ?? "pricing___fr-ca",
    path: pricingcD0QoPpd7TMeta?.path ?? "/fr-ca/pricing",
    meta: pricingcD0QoPpd7TMeta || {},
    alias: pricingcD0QoPpd7TMeta?.alias || [],
    redirect: pricingcD0QoPpd7TMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Etpl9pIBgHMeta?.name ?? "products-prefix-slug___en-US",
    path: _91slug_93Etpl9pIBgHMeta?.path ?? "/products/:prefix()/:slug()",
    meta: _91slug_93Etpl9pIBgHMeta || {},
    alias: _91slug_93Etpl9pIBgHMeta?.alias || [],
    redirect: _91slug_93Etpl9pIBgHMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Etpl9pIBgHMeta?.name ?? "products-prefix-slug___ca",
    path: _91slug_93Etpl9pIBgHMeta?.path ?? "/ca/products/:prefix()/:slug()",
    meta: _91slug_93Etpl9pIBgHMeta || {},
    alias: _91slug_93Etpl9pIBgHMeta?.alias || [],
    redirect: _91slug_93Etpl9pIBgHMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Etpl9pIBgHMeta?.name ?? "products-prefix-slug___nl",
    path: _91slug_93Etpl9pIBgHMeta?.path ?? "/nl/products/:prefix()/:slug()",
    meta: _91slug_93Etpl9pIBgHMeta || {},
    alias: _91slug_93Etpl9pIBgHMeta?.alias || [],
    redirect: _91slug_93Etpl9pIBgHMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Etpl9pIBgHMeta?.name ?? "products-prefix-slug___uk",
    path: _91slug_93Etpl9pIBgHMeta?.path ?? "/uk/products/:prefix()/:slug()",
    meta: _91slug_93Etpl9pIBgHMeta || {},
    alias: _91slug_93Etpl9pIBgHMeta?.alias || [],
    redirect: _91slug_93Etpl9pIBgHMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Etpl9pIBgHMeta?.name ?? "products-prefix-slug___de",
    path: _91slug_93Etpl9pIBgHMeta?.path ?? "/de/products/:prefix()/:slug()",
    meta: _91slug_93Etpl9pIBgHMeta || {},
    alias: _91slug_93Etpl9pIBgHMeta?.alias || [],
    redirect: _91slug_93Etpl9pIBgHMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Etpl9pIBgHMeta?.name ?? "products-prefix-slug___mx",
    path: _91slug_93Etpl9pIBgHMeta?.path ?? "/mx/products/:prefix()/:slug()",
    meta: _91slug_93Etpl9pIBgHMeta || {},
    alias: _91slug_93Etpl9pIBgHMeta?.alias || [],
    redirect: _91slug_93Etpl9pIBgHMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Etpl9pIBgHMeta?.name ?? "products-prefix-slug___fr",
    path: _91slug_93Etpl9pIBgHMeta?.path ?? "/fr/products/:prefix()/:slug()",
    meta: _91slug_93Etpl9pIBgHMeta || {},
    alias: _91slug_93Etpl9pIBgHMeta?.alias || [],
    redirect: _91slug_93Etpl9pIBgHMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Etpl9pIBgHMeta?.name ?? "products-prefix-slug___fr-ca",
    path: _91slug_93Etpl9pIBgHMeta?.path ?? "/fr-ca/products/:prefix()/:slug()",
    meta: _91slug_93Etpl9pIBgHMeta || {},
    alias: _91slug_93Etpl9pIBgHMeta?.alias || [],
    redirect: _91slug_93Etpl9pIBgHMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93myYOEZT9YiMeta?.name ?? "products-slug___en-US",
    path: _91slug_93myYOEZT9YiMeta?.path ?? "/products/:slug()",
    meta: _91slug_93myYOEZT9YiMeta || {},
    alias: _91slug_93myYOEZT9YiMeta?.alias || [],
    redirect: _91slug_93myYOEZT9YiMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93myYOEZT9YiMeta?.name ?? "products-slug___ca",
    path: _91slug_93myYOEZT9YiMeta?.path ?? "/ca/products/:slug()",
    meta: _91slug_93myYOEZT9YiMeta || {},
    alias: _91slug_93myYOEZT9YiMeta?.alias || [],
    redirect: _91slug_93myYOEZT9YiMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93myYOEZT9YiMeta?.name ?? "products-slug___nl",
    path: _91slug_93myYOEZT9YiMeta?.path ?? "/nl/products/:slug()",
    meta: _91slug_93myYOEZT9YiMeta || {},
    alias: _91slug_93myYOEZT9YiMeta?.alias || [],
    redirect: _91slug_93myYOEZT9YiMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93myYOEZT9YiMeta?.name ?? "products-slug___uk",
    path: _91slug_93myYOEZT9YiMeta?.path ?? "/uk/products/:slug()",
    meta: _91slug_93myYOEZT9YiMeta || {},
    alias: _91slug_93myYOEZT9YiMeta?.alias || [],
    redirect: _91slug_93myYOEZT9YiMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93myYOEZT9YiMeta?.name ?? "products-slug___de",
    path: _91slug_93myYOEZT9YiMeta?.path ?? "/de/products/:slug()",
    meta: _91slug_93myYOEZT9YiMeta || {},
    alias: _91slug_93myYOEZT9YiMeta?.alias || [],
    redirect: _91slug_93myYOEZT9YiMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93myYOEZT9YiMeta?.name ?? "products-slug___mx",
    path: _91slug_93myYOEZT9YiMeta?.path ?? "/mx/products/:slug()",
    meta: _91slug_93myYOEZT9YiMeta || {},
    alias: _91slug_93myYOEZT9YiMeta?.alias || [],
    redirect: _91slug_93myYOEZT9YiMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93myYOEZT9YiMeta?.name ?? "products-slug___fr",
    path: _91slug_93myYOEZT9YiMeta?.path ?? "/fr/products/:slug()",
    meta: _91slug_93myYOEZT9YiMeta || {},
    alias: _91slug_93myYOEZT9YiMeta?.alias || [],
    redirect: _91slug_93myYOEZT9YiMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93myYOEZT9YiMeta?.name ?? "products-slug___fr-ca",
    path: _91slug_93myYOEZT9YiMeta?.path ?? "/fr-ca/products/:slug()",
    meta: _91slug_93myYOEZT9YiMeta || {},
    alias: _91slug_93myYOEZT9YiMeta?.alias || [],
    redirect: _91slug_93myYOEZT9YiMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingAPHX9J5TS3Meta?.name ?? "products-equipment-monitoring-asset-tracking___en-US",
    path: asset_45trackingAPHX9J5TS3Meta?.path ?? "/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingAPHX9J5TS3Meta || {},
    alias: asset_45trackingAPHX9J5TS3Meta?.alias || [],
    redirect: asset_45trackingAPHX9J5TS3Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingAPHX9J5TS3Meta?.name ?? "products-equipment-monitoring-asset-tracking___ca",
    path: asset_45trackingAPHX9J5TS3Meta?.path ?? "/ca/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingAPHX9J5TS3Meta || {},
    alias: asset_45trackingAPHX9J5TS3Meta?.alias || [],
    redirect: asset_45trackingAPHX9J5TS3Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingAPHX9J5TS3Meta?.name ?? "products-equipment-monitoring-asset-tracking___nl",
    path: asset_45trackingAPHX9J5TS3Meta?.path ?? "/nl/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingAPHX9J5TS3Meta || {},
    alias: asset_45trackingAPHX9J5TS3Meta?.alias || [],
    redirect: asset_45trackingAPHX9J5TS3Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingAPHX9J5TS3Meta?.name ?? "products-equipment-monitoring-asset-tracking___uk",
    path: asset_45trackingAPHX9J5TS3Meta?.path ?? "/uk/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingAPHX9J5TS3Meta || {},
    alias: asset_45trackingAPHX9J5TS3Meta?.alias || [],
    redirect: asset_45trackingAPHX9J5TS3Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingAPHX9J5TS3Meta?.name ?? "products-equipment-monitoring-asset-tracking___de",
    path: asset_45trackingAPHX9J5TS3Meta?.path ?? "/de/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingAPHX9J5TS3Meta || {},
    alias: asset_45trackingAPHX9J5TS3Meta?.alias || [],
    redirect: asset_45trackingAPHX9J5TS3Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingAPHX9J5TS3Meta?.name ?? "products-equipment-monitoring-asset-tracking___mx",
    path: asset_45trackingAPHX9J5TS3Meta?.path ?? "/mx/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingAPHX9J5TS3Meta || {},
    alias: asset_45trackingAPHX9J5TS3Meta?.alias || [],
    redirect: asset_45trackingAPHX9J5TS3Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingAPHX9J5TS3Meta?.name ?? "products-equipment-monitoring-asset-tracking___fr",
    path: asset_45trackingAPHX9J5TS3Meta?.path ?? "/fr/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingAPHX9J5TS3Meta || {},
    alias: asset_45trackingAPHX9J5TS3Meta?.alias || [],
    redirect: asset_45trackingAPHX9J5TS3Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingAPHX9J5TS3Meta?.name ?? "products-equipment-monitoring-asset-tracking___fr-ca",
    path: asset_45trackingAPHX9J5TS3Meta?.path ?? "/fr-ca/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingAPHX9J5TS3Meta || {},
    alias: asset_45trackingAPHX9J5TS3Meta?.alias || [],
    redirect: asset_45trackingAPHX9J5TS3Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingxx2tspZwoYMeta?.name ?? "products-equipment-monitoring-equipment-tracking___en-US",
    path: equipment_45trackingxx2tspZwoYMeta?.path ?? "/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingxx2tspZwoYMeta || {},
    alias: equipment_45trackingxx2tspZwoYMeta?.alias || [],
    redirect: equipment_45trackingxx2tspZwoYMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingxx2tspZwoYMeta?.name ?? "products-equipment-monitoring-equipment-tracking___ca",
    path: equipment_45trackingxx2tspZwoYMeta?.path ?? "/ca/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingxx2tspZwoYMeta || {},
    alias: equipment_45trackingxx2tspZwoYMeta?.alias || [],
    redirect: equipment_45trackingxx2tspZwoYMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingxx2tspZwoYMeta?.name ?? "products-equipment-monitoring-equipment-tracking___nl",
    path: equipment_45trackingxx2tspZwoYMeta?.path ?? "/nl/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingxx2tspZwoYMeta || {},
    alias: equipment_45trackingxx2tspZwoYMeta?.alias || [],
    redirect: equipment_45trackingxx2tspZwoYMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingxx2tspZwoYMeta?.name ?? "products-equipment-monitoring-equipment-tracking___uk",
    path: equipment_45trackingxx2tspZwoYMeta?.path ?? "/uk/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingxx2tspZwoYMeta || {},
    alias: equipment_45trackingxx2tspZwoYMeta?.alias || [],
    redirect: equipment_45trackingxx2tspZwoYMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingxx2tspZwoYMeta?.name ?? "products-equipment-monitoring-equipment-tracking___de",
    path: equipment_45trackingxx2tspZwoYMeta?.path ?? "/de/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingxx2tspZwoYMeta || {},
    alias: equipment_45trackingxx2tspZwoYMeta?.alias || [],
    redirect: equipment_45trackingxx2tspZwoYMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingxx2tspZwoYMeta?.name ?? "products-equipment-monitoring-equipment-tracking___mx",
    path: equipment_45trackingxx2tspZwoYMeta?.path ?? "/mx/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingxx2tspZwoYMeta || {},
    alias: equipment_45trackingxx2tspZwoYMeta?.alias || [],
    redirect: equipment_45trackingxx2tspZwoYMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingxx2tspZwoYMeta?.name ?? "products-equipment-monitoring-equipment-tracking___fr",
    path: equipment_45trackingxx2tspZwoYMeta?.path ?? "/fr/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingxx2tspZwoYMeta || {},
    alias: equipment_45trackingxx2tspZwoYMeta?.alias || [],
    redirect: equipment_45trackingxx2tspZwoYMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingxx2tspZwoYMeta?.name ?? "products-equipment-monitoring-equipment-tracking___fr-ca",
    path: equipment_45trackingxx2tspZwoYMeta?.path ?? "/fr-ca/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingxx2tspZwoYMeta || {},
    alias: equipment_45trackingxx2tspZwoYMeta?.alias || [],
    redirect: equipment_45trackingxx2tspZwoYMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoring9tMBkgMNvKMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___en-US",
    path: reefer_45monitoring9tMBkgMNvKMeta?.path ?? "/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoring9tMBkgMNvKMeta || {},
    alias: reefer_45monitoring9tMBkgMNvKMeta?.alias || [],
    redirect: reefer_45monitoring9tMBkgMNvKMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoring9tMBkgMNvKMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___ca",
    path: reefer_45monitoring9tMBkgMNvKMeta?.path ?? "/ca/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoring9tMBkgMNvKMeta || {},
    alias: reefer_45monitoring9tMBkgMNvKMeta?.alias || [],
    redirect: reefer_45monitoring9tMBkgMNvKMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoring9tMBkgMNvKMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___nl",
    path: reefer_45monitoring9tMBkgMNvKMeta?.path ?? "/nl/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoring9tMBkgMNvKMeta || {},
    alias: reefer_45monitoring9tMBkgMNvKMeta?.alias || [],
    redirect: reefer_45monitoring9tMBkgMNvKMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoring9tMBkgMNvKMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___uk",
    path: reefer_45monitoring9tMBkgMNvKMeta?.path ?? "/uk/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoring9tMBkgMNvKMeta || {},
    alias: reefer_45monitoring9tMBkgMNvKMeta?.alias || [],
    redirect: reefer_45monitoring9tMBkgMNvKMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoring9tMBkgMNvKMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___de",
    path: reefer_45monitoring9tMBkgMNvKMeta?.path ?? "/de/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoring9tMBkgMNvKMeta || {},
    alias: reefer_45monitoring9tMBkgMNvKMeta?.alias || [],
    redirect: reefer_45monitoring9tMBkgMNvKMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoring9tMBkgMNvKMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___mx",
    path: reefer_45monitoring9tMBkgMNvKMeta?.path ?? "/mx/productos/rastreo-equipos/refrigerados",
    meta: reefer_45monitoring9tMBkgMNvKMeta || {},
    alias: reefer_45monitoring9tMBkgMNvKMeta?.alias || [],
    redirect: reefer_45monitoring9tMBkgMNvKMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoring9tMBkgMNvKMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___fr",
    path: reefer_45monitoring9tMBkgMNvKMeta?.path ?? "/fr/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoring9tMBkgMNvKMeta || {},
    alias: reefer_45monitoring9tMBkgMNvKMeta?.alias || [],
    redirect: reefer_45monitoring9tMBkgMNvKMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoring9tMBkgMNvKMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___fr-ca",
    path: reefer_45monitoring9tMBkgMNvKMeta?.path ?? "/fr-ca/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoring9tMBkgMNvKMeta || {},
    alias: reefer_45monitoring9tMBkgMNvKMeta?.alias || [],
    redirect: reefer_45monitoring9tMBkgMNvKMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailerstFvzlMgqkkMeta?.name ?? "products-equipment-monitoring-smart-trailers___en-US",
    path: smart_45trailerstFvzlMgqkkMeta?.path ?? "/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailerstFvzlMgqkkMeta || {},
    alias: smart_45trailerstFvzlMgqkkMeta?.alias || [],
    redirect: smart_45trailerstFvzlMgqkkMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailerstFvzlMgqkkMeta?.name ?? "products-equipment-monitoring-smart-trailers___ca",
    path: smart_45trailerstFvzlMgqkkMeta?.path ?? "/ca/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailerstFvzlMgqkkMeta || {},
    alias: smart_45trailerstFvzlMgqkkMeta?.alias || [],
    redirect: smart_45trailerstFvzlMgqkkMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailerstFvzlMgqkkMeta?.name ?? "products-equipment-monitoring-smart-trailers___nl",
    path: smart_45trailerstFvzlMgqkkMeta?.path ?? "/nl/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailerstFvzlMgqkkMeta || {},
    alias: smart_45trailerstFvzlMgqkkMeta?.alias || [],
    redirect: smart_45trailerstFvzlMgqkkMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailerstFvzlMgqkkMeta?.name ?? "products-equipment-monitoring-smart-trailers___uk",
    path: smart_45trailerstFvzlMgqkkMeta?.path ?? "/uk/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailerstFvzlMgqkkMeta || {},
    alias: smart_45trailerstFvzlMgqkkMeta?.alias || [],
    redirect: smart_45trailerstFvzlMgqkkMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailerstFvzlMgqkkMeta?.name ?? "products-equipment-monitoring-smart-trailers___de",
    path: smart_45trailerstFvzlMgqkkMeta?.path ?? "/de/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailerstFvzlMgqkkMeta || {},
    alias: smart_45trailerstFvzlMgqkkMeta?.alias || [],
    redirect: smart_45trailerstFvzlMgqkkMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailerstFvzlMgqkkMeta?.name ?? "products-equipment-monitoring-smart-trailers___mx",
    path: smart_45trailerstFvzlMgqkkMeta?.path ?? "/mx/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailerstFvzlMgqkkMeta || {},
    alias: smart_45trailerstFvzlMgqkkMeta?.alias || [],
    redirect: smart_45trailerstFvzlMgqkkMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailerstFvzlMgqkkMeta?.name ?? "products-equipment-monitoring-smart-trailers___fr",
    path: smart_45trailerstFvzlMgqkkMeta?.path ?? "/fr/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailerstFvzlMgqkkMeta || {},
    alias: smart_45trailerstFvzlMgqkkMeta?.alias || [],
    redirect: smart_45trailerstFvzlMgqkkMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailerstFvzlMgqkkMeta?.name ?? "products-equipment-monitoring-smart-trailers___fr-ca",
    path: smart_45trailerstFvzlMgqkkMeta?.path ?? "/fr-ca/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailerstFvzlMgqkkMeta || {},
    alias: smart_45trailerstFvzlMgqkkMeta?.alias || [],
    redirect: smart_45trailerstFvzlMgqkkMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingrHxkpdepdhMeta?.name ?? "products-equipment-monitoring-trailer-tracking___en-US",
    path: trailer_45trackingrHxkpdepdhMeta?.path ?? "/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingrHxkpdepdhMeta || {},
    alias: trailer_45trackingrHxkpdepdhMeta?.alias || [],
    redirect: trailer_45trackingrHxkpdepdhMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingrHxkpdepdhMeta?.name ?? "products-equipment-monitoring-trailer-tracking___ca",
    path: trailer_45trackingrHxkpdepdhMeta?.path ?? "/ca/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingrHxkpdepdhMeta || {},
    alias: trailer_45trackingrHxkpdepdhMeta?.alias || [],
    redirect: trailer_45trackingrHxkpdepdhMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingrHxkpdepdhMeta?.name ?? "products-equipment-monitoring-trailer-tracking___nl",
    path: trailer_45trackingrHxkpdepdhMeta?.path ?? "/nl/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingrHxkpdepdhMeta || {},
    alias: trailer_45trackingrHxkpdepdhMeta?.alias || [],
    redirect: trailer_45trackingrHxkpdepdhMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingrHxkpdepdhMeta?.name ?? "products-equipment-monitoring-trailer-tracking___uk",
    path: trailer_45trackingrHxkpdepdhMeta?.path ?? "/uk/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingrHxkpdepdhMeta || {},
    alias: trailer_45trackingrHxkpdepdhMeta?.alias || [],
    redirect: trailer_45trackingrHxkpdepdhMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingrHxkpdepdhMeta?.name ?? "products-equipment-monitoring-trailer-tracking___de",
    path: trailer_45trackingrHxkpdepdhMeta?.path ?? "/de/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingrHxkpdepdhMeta || {},
    alias: trailer_45trackingrHxkpdepdhMeta?.alias || [],
    redirect: trailer_45trackingrHxkpdepdhMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingrHxkpdepdhMeta?.name ?? "products-equipment-monitoring-trailer-tracking___mx",
    path: trailer_45trackingrHxkpdepdhMeta?.path ?? "/mx/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingrHxkpdepdhMeta || {},
    alias: trailer_45trackingrHxkpdepdhMeta?.alias || [],
    redirect: trailer_45trackingrHxkpdepdhMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingrHxkpdepdhMeta?.name ?? "products-equipment-monitoring-trailer-tracking___fr",
    path: trailer_45trackingrHxkpdepdhMeta?.path ?? "/fr/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingrHxkpdepdhMeta || {},
    alias: trailer_45trackingrHxkpdepdhMeta?.alias || [],
    redirect: trailer_45trackingrHxkpdepdhMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingrHxkpdepdhMeta?.name ?? "products-equipment-monitoring-trailer-tracking___fr-ca",
    path: trailer_45trackingrHxkpdepdhMeta?.path ?? "/fr-ca/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingrHxkpdepdhMeta || {},
    alias: trailer_45trackingrHxkpdepdhMeta?.alias || [],
    redirect: trailer_45trackingrHxkpdepdhMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: indexNLMWZ67fWhMeta?.name ?? "products___en-US",
    path: indexNLMWZ67fWhMeta?.path ?? "/products",
    meta: indexNLMWZ67fWhMeta || {},
    alias: indexNLMWZ67fWhMeta?.alias || [],
    redirect: indexNLMWZ67fWhMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexNLMWZ67fWhMeta?.name ?? "products___ca",
    path: indexNLMWZ67fWhMeta?.path ?? "/ca/products",
    meta: indexNLMWZ67fWhMeta || {},
    alias: indexNLMWZ67fWhMeta?.alias || [],
    redirect: indexNLMWZ67fWhMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexNLMWZ67fWhMeta?.name ?? "products___nl",
    path: indexNLMWZ67fWhMeta?.path ?? "/nl/products",
    meta: indexNLMWZ67fWhMeta || {},
    alias: indexNLMWZ67fWhMeta?.alias || [],
    redirect: indexNLMWZ67fWhMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexNLMWZ67fWhMeta?.name ?? "products___uk",
    path: indexNLMWZ67fWhMeta?.path ?? "/uk/products",
    meta: indexNLMWZ67fWhMeta || {},
    alias: indexNLMWZ67fWhMeta?.alias || [],
    redirect: indexNLMWZ67fWhMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexNLMWZ67fWhMeta?.name ?? "products___de",
    path: indexNLMWZ67fWhMeta?.path ?? "/de/products",
    meta: indexNLMWZ67fWhMeta || {},
    alias: indexNLMWZ67fWhMeta?.alias || [],
    redirect: indexNLMWZ67fWhMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexNLMWZ67fWhMeta?.name ?? "products___mx",
    path: indexNLMWZ67fWhMeta?.path ?? "/mx/products",
    meta: indexNLMWZ67fWhMeta || {},
    alias: indexNLMWZ67fWhMeta?.alias || [],
    redirect: indexNLMWZ67fWhMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexNLMWZ67fWhMeta?.name ?? "products___fr",
    path: indexNLMWZ67fWhMeta?.path ?? "/fr/products",
    meta: indexNLMWZ67fWhMeta || {},
    alias: indexNLMWZ67fWhMeta?.alias || [],
    redirect: indexNLMWZ67fWhMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexNLMWZ67fWhMeta?.name ?? "products___fr-ca",
    path: indexNLMWZ67fWhMeta?.path ?? "/fr-ca/products",
    meta: indexNLMWZ67fWhMeta || {},
    alias: indexNLMWZ67fWhMeta?.alias || [],
    redirect: indexNLMWZ67fWhMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93C80Tzk4TpwMeta?.name ?? "products-models-slug___en-US",
    path: _91slug_93C80Tzk4TpwMeta?.path ?? "/products/models/:slug()",
    meta: _91slug_93C80Tzk4TpwMeta || {},
    alias: _91slug_93C80Tzk4TpwMeta?.alias || [],
    redirect: _91slug_93C80Tzk4TpwMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93C80Tzk4TpwMeta?.name ?? "products-models-slug___ca",
    path: _91slug_93C80Tzk4TpwMeta?.path ?? "/ca/products/models/:slug()",
    meta: _91slug_93C80Tzk4TpwMeta || {},
    alias: _91slug_93C80Tzk4TpwMeta?.alias || [],
    redirect: _91slug_93C80Tzk4TpwMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93C80Tzk4TpwMeta?.name ?? "products-models-slug___nl",
    path: _91slug_93C80Tzk4TpwMeta?.path ?? "/nl/products/models/:slug()",
    meta: _91slug_93C80Tzk4TpwMeta || {},
    alias: _91slug_93C80Tzk4TpwMeta?.alias || [],
    redirect: _91slug_93C80Tzk4TpwMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93C80Tzk4TpwMeta?.name ?? "products-models-slug___uk",
    path: _91slug_93C80Tzk4TpwMeta?.path ?? "/uk/products/models/:slug()",
    meta: _91slug_93C80Tzk4TpwMeta || {},
    alias: _91slug_93C80Tzk4TpwMeta?.alias || [],
    redirect: _91slug_93C80Tzk4TpwMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93C80Tzk4TpwMeta?.name ?? "products-models-slug___de",
    path: _91slug_93C80Tzk4TpwMeta?.path ?? "/de/products/models/:slug()",
    meta: _91slug_93C80Tzk4TpwMeta || {},
    alias: _91slug_93C80Tzk4TpwMeta?.alias || [],
    redirect: _91slug_93C80Tzk4TpwMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93C80Tzk4TpwMeta?.name ?? "products-models-slug___mx",
    path: _91slug_93C80Tzk4TpwMeta?.path ?? "/mx/products/models/:slug()",
    meta: _91slug_93C80Tzk4TpwMeta || {},
    alias: _91slug_93C80Tzk4TpwMeta?.alias || [],
    redirect: _91slug_93C80Tzk4TpwMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93C80Tzk4TpwMeta?.name ?? "products-models-slug___fr",
    path: _91slug_93C80Tzk4TpwMeta?.path ?? "/fr/products/models/:slug()",
    meta: _91slug_93C80Tzk4TpwMeta || {},
    alias: _91slug_93C80Tzk4TpwMeta?.alias || [],
    redirect: _91slug_93C80Tzk4TpwMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93C80Tzk4TpwMeta?.name ?? "products-models-slug___fr-ca",
    path: _91slug_93C80Tzk4TpwMeta?.path ?? "/fr-ca/products/models/:slug()",
    meta: _91slug_93C80Tzk4TpwMeta || {},
    alias: _91slug_93C80Tzk4TpwMeta?.alias || [],
    redirect: _91slug_93C80Tzk4TpwMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexv0GspYyO1KMeta?.name ?? "products-models___en-US",
    path: indexv0GspYyO1KMeta?.path ?? "/products/models",
    meta: indexv0GspYyO1KMeta || {},
    alias: indexv0GspYyO1KMeta?.alias || [],
    redirect: indexv0GspYyO1KMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexv0GspYyO1KMeta?.name ?? "products-models___ca",
    path: indexv0GspYyO1KMeta?.path ?? "/ca/products/models",
    meta: indexv0GspYyO1KMeta || {},
    alias: indexv0GspYyO1KMeta?.alias || [],
    redirect: indexv0GspYyO1KMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexv0GspYyO1KMeta?.name ?? "products-models___nl",
    path: indexv0GspYyO1KMeta?.path ?? "/nl/products/models",
    meta: indexv0GspYyO1KMeta || {},
    alias: indexv0GspYyO1KMeta?.alias || [],
    redirect: indexv0GspYyO1KMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexv0GspYyO1KMeta?.name ?? "products-models___uk",
    path: indexv0GspYyO1KMeta?.path ?? "/uk/products/models",
    meta: indexv0GspYyO1KMeta || {},
    alias: indexv0GspYyO1KMeta?.alias || [],
    redirect: indexv0GspYyO1KMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexv0GspYyO1KMeta?.name ?? "products-models___de",
    path: indexv0GspYyO1KMeta?.path ?? "/de/products/models",
    meta: indexv0GspYyO1KMeta || {},
    alias: indexv0GspYyO1KMeta?.alias || [],
    redirect: indexv0GspYyO1KMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexv0GspYyO1KMeta?.name ?? "products-models___mx",
    path: indexv0GspYyO1KMeta?.path ?? "/mx/products/models",
    meta: indexv0GspYyO1KMeta || {},
    alias: indexv0GspYyO1KMeta?.alias || [],
    redirect: indexv0GspYyO1KMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexv0GspYyO1KMeta?.name ?? "products-models___fr",
    path: indexv0GspYyO1KMeta?.path ?? "/fr/products/models",
    meta: indexv0GspYyO1KMeta || {},
    alias: indexv0GspYyO1KMeta?.alias || [],
    redirect: indexv0GspYyO1KMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexv0GspYyO1KMeta?.name ?? "products-models___fr-ca",
    path: indexv0GspYyO1KMeta?.path ?? "/fr-ca/products/models",
    meta: indexv0GspYyO1KMeta || {},
    alias: indexv0GspYyO1KMeta?.alias || [],
    redirect: indexv0GspYyO1KMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingi5Amfph1LbMeta?.name ?? "products-telematics-gps-fleet-tracking___en-US",
    path: gps_45fleet_45trackingi5Amfph1LbMeta?.path ?? "/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingi5Amfph1LbMeta || {},
    alias: gps_45fleet_45trackingi5Amfph1LbMeta?.alias || [],
    redirect: gps_45fleet_45trackingi5Amfph1LbMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingi5Amfph1LbMeta?.name ?? "products-telematics-gps-fleet-tracking___ca",
    path: gps_45fleet_45trackingi5Amfph1LbMeta?.path ?? "/ca/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingi5Amfph1LbMeta || {},
    alias: gps_45fleet_45trackingi5Amfph1LbMeta?.alias || [],
    redirect: gps_45fleet_45trackingi5Amfph1LbMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingi5Amfph1LbMeta?.name ?? "products-telematics-gps-fleet-tracking___nl",
    path: gps_45fleet_45trackingi5Amfph1LbMeta?.path ?? "/nl/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingi5Amfph1LbMeta || {},
    alias: gps_45fleet_45trackingi5Amfph1LbMeta?.alias || [],
    redirect: gps_45fleet_45trackingi5Amfph1LbMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingi5Amfph1LbMeta?.name ?? "products-telematics-gps-fleet-tracking___uk",
    path: gps_45fleet_45trackingi5Amfph1LbMeta?.path ?? "/uk/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingi5Amfph1LbMeta || {},
    alias: gps_45fleet_45trackingi5Amfph1LbMeta?.alias || [],
    redirect: gps_45fleet_45trackingi5Amfph1LbMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingi5Amfph1LbMeta?.name ?? "products-telematics-gps-fleet-tracking___de",
    path: gps_45fleet_45trackingi5Amfph1LbMeta?.path ?? "/de/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingi5Amfph1LbMeta || {},
    alias: gps_45fleet_45trackingi5Amfph1LbMeta?.alias || [],
    redirect: gps_45fleet_45trackingi5Amfph1LbMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingi5Amfph1LbMeta?.name ?? "products-telematics-gps-fleet-tracking___mx",
    path: gps_45fleet_45trackingi5Amfph1LbMeta?.path ?? "/mx/productos/telematica/rastreo-gps-flota",
    meta: gps_45fleet_45trackingi5Amfph1LbMeta || {},
    alias: gps_45fleet_45trackingi5Amfph1LbMeta?.alias || [],
    redirect: gps_45fleet_45trackingi5Amfph1LbMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingi5Amfph1LbMeta?.name ?? "products-telematics-gps-fleet-tracking___fr",
    path: gps_45fleet_45trackingi5Amfph1LbMeta?.path ?? "/fr/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingi5Amfph1LbMeta || {},
    alias: gps_45fleet_45trackingi5Amfph1LbMeta?.alias || [],
    redirect: gps_45fleet_45trackingi5Amfph1LbMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingi5Amfph1LbMeta?.name ?? "products-telematics-gps-fleet-tracking___fr-ca",
    path: gps_45fleet_45trackingi5Amfph1LbMeta?.path ?? "/fr-ca/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingi5Amfph1LbMeta || {},
    alias: gps_45fleet_45trackingi5Amfph1LbMeta?.alias || [],
    redirect: gps_45fleet_45trackingi5Amfph1LbMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: indexqUyUTR3XEfMeta?.name ?? "products-telematics___en-US",
    path: indexqUyUTR3XEfMeta?.path ?? "/products/telematics",
    meta: indexqUyUTR3XEfMeta || {},
    alias: indexqUyUTR3XEfMeta?.alias || [],
    redirect: indexqUyUTR3XEfMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexqUyUTR3XEfMeta?.name ?? "products-telematics___ca",
    path: indexqUyUTR3XEfMeta?.path ?? "/ca/products/telematics",
    meta: indexqUyUTR3XEfMeta || {},
    alias: indexqUyUTR3XEfMeta?.alias || [],
    redirect: indexqUyUTR3XEfMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexqUyUTR3XEfMeta?.name ?? "products-telematics___nl",
    path: indexqUyUTR3XEfMeta?.path ?? "/nl/products/telematics",
    meta: indexqUyUTR3XEfMeta || {},
    alias: indexqUyUTR3XEfMeta?.alias || [],
    redirect: indexqUyUTR3XEfMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexqUyUTR3XEfMeta?.name ?? "products-telematics___uk",
    path: indexqUyUTR3XEfMeta?.path ?? "/uk/products/telematics",
    meta: indexqUyUTR3XEfMeta || {},
    alias: indexqUyUTR3XEfMeta?.alias || [],
    redirect: indexqUyUTR3XEfMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexqUyUTR3XEfMeta?.name ?? "products-telematics___de",
    path: indexqUyUTR3XEfMeta?.path ?? "/de/products/telematics",
    meta: indexqUyUTR3XEfMeta || {},
    alias: indexqUyUTR3XEfMeta?.alias || [],
    redirect: indexqUyUTR3XEfMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexqUyUTR3XEfMeta?.name ?? "products-telematics___mx",
    path: indexqUyUTR3XEfMeta?.path ?? "/mx/products/flota",
    meta: indexqUyUTR3XEfMeta || {},
    alias: indexqUyUTR3XEfMeta?.alias || [],
    redirect: indexqUyUTR3XEfMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexqUyUTR3XEfMeta?.name ?? "products-telematics___fr",
    path: indexqUyUTR3XEfMeta?.path ?? "/fr/products/telematics",
    meta: indexqUyUTR3XEfMeta || {},
    alias: indexqUyUTR3XEfMeta?.alias || [],
    redirect: indexqUyUTR3XEfMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexqUyUTR3XEfMeta?.name ?? "products-telematics___fr-ca",
    path: indexqUyUTR3XEfMeta?.path ?? "/fr-ca/products/telematics",
    meta: indexqUyUTR3XEfMeta || {},
    alias: indexqUyUTR3XEfMeta?.alias || [],
    redirect: indexqUyUTR3XEfMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: referralsIB8LnsZJqtMeta?.name ?? "referrals___en-US",
    path: referralsIB8LnsZJqtMeta?.path ?? "/referrals",
    meta: referralsIB8LnsZJqtMeta || {},
    alias: referralsIB8LnsZJqtMeta?.alias || [],
    redirect: referralsIB8LnsZJqtMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsIB8LnsZJqtMeta?.name ?? "referrals___ca",
    path: referralsIB8LnsZJqtMeta?.path ?? "/ca/referrals",
    meta: referralsIB8LnsZJqtMeta || {},
    alias: referralsIB8LnsZJqtMeta?.alias || [],
    redirect: referralsIB8LnsZJqtMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsIB8LnsZJqtMeta?.name ?? "referrals___nl",
    path: referralsIB8LnsZJqtMeta?.path ?? "/nl/referrals",
    meta: referralsIB8LnsZJqtMeta || {},
    alias: referralsIB8LnsZJqtMeta?.alias || [],
    redirect: referralsIB8LnsZJqtMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsIB8LnsZJqtMeta?.name ?? "referrals___uk",
    path: referralsIB8LnsZJqtMeta?.path ?? "/uk/referrals",
    meta: referralsIB8LnsZJqtMeta || {},
    alias: referralsIB8LnsZJqtMeta?.alias || [],
    redirect: referralsIB8LnsZJqtMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsIB8LnsZJqtMeta?.name ?? "referrals___de",
    path: referralsIB8LnsZJqtMeta?.path ?? "/de/referrals",
    meta: referralsIB8LnsZJqtMeta || {},
    alias: referralsIB8LnsZJqtMeta?.alias || [],
    redirect: referralsIB8LnsZJqtMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsIB8LnsZJqtMeta?.name ?? "referrals___mx",
    path: referralsIB8LnsZJqtMeta?.path ?? "/mx/referrals",
    meta: referralsIB8LnsZJqtMeta || {},
    alias: referralsIB8LnsZJqtMeta?.alias || [],
    redirect: referralsIB8LnsZJqtMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsIB8LnsZJqtMeta?.name ?? "referrals___fr",
    path: referralsIB8LnsZJqtMeta?.path ?? "/fr/referrals",
    meta: referralsIB8LnsZJqtMeta || {},
    alias: referralsIB8LnsZJqtMeta?.alias || [],
    redirect: referralsIB8LnsZJqtMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsIB8LnsZJqtMeta?.name ?? "referrals___fr-ca",
    path: referralsIB8LnsZJqtMeta?.path ?? "/fr-ca/referrals",
    meta: referralsIB8LnsZJqtMeta || {},
    alias: referralsIB8LnsZJqtMeta?.alias || [],
    redirect: referralsIB8LnsZJqtMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XwIbcQ7G8GMeta?.name ?? "resources-slug___en-US",
    path: _91slug_93XwIbcQ7G8GMeta?.path ?? "/resources/:slug()",
    meta: _91slug_93XwIbcQ7G8GMeta || {},
    alias: _91slug_93XwIbcQ7G8GMeta?.alias || [],
    redirect: _91slug_93XwIbcQ7G8GMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XwIbcQ7G8GMeta?.name ?? "resources-slug___ca",
    path: _91slug_93XwIbcQ7G8GMeta?.path ?? "/ca/resources/:slug()",
    meta: _91slug_93XwIbcQ7G8GMeta || {},
    alias: _91slug_93XwIbcQ7G8GMeta?.alias || [],
    redirect: _91slug_93XwIbcQ7G8GMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XwIbcQ7G8GMeta?.name ?? "resources-slug___nl",
    path: _91slug_93XwIbcQ7G8GMeta?.path ?? "/nl/resources/:slug()",
    meta: _91slug_93XwIbcQ7G8GMeta || {},
    alias: _91slug_93XwIbcQ7G8GMeta?.alias || [],
    redirect: _91slug_93XwIbcQ7G8GMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XwIbcQ7G8GMeta?.name ?? "resources-slug___uk",
    path: _91slug_93XwIbcQ7G8GMeta?.path ?? "/uk/resources/:slug()",
    meta: _91slug_93XwIbcQ7G8GMeta || {},
    alias: _91slug_93XwIbcQ7G8GMeta?.alias || [],
    redirect: _91slug_93XwIbcQ7G8GMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XwIbcQ7G8GMeta?.name ?? "resources-slug___de",
    path: _91slug_93XwIbcQ7G8GMeta?.path ?? "/de/resources/:slug()",
    meta: _91slug_93XwIbcQ7G8GMeta || {},
    alias: _91slug_93XwIbcQ7G8GMeta?.alias || [],
    redirect: _91slug_93XwIbcQ7G8GMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XwIbcQ7G8GMeta?.name ?? "resources-slug___mx",
    path: _91slug_93XwIbcQ7G8GMeta?.path ?? "/mx/resources/:slug()",
    meta: _91slug_93XwIbcQ7G8GMeta || {},
    alias: _91slug_93XwIbcQ7G8GMeta?.alias || [],
    redirect: _91slug_93XwIbcQ7G8GMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XwIbcQ7G8GMeta?.name ?? "resources-slug___fr",
    path: _91slug_93XwIbcQ7G8GMeta?.path ?? "/fr/resources/:slug()",
    meta: _91slug_93XwIbcQ7G8GMeta || {},
    alias: _91slug_93XwIbcQ7G8GMeta?.alias || [],
    redirect: _91slug_93XwIbcQ7G8GMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XwIbcQ7G8GMeta?.name ?? "resources-slug___fr-ca",
    path: _91slug_93XwIbcQ7G8GMeta?.path ?? "/fr-ca/resources/:slug()",
    meta: _91slug_93XwIbcQ7G8GMeta || {},
    alias: _91slug_93XwIbcQ7G8GMeta?.alias || [],
    redirect: _91slug_93XwIbcQ7G8GMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: brand_45assets2CrUbMkpB5Meta?.name ?? "resources-brand-assets___en-US",
    path: brand_45assets2CrUbMkpB5Meta?.path ?? "/resources/brand-assets",
    meta: brand_45assets2CrUbMkpB5Meta || {},
    alias: brand_45assets2CrUbMkpB5Meta?.alias || [],
    redirect: brand_45assets2CrUbMkpB5Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assets2CrUbMkpB5Meta?.name ?? "resources-brand-assets___ca",
    path: brand_45assets2CrUbMkpB5Meta?.path ?? "/ca/resources/brand-assets",
    meta: brand_45assets2CrUbMkpB5Meta || {},
    alias: brand_45assets2CrUbMkpB5Meta?.alias || [],
    redirect: brand_45assets2CrUbMkpB5Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assets2CrUbMkpB5Meta?.name ?? "resources-brand-assets___nl",
    path: brand_45assets2CrUbMkpB5Meta?.path ?? "/nl/resources/brand-assets",
    meta: brand_45assets2CrUbMkpB5Meta || {},
    alias: brand_45assets2CrUbMkpB5Meta?.alias || [],
    redirect: brand_45assets2CrUbMkpB5Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assets2CrUbMkpB5Meta?.name ?? "resources-brand-assets___uk",
    path: brand_45assets2CrUbMkpB5Meta?.path ?? "/uk/resources/brand-assets",
    meta: brand_45assets2CrUbMkpB5Meta || {},
    alias: brand_45assets2CrUbMkpB5Meta?.alias || [],
    redirect: brand_45assets2CrUbMkpB5Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assets2CrUbMkpB5Meta?.name ?? "resources-brand-assets___de",
    path: brand_45assets2CrUbMkpB5Meta?.path ?? "/de/resources/brand-assets",
    meta: brand_45assets2CrUbMkpB5Meta || {},
    alias: brand_45assets2CrUbMkpB5Meta?.alias || [],
    redirect: brand_45assets2CrUbMkpB5Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assets2CrUbMkpB5Meta?.name ?? "resources-brand-assets___mx",
    path: brand_45assets2CrUbMkpB5Meta?.path ?? "/mx/resources/brand-assets",
    meta: brand_45assets2CrUbMkpB5Meta || {},
    alias: brand_45assets2CrUbMkpB5Meta?.alias || [],
    redirect: brand_45assets2CrUbMkpB5Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assets2CrUbMkpB5Meta?.name ?? "resources-brand-assets___fr",
    path: brand_45assets2CrUbMkpB5Meta?.path ?? "/fr/resources/brand-assets",
    meta: brand_45assets2CrUbMkpB5Meta || {},
    alias: brand_45assets2CrUbMkpB5Meta?.alias || [],
    redirect: brand_45assets2CrUbMkpB5Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assets2CrUbMkpB5Meta?.name ?? "resources-brand-assets___fr-ca",
    path: brand_45assets2CrUbMkpB5Meta?.path ?? "/fr-ca/resources/brand-assets",
    meta: brand_45assets2CrUbMkpB5Meta || {},
    alias: brand_45assets2CrUbMkpB5Meta?.alias || [],
    redirect: brand_45assets2CrUbMkpB5Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZUsCpf1s1rMeta?.name ?? "resources-content-slug___en-US",
    path: _91slug_93ZUsCpf1s1rMeta?.path ?? "/resources/content/:slug()",
    meta: _91slug_93ZUsCpf1s1rMeta || {},
    alias: _91slug_93ZUsCpf1s1rMeta?.alias || [],
    redirect: _91slug_93ZUsCpf1s1rMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZUsCpf1s1rMeta?.name ?? "resources-content-slug___ca",
    path: _91slug_93ZUsCpf1s1rMeta?.path ?? "/ca/resources/content/:slug()",
    meta: _91slug_93ZUsCpf1s1rMeta || {},
    alias: _91slug_93ZUsCpf1s1rMeta?.alias || [],
    redirect: _91slug_93ZUsCpf1s1rMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZUsCpf1s1rMeta?.name ?? "resources-content-slug___nl",
    path: _91slug_93ZUsCpf1s1rMeta?.path ?? "/nl/resources/content/:slug()",
    meta: _91slug_93ZUsCpf1s1rMeta || {},
    alias: _91slug_93ZUsCpf1s1rMeta?.alias || [],
    redirect: _91slug_93ZUsCpf1s1rMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZUsCpf1s1rMeta?.name ?? "resources-content-slug___uk",
    path: _91slug_93ZUsCpf1s1rMeta?.path ?? "/uk/resources/content/:slug()",
    meta: _91slug_93ZUsCpf1s1rMeta || {},
    alias: _91slug_93ZUsCpf1s1rMeta?.alias || [],
    redirect: _91slug_93ZUsCpf1s1rMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZUsCpf1s1rMeta?.name ?? "resources-content-slug___de",
    path: _91slug_93ZUsCpf1s1rMeta?.path ?? "/de/resources/content/:slug()",
    meta: _91slug_93ZUsCpf1s1rMeta || {},
    alias: _91slug_93ZUsCpf1s1rMeta?.alias || [],
    redirect: _91slug_93ZUsCpf1s1rMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZUsCpf1s1rMeta?.name ?? "resources-content-slug___mx",
    path: _91slug_93ZUsCpf1s1rMeta?.path ?? "/mx/resources/content/:slug()",
    meta: _91slug_93ZUsCpf1s1rMeta || {},
    alias: _91slug_93ZUsCpf1s1rMeta?.alias || [],
    redirect: _91slug_93ZUsCpf1s1rMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZUsCpf1s1rMeta?.name ?? "resources-content-slug___fr",
    path: _91slug_93ZUsCpf1s1rMeta?.path ?? "/fr/resources/content/:slug()",
    meta: _91slug_93ZUsCpf1s1rMeta || {},
    alias: _91slug_93ZUsCpf1s1rMeta?.alias || [],
    redirect: _91slug_93ZUsCpf1s1rMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZUsCpf1s1rMeta?.name ?? "resources-content-slug___fr-ca",
    path: _91slug_93ZUsCpf1s1rMeta?.path ?? "/fr-ca/resources/content/:slug()",
    meta: _91slug_93ZUsCpf1s1rMeta || {},
    alias: _91slug_93ZUsCpf1s1rMeta?.alias || [],
    redirect: _91slug_93ZUsCpf1s1rMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerL4v0R6EFuOMeta?.name ?? "resources-content-email-preferences-center___en-US",
    path: email_45preferences_45centerL4v0R6EFuOMeta?.path ?? "/resources/content/email-preferences-center",
    meta: email_45preferences_45centerL4v0R6EFuOMeta || {},
    alias: email_45preferences_45centerL4v0R6EFuOMeta?.alias || [],
    redirect: email_45preferences_45centerL4v0R6EFuOMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerL4v0R6EFuOMeta?.name ?? "resources-content-email-preferences-center___ca",
    path: email_45preferences_45centerL4v0R6EFuOMeta?.path ?? "/ca/resources/content/email-preferences-center",
    meta: email_45preferences_45centerL4v0R6EFuOMeta || {},
    alias: email_45preferences_45centerL4v0R6EFuOMeta?.alias || [],
    redirect: email_45preferences_45centerL4v0R6EFuOMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerL4v0R6EFuOMeta?.name ?? "resources-content-email-preferences-center___nl",
    path: email_45preferences_45centerL4v0R6EFuOMeta?.path ?? "/nl/resources/content/email-preferences-center",
    meta: email_45preferences_45centerL4v0R6EFuOMeta || {},
    alias: email_45preferences_45centerL4v0R6EFuOMeta?.alias || [],
    redirect: email_45preferences_45centerL4v0R6EFuOMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerL4v0R6EFuOMeta?.name ?? "resources-content-email-preferences-center___uk",
    path: email_45preferences_45centerL4v0R6EFuOMeta?.path ?? "/uk/resources/content/email-preferences-center",
    meta: email_45preferences_45centerL4v0R6EFuOMeta || {},
    alias: email_45preferences_45centerL4v0R6EFuOMeta?.alias || [],
    redirect: email_45preferences_45centerL4v0R6EFuOMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerL4v0R6EFuOMeta?.name ?? "resources-content-email-preferences-center___de",
    path: email_45preferences_45centerL4v0R6EFuOMeta?.path ?? "/de/resources/content/email-preferences-center",
    meta: email_45preferences_45centerL4v0R6EFuOMeta || {},
    alias: email_45preferences_45centerL4v0R6EFuOMeta?.alias || [],
    redirect: email_45preferences_45centerL4v0R6EFuOMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerL4v0R6EFuOMeta?.name ?? "resources-content-email-preferences-center___mx",
    path: email_45preferences_45centerL4v0R6EFuOMeta?.path ?? "/mx/resources/content/email-preferences-center",
    meta: email_45preferences_45centerL4v0R6EFuOMeta || {},
    alias: email_45preferences_45centerL4v0R6EFuOMeta?.alias || [],
    redirect: email_45preferences_45centerL4v0R6EFuOMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerL4v0R6EFuOMeta?.name ?? "resources-content-email-preferences-center___fr",
    path: email_45preferences_45centerL4v0R6EFuOMeta?.path ?? "/fr/resources/content/email-preferences-center",
    meta: email_45preferences_45centerL4v0R6EFuOMeta || {},
    alias: email_45preferences_45centerL4v0R6EFuOMeta?.alias || [],
    redirect: email_45preferences_45centerL4v0R6EFuOMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerL4v0R6EFuOMeta?.name ?? "resources-content-email-preferences-center___fr-ca",
    path: email_45preferences_45centerL4v0R6EFuOMeta?.path ?? "/fr-ca/resources/content/email-preferences-center",
    meta: email_45preferences_45centerL4v0R6EFuOMeta || {},
    alias: email_45preferences_45centerL4v0R6EFuOMeta?.alias || [],
    redirect: email_45preferences_45centerL4v0R6EFuOMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93865XW1S5VuMeta?.name ?? "resources-experts-slug___en-US",
    path: _91slug_93865XW1S5VuMeta?.path ?? "/resources/experts/:slug()",
    meta: _91slug_93865XW1S5VuMeta || {},
    alias: _91slug_93865XW1S5VuMeta?.alias || [],
    redirect: _91slug_93865XW1S5VuMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93865XW1S5VuMeta?.name ?? "resources-experts-slug___ca",
    path: _91slug_93865XW1S5VuMeta?.path ?? "/ca/resources/experts/:slug()",
    meta: _91slug_93865XW1S5VuMeta || {},
    alias: _91slug_93865XW1S5VuMeta?.alias || [],
    redirect: _91slug_93865XW1S5VuMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93865XW1S5VuMeta?.name ?? "resources-experts-slug___nl",
    path: _91slug_93865XW1S5VuMeta?.path ?? "/nl/resources/experts/:slug()",
    meta: _91slug_93865XW1S5VuMeta || {},
    alias: _91slug_93865XW1S5VuMeta?.alias || [],
    redirect: _91slug_93865XW1S5VuMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93865XW1S5VuMeta?.name ?? "resources-experts-slug___uk",
    path: _91slug_93865XW1S5VuMeta?.path ?? "/uk/resources/experts/:slug()",
    meta: _91slug_93865XW1S5VuMeta || {},
    alias: _91slug_93865XW1S5VuMeta?.alias || [],
    redirect: _91slug_93865XW1S5VuMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93865XW1S5VuMeta?.name ?? "resources-experts-slug___de",
    path: _91slug_93865XW1S5VuMeta?.path ?? "/de/resources/experts/:slug()",
    meta: _91slug_93865XW1S5VuMeta || {},
    alias: _91slug_93865XW1S5VuMeta?.alias || [],
    redirect: _91slug_93865XW1S5VuMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93865XW1S5VuMeta?.name ?? "resources-experts-slug___mx",
    path: _91slug_93865XW1S5VuMeta?.path ?? "/mx/resources/experts/:slug()",
    meta: _91slug_93865XW1S5VuMeta || {},
    alias: _91slug_93865XW1S5VuMeta?.alias || [],
    redirect: _91slug_93865XW1S5VuMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93865XW1S5VuMeta?.name ?? "resources-experts-slug___fr",
    path: _91slug_93865XW1S5VuMeta?.path ?? "/fr/resources/experts/:slug()",
    meta: _91slug_93865XW1S5VuMeta || {},
    alias: _91slug_93865XW1S5VuMeta?.alias || [],
    redirect: _91slug_93865XW1S5VuMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93865XW1S5VuMeta?.name ?? "resources-experts-slug___fr-ca",
    path: _91slug_93865XW1S5VuMeta?.path ?? "/fr-ca/resources/experts/:slug()",
    meta: _91slug_93865XW1S5VuMeta || {},
    alias: _91slug_93865XW1S5VuMeta?.alias || [],
    redirect: _91slug_93865XW1S5VuMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9L8tTSzFIMeta?.name ?? "resources-experts-category-slug___en-US",
    path: _91slug_93k9L8tTSzFIMeta?.path ?? "/resources/experts/category/:slug()",
    meta: _91slug_93k9L8tTSzFIMeta || {},
    alias: _91slug_93k9L8tTSzFIMeta?.alias || [],
    redirect: _91slug_93k9L8tTSzFIMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9L8tTSzFIMeta?.name ?? "resources-experts-category-slug___ca",
    path: _91slug_93k9L8tTSzFIMeta?.path ?? "/ca/resources/experts/category/:slug()",
    meta: _91slug_93k9L8tTSzFIMeta || {},
    alias: _91slug_93k9L8tTSzFIMeta?.alias || [],
    redirect: _91slug_93k9L8tTSzFIMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9L8tTSzFIMeta?.name ?? "resources-experts-category-slug___nl",
    path: _91slug_93k9L8tTSzFIMeta?.path ?? "/nl/resources/experts/category/:slug()",
    meta: _91slug_93k9L8tTSzFIMeta || {},
    alias: _91slug_93k9L8tTSzFIMeta?.alias || [],
    redirect: _91slug_93k9L8tTSzFIMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9L8tTSzFIMeta?.name ?? "resources-experts-category-slug___uk",
    path: _91slug_93k9L8tTSzFIMeta?.path ?? "/uk/resources/experts/category/:slug()",
    meta: _91slug_93k9L8tTSzFIMeta || {},
    alias: _91slug_93k9L8tTSzFIMeta?.alias || [],
    redirect: _91slug_93k9L8tTSzFIMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9L8tTSzFIMeta?.name ?? "resources-experts-category-slug___de",
    path: _91slug_93k9L8tTSzFIMeta?.path ?? "/de/resources/experts/category/:slug()",
    meta: _91slug_93k9L8tTSzFIMeta || {},
    alias: _91slug_93k9L8tTSzFIMeta?.alias || [],
    redirect: _91slug_93k9L8tTSzFIMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9L8tTSzFIMeta?.name ?? "resources-experts-category-slug___mx",
    path: _91slug_93k9L8tTSzFIMeta?.path ?? "/mx/resources/experts/category/:slug()",
    meta: _91slug_93k9L8tTSzFIMeta || {},
    alias: _91slug_93k9L8tTSzFIMeta?.alias || [],
    redirect: _91slug_93k9L8tTSzFIMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9L8tTSzFIMeta?.name ?? "resources-experts-category-slug___fr",
    path: _91slug_93k9L8tTSzFIMeta?.path ?? "/fr/resources/experts/category/:slug()",
    meta: _91slug_93k9L8tTSzFIMeta || {},
    alias: _91slug_93k9L8tTSzFIMeta?.alias || [],
    redirect: _91slug_93k9L8tTSzFIMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9L8tTSzFIMeta?.name ?? "resources-experts-category-slug___fr-ca",
    path: _91slug_93k9L8tTSzFIMeta?.path ?? "/fr-ca/resources/experts/category/:slug()",
    meta: _91slug_93k9L8tTSzFIMeta || {},
    alias: _91slug_93k9L8tTSzFIMeta?.alias || [],
    redirect: _91slug_93k9L8tTSzFIMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexv09paYUT59Meta?.name ?? "resources-experts___en-US",
    path: indexv09paYUT59Meta?.path ?? "/resources/experts",
    meta: indexv09paYUT59Meta || {},
    alias: indexv09paYUT59Meta?.alias || [],
    redirect: indexv09paYUT59Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexv09paYUT59Meta?.name ?? "resources-experts___ca",
    path: indexv09paYUT59Meta?.path ?? "/ca/resources/experts",
    meta: indexv09paYUT59Meta || {},
    alias: indexv09paYUT59Meta?.alias || [],
    redirect: indexv09paYUT59Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexv09paYUT59Meta?.name ?? "resources-experts___nl",
    path: indexv09paYUT59Meta?.path ?? "/nl/resources/experts",
    meta: indexv09paYUT59Meta || {},
    alias: indexv09paYUT59Meta?.alias || [],
    redirect: indexv09paYUT59Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexv09paYUT59Meta?.name ?? "resources-experts___uk",
    path: indexv09paYUT59Meta?.path ?? "/uk/resources/experts",
    meta: indexv09paYUT59Meta || {},
    alias: indexv09paYUT59Meta?.alias || [],
    redirect: indexv09paYUT59Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexv09paYUT59Meta?.name ?? "resources-experts___de",
    path: indexv09paYUT59Meta?.path ?? "/de/resources/experts",
    meta: indexv09paYUT59Meta || {},
    alias: indexv09paYUT59Meta?.alias || [],
    redirect: indexv09paYUT59Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexv09paYUT59Meta?.name ?? "resources-experts___mx",
    path: indexv09paYUT59Meta?.path ?? "/mx/resources/experts",
    meta: indexv09paYUT59Meta || {},
    alias: indexv09paYUT59Meta?.alias || [],
    redirect: indexv09paYUT59Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexv09paYUT59Meta?.name ?? "resources-experts___fr",
    path: indexv09paYUT59Meta?.path ?? "/fr/resources/experts",
    meta: indexv09paYUT59Meta || {},
    alias: indexv09paYUT59Meta?.alias || [],
    redirect: indexv09paYUT59Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexv09paYUT59Meta?.name ?? "resources-experts___fr-ca",
    path: indexv09paYUT59Meta?.path ?? "/fr-ca/resources/experts",
    meta: indexv09paYUT59Meta || {},
    alias: indexv09paYUT59Meta?.alias || [],
    redirect: indexv09paYUT59Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexDaVrFESPHwMeta?.name ?? "resources___en-US",
    path: indexDaVrFESPHwMeta?.path ?? "/resources",
    meta: indexDaVrFESPHwMeta || {},
    alias: indexDaVrFESPHwMeta?.alias || [],
    redirect: indexDaVrFESPHwMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexDaVrFESPHwMeta?.name ?? "resources___ca",
    path: indexDaVrFESPHwMeta?.path ?? "/ca/resources",
    meta: indexDaVrFESPHwMeta || {},
    alias: indexDaVrFESPHwMeta?.alias || [],
    redirect: indexDaVrFESPHwMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexDaVrFESPHwMeta?.name ?? "resources___nl",
    path: indexDaVrFESPHwMeta?.path ?? "/nl/resources",
    meta: indexDaVrFESPHwMeta || {},
    alias: indexDaVrFESPHwMeta?.alias || [],
    redirect: indexDaVrFESPHwMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexDaVrFESPHwMeta?.name ?? "resources___uk",
    path: indexDaVrFESPHwMeta?.path ?? "/uk/resources",
    meta: indexDaVrFESPHwMeta || {},
    alias: indexDaVrFESPHwMeta?.alias || [],
    redirect: indexDaVrFESPHwMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexDaVrFESPHwMeta?.name ?? "resources___de",
    path: indexDaVrFESPHwMeta?.path ?? "/de/resources",
    meta: indexDaVrFESPHwMeta || {},
    alias: indexDaVrFESPHwMeta?.alias || [],
    redirect: indexDaVrFESPHwMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexDaVrFESPHwMeta?.name ?? "resources___mx",
    path: indexDaVrFESPHwMeta?.path ?? "/mx/resources",
    meta: indexDaVrFESPHwMeta || {},
    alias: indexDaVrFESPHwMeta?.alias || [],
    redirect: indexDaVrFESPHwMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexDaVrFESPHwMeta?.name ?? "resources___fr",
    path: indexDaVrFESPHwMeta?.path ?? "/fr/resources",
    meta: indexDaVrFESPHwMeta || {},
    alias: indexDaVrFESPHwMeta?.alias || [],
    redirect: indexDaVrFESPHwMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexDaVrFESPHwMeta?.name ?? "resources___fr-ca",
    path: indexDaVrFESPHwMeta?.path ?? "/fr-ca/resources",
    meta: indexDaVrFESPHwMeta || {},
    alias: indexDaVrFESPHwMeta?.alias || [],
    redirect: indexDaVrFESPHwMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ywekjdCWHgMeta?.name ?? "resources-marketplace-slug___en-US",
    path: _91slug_93ywekjdCWHgMeta?.path ?? "/resources/marketplace/:slug()",
    meta: _91slug_93ywekjdCWHgMeta || {},
    alias: _91slug_93ywekjdCWHgMeta?.alias || [],
    redirect: _91slug_93ywekjdCWHgMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ywekjdCWHgMeta?.name ?? "resources-marketplace-slug___ca",
    path: _91slug_93ywekjdCWHgMeta?.path ?? "/ca/resources/marketplace/:slug()",
    meta: _91slug_93ywekjdCWHgMeta || {},
    alias: _91slug_93ywekjdCWHgMeta?.alias || [],
    redirect: _91slug_93ywekjdCWHgMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ywekjdCWHgMeta?.name ?? "resources-marketplace-slug___nl",
    path: _91slug_93ywekjdCWHgMeta?.path ?? "/nl/resources/marketplace/:slug()",
    meta: _91slug_93ywekjdCWHgMeta || {},
    alias: _91slug_93ywekjdCWHgMeta?.alias || [],
    redirect: _91slug_93ywekjdCWHgMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ywekjdCWHgMeta?.name ?? "resources-marketplace-slug___uk",
    path: _91slug_93ywekjdCWHgMeta?.path ?? "/uk/resources/marketplace/:slug()",
    meta: _91slug_93ywekjdCWHgMeta || {},
    alias: _91slug_93ywekjdCWHgMeta?.alias || [],
    redirect: _91slug_93ywekjdCWHgMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ywekjdCWHgMeta?.name ?? "resources-marketplace-slug___de",
    path: _91slug_93ywekjdCWHgMeta?.path ?? "/de/resources/marketplace/:slug()",
    meta: _91slug_93ywekjdCWHgMeta || {},
    alias: _91slug_93ywekjdCWHgMeta?.alias || [],
    redirect: _91slug_93ywekjdCWHgMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ywekjdCWHgMeta?.name ?? "resources-marketplace-slug___mx",
    path: _91slug_93ywekjdCWHgMeta?.path ?? "/mx/resources/marketplace/:slug()",
    meta: _91slug_93ywekjdCWHgMeta || {},
    alias: _91slug_93ywekjdCWHgMeta?.alias || [],
    redirect: _91slug_93ywekjdCWHgMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ywekjdCWHgMeta?.name ?? "resources-marketplace-slug___fr",
    path: _91slug_93ywekjdCWHgMeta?.path ?? "/fr/resources/marketplace/:slug()",
    meta: _91slug_93ywekjdCWHgMeta || {},
    alias: _91slug_93ywekjdCWHgMeta?.alias || [],
    redirect: _91slug_93ywekjdCWHgMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ywekjdCWHgMeta?.name ?? "resources-marketplace-slug___fr-ca",
    path: _91slug_93ywekjdCWHgMeta?.path ?? "/fr-ca/resources/marketplace/:slug()",
    meta: _91slug_93ywekjdCWHgMeta || {},
    alias: _91slug_93ywekjdCWHgMeta?.alias || [],
    redirect: _91slug_93ywekjdCWHgMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93M2QWfDTfhDMeta?.name ?? "resources-marketplace-category-slug___en-US",
    path: _91slug_93M2QWfDTfhDMeta?.path ?? "/resources/marketplace/category/:slug()",
    meta: _91slug_93M2QWfDTfhDMeta || {},
    alias: _91slug_93M2QWfDTfhDMeta?.alias || [],
    redirect: _91slug_93M2QWfDTfhDMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93M2QWfDTfhDMeta?.name ?? "resources-marketplace-category-slug___ca",
    path: _91slug_93M2QWfDTfhDMeta?.path ?? "/ca/resources/marketplace/category/:slug()",
    meta: _91slug_93M2QWfDTfhDMeta || {},
    alias: _91slug_93M2QWfDTfhDMeta?.alias || [],
    redirect: _91slug_93M2QWfDTfhDMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93M2QWfDTfhDMeta?.name ?? "resources-marketplace-category-slug___nl",
    path: _91slug_93M2QWfDTfhDMeta?.path ?? "/nl/resources/marketplace/category/:slug()",
    meta: _91slug_93M2QWfDTfhDMeta || {},
    alias: _91slug_93M2QWfDTfhDMeta?.alias || [],
    redirect: _91slug_93M2QWfDTfhDMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93M2QWfDTfhDMeta?.name ?? "resources-marketplace-category-slug___uk",
    path: _91slug_93M2QWfDTfhDMeta?.path ?? "/uk/resources/marketplace/category/:slug()",
    meta: _91slug_93M2QWfDTfhDMeta || {},
    alias: _91slug_93M2QWfDTfhDMeta?.alias || [],
    redirect: _91slug_93M2QWfDTfhDMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93M2QWfDTfhDMeta?.name ?? "resources-marketplace-category-slug___de",
    path: _91slug_93M2QWfDTfhDMeta?.path ?? "/de/resources/marketplace/category/:slug()",
    meta: _91slug_93M2QWfDTfhDMeta || {},
    alias: _91slug_93M2QWfDTfhDMeta?.alias || [],
    redirect: _91slug_93M2QWfDTfhDMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93M2QWfDTfhDMeta?.name ?? "resources-marketplace-category-slug___mx",
    path: _91slug_93M2QWfDTfhDMeta?.path ?? "/mx/resources/marketplace/category/:slug()",
    meta: _91slug_93M2QWfDTfhDMeta || {},
    alias: _91slug_93M2QWfDTfhDMeta?.alias || [],
    redirect: _91slug_93M2QWfDTfhDMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93M2QWfDTfhDMeta?.name ?? "resources-marketplace-category-slug___fr",
    path: _91slug_93M2QWfDTfhDMeta?.path ?? "/fr/resources/marketplace/category/:slug()",
    meta: _91slug_93M2QWfDTfhDMeta || {},
    alias: _91slug_93M2QWfDTfhDMeta?.alias || [],
    redirect: _91slug_93M2QWfDTfhDMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93M2QWfDTfhDMeta?.name ?? "resources-marketplace-category-slug___fr-ca",
    path: _91slug_93M2QWfDTfhDMeta?.path ?? "/fr-ca/resources/marketplace/category/:slug()",
    meta: _91slug_93M2QWfDTfhDMeta || {},
    alias: _91slug_93M2QWfDTfhDMeta?.alias || [],
    redirect: _91slug_93M2QWfDTfhDMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: index0N7CTDvv30Meta?.name ?? "resources-marketplace___en-US",
    path: index0N7CTDvv30Meta?.path ?? "/resources/marketplace",
    meta: index0N7CTDvv30Meta || {},
    alias: index0N7CTDvv30Meta?.alias || [],
    redirect: index0N7CTDvv30Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: index0N7CTDvv30Meta?.name ?? "resources-marketplace___ca",
    path: index0N7CTDvv30Meta?.path ?? "/ca/resources/marketplace",
    meta: index0N7CTDvv30Meta || {},
    alias: index0N7CTDvv30Meta?.alias || [],
    redirect: index0N7CTDvv30Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: index0N7CTDvv30Meta?.name ?? "resources-marketplace___nl",
    path: index0N7CTDvv30Meta?.path ?? "/nl/resources/marketplace",
    meta: index0N7CTDvv30Meta || {},
    alias: index0N7CTDvv30Meta?.alias || [],
    redirect: index0N7CTDvv30Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: index0N7CTDvv30Meta?.name ?? "resources-marketplace___uk",
    path: index0N7CTDvv30Meta?.path ?? "/uk/resources/marketplace",
    meta: index0N7CTDvv30Meta || {},
    alias: index0N7CTDvv30Meta?.alias || [],
    redirect: index0N7CTDvv30Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: index0N7CTDvv30Meta?.name ?? "resources-marketplace___de",
    path: index0N7CTDvv30Meta?.path ?? "/de/resources/marketplace",
    meta: index0N7CTDvv30Meta || {},
    alias: index0N7CTDvv30Meta?.alias || [],
    redirect: index0N7CTDvv30Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: index0N7CTDvv30Meta?.name ?? "resources-marketplace___mx",
    path: index0N7CTDvv30Meta?.path ?? "/mx/resources/marketplace",
    meta: index0N7CTDvv30Meta || {},
    alias: index0N7CTDvv30Meta?.alias || [],
    redirect: index0N7CTDvv30Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: index0N7CTDvv30Meta?.name ?? "resources-marketplace___fr",
    path: index0N7CTDvv30Meta?.path ?? "/fr/resources/marketplace",
    meta: index0N7CTDvv30Meta || {},
    alias: index0N7CTDvv30Meta?.alias || [],
    redirect: index0N7CTDvv30Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: index0N7CTDvv30Meta?.name ?? "resources-marketplace___fr-ca",
    path: index0N7CTDvv30Meta?.path ?? "/fr-ca/resources/marketplace",
    meta: index0N7CTDvv30Meta || {},
    alias: index0N7CTDvv30Meta?.alias || [],
    redirect: index0N7CTDvv30Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsara7w6j0pFMfxMeta?.name ?? "resources-partner-programs-build-with-samsara___en-US",
    path: build_45with_45samsara7w6j0pFMfxMeta?.path ?? "/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsara7w6j0pFMfxMeta || {},
    alias: build_45with_45samsara7w6j0pFMfxMeta?.alias || [],
    redirect: build_45with_45samsara7w6j0pFMfxMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsara7w6j0pFMfxMeta?.name ?? "resources-partner-programs-build-with-samsara___ca",
    path: build_45with_45samsara7w6j0pFMfxMeta?.path ?? "/ca/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsara7w6j0pFMfxMeta || {},
    alias: build_45with_45samsara7w6j0pFMfxMeta?.alias || [],
    redirect: build_45with_45samsara7w6j0pFMfxMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsara7w6j0pFMfxMeta?.name ?? "resources-partner-programs-build-with-samsara___nl",
    path: build_45with_45samsara7w6j0pFMfxMeta?.path ?? "/nl/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsara7w6j0pFMfxMeta || {},
    alias: build_45with_45samsara7w6j0pFMfxMeta?.alias || [],
    redirect: build_45with_45samsara7w6j0pFMfxMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsara7w6j0pFMfxMeta?.name ?? "resources-partner-programs-build-with-samsara___uk",
    path: build_45with_45samsara7w6j0pFMfxMeta?.path ?? "/uk/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsara7w6j0pFMfxMeta || {},
    alias: build_45with_45samsara7w6j0pFMfxMeta?.alias || [],
    redirect: build_45with_45samsara7w6j0pFMfxMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsara7w6j0pFMfxMeta?.name ?? "resources-partner-programs-build-with-samsara___de",
    path: build_45with_45samsara7w6j0pFMfxMeta?.path ?? "/de/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsara7w6j0pFMfxMeta || {},
    alias: build_45with_45samsara7w6j0pFMfxMeta?.alias || [],
    redirect: build_45with_45samsara7w6j0pFMfxMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsara7w6j0pFMfxMeta?.name ?? "resources-partner-programs-build-with-samsara___mx",
    path: build_45with_45samsara7w6j0pFMfxMeta?.path ?? "/mx/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsara7w6j0pFMfxMeta || {},
    alias: build_45with_45samsara7w6j0pFMfxMeta?.alias || [],
    redirect: build_45with_45samsara7w6j0pFMfxMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsara7w6j0pFMfxMeta?.name ?? "resources-partner-programs-build-with-samsara___fr",
    path: build_45with_45samsara7w6j0pFMfxMeta?.path ?? "/fr/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsara7w6j0pFMfxMeta || {},
    alias: build_45with_45samsara7w6j0pFMfxMeta?.alias || [],
    redirect: build_45with_45samsara7w6j0pFMfxMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsara7w6j0pFMfxMeta?.name ?? "resources-partner-programs-build-with-samsara___fr-ca",
    path: build_45with_45samsara7w6j0pFMfxMeta?.path ?? "/fr-ca/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsara7w6j0pFMfxMeta || {},
    alias: build_45with_45samsara7w6j0pFMfxMeta?.alias || [],
    redirect: build_45with_45samsara7w6j0pFMfxMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsLxoIGtdXjCMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___en-US",
    path: evolve_45insurance_45programsLxoIGtdXjCMeta?.path ?? "/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsLxoIGtdXjCMeta || {},
    alias: evolve_45insurance_45programsLxoIGtdXjCMeta?.alias || [],
    redirect: evolve_45insurance_45programsLxoIGtdXjCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsLxoIGtdXjCMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___ca",
    path: evolve_45insurance_45programsLxoIGtdXjCMeta?.path ?? "/ca/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsLxoIGtdXjCMeta || {},
    alias: evolve_45insurance_45programsLxoIGtdXjCMeta?.alias || [],
    redirect: evolve_45insurance_45programsLxoIGtdXjCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsLxoIGtdXjCMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___nl",
    path: evolve_45insurance_45programsLxoIGtdXjCMeta?.path ?? "/nl/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsLxoIGtdXjCMeta || {},
    alias: evolve_45insurance_45programsLxoIGtdXjCMeta?.alias || [],
    redirect: evolve_45insurance_45programsLxoIGtdXjCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsLxoIGtdXjCMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___uk",
    path: evolve_45insurance_45programsLxoIGtdXjCMeta?.path ?? "/uk/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsLxoIGtdXjCMeta || {},
    alias: evolve_45insurance_45programsLxoIGtdXjCMeta?.alias || [],
    redirect: evolve_45insurance_45programsLxoIGtdXjCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsLxoIGtdXjCMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___de",
    path: evolve_45insurance_45programsLxoIGtdXjCMeta?.path ?? "/de/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsLxoIGtdXjCMeta || {},
    alias: evolve_45insurance_45programsLxoIGtdXjCMeta?.alias || [],
    redirect: evolve_45insurance_45programsLxoIGtdXjCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsLxoIGtdXjCMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___mx",
    path: evolve_45insurance_45programsLxoIGtdXjCMeta?.path ?? "/mx/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsLxoIGtdXjCMeta || {},
    alias: evolve_45insurance_45programsLxoIGtdXjCMeta?.alias || [],
    redirect: evolve_45insurance_45programsLxoIGtdXjCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsLxoIGtdXjCMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___fr",
    path: evolve_45insurance_45programsLxoIGtdXjCMeta?.path ?? "/fr/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsLxoIGtdXjCMeta || {},
    alias: evolve_45insurance_45programsLxoIGtdXjCMeta?.alias || [],
    redirect: evolve_45insurance_45programsLxoIGtdXjCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsLxoIGtdXjCMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___fr-ca",
    path: evolve_45insurance_45programsLxoIGtdXjCMeta?.path ?? "/fr-ca/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsLxoIGtdXjCMeta || {},
    alias: evolve_45insurance_45programsLxoIGtdXjCMeta?.alias || [],
    redirect: evolve_45insurance_45programsLxoIGtdXjCMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: indexSluW3nDWHvMeta?.name ?? "resources-partner-programs___en-US",
    path: indexSluW3nDWHvMeta?.path ?? "/resources/partner-programs",
    meta: indexSluW3nDWHvMeta || {},
    alias: indexSluW3nDWHvMeta?.alias || [],
    redirect: indexSluW3nDWHvMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexSluW3nDWHvMeta?.name ?? "resources-partner-programs___ca",
    path: indexSluW3nDWHvMeta?.path ?? "/ca/resources/partner-programs",
    meta: indexSluW3nDWHvMeta || {},
    alias: indexSluW3nDWHvMeta?.alias || [],
    redirect: indexSluW3nDWHvMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexSluW3nDWHvMeta?.name ?? "resources-partner-programs___nl",
    path: indexSluW3nDWHvMeta?.path ?? "/nl/resources/partner-programs",
    meta: indexSluW3nDWHvMeta || {},
    alias: indexSluW3nDWHvMeta?.alias || [],
    redirect: indexSluW3nDWHvMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexSluW3nDWHvMeta?.name ?? "resources-partner-programs___uk",
    path: indexSluW3nDWHvMeta?.path ?? "/uk/resources/partner-programs",
    meta: indexSluW3nDWHvMeta || {},
    alias: indexSluW3nDWHvMeta?.alias || [],
    redirect: indexSluW3nDWHvMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexSluW3nDWHvMeta?.name ?? "resources-partner-programs___de",
    path: indexSluW3nDWHvMeta?.path ?? "/de/resources/partner-programs",
    meta: indexSluW3nDWHvMeta || {},
    alias: indexSluW3nDWHvMeta?.alias || [],
    redirect: indexSluW3nDWHvMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexSluW3nDWHvMeta?.name ?? "resources-partner-programs___mx",
    path: indexSluW3nDWHvMeta?.path ?? "/mx/resources/partner-programs",
    meta: indexSluW3nDWHvMeta || {},
    alias: indexSluW3nDWHvMeta?.alias || [],
    redirect: indexSluW3nDWHvMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexSluW3nDWHvMeta?.name ?? "resources-partner-programs___fr",
    path: indexSluW3nDWHvMeta?.path ?? "/fr/resources/partner-programs",
    meta: indexSluW3nDWHvMeta || {},
    alias: indexSluW3nDWHvMeta?.alias || [],
    redirect: indexSluW3nDWHvMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexSluW3nDWHvMeta?.name ?? "resources-partner-programs___fr-ca",
    path: indexSluW3nDWHvMeta?.path ?? "/fr-ca/resources/partner-programs",
    meta: indexSluW3nDWHvMeta || {},
    alias: indexSluW3nDWHvMeta?.alias || [],
    redirect: indexSluW3nDWHvMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesf2FcrLE5X8Meta?.name ?? "resources-partner-programs-provide-services___en-US",
    path: provide_45servicesf2FcrLE5X8Meta?.path ?? "/resources/partner-programs/provide-services",
    meta: provide_45servicesf2FcrLE5X8Meta || {},
    alias: provide_45servicesf2FcrLE5X8Meta?.alias || [],
    redirect: provide_45servicesf2FcrLE5X8Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesf2FcrLE5X8Meta?.name ?? "resources-partner-programs-provide-services___ca",
    path: provide_45servicesf2FcrLE5X8Meta?.path ?? "/ca/resources/partner-programs/provide-services",
    meta: provide_45servicesf2FcrLE5X8Meta || {},
    alias: provide_45servicesf2FcrLE5X8Meta?.alias || [],
    redirect: provide_45servicesf2FcrLE5X8Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesf2FcrLE5X8Meta?.name ?? "resources-partner-programs-provide-services___nl",
    path: provide_45servicesf2FcrLE5X8Meta?.path ?? "/nl/resources/partner-programs/provide-services",
    meta: provide_45servicesf2FcrLE5X8Meta || {},
    alias: provide_45servicesf2FcrLE5X8Meta?.alias || [],
    redirect: provide_45servicesf2FcrLE5X8Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesf2FcrLE5X8Meta?.name ?? "resources-partner-programs-provide-services___uk",
    path: provide_45servicesf2FcrLE5X8Meta?.path ?? "/uk/resources/partner-programs/provide-services",
    meta: provide_45servicesf2FcrLE5X8Meta || {},
    alias: provide_45servicesf2FcrLE5X8Meta?.alias || [],
    redirect: provide_45servicesf2FcrLE5X8Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesf2FcrLE5X8Meta?.name ?? "resources-partner-programs-provide-services___de",
    path: provide_45servicesf2FcrLE5X8Meta?.path ?? "/de/resources/partner-programs/provide-services",
    meta: provide_45servicesf2FcrLE5X8Meta || {},
    alias: provide_45servicesf2FcrLE5X8Meta?.alias || [],
    redirect: provide_45servicesf2FcrLE5X8Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesf2FcrLE5X8Meta?.name ?? "resources-partner-programs-provide-services___mx",
    path: provide_45servicesf2FcrLE5X8Meta?.path ?? "/mx/resources/partner-programs/provide-services",
    meta: provide_45servicesf2FcrLE5X8Meta || {},
    alias: provide_45servicesf2FcrLE5X8Meta?.alias || [],
    redirect: provide_45servicesf2FcrLE5X8Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesf2FcrLE5X8Meta?.name ?? "resources-partner-programs-provide-services___fr",
    path: provide_45servicesf2FcrLE5X8Meta?.path ?? "/fr/resources/partner-programs/provide-services",
    meta: provide_45servicesf2FcrLE5X8Meta || {},
    alias: provide_45servicesf2FcrLE5X8Meta?.alias || [],
    redirect: provide_45servicesf2FcrLE5X8Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesf2FcrLE5X8Meta?.name ?? "resources-partner-programs-provide-services___fr-ca",
    path: provide_45servicesf2FcrLE5X8Meta?.path ?? "/fr-ca/resources/partner-programs/provide-services",
    meta: provide_45servicesf2FcrLE5X8Meta || {},
    alias: provide_45servicesf2FcrLE5X8Meta?.alias || [],
    redirect: provide_45servicesf2FcrLE5X8Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraDVmUqTvZ9SMeta?.name ?? "resources-partner-programs-sell-samsara___en-US",
    path: sell_45samsaraDVmUqTvZ9SMeta?.path ?? "/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraDVmUqTvZ9SMeta || {},
    alias: sell_45samsaraDVmUqTvZ9SMeta?.alias || [],
    redirect: sell_45samsaraDVmUqTvZ9SMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraDVmUqTvZ9SMeta?.name ?? "resources-partner-programs-sell-samsara___ca",
    path: sell_45samsaraDVmUqTvZ9SMeta?.path ?? "/ca/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraDVmUqTvZ9SMeta || {},
    alias: sell_45samsaraDVmUqTvZ9SMeta?.alias || [],
    redirect: sell_45samsaraDVmUqTvZ9SMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraDVmUqTvZ9SMeta?.name ?? "resources-partner-programs-sell-samsara___nl",
    path: sell_45samsaraDVmUqTvZ9SMeta?.path ?? "/nl/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraDVmUqTvZ9SMeta || {},
    alias: sell_45samsaraDVmUqTvZ9SMeta?.alias || [],
    redirect: sell_45samsaraDVmUqTvZ9SMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraDVmUqTvZ9SMeta?.name ?? "resources-partner-programs-sell-samsara___uk",
    path: sell_45samsaraDVmUqTvZ9SMeta?.path ?? "/uk/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraDVmUqTvZ9SMeta || {},
    alias: sell_45samsaraDVmUqTvZ9SMeta?.alias || [],
    redirect: sell_45samsaraDVmUqTvZ9SMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraDVmUqTvZ9SMeta?.name ?? "resources-partner-programs-sell-samsara___de",
    path: sell_45samsaraDVmUqTvZ9SMeta?.path ?? "/de/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraDVmUqTvZ9SMeta || {},
    alias: sell_45samsaraDVmUqTvZ9SMeta?.alias || [],
    redirect: sell_45samsaraDVmUqTvZ9SMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraDVmUqTvZ9SMeta?.name ?? "resources-partner-programs-sell-samsara___mx",
    path: sell_45samsaraDVmUqTvZ9SMeta?.path ?? "/mx/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraDVmUqTvZ9SMeta || {},
    alias: sell_45samsaraDVmUqTvZ9SMeta?.alias || [],
    redirect: sell_45samsaraDVmUqTvZ9SMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraDVmUqTvZ9SMeta?.name ?? "resources-partner-programs-sell-samsara___fr",
    path: sell_45samsaraDVmUqTvZ9SMeta?.path ?? "/fr/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraDVmUqTvZ9SMeta || {},
    alias: sell_45samsaraDVmUqTvZ9SMeta?.alias || [],
    redirect: sell_45samsaraDVmUqTvZ9SMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraDVmUqTvZ9SMeta?.name ?? "resources-partner-programs-sell-samsara___fr-ca",
    path: sell_45samsaraDVmUqTvZ9SMeta?.path ?? "/fr-ca/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraDVmUqTvZ9SMeta || {},
    alias: sell_45samsaraDVmUqTvZ9SMeta?.alias || [],
    redirect: sell_45samsaraDVmUqTvZ9SMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: tourbk9dRmRaQEMeta?.name ?? "resources-tour___en-US",
    path: tourbk9dRmRaQEMeta?.path ?? "/resources/tour",
    meta: tourbk9dRmRaQEMeta || {},
    alias: tourbk9dRmRaQEMeta?.alias || [],
    redirect: tourbk9dRmRaQEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourbk9dRmRaQEMeta?.name ?? "resources-tour___ca",
    path: tourbk9dRmRaQEMeta?.path ?? "/ca/resources/tour",
    meta: tourbk9dRmRaQEMeta || {},
    alias: tourbk9dRmRaQEMeta?.alias || [],
    redirect: tourbk9dRmRaQEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourbk9dRmRaQEMeta?.name ?? "resources-tour___nl",
    path: tourbk9dRmRaQEMeta?.path ?? "/nl/resources/tour",
    meta: tourbk9dRmRaQEMeta || {},
    alias: tourbk9dRmRaQEMeta?.alias || [],
    redirect: tourbk9dRmRaQEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourbk9dRmRaQEMeta?.name ?? "resources-tour___uk",
    path: tourbk9dRmRaQEMeta?.path ?? "/uk/resources/tour",
    meta: tourbk9dRmRaQEMeta || {},
    alias: tourbk9dRmRaQEMeta?.alias || [],
    redirect: tourbk9dRmRaQEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourbk9dRmRaQEMeta?.name ?? "resources-tour___de",
    path: tourbk9dRmRaQEMeta?.path ?? "/de/resources/tour",
    meta: tourbk9dRmRaQEMeta || {},
    alias: tourbk9dRmRaQEMeta?.alias || [],
    redirect: tourbk9dRmRaQEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourbk9dRmRaQEMeta?.name ?? "resources-tour___mx",
    path: tourbk9dRmRaQEMeta?.path ?? "/mx/resources/tour",
    meta: tourbk9dRmRaQEMeta || {},
    alias: tourbk9dRmRaQEMeta?.alias || [],
    redirect: tourbk9dRmRaQEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourbk9dRmRaQEMeta?.name ?? "resources-tour___fr",
    path: tourbk9dRmRaQEMeta?.path ?? "/fr/resources/tour",
    meta: tourbk9dRmRaQEMeta || {},
    alias: tourbk9dRmRaQEMeta?.alias || [],
    redirect: tourbk9dRmRaQEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourbk9dRmRaQEMeta?.name ?? "resources-tour___fr-ca",
    path: tourbk9dRmRaQEMeta?.path ?? "/fr-ca/resources/tour",
    meta: tourbk9dRmRaQEMeta || {},
    alias: tourbk9dRmRaQEMeta?.alias || [],
    redirect: tourbk9dRmRaQEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: videosgx9YYsGeaZMeta?.name ?? "resources-videos___en-US",
    path: videosgx9YYsGeaZMeta?.path ?? "/resources/videos",
    meta: videosgx9YYsGeaZMeta || {},
    alias: videosgx9YYsGeaZMeta?.alias || [],
    redirect: videosgx9YYsGeaZMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosgx9YYsGeaZMeta?.name ?? "resources-videos___ca",
    path: videosgx9YYsGeaZMeta?.path ?? "/ca/resources/videos",
    meta: videosgx9YYsGeaZMeta || {},
    alias: videosgx9YYsGeaZMeta?.alias || [],
    redirect: videosgx9YYsGeaZMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosgx9YYsGeaZMeta?.name ?? "resources-videos___nl",
    path: videosgx9YYsGeaZMeta?.path ?? "/nl/resources/videos",
    meta: videosgx9YYsGeaZMeta || {},
    alias: videosgx9YYsGeaZMeta?.alias || [],
    redirect: videosgx9YYsGeaZMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosgx9YYsGeaZMeta?.name ?? "resources-videos___uk",
    path: videosgx9YYsGeaZMeta?.path ?? "/uk/resources/videos",
    meta: videosgx9YYsGeaZMeta || {},
    alias: videosgx9YYsGeaZMeta?.alias || [],
    redirect: videosgx9YYsGeaZMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosgx9YYsGeaZMeta?.name ?? "resources-videos___de",
    path: videosgx9YYsGeaZMeta?.path ?? "/de/resources/videos",
    meta: videosgx9YYsGeaZMeta || {},
    alias: videosgx9YYsGeaZMeta?.alias || [],
    redirect: videosgx9YYsGeaZMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosgx9YYsGeaZMeta?.name ?? "resources-videos___mx",
    path: videosgx9YYsGeaZMeta?.path ?? "/mx/resources/videos",
    meta: videosgx9YYsGeaZMeta || {},
    alias: videosgx9YYsGeaZMeta?.alias || [],
    redirect: videosgx9YYsGeaZMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosgx9YYsGeaZMeta?.name ?? "resources-videos___fr",
    path: videosgx9YYsGeaZMeta?.path ?? "/fr/resources/videos",
    meta: videosgx9YYsGeaZMeta || {},
    alias: videosgx9YYsGeaZMeta?.alias || [],
    redirect: videosgx9YYsGeaZMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosgx9YYsGeaZMeta?.name ?? "resources-videos___fr-ca",
    path: videosgx9YYsGeaZMeta?.path ?? "/fr-ca/resources/videos",
    meta: videosgx9YYsGeaZMeta || {},
    alias: videosgx9YYsGeaZMeta?.alias || [],
    redirect: videosgx9YYsGeaZMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: indexkJdUfzF9U1Meta?.name ?? "roi-calculator___en-US",
    path: indexkJdUfzF9U1Meta?.path ?? "/roi-calculator",
    meta: indexkJdUfzF9U1Meta || {},
    alias: indexkJdUfzF9U1Meta?.alias || [],
    redirect: indexkJdUfzF9U1Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexkJdUfzF9U1Meta?.name ?? "roi-calculator___ca",
    path: indexkJdUfzF9U1Meta?.path ?? "/ca/roi-calculator",
    meta: indexkJdUfzF9U1Meta || {},
    alias: indexkJdUfzF9U1Meta?.alias || [],
    redirect: indexkJdUfzF9U1Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexkJdUfzF9U1Meta?.name ?? "roi-calculator___nl",
    path: indexkJdUfzF9U1Meta?.path ?? "/nl/roi-calculator",
    meta: indexkJdUfzF9U1Meta || {},
    alias: indexkJdUfzF9U1Meta?.alias || [],
    redirect: indexkJdUfzF9U1Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexkJdUfzF9U1Meta?.name ?? "roi-calculator___uk",
    path: indexkJdUfzF9U1Meta?.path ?? "/uk/roi-calculator",
    meta: indexkJdUfzF9U1Meta || {},
    alias: indexkJdUfzF9U1Meta?.alias || [],
    redirect: indexkJdUfzF9U1Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexkJdUfzF9U1Meta?.name ?? "roi-calculator___de",
    path: indexkJdUfzF9U1Meta?.path ?? "/de/roi-calculator",
    meta: indexkJdUfzF9U1Meta || {},
    alias: indexkJdUfzF9U1Meta?.alias || [],
    redirect: indexkJdUfzF9U1Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexkJdUfzF9U1Meta?.name ?? "roi-calculator___mx",
    path: indexkJdUfzF9U1Meta?.path ?? "/mx/roi-calculator",
    meta: indexkJdUfzF9U1Meta || {},
    alias: indexkJdUfzF9U1Meta?.alias || [],
    redirect: indexkJdUfzF9U1Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexkJdUfzF9U1Meta?.name ?? "roi-calculator___fr",
    path: indexkJdUfzF9U1Meta?.path ?? "/fr/roi-calculator",
    meta: indexkJdUfzF9U1Meta || {},
    alias: indexkJdUfzF9U1Meta?.alias || [],
    redirect: indexkJdUfzF9U1Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexkJdUfzF9U1Meta?.name ?? "roi-calculator___fr-ca",
    path: indexkJdUfzF9U1Meta?.path ?? "/fr-ca/roi-calculator",
    meta: indexkJdUfzF9U1Meta || {},
    alias: indexkJdUfzF9U1Meta?.alias || [],
    redirect: indexkJdUfzF9U1Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesSbQknknTBDMeta?.name ?? "samsara-ventures___en-US",
    path: samsara_45venturesSbQknknTBDMeta?.path ?? "/samsara-ventures",
    meta: samsara_45venturesSbQknknTBDMeta || {},
    alias: samsara_45venturesSbQknknTBDMeta?.alias || [],
    redirect: samsara_45venturesSbQknknTBDMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesSbQknknTBDMeta?.name ?? "samsara-ventures___ca",
    path: samsara_45venturesSbQknknTBDMeta?.path ?? "/ca/samsara-ventures",
    meta: samsara_45venturesSbQknknTBDMeta || {},
    alias: samsara_45venturesSbQknknTBDMeta?.alias || [],
    redirect: samsara_45venturesSbQknknTBDMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesSbQknknTBDMeta?.name ?? "samsara-ventures___nl",
    path: samsara_45venturesSbQknknTBDMeta?.path ?? "/nl/samsara-ventures",
    meta: samsara_45venturesSbQknknTBDMeta || {},
    alias: samsara_45venturesSbQknknTBDMeta?.alias || [],
    redirect: samsara_45venturesSbQknknTBDMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesSbQknknTBDMeta?.name ?? "samsara-ventures___uk",
    path: samsara_45venturesSbQknknTBDMeta?.path ?? "/uk/samsara-ventures",
    meta: samsara_45venturesSbQknknTBDMeta || {},
    alias: samsara_45venturesSbQknknTBDMeta?.alias || [],
    redirect: samsara_45venturesSbQknknTBDMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesSbQknknTBDMeta?.name ?? "samsara-ventures___de",
    path: samsara_45venturesSbQknknTBDMeta?.path ?? "/de/samsara-ventures",
    meta: samsara_45venturesSbQknknTBDMeta || {},
    alias: samsara_45venturesSbQknknTBDMeta?.alias || [],
    redirect: samsara_45venturesSbQknknTBDMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesSbQknknTBDMeta?.name ?? "samsara-ventures___mx",
    path: samsara_45venturesSbQknknTBDMeta?.path ?? "/mx/samsara-ventures",
    meta: samsara_45venturesSbQknknTBDMeta || {},
    alias: samsara_45venturesSbQknknTBDMeta?.alias || [],
    redirect: samsara_45venturesSbQknknTBDMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesSbQknknTBDMeta?.name ?? "samsara-ventures___fr",
    path: samsara_45venturesSbQknknTBDMeta?.path ?? "/fr/samsara-ventures",
    meta: samsara_45venturesSbQknknTBDMeta || {},
    alias: samsara_45venturesSbQknknTBDMeta?.alias || [],
    redirect: samsara_45venturesSbQknknTBDMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesSbQknknTBDMeta?.name ?? "samsara-ventures___fr-ca",
    path: samsara_45venturesSbQknknTBDMeta?.path ?? "/fr-ca/samsara-ventures",
    meta: samsara_45venturesSbQknknTBDMeta || {},
    alias: samsara_45venturesSbQknknTBDMeta?.alias || [],
    redirect: samsara_45venturesSbQknknTBDMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: security_45summit7XN8t40EB9Meta?.name ?? "security-summit___en-US",
    path: security_45summit7XN8t40EB9Meta?.path ?? "/security-summit",
    meta: security_45summit7XN8t40EB9Meta || {},
    alias: security_45summit7XN8t40EB9Meta?.alias || [],
    redirect: security_45summit7XN8t40EB9Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summit7XN8t40EB9Meta?.name ?? "security-summit___ca",
    path: security_45summit7XN8t40EB9Meta?.path ?? "/ca/security-summit",
    meta: security_45summit7XN8t40EB9Meta || {},
    alias: security_45summit7XN8t40EB9Meta?.alias || [],
    redirect: security_45summit7XN8t40EB9Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summit7XN8t40EB9Meta?.name ?? "security-summit___nl",
    path: security_45summit7XN8t40EB9Meta?.path ?? "/nl/security-summit",
    meta: security_45summit7XN8t40EB9Meta || {},
    alias: security_45summit7XN8t40EB9Meta?.alias || [],
    redirect: security_45summit7XN8t40EB9Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summit7XN8t40EB9Meta?.name ?? "security-summit___uk",
    path: security_45summit7XN8t40EB9Meta?.path ?? "/uk/security-summit",
    meta: security_45summit7XN8t40EB9Meta || {},
    alias: security_45summit7XN8t40EB9Meta?.alias || [],
    redirect: security_45summit7XN8t40EB9Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summit7XN8t40EB9Meta?.name ?? "security-summit___de",
    path: security_45summit7XN8t40EB9Meta?.path ?? "/de/security-summit",
    meta: security_45summit7XN8t40EB9Meta || {},
    alias: security_45summit7XN8t40EB9Meta?.alias || [],
    redirect: security_45summit7XN8t40EB9Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summit7XN8t40EB9Meta?.name ?? "security-summit___mx",
    path: security_45summit7XN8t40EB9Meta?.path ?? "/mx/security-summit",
    meta: security_45summit7XN8t40EB9Meta || {},
    alias: security_45summit7XN8t40EB9Meta?.alias || [],
    redirect: security_45summit7XN8t40EB9Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summit7XN8t40EB9Meta?.name ?? "security-summit___fr",
    path: security_45summit7XN8t40EB9Meta?.path ?? "/fr/security-summit",
    meta: security_45summit7XN8t40EB9Meta || {},
    alias: security_45summit7XN8t40EB9Meta?.alias || [],
    redirect: security_45summit7XN8t40EB9Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summit7XN8t40EB9Meta?.name ?? "security-summit___fr-ca",
    path: security_45summit7XN8t40EB9Meta?.path ?? "/fr-ca/security-summit",
    meta: security_45summit7XN8t40EB9Meta || {},
    alias: security_45summit7XN8t40EB9Meta?.alias || [],
    redirect: security_45summit7XN8t40EB9Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EwZrfKYUtiMeta?.name ?? "solutions-slug___en-US",
    path: _91slug_93EwZrfKYUtiMeta?.path ?? "/solutions/:slug()",
    meta: _91slug_93EwZrfKYUtiMeta || {},
    alias: _91slug_93EwZrfKYUtiMeta?.alias || [],
    redirect: _91slug_93EwZrfKYUtiMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EwZrfKYUtiMeta?.name ?? "solutions-slug___ca",
    path: _91slug_93EwZrfKYUtiMeta?.path ?? "/ca/solutions/:slug()",
    meta: _91slug_93EwZrfKYUtiMeta || {},
    alias: _91slug_93EwZrfKYUtiMeta?.alias || [],
    redirect: _91slug_93EwZrfKYUtiMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EwZrfKYUtiMeta?.name ?? "solutions-slug___nl",
    path: _91slug_93EwZrfKYUtiMeta?.path ?? "/nl/solutions/:slug()",
    meta: _91slug_93EwZrfKYUtiMeta || {},
    alias: _91slug_93EwZrfKYUtiMeta?.alias || [],
    redirect: _91slug_93EwZrfKYUtiMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EwZrfKYUtiMeta?.name ?? "solutions-slug___uk",
    path: _91slug_93EwZrfKYUtiMeta?.path ?? "/uk/solutions/:slug()",
    meta: _91slug_93EwZrfKYUtiMeta || {},
    alias: _91slug_93EwZrfKYUtiMeta?.alias || [],
    redirect: _91slug_93EwZrfKYUtiMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EwZrfKYUtiMeta?.name ?? "solutions-slug___de",
    path: _91slug_93EwZrfKYUtiMeta?.path ?? "/de/solutions/:slug()",
    meta: _91slug_93EwZrfKYUtiMeta || {},
    alias: _91slug_93EwZrfKYUtiMeta?.alias || [],
    redirect: _91slug_93EwZrfKYUtiMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EwZrfKYUtiMeta?.name ?? "solutions-slug___mx",
    path: _91slug_93EwZrfKYUtiMeta?.path ?? "/mx/solutions/:slug()",
    meta: _91slug_93EwZrfKYUtiMeta || {},
    alias: _91slug_93EwZrfKYUtiMeta?.alias || [],
    redirect: _91slug_93EwZrfKYUtiMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EwZrfKYUtiMeta?.name ?? "solutions-slug___fr",
    path: _91slug_93EwZrfKYUtiMeta?.path ?? "/fr/solutions/:slug()",
    meta: _91slug_93EwZrfKYUtiMeta || {},
    alias: _91slug_93EwZrfKYUtiMeta?.alias || [],
    redirect: _91slug_93EwZrfKYUtiMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EwZrfKYUtiMeta?.name ?? "solutions-slug___fr-ca",
    path: _91slug_93EwZrfKYUtiMeta?.path ?? "/fr-ca/solutions/:slug()",
    meta: _91slug_93EwZrfKYUtiMeta || {},
    alias: _91slug_93EwZrfKYUtiMeta?.alias || [],
    redirect: _91slug_93EwZrfKYUtiMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexBu0jp0EM5jMeta?.name ?? "solutions___en-US",
    path: indexBu0jp0EM5jMeta?.path ?? "/solutions",
    meta: indexBu0jp0EM5jMeta || {},
    alias: indexBu0jp0EM5jMeta?.alias || [],
    redirect: indexBu0jp0EM5jMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexBu0jp0EM5jMeta?.name ?? "solutions___ca",
    path: indexBu0jp0EM5jMeta?.path ?? "/ca/solutions",
    meta: indexBu0jp0EM5jMeta || {},
    alias: indexBu0jp0EM5jMeta?.alias || [],
    redirect: indexBu0jp0EM5jMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexBu0jp0EM5jMeta?.name ?? "solutions___nl",
    path: indexBu0jp0EM5jMeta?.path ?? "/nl/solutions",
    meta: indexBu0jp0EM5jMeta || {},
    alias: indexBu0jp0EM5jMeta?.alias || [],
    redirect: indexBu0jp0EM5jMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexBu0jp0EM5jMeta?.name ?? "solutions___uk",
    path: indexBu0jp0EM5jMeta?.path ?? "/uk/solutions",
    meta: indexBu0jp0EM5jMeta || {},
    alias: indexBu0jp0EM5jMeta?.alias || [],
    redirect: indexBu0jp0EM5jMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexBu0jp0EM5jMeta?.name ?? "solutions___de",
    path: indexBu0jp0EM5jMeta?.path ?? "/de/solutions",
    meta: indexBu0jp0EM5jMeta || {},
    alias: indexBu0jp0EM5jMeta?.alias || [],
    redirect: indexBu0jp0EM5jMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexBu0jp0EM5jMeta?.name ?? "solutions___mx",
    path: indexBu0jp0EM5jMeta?.path ?? "/mx/solutions",
    meta: indexBu0jp0EM5jMeta || {},
    alias: indexBu0jp0EM5jMeta?.alias || [],
    redirect: indexBu0jp0EM5jMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexBu0jp0EM5jMeta?.name ?? "solutions___fr",
    path: indexBu0jp0EM5jMeta?.path ?? "/fr/solutions",
    meta: indexBu0jp0EM5jMeta || {},
    alias: indexBu0jp0EM5jMeta?.alias || [],
    redirect: indexBu0jp0EM5jMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexBu0jp0EM5jMeta?.name ?? "solutions___fr-ca",
    path: indexBu0jp0EM5jMeta?.path ?? "/fr-ca/solutions",
    meta: indexBu0jp0EM5jMeta || {},
    alias: indexBu0jp0EM5jMeta?.alias || [],
    redirect: indexBu0jp0EM5jMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: securityURWb31nu2xMeta?.name ?? "solutions-security___en-US",
    path: securityURWb31nu2xMeta?.path ?? "/solutions/security",
    meta: securityURWb31nu2xMeta || {},
    alias: securityURWb31nu2xMeta?.alias || [],
    redirect: securityURWb31nu2xMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityURWb31nu2xMeta?.name ?? "solutions-security___ca",
    path: securityURWb31nu2xMeta?.path ?? "/ca/solutions/security",
    meta: securityURWb31nu2xMeta || {},
    alias: securityURWb31nu2xMeta?.alias || [],
    redirect: securityURWb31nu2xMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityURWb31nu2xMeta?.name ?? "solutions-security___nl",
    path: securityURWb31nu2xMeta?.path ?? "/nl/solutions/security",
    meta: securityURWb31nu2xMeta || {},
    alias: securityURWb31nu2xMeta?.alias || [],
    redirect: securityURWb31nu2xMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityURWb31nu2xMeta?.name ?? "solutions-security___uk",
    path: securityURWb31nu2xMeta?.path ?? "/uk/solutions/security",
    meta: securityURWb31nu2xMeta || {},
    alias: securityURWb31nu2xMeta?.alias || [],
    redirect: securityURWb31nu2xMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityURWb31nu2xMeta?.name ?? "solutions-security___de",
    path: securityURWb31nu2xMeta?.path ?? "/de/solutions/security",
    meta: securityURWb31nu2xMeta || {},
    alias: securityURWb31nu2xMeta?.alias || [],
    redirect: securityURWb31nu2xMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityURWb31nu2xMeta?.name ?? "solutions-security___mx",
    path: securityURWb31nu2xMeta?.path ?? "/mx/soluciones/seguridad-patrimonial",
    meta: securityURWb31nu2xMeta || {},
    alias: securityURWb31nu2xMeta?.alias || [],
    redirect: securityURWb31nu2xMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityURWb31nu2xMeta?.name ?? "solutions-security___fr",
    path: securityURWb31nu2xMeta?.path ?? "/fr/solutions/security",
    meta: securityURWb31nu2xMeta || {},
    alias: securityURWb31nu2xMeta?.alias || [],
    redirect: securityURWb31nu2xMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityURWb31nu2xMeta?.name ?? "solutions-security___fr-ca",
    path: securityURWb31nu2xMeta?.path ?? "/fr-ca/solutions/security",
    meta: securityURWb31nu2xMeta || {},
    alias: securityURWb31nu2xMeta?.alias || [],
    redirect: securityURWb31nu2xMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: space8vynKz2GwqMeta?.name ?? "space___en-US",
    path: space8vynKz2GwqMeta?.path ?? "/space",
    meta: space8vynKz2GwqMeta || {},
    alias: space8vynKz2GwqMeta?.alias || [],
    redirect: space8vynKz2GwqMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: space8vynKz2GwqMeta?.name ?? "space___ca",
    path: space8vynKz2GwqMeta?.path ?? "/ca/space",
    meta: space8vynKz2GwqMeta || {},
    alias: space8vynKz2GwqMeta?.alias || [],
    redirect: space8vynKz2GwqMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: space8vynKz2GwqMeta?.name ?? "space___nl",
    path: space8vynKz2GwqMeta?.path ?? "/nl/space",
    meta: space8vynKz2GwqMeta || {},
    alias: space8vynKz2GwqMeta?.alias || [],
    redirect: space8vynKz2GwqMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: space8vynKz2GwqMeta?.name ?? "space___uk",
    path: space8vynKz2GwqMeta?.path ?? "/uk/space",
    meta: space8vynKz2GwqMeta || {},
    alias: space8vynKz2GwqMeta?.alias || [],
    redirect: space8vynKz2GwqMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: space8vynKz2GwqMeta?.name ?? "space___de",
    path: space8vynKz2GwqMeta?.path ?? "/de/space",
    meta: space8vynKz2GwqMeta || {},
    alias: space8vynKz2GwqMeta?.alias || [],
    redirect: space8vynKz2GwqMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: space8vynKz2GwqMeta?.name ?? "space___mx",
    path: space8vynKz2GwqMeta?.path ?? "/mx/space",
    meta: space8vynKz2GwqMeta || {},
    alias: space8vynKz2GwqMeta?.alias || [],
    redirect: space8vynKz2GwqMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: space8vynKz2GwqMeta?.name ?? "space___fr",
    path: space8vynKz2GwqMeta?.path ?? "/fr/space",
    meta: space8vynKz2GwqMeta || {},
    alias: space8vynKz2GwqMeta?.alias || [],
    redirect: space8vynKz2GwqMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: space8vynKz2GwqMeta?.name ?? "space___fr-ca",
    path: space8vynKz2GwqMeta?.path ?? "/fr-ca/space",
    meta: space8vynKz2GwqMeta || {},
    alias: space8vynKz2GwqMeta?.alias || [],
    redirect: space8vynKz2GwqMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: sparkF6uU47j19sMeta?.name ?? "spark___en-US",
    path: sparkF6uU47j19sMeta?.path ?? "/spark",
    meta: sparkF6uU47j19sMeta || {},
    alias: sparkF6uU47j19sMeta?.alias || [],
    redirect: sparkF6uU47j19sMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkF6uU47j19sMeta?.name ?? "spark___ca",
    path: sparkF6uU47j19sMeta?.path ?? "/ca/spark",
    meta: sparkF6uU47j19sMeta || {},
    alias: sparkF6uU47j19sMeta?.alias || [],
    redirect: sparkF6uU47j19sMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkF6uU47j19sMeta?.name ?? "spark___nl",
    path: sparkF6uU47j19sMeta?.path ?? "/nl/spark",
    meta: sparkF6uU47j19sMeta || {},
    alias: sparkF6uU47j19sMeta?.alias || [],
    redirect: sparkF6uU47j19sMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkF6uU47j19sMeta?.name ?? "spark___uk",
    path: sparkF6uU47j19sMeta?.path ?? "/uk/spark",
    meta: sparkF6uU47j19sMeta || {},
    alias: sparkF6uU47j19sMeta?.alias || [],
    redirect: sparkF6uU47j19sMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkF6uU47j19sMeta?.name ?? "spark___de",
    path: sparkF6uU47j19sMeta?.path ?? "/de/spark",
    meta: sparkF6uU47j19sMeta || {},
    alias: sparkF6uU47j19sMeta?.alias || [],
    redirect: sparkF6uU47j19sMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkF6uU47j19sMeta?.name ?? "spark___mx",
    path: sparkF6uU47j19sMeta?.path ?? "/mx/spark",
    meta: sparkF6uU47j19sMeta || {},
    alias: sparkF6uU47j19sMeta?.alias || [],
    redirect: sparkF6uU47j19sMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkF6uU47j19sMeta?.name ?? "spark___fr",
    path: sparkF6uU47j19sMeta?.path ?? "/fr/spark",
    meta: sparkF6uU47j19sMeta || {},
    alias: sparkF6uU47j19sMeta?.alias || [],
    redirect: sparkF6uU47j19sMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkF6uU47j19sMeta?.name ?? "spark___fr-ca",
    path: sparkF6uU47j19sMeta?.path ?? "/fr-ca/spark",
    meta: sparkF6uU47j19sMeta || {},
    alias: sparkF6uU47j19sMeta?.alias || [],
    redirect: sparkF6uU47j19sMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z0ICenCrNEMeta?.name ?? "styles-slug___en-US",
    path: _91slug_93z0ICenCrNEMeta?.path ?? "/styles/:slug()",
    meta: _91slug_93z0ICenCrNEMeta || {},
    alias: _91slug_93z0ICenCrNEMeta?.alias || [],
    redirect: _91slug_93z0ICenCrNEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z0ICenCrNEMeta?.name ?? "styles-slug___ca",
    path: _91slug_93z0ICenCrNEMeta?.path ?? "/ca/styles/:slug()",
    meta: _91slug_93z0ICenCrNEMeta || {},
    alias: _91slug_93z0ICenCrNEMeta?.alias || [],
    redirect: _91slug_93z0ICenCrNEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z0ICenCrNEMeta?.name ?? "styles-slug___nl",
    path: _91slug_93z0ICenCrNEMeta?.path ?? "/nl/styles/:slug()",
    meta: _91slug_93z0ICenCrNEMeta || {},
    alias: _91slug_93z0ICenCrNEMeta?.alias || [],
    redirect: _91slug_93z0ICenCrNEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z0ICenCrNEMeta?.name ?? "styles-slug___uk",
    path: _91slug_93z0ICenCrNEMeta?.path ?? "/uk/styles/:slug()",
    meta: _91slug_93z0ICenCrNEMeta || {},
    alias: _91slug_93z0ICenCrNEMeta?.alias || [],
    redirect: _91slug_93z0ICenCrNEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z0ICenCrNEMeta?.name ?? "styles-slug___de",
    path: _91slug_93z0ICenCrNEMeta?.path ?? "/de/styles/:slug()",
    meta: _91slug_93z0ICenCrNEMeta || {},
    alias: _91slug_93z0ICenCrNEMeta?.alias || [],
    redirect: _91slug_93z0ICenCrNEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z0ICenCrNEMeta?.name ?? "styles-slug___mx",
    path: _91slug_93z0ICenCrNEMeta?.path ?? "/mx/styles/:slug()",
    meta: _91slug_93z0ICenCrNEMeta || {},
    alias: _91slug_93z0ICenCrNEMeta?.alias || [],
    redirect: _91slug_93z0ICenCrNEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z0ICenCrNEMeta?.name ?? "styles-slug___fr",
    path: _91slug_93z0ICenCrNEMeta?.path ?? "/fr/styles/:slug()",
    meta: _91slug_93z0ICenCrNEMeta || {},
    alias: _91slug_93z0ICenCrNEMeta?.alias || [],
    redirect: _91slug_93z0ICenCrNEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z0ICenCrNEMeta?.name ?? "styles-slug___fr-ca",
    path: _91slug_93z0ICenCrNEMeta?.path ?? "/fr-ca/styles/:slug()",
    meta: _91slug_93z0ICenCrNEMeta || {},
    alias: _91slug_93z0ICenCrNEMeta?.alias || [],
    redirect: _91slug_93z0ICenCrNEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rviAkGUlWEMeta?.name ?? "support-prefix-slug___en-US",
    path: _91slug_93rviAkGUlWEMeta?.path ?? "/support/:prefix()/:slug()",
    meta: _91slug_93rviAkGUlWEMeta || {},
    alias: _91slug_93rviAkGUlWEMeta?.alias || [],
    redirect: _91slug_93rviAkGUlWEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rviAkGUlWEMeta?.name ?? "support-prefix-slug___ca",
    path: _91slug_93rviAkGUlWEMeta?.path ?? "/ca/support/:prefix()/:slug()",
    meta: _91slug_93rviAkGUlWEMeta || {},
    alias: _91slug_93rviAkGUlWEMeta?.alias || [],
    redirect: _91slug_93rviAkGUlWEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rviAkGUlWEMeta?.name ?? "support-prefix-slug___nl",
    path: _91slug_93rviAkGUlWEMeta?.path ?? "/nl/support/:prefix()/:slug()",
    meta: _91slug_93rviAkGUlWEMeta || {},
    alias: _91slug_93rviAkGUlWEMeta?.alias || [],
    redirect: _91slug_93rviAkGUlWEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rviAkGUlWEMeta?.name ?? "support-prefix-slug___uk",
    path: _91slug_93rviAkGUlWEMeta?.path ?? "/uk/support/:prefix()/:slug()",
    meta: _91slug_93rviAkGUlWEMeta || {},
    alias: _91slug_93rviAkGUlWEMeta?.alias || [],
    redirect: _91slug_93rviAkGUlWEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rviAkGUlWEMeta?.name ?? "support-prefix-slug___de",
    path: _91slug_93rviAkGUlWEMeta?.path ?? "/de/support/:prefix()/:slug()",
    meta: _91slug_93rviAkGUlWEMeta || {},
    alias: _91slug_93rviAkGUlWEMeta?.alias || [],
    redirect: _91slug_93rviAkGUlWEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rviAkGUlWEMeta?.name ?? "support-prefix-slug___mx",
    path: _91slug_93rviAkGUlWEMeta?.path ?? "/mx/support/:prefix()/:slug()",
    meta: _91slug_93rviAkGUlWEMeta || {},
    alias: _91slug_93rviAkGUlWEMeta?.alias || [],
    redirect: _91slug_93rviAkGUlWEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rviAkGUlWEMeta?.name ?? "support-prefix-slug___fr",
    path: _91slug_93rviAkGUlWEMeta?.path ?? "/fr/support/:prefix()/:slug()",
    meta: _91slug_93rviAkGUlWEMeta || {},
    alias: _91slug_93rviAkGUlWEMeta?.alias || [],
    redirect: _91slug_93rviAkGUlWEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rviAkGUlWEMeta?.name ?? "support-prefix-slug___fr-ca",
    path: _91slug_93rviAkGUlWEMeta?.path ?? "/fr-ca/support/:prefix()/:slug()",
    meta: _91slug_93rviAkGUlWEMeta || {},
    alias: _91slug_93rviAkGUlWEMeta?.alias || [],
    redirect: _91slug_93rviAkGUlWEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ic9x24khsgMeta?.name ?? "support-slug___en-US",
    path: _91slug_93ic9x24khsgMeta?.path ?? "/support/:slug()",
    meta: _91slug_93ic9x24khsgMeta || {},
    alias: _91slug_93ic9x24khsgMeta?.alias || [],
    redirect: _91slug_93ic9x24khsgMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ic9x24khsgMeta?.name ?? "support-slug___ca",
    path: _91slug_93ic9x24khsgMeta?.path ?? "/ca/support/:slug()",
    meta: _91slug_93ic9x24khsgMeta || {},
    alias: _91slug_93ic9x24khsgMeta?.alias || [],
    redirect: _91slug_93ic9x24khsgMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ic9x24khsgMeta?.name ?? "support-slug___nl",
    path: _91slug_93ic9x24khsgMeta?.path ?? "/nl/support/:slug()",
    meta: _91slug_93ic9x24khsgMeta || {},
    alias: _91slug_93ic9x24khsgMeta?.alias || [],
    redirect: _91slug_93ic9x24khsgMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ic9x24khsgMeta?.name ?? "support-slug___uk",
    path: _91slug_93ic9x24khsgMeta?.path ?? "/uk/support/:slug()",
    meta: _91slug_93ic9x24khsgMeta || {},
    alias: _91slug_93ic9x24khsgMeta?.alias || [],
    redirect: _91slug_93ic9x24khsgMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ic9x24khsgMeta?.name ?? "support-slug___de",
    path: _91slug_93ic9x24khsgMeta?.path ?? "/de/support/:slug()",
    meta: _91slug_93ic9x24khsgMeta || {},
    alias: _91slug_93ic9x24khsgMeta?.alias || [],
    redirect: _91slug_93ic9x24khsgMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ic9x24khsgMeta?.name ?? "support-slug___mx",
    path: _91slug_93ic9x24khsgMeta?.path ?? "/mx/support/:slug()",
    meta: _91slug_93ic9x24khsgMeta || {},
    alias: _91slug_93ic9x24khsgMeta?.alias || [],
    redirect: _91slug_93ic9x24khsgMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ic9x24khsgMeta?.name ?? "support-slug___fr",
    path: _91slug_93ic9x24khsgMeta?.path ?? "/fr/support/:slug()",
    meta: _91slug_93ic9x24khsgMeta || {},
    alias: _91slug_93ic9x24khsgMeta?.alias || [],
    redirect: _91slug_93ic9x24khsgMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ic9x24khsgMeta?.name ?? "support-slug___fr-ca",
    path: _91slug_93ic9x24khsgMeta?.path ?? "/fr-ca/support/:slug()",
    meta: _91slug_93ic9x24khsgMeta || {},
    alias: _91slug_93ic9x24khsgMeta?.alias || [],
    redirect: _91slug_93ic9x24khsgMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyYYjBWOkWEEMeta?.name ?? "support-apps-privacy-policy___en-US",
    path: apps_45privacy_45policyYYjBWOkWEEMeta?.path ?? "/support/apps-privacy-policy",
    meta: apps_45privacy_45policyYYjBWOkWEEMeta || {},
    alias: apps_45privacy_45policyYYjBWOkWEEMeta?.alias || [],
    redirect: apps_45privacy_45policyYYjBWOkWEEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyYYjBWOkWEEMeta?.name ?? "support-apps-privacy-policy___ca",
    path: apps_45privacy_45policyYYjBWOkWEEMeta?.path ?? "/ca/support/apps-privacy-policy",
    meta: apps_45privacy_45policyYYjBWOkWEEMeta || {},
    alias: apps_45privacy_45policyYYjBWOkWEEMeta?.alias || [],
    redirect: apps_45privacy_45policyYYjBWOkWEEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyYYjBWOkWEEMeta?.name ?? "support-apps-privacy-policy___nl",
    path: apps_45privacy_45policyYYjBWOkWEEMeta?.path ?? "/nl/support/apps-privacy-policy",
    meta: apps_45privacy_45policyYYjBWOkWEEMeta || {},
    alias: apps_45privacy_45policyYYjBWOkWEEMeta?.alias || [],
    redirect: apps_45privacy_45policyYYjBWOkWEEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyYYjBWOkWEEMeta?.name ?? "support-apps-privacy-policy___uk",
    path: apps_45privacy_45policyYYjBWOkWEEMeta?.path ?? "/uk/support/apps-privacy-policy",
    meta: apps_45privacy_45policyYYjBWOkWEEMeta || {},
    alias: apps_45privacy_45policyYYjBWOkWEEMeta?.alias || [],
    redirect: apps_45privacy_45policyYYjBWOkWEEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyYYjBWOkWEEMeta?.name ?? "support-apps-privacy-policy___de",
    path: apps_45privacy_45policyYYjBWOkWEEMeta?.path ?? "/de/support/apps-privacy-policy",
    meta: apps_45privacy_45policyYYjBWOkWEEMeta || {},
    alias: apps_45privacy_45policyYYjBWOkWEEMeta?.alias || [],
    redirect: apps_45privacy_45policyYYjBWOkWEEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyYYjBWOkWEEMeta?.name ?? "support-apps-privacy-policy___mx",
    path: apps_45privacy_45policyYYjBWOkWEEMeta?.path ?? "/mx/support/apps-privacy-policy",
    meta: apps_45privacy_45policyYYjBWOkWEEMeta || {},
    alias: apps_45privacy_45policyYYjBWOkWEEMeta?.alias || [],
    redirect: apps_45privacy_45policyYYjBWOkWEEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyYYjBWOkWEEMeta?.name ?? "support-apps-privacy-policy___fr",
    path: apps_45privacy_45policyYYjBWOkWEEMeta?.path ?? "/fr/support/apps-privacy-policy",
    meta: apps_45privacy_45policyYYjBWOkWEEMeta || {},
    alias: apps_45privacy_45policyYYjBWOkWEEMeta?.alias || [],
    redirect: apps_45privacy_45policyYYjBWOkWEEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyYYjBWOkWEEMeta?.name ?? "support-apps-privacy-policy___fr-ca",
    path: apps_45privacy_45policyYYjBWOkWEEMeta?.path ?? "/fr-ca/support/apps-privacy-policy",
    meta: apps_45privacy_45policyYYjBWOkWEEMeta || {},
    alias: apps_45privacy_45policyYYjBWOkWEEMeta?.alias || [],
    redirect: apps_45privacy_45policyYYjBWOkWEEMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqyGwUiJGMMcMeta?.name ?? "support-customer-referrals-faq___en-US",
    path: customer_45referrals_45faqyGwUiJGMMcMeta?.path ?? "/support/customer-referrals-faq",
    meta: customer_45referrals_45faqyGwUiJGMMcMeta || {},
    alias: customer_45referrals_45faqyGwUiJGMMcMeta?.alias || [],
    redirect: customer_45referrals_45faqyGwUiJGMMcMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqyGwUiJGMMcMeta?.name ?? "support-customer-referrals-faq___ca",
    path: customer_45referrals_45faqyGwUiJGMMcMeta?.path ?? "/ca/support/customer-referrals-faq",
    meta: customer_45referrals_45faqyGwUiJGMMcMeta || {},
    alias: customer_45referrals_45faqyGwUiJGMMcMeta?.alias || [],
    redirect: customer_45referrals_45faqyGwUiJGMMcMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqyGwUiJGMMcMeta?.name ?? "support-customer-referrals-faq___nl",
    path: customer_45referrals_45faqyGwUiJGMMcMeta?.path ?? "/nl/support/customer-referrals-faq",
    meta: customer_45referrals_45faqyGwUiJGMMcMeta || {},
    alias: customer_45referrals_45faqyGwUiJGMMcMeta?.alias || [],
    redirect: customer_45referrals_45faqyGwUiJGMMcMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqyGwUiJGMMcMeta?.name ?? "support-customer-referrals-faq___uk",
    path: customer_45referrals_45faqyGwUiJGMMcMeta?.path ?? "/uk/support/customer-referrals-faq",
    meta: customer_45referrals_45faqyGwUiJGMMcMeta || {},
    alias: customer_45referrals_45faqyGwUiJGMMcMeta?.alias || [],
    redirect: customer_45referrals_45faqyGwUiJGMMcMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqyGwUiJGMMcMeta?.name ?? "support-customer-referrals-faq___de",
    path: customer_45referrals_45faqyGwUiJGMMcMeta?.path ?? "/de/support/customer-referrals-faq",
    meta: customer_45referrals_45faqyGwUiJGMMcMeta || {},
    alias: customer_45referrals_45faqyGwUiJGMMcMeta?.alias || [],
    redirect: customer_45referrals_45faqyGwUiJGMMcMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqyGwUiJGMMcMeta?.name ?? "support-customer-referrals-faq___mx",
    path: customer_45referrals_45faqyGwUiJGMMcMeta?.path ?? "/mx/support/customer-referrals-faq",
    meta: customer_45referrals_45faqyGwUiJGMMcMeta || {},
    alias: customer_45referrals_45faqyGwUiJGMMcMeta?.alias || [],
    redirect: customer_45referrals_45faqyGwUiJGMMcMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqyGwUiJGMMcMeta?.name ?? "support-customer-referrals-faq___fr",
    path: customer_45referrals_45faqyGwUiJGMMcMeta?.path ?? "/fr/support/customer-referrals-faq",
    meta: customer_45referrals_45faqyGwUiJGMMcMeta || {},
    alias: customer_45referrals_45faqyGwUiJGMMcMeta?.alias || [],
    redirect: customer_45referrals_45faqyGwUiJGMMcMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqyGwUiJGMMcMeta?.name ?? "support-customer-referrals-faq___fr-ca",
    path: customer_45referrals_45faqyGwUiJGMMcMeta?.path ?? "/fr-ca/support/customer-referrals-faq",
    meta: customer_45referrals_45faqyGwUiJGMMcMeta || {},
    alias: customer_45referrals_45faqyGwUiJGMMcMeta?.alias || [],
    redirect: customer_45referrals_45faqyGwUiJGMMcMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanish2uuM0qPCPWMeta?.name ?? "support-driver-training-spanish___en-US",
    path: driver_45training_45spanish2uuM0qPCPWMeta?.path ?? "/support/driver-training-spanish",
    meta: driver_45training_45spanish2uuM0qPCPWMeta || {},
    alias: driver_45training_45spanish2uuM0qPCPWMeta?.alias || [],
    redirect: driver_45training_45spanish2uuM0qPCPWMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanish2uuM0qPCPWMeta?.name ?? "support-driver-training-spanish___ca",
    path: driver_45training_45spanish2uuM0qPCPWMeta?.path ?? "/ca/support/driver-training-spanish",
    meta: driver_45training_45spanish2uuM0qPCPWMeta || {},
    alias: driver_45training_45spanish2uuM0qPCPWMeta?.alias || [],
    redirect: driver_45training_45spanish2uuM0qPCPWMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanish2uuM0qPCPWMeta?.name ?? "support-driver-training-spanish___nl",
    path: driver_45training_45spanish2uuM0qPCPWMeta?.path ?? "/nl/support/driver-training-spanish",
    meta: driver_45training_45spanish2uuM0qPCPWMeta || {},
    alias: driver_45training_45spanish2uuM0qPCPWMeta?.alias || [],
    redirect: driver_45training_45spanish2uuM0qPCPWMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanish2uuM0qPCPWMeta?.name ?? "support-driver-training-spanish___uk",
    path: driver_45training_45spanish2uuM0qPCPWMeta?.path ?? "/uk/support/driver-training-spanish",
    meta: driver_45training_45spanish2uuM0qPCPWMeta || {},
    alias: driver_45training_45spanish2uuM0qPCPWMeta?.alias || [],
    redirect: driver_45training_45spanish2uuM0qPCPWMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanish2uuM0qPCPWMeta?.name ?? "support-driver-training-spanish___de",
    path: driver_45training_45spanish2uuM0qPCPWMeta?.path ?? "/de/support/driver-training-spanish",
    meta: driver_45training_45spanish2uuM0qPCPWMeta || {},
    alias: driver_45training_45spanish2uuM0qPCPWMeta?.alias || [],
    redirect: driver_45training_45spanish2uuM0qPCPWMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanish2uuM0qPCPWMeta?.name ?? "support-driver-training-spanish___mx",
    path: driver_45training_45spanish2uuM0qPCPWMeta?.path ?? "/mx/support/driver-training-spanish",
    meta: driver_45training_45spanish2uuM0qPCPWMeta || {},
    alias: driver_45training_45spanish2uuM0qPCPWMeta?.alias || [],
    redirect: driver_45training_45spanish2uuM0qPCPWMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanish2uuM0qPCPWMeta?.name ?? "support-driver-training-spanish___fr",
    path: driver_45training_45spanish2uuM0qPCPWMeta?.path ?? "/fr/support/driver-training-spanish",
    meta: driver_45training_45spanish2uuM0qPCPWMeta || {},
    alias: driver_45training_45spanish2uuM0qPCPWMeta?.alias || [],
    redirect: driver_45training_45spanish2uuM0qPCPWMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanish2uuM0qPCPWMeta?.name ?? "support-driver-training-spanish___fr-ca",
    path: driver_45training_45spanish2uuM0qPCPWMeta?.path ?? "/fr-ca/support/driver-training-spanish",
    meta: driver_45training_45spanish2uuM0qPCPWMeta || {},
    alias: driver_45training_45spanish2uuM0qPCPWMeta?.alias || [],
    redirect: driver_45training_45spanish2uuM0qPCPWMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingilKR58QBHRMeta?.name ?? "support-driver-training___en-US",
    path: driver_45trainingilKR58QBHRMeta?.path ?? "/support/driver-training",
    meta: driver_45trainingilKR58QBHRMeta || {},
    alias: driver_45trainingilKR58QBHRMeta?.alias || [],
    redirect: driver_45trainingilKR58QBHRMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingilKR58QBHRMeta?.name ?? "support-driver-training___ca",
    path: driver_45trainingilKR58QBHRMeta?.path ?? "/ca/support/driver-training",
    meta: driver_45trainingilKR58QBHRMeta || {},
    alias: driver_45trainingilKR58QBHRMeta?.alias || [],
    redirect: driver_45trainingilKR58QBHRMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingilKR58QBHRMeta?.name ?? "support-driver-training___nl",
    path: driver_45trainingilKR58QBHRMeta?.path ?? "/nl/support/driver-training",
    meta: driver_45trainingilKR58QBHRMeta || {},
    alias: driver_45trainingilKR58QBHRMeta?.alias || [],
    redirect: driver_45trainingilKR58QBHRMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingilKR58QBHRMeta?.name ?? "support-driver-training___uk",
    path: driver_45trainingilKR58QBHRMeta?.path ?? "/uk/support/driver-training",
    meta: driver_45trainingilKR58QBHRMeta || {},
    alias: driver_45trainingilKR58QBHRMeta?.alias || [],
    redirect: driver_45trainingilKR58QBHRMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingilKR58QBHRMeta?.name ?? "support-driver-training___de",
    path: driver_45trainingilKR58QBHRMeta?.path ?? "/de/support/driver-training",
    meta: driver_45trainingilKR58QBHRMeta || {},
    alias: driver_45trainingilKR58QBHRMeta?.alias || [],
    redirect: driver_45trainingilKR58QBHRMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingilKR58QBHRMeta?.name ?? "support-driver-training___mx",
    path: driver_45trainingilKR58QBHRMeta?.path ?? "/mx/support/driver-training",
    meta: driver_45trainingilKR58QBHRMeta || {},
    alias: driver_45trainingilKR58QBHRMeta?.alias || [],
    redirect: driver_45trainingilKR58QBHRMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingilKR58QBHRMeta?.name ?? "support-driver-training___fr",
    path: driver_45trainingilKR58QBHRMeta?.path ?? "/fr/support/driver-training",
    meta: driver_45trainingilKR58QBHRMeta || {},
    alias: driver_45trainingilKR58QBHRMeta?.alias || [],
    redirect: driver_45trainingilKR58QBHRMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingilKR58QBHRMeta?.name ?? "support-driver-training___fr-ca",
    path: driver_45trainingilKR58QBHRMeta?.path ?? "/fr-ca/support/driver-training",
    meta: driver_45trainingilKR58QBHRMeta || {},
    alias: driver_45trainingilKR58QBHRMeta?.alias || [],
    redirect: driver_45trainingilKR58QBHRMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: indexzNFmc5BrqhMeta?.name ?? "support___en-US",
    path: indexzNFmc5BrqhMeta?.path ?? "/support",
    meta: indexzNFmc5BrqhMeta || {},
    alias: indexzNFmc5BrqhMeta?.alias || [],
    redirect: indexzNFmc5BrqhMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexzNFmc5BrqhMeta?.name ?? "support___ca",
    path: indexzNFmc5BrqhMeta?.path ?? "/ca/support",
    meta: indexzNFmc5BrqhMeta || {},
    alias: indexzNFmc5BrqhMeta?.alias || [],
    redirect: indexzNFmc5BrqhMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexzNFmc5BrqhMeta?.name ?? "support___nl",
    path: indexzNFmc5BrqhMeta?.path ?? "/nl/support",
    meta: indexzNFmc5BrqhMeta || {},
    alias: indexzNFmc5BrqhMeta?.alias || [],
    redirect: indexzNFmc5BrqhMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexzNFmc5BrqhMeta?.name ?? "support___uk",
    path: indexzNFmc5BrqhMeta?.path ?? "/uk/support",
    meta: indexzNFmc5BrqhMeta || {},
    alias: indexzNFmc5BrqhMeta?.alias || [],
    redirect: indexzNFmc5BrqhMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexzNFmc5BrqhMeta?.name ?? "support___de",
    path: indexzNFmc5BrqhMeta?.path ?? "/de/support",
    meta: indexzNFmc5BrqhMeta || {},
    alias: indexzNFmc5BrqhMeta?.alias || [],
    redirect: indexzNFmc5BrqhMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexzNFmc5BrqhMeta?.name ?? "support___mx",
    path: indexzNFmc5BrqhMeta?.path ?? "/mx/support",
    meta: indexzNFmc5BrqhMeta || {},
    alias: indexzNFmc5BrqhMeta?.alias || [],
    redirect: indexzNFmc5BrqhMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexzNFmc5BrqhMeta?.name ?? "support___fr",
    path: indexzNFmc5BrqhMeta?.path ?? "/fr/support",
    meta: indexzNFmc5BrqhMeta || {},
    alias: indexzNFmc5BrqhMeta?.alias || [],
    redirect: indexzNFmc5BrqhMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexzNFmc5BrqhMeta?.name ?? "support___fr-ca",
    path: indexzNFmc5BrqhMeta?.path ?? "/fr-ca/support",
    meta: indexzNFmc5BrqhMeta || {},
    alias: indexzNFmc5BrqhMeta?.alias || [],
    redirect: indexzNFmc5BrqhMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: industrialTWgZEp1uj1Meta?.name ?? "support-industrial___en-US",
    path: industrialTWgZEp1uj1Meta?.path ?? "/support/industrial",
    meta: industrialTWgZEp1uj1Meta || {},
    alias: industrialTWgZEp1uj1Meta?.alias || [],
    redirect: industrialTWgZEp1uj1Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialTWgZEp1uj1Meta?.name ?? "support-industrial___ca",
    path: industrialTWgZEp1uj1Meta?.path ?? "/ca/support/industrial",
    meta: industrialTWgZEp1uj1Meta || {},
    alias: industrialTWgZEp1uj1Meta?.alias || [],
    redirect: industrialTWgZEp1uj1Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialTWgZEp1uj1Meta?.name ?? "support-industrial___nl",
    path: industrialTWgZEp1uj1Meta?.path ?? "/nl/support/industrial",
    meta: industrialTWgZEp1uj1Meta || {},
    alias: industrialTWgZEp1uj1Meta?.alias || [],
    redirect: industrialTWgZEp1uj1Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialTWgZEp1uj1Meta?.name ?? "support-industrial___uk",
    path: industrialTWgZEp1uj1Meta?.path ?? "/uk/support/industrial",
    meta: industrialTWgZEp1uj1Meta || {},
    alias: industrialTWgZEp1uj1Meta?.alias || [],
    redirect: industrialTWgZEp1uj1Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialTWgZEp1uj1Meta?.name ?? "support-industrial___de",
    path: industrialTWgZEp1uj1Meta?.path ?? "/de/support/industrial",
    meta: industrialTWgZEp1uj1Meta || {},
    alias: industrialTWgZEp1uj1Meta?.alias || [],
    redirect: industrialTWgZEp1uj1Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialTWgZEp1uj1Meta?.name ?? "support-industrial___mx",
    path: industrialTWgZEp1uj1Meta?.path ?? "/mx/support/industrial",
    meta: industrialTWgZEp1uj1Meta || {},
    alias: industrialTWgZEp1uj1Meta?.alias || [],
    redirect: industrialTWgZEp1uj1Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialTWgZEp1uj1Meta?.name ?? "support-industrial___fr",
    path: industrialTWgZEp1uj1Meta?.path ?? "/fr/support/industrial",
    meta: industrialTWgZEp1uj1Meta || {},
    alias: industrialTWgZEp1uj1Meta?.alias || [],
    redirect: industrialTWgZEp1uj1Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialTWgZEp1uj1Meta?.name ?? "support-industrial___fr-ca",
    path: industrialTWgZEp1uj1Meta?.path ?? "/fr-ca/support/industrial",
    meta: industrialTWgZEp1uj1Meta || {},
    alias: industrialTWgZEp1uj1Meta?.alias || [],
    redirect: industrialTWgZEp1uj1Meta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statement4TNMOz4HtIMeta?.name ?? "support-modern-slavery-statement___en-US",
    path: modern_45slavery_45statement4TNMOz4HtIMeta?.path ?? "/support/modern-slavery-statement",
    meta: modern_45slavery_45statement4TNMOz4HtIMeta || {},
    alias: modern_45slavery_45statement4TNMOz4HtIMeta?.alias || [],
    redirect: modern_45slavery_45statement4TNMOz4HtIMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statement4TNMOz4HtIMeta?.name ?? "support-modern-slavery-statement___ca",
    path: modern_45slavery_45statement4TNMOz4HtIMeta?.path ?? "/ca/support/modern-slavery-statement",
    meta: modern_45slavery_45statement4TNMOz4HtIMeta || {},
    alias: modern_45slavery_45statement4TNMOz4HtIMeta?.alias || [],
    redirect: modern_45slavery_45statement4TNMOz4HtIMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statement4TNMOz4HtIMeta?.name ?? "support-modern-slavery-statement___nl",
    path: modern_45slavery_45statement4TNMOz4HtIMeta?.path ?? "/nl/support/modern-slavery-statement",
    meta: modern_45slavery_45statement4TNMOz4HtIMeta || {},
    alias: modern_45slavery_45statement4TNMOz4HtIMeta?.alias || [],
    redirect: modern_45slavery_45statement4TNMOz4HtIMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statement4TNMOz4HtIMeta?.name ?? "support-modern-slavery-statement___uk",
    path: modern_45slavery_45statement4TNMOz4HtIMeta?.path ?? "/uk/support/modern-slavery-statement",
    meta: modern_45slavery_45statement4TNMOz4HtIMeta || {},
    alias: modern_45slavery_45statement4TNMOz4HtIMeta?.alias || [],
    redirect: modern_45slavery_45statement4TNMOz4HtIMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statement4TNMOz4HtIMeta?.name ?? "support-modern-slavery-statement___de",
    path: modern_45slavery_45statement4TNMOz4HtIMeta?.path ?? "/de/support/modern-slavery-statement",
    meta: modern_45slavery_45statement4TNMOz4HtIMeta || {},
    alias: modern_45slavery_45statement4TNMOz4HtIMeta?.alias || [],
    redirect: modern_45slavery_45statement4TNMOz4HtIMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statement4TNMOz4HtIMeta?.name ?? "support-modern-slavery-statement___mx",
    path: modern_45slavery_45statement4TNMOz4HtIMeta?.path ?? "/mx/support/modern-slavery-statement",
    meta: modern_45slavery_45statement4TNMOz4HtIMeta || {},
    alias: modern_45slavery_45statement4TNMOz4HtIMeta?.alias || [],
    redirect: modern_45slavery_45statement4TNMOz4HtIMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statement4TNMOz4HtIMeta?.name ?? "support-modern-slavery-statement___fr",
    path: modern_45slavery_45statement4TNMOz4HtIMeta?.path ?? "/fr/support/modern-slavery-statement",
    meta: modern_45slavery_45statement4TNMOz4HtIMeta || {},
    alias: modern_45slavery_45statement4TNMOz4HtIMeta?.alias || [],
    redirect: modern_45slavery_45statement4TNMOz4HtIMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statement4TNMOz4HtIMeta?.name ?? "support-modern-slavery-statement___fr-ca",
    path: modern_45slavery_45statement4TNMOz4HtIMeta?.path ?? "/fr-ca/support/modern-slavery-statement",
    meta: modern_45slavery_45statement4TNMOz4HtIMeta || {},
    alias: modern_45slavery_45statement4TNMOz4HtIMeta?.alias || [],
    redirect: modern_45slavery_45statement4TNMOz4HtIMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyGE979YShNJMeta?.name ?? "support-privacy-apps-privacy-policy___en-US",
    path: apps_45privacy_45policyGE979YShNJMeta?.path ?? "/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyGE979YShNJMeta || {},
    alias: apps_45privacy_45policyGE979YShNJMeta?.alias || [],
    redirect: apps_45privacy_45policyGE979YShNJMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyGE979YShNJMeta?.name ?? "support-privacy-apps-privacy-policy___ca",
    path: apps_45privacy_45policyGE979YShNJMeta?.path ?? "/ca/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyGE979YShNJMeta || {},
    alias: apps_45privacy_45policyGE979YShNJMeta?.alias || [],
    redirect: apps_45privacy_45policyGE979YShNJMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyGE979YShNJMeta?.name ?? "support-privacy-apps-privacy-policy___nl",
    path: apps_45privacy_45policyGE979YShNJMeta?.path ?? "/nl/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyGE979YShNJMeta || {},
    alias: apps_45privacy_45policyGE979YShNJMeta?.alias || [],
    redirect: apps_45privacy_45policyGE979YShNJMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyGE979YShNJMeta?.name ?? "support-privacy-apps-privacy-policy___uk",
    path: apps_45privacy_45policyGE979YShNJMeta?.path ?? "/uk/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyGE979YShNJMeta || {},
    alias: apps_45privacy_45policyGE979YShNJMeta?.alias || [],
    redirect: apps_45privacy_45policyGE979YShNJMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyGE979YShNJMeta?.name ?? "support-privacy-apps-privacy-policy___de",
    path: apps_45privacy_45policyGE979YShNJMeta?.path ?? "/de/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyGE979YShNJMeta || {},
    alias: apps_45privacy_45policyGE979YShNJMeta?.alias || [],
    redirect: apps_45privacy_45policyGE979YShNJMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyGE979YShNJMeta?.name ?? "support-privacy-apps-privacy-policy___mx",
    path: apps_45privacy_45policyGE979YShNJMeta?.path ?? "/mx/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyGE979YShNJMeta || {},
    alias: apps_45privacy_45policyGE979YShNJMeta?.alias || [],
    redirect: apps_45privacy_45policyGE979YShNJMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyGE979YShNJMeta?.name ?? "support-privacy-apps-privacy-policy___fr",
    path: apps_45privacy_45policyGE979YShNJMeta?.path ?? "/fr/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyGE979YShNJMeta || {},
    alias: apps_45privacy_45policyGE979YShNJMeta?.alias || [],
    redirect: apps_45privacy_45policyGE979YShNJMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyGE979YShNJMeta?.name ?? "support-privacy-apps-privacy-policy___fr-ca",
    path: apps_45privacy_45policyGE979YShNJMeta?.path ?? "/fr-ca/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyGE979YShNJMeta || {},
    alias: apps_45privacy_45policyGE979YShNJMeta?.alias || [],
    redirect: apps_45privacy_45policyGE979YShNJMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: indexrqcvbeoe2KMeta?.name ?? "support-privacy___en-US",
    path: indexrqcvbeoe2KMeta?.path ?? "/support/privacy",
    meta: indexrqcvbeoe2KMeta || {},
    alias: indexrqcvbeoe2KMeta?.alias || [],
    redirect: indexrqcvbeoe2KMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexrqcvbeoe2KMeta?.name ?? "support-privacy___ca",
    path: indexrqcvbeoe2KMeta?.path ?? "/ca/support/privacy",
    meta: indexrqcvbeoe2KMeta || {},
    alias: indexrqcvbeoe2KMeta?.alias || [],
    redirect: indexrqcvbeoe2KMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexrqcvbeoe2KMeta?.name ?? "support-privacy___nl",
    path: indexrqcvbeoe2KMeta?.path ?? "/nl/support/privacy",
    meta: indexrqcvbeoe2KMeta || {},
    alias: indexrqcvbeoe2KMeta?.alias || [],
    redirect: indexrqcvbeoe2KMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexrqcvbeoe2KMeta?.name ?? "support-privacy___uk",
    path: indexrqcvbeoe2KMeta?.path ?? "/uk/support/privacy",
    meta: indexrqcvbeoe2KMeta || {},
    alias: indexrqcvbeoe2KMeta?.alias || [],
    redirect: indexrqcvbeoe2KMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexrqcvbeoe2KMeta?.name ?? "support-privacy___de",
    path: indexrqcvbeoe2KMeta?.path ?? "/de/support/privacy",
    meta: indexrqcvbeoe2KMeta || {},
    alias: indexrqcvbeoe2KMeta?.alias || [],
    redirect: indexrqcvbeoe2KMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexrqcvbeoe2KMeta?.name ?? "support-privacy___mx",
    path: indexrqcvbeoe2KMeta?.path ?? "/mx/support/privacy",
    meta: indexrqcvbeoe2KMeta || {},
    alias: indexrqcvbeoe2KMeta?.alias || [],
    redirect: indexrqcvbeoe2KMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexrqcvbeoe2KMeta?.name ?? "support-privacy___fr",
    path: indexrqcvbeoe2KMeta?.path ?? "/fr/support/privacy",
    meta: indexrqcvbeoe2KMeta || {},
    alias: indexrqcvbeoe2KMeta?.alias || [],
    redirect: indexrqcvbeoe2KMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexrqcvbeoe2KMeta?.name ?? "support-privacy___fr-ca",
    path: indexrqcvbeoe2KMeta?.path ?? "/fr-ca/support/privacy",
    meta: indexrqcvbeoe2KMeta || {},
    alias: indexrqcvbeoe2KMeta?.alias || [],
    redirect: indexrqcvbeoe2KMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresoSgXkFlwWzMeta?.name ?? "support-privacy-special-features___en-US",
    path: special_45featuresoSgXkFlwWzMeta?.path ?? "/support/privacy/special-features",
    meta: special_45featuresoSgXkFlwWzMeta || {},
    alias: special_45featuresoSgXkFlwWzMeta?.alias || [],
    redirect: special_45featuresoSgXkFlwWzMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresoSgXkFlwWzMeta?.name ?? "support-privacy-special-features___ca",
    path: special_45featuresoSgXkFlwWzMeta?.path ?? "/ca/support/privacy/special-features",
    meta: special_45featuresoSgXkFlwWzMeta || {},
    alias: special_45featuresoSgXkFlwWzMeta?.alias || [],
    redirect: special_45featuresoSgXkFlwWzMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresoSgXkFlwWzMeta?.name ?? "support-privacy-special-features___nl",
    path: special_45featuresoSgXkFlwWzMeta?.path ?? "/nl/support/privacy/special-features",
    meta: special_45featuresoSgXkFlwWzMeta || {},
    alias: special_45featuresoSgXkFlwWzMeta?.alias || [],
    redirect: special_45featuresoSgXkFlwWzMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresoSgXkFlwWzMeta?.name ?? "support-privacy-special-features___uk",
    path: special_45featuresoSgXkFlwWzMeta?.path ?? "/uk/support/privacy/special-features",
    meta: special_45featuresoSgXkFlwWzMeta || {},
    alias: special_45featuresoSgXkFlwWzMeta?.alias || [],
    redirect: special_45featuresoSgXkFlwWzMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresoSgXkFlwWzMeta?.name ?? "support-privacy-special-features___de",
    path: special_45featuresoSgXkFlwWzMeta?.path ?? "/de/support/privacy/special-features",
    meta: special_45featuresoSgXkFlwWzMeta || {},
    alias: special_45featuresoSgXkFlwWzMeta?.alias || [],
    redirect: special_45featuresoSgXkFlwWzMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresoSgXkFlwWzMeta?.name ?? "support-privacy-special-features___mx",
    path: special_45featuresoSgXkFlwWzMeta?.path ?? "/mx/support/privacy/special-features",
    meta: special_45featuresoSgXkFlwWzMeta || {},
    alias: special_45featuresoSgXkFlwWzMeta?.alias || [],
    redirect: special_45featuresoSgXkFlwWzMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresoSgXkFlwWzMeta?.name ?? "support-privacy-special-features___fr",
    path: special_45featuresoSgXkFlwWzMeta?.path ?? "/fr/support/privacy/special-features",
    meta: special_45featuresoSgXkFlwWzMeta || {},
    alias: special_45featuresoSgXkFlwWzMeta?.alias || [],
    redirect: special_45featuresoSgXkFlwWzMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresoSgXkFlwWzMeta?.name ?? "support-privacy-special-features___fr-ca",
    path: special_45featuresoSgXkFlwWzMeta?.path ?? "/fr-ca/support/privacy/special-features",
    meta: special_45featuresoSgXkFlwWzMeta || {},
    alias: special_45featuresoSgXkFlwWzMeta?.alias || [],
    redirect: special_45featuresoSgXkFlwWzMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listPc598awrhIMeta?.name ?? "support-privacy-subprocessor-list___en-US",
    path: subprocessor_45listPc598awrhIMeta?.path ?? "/support/privacy/subprocessor-list",
    meta: subprocessor_45listPc598awrhIMeta || {},
    alias: subprocessor_45listPc598awrhIMeta?.alias || [],
    redirect: subprocessor_45listPc598awrhIMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listPc598awrhIMeta?.name ?? "support-privacy-subprocessor-list___ca",
    path: subprocessor_45listPc598awrhIMeta?.path ?? "/ca/support/privacy/subprocessor-list",
    meta: subprocessor_45listPc598awrhIMeta || {},
    alias: subprocessor_45listPc598awrhIMeta?.alias || [],
    redirect: subprocessor_45listPc598awrhIMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listPc598awrhIMeta?.name ?? "support-privacy-subprocessor-list___nl",
    path: subprocessor_45listPc598awrhIMeta?.path ?? "/nl/support/privacy/subprocessor-list",
    meta: subprocessor_45listPc598awrhIMeta || {},
    alias: subprocessor_45listPc598awrhIMeta?.alias || [],
    redirect: subprocessor_45listPc598awrhIMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listPc598awrhIMeta?.name ?? "support-privacy-subprocessor-list___uk",
    path: subprocessor_45listPc598awrhIMeta?.path ?? "/uk/support/privacy/subprocessor-list",
    meta: subprocessor_45listPc598awrhIMeta || {},
    alias: subprocessor_45listPc598awrhIMeta?.alias || [],
    redirect: subprocessor_45listPc598awrhIMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listPc598awrhIMeta?.name ?? "support-privacy-subprocessor-list___de",
    path: subprocessor_45listPc598awrhIMeta?.path ?? "/de/support/privacy/subprocessor-list",
    meta: subprocessor_45listPc598awrhIMeta || {},
    alias: subprocessor_45listPc598awrhIMeta?.alias || [],
    redirect: subprocessor_45listPc598awrhIMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listPc598awrhIMeta?.name ?? "support-privacy-subprocessor-list___mx",
    path: subprocessor_45listPc598awrhIMeta?.path ?? "/mx/support/privacy/subprocessor-list",
    meta: subprocessor_45listPc598awrhIMeta || {},
    alias: subprocessor_45listPc598awrhIMeta?.alias || [],
    redirect: subprocessor_45listPc598awrhIMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listPc598awrhIMeta?.name ?? "support-privacy-subprocessor-list___fr",
    path: subprocessor_45listPc598awrhIMeta?.path ?? "/fr/support/privacy/subprocessor-list",
    meta: subprocessor_45listPc598awrhIMeta || {},
    alias: subprocessor_45listPc598awrhIMeta?.alias || [],
    redirect: subprocessor_45listPc598awrhIMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listPc598awrhIMeta?.name ?? "support-privacy-subprocessor-list___fr-ca",
    path: subprocessor_45listPc598awrhIMeta?.path ?? "/fr-ca/support/privacy/subprocessor-list",
    meta: subprocessor_45listPc598awrhIMeta || {},
    alias: subprocessor_45listPc598awrhIMeta?.alias || [],
    redirect: subprocessor_45listPc598awrhIMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesU9jqRf37VoMeta?.name ?? "support-support-kb-articles___en-US",
    path: support_45kb_45articlesU9jqRf37VoMeta?.path ?? "/support/support-kb-articles",
    meta: support_45kb_45articlesU9jqRf37VoMeta || {},
    alias: support_45kb_45articlesU9jqRf37VoMeta?.alias || [],
    redirect: support_45kb_45articlesU9jqRf37VoMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesU9jqRf37VoMeta?.name ?? "support-support-kb-articles___ca",
    path: support_45kb_45articlesU9jqRf37VoMeta?.path ?? "/ca/support/support-kb-articles",
    meta: support_45kb_45articlesU9jqRf37VoMeta || {},
    alias: support_45kb_45articlesU9jqRf37VoMeta?.alias || [],
    redirect: support_45kb_45articlesU9jqRf37VoMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesU9jqRf37VoMeta?.name ?? "support-support-kb-articles___nl",
    path: support_45kb_45articlesU9jqRf37VoMeta?.path ?? "/nl/support/support-kb-articles",
    meta: support_45kb_45articlesU9jqRf37VoMeta || {},
    alias: support_45kb_45articlesU9jqRf37VoMeta?.alias || [],
    redirect: support_45kb_45articlesU9jqRf37VoMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesU9jqRf37VoMeta?.name ?? "support-support-kb-articles___uk",
    path: support_45kb_45articlesU9jqRf37VoMeta?.path ?? "/uk/support/support-kb-articles",
    meta: support_45kb_45articlesU9jqRf37VoMeta || {},
    alias: support_45kb_45articlesU9jqRf37VoMeta?.alias || [],
    redirect: support_45kb_45articlesU9jqRf37VoMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesU9jqRf37VoMeta?.name ?? "support-support-kb-articles___de",
    path: support_45kb_45articlesU9jqRf37VoMeta?.path ?? "/de/support/support-kb-articles",
    meta: support_45kb_45articlesU9jqRf37VoMeta || {},
    alias: support_45kb_45articlesU9jqRf37VoMeta?.alias || [],
    redirect: support_45kb_45articlesU9jqRf37VoMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesU9jqRf37VoMeta?.name ?? "support-support-kb-articles___mx",
    path: support_45kb_45articlesU9jqRf37VoMeta?.path ?? "/mx/support/support-kb-articles",
    meta: support_45kb_45articlesU9jqRf37VoMeta || {},
    alias: support_45kb_45articlesU9jqRf37VoMeta?.alias || [],
    redirect: support_45kb_45articlesU9jqRf37VoMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesU9jqRf37VoMeta?.name ?? "support-support-kb-articles___fr",
    path: support_45kb_45articlesU9jqRf37VoMeta?.path ?? "/fr/support/support-kb-articles",
    meta: support_45kb_45articlesU9jqRf37VoMeta || {},
    alias: support_45kb_45articlesU9jqRf37VoMeta?.alias || [],
    redirect: support_45kb_45articlesU9jqRf37VoMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesU9jqRf37VoMeta?.name ?? "support-support-kb-articles___fr-ca",
    path: support_45kb_45articlesU9jqRf37VoMeta?.path ?? "/fr-ca/support/support-kb-articles",
    meta: support_45kb_45articlesU9jqRf37VoMeta || {},
    alias: support_45kb_45articlesU9jqRf37VoMeta?.alias || [],
    redirect: support_45kb_45articlesU9jqRf37VoMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagensUAr9uEsFMeta?.name ?? "thank-you-page___en-US",
    path: thank_45you_45pagensUAr9uEsFMeta?.path ?? "/thank-you-page",
    meta: thank_45you_45pagensUAr9uEsFMeta || {},
    alias: thank_45you_45pagensUAr9uEsFMeta?.alias || [],
    redirect: thank_45you_45pagensUAr9uEsFMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagensUAr9uEsFMeta?.name ?? "thank-you-page___ca",
    path: thank_45you_45pagensUAr9uEsFMeta?.path ?? "/ca/thank-you-page",
    meta: thank_45you_45pagensUAr9uEsFMeta || {},
    alias: thank_45you_45pagensUAr9uEsFMeta?.alias || [],
    redirect: thank_45you_45pagensUAr9uEsFMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagensUAr9uEsFMeta?.name ?? "thank-you-page___nl",
    path: thank_45you_45pagensUAr9uEsFMeta?.path ?? "/nl/thank-you-page",
    meta: thank_45you_45pagensUAr9uEsFMeta || {},
    alias: thank_45you_45pagensUAr9uEsFMeta?.alias || [],
    redirect: thank_45you_45pagensUAr9uEsFMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagensUAr9uEsFMeta?.name ?? "thank-you-page___uk",
    path: thank_45you_45pagensUAr9uEsFMeta?.path ?? "/uk/thank-you-page",
    meta: thank_45you_45pagensUAr9uEsFMeta || {},
    alias: thank_45you_45pagensUAr9uEsFMeta?.alias || [],
    redirect: thank_45you_45pagensUAr9uEsFMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagensUAr9uEsFMeta?.name ?? "thank-you-page___de",
    path: thank_45you_45pagensUAr9uEsFMeta?.path ?? "/de/thank-you-page",
    meta: thank_45you_45pagensUAr9uEsFMeta || {},
    alias: thank_45you_45pagensUAr9uEsFMeta?.alias || [],
    redirect: thank_45you_45pagensUAr9uEsFMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagensUAr9uEsFMeta?.name ?? "thank-you-page___mx",
    path: thank_45you_45pagensUAr9uEsFMeta?.path ?? "/mx/thank-you-page",
    meta: thank_45you_45pagensUAr9uEsFMeta || {},
    alias: thank_45you_45pagensUAr9uEsFMeta?.alias || [],
    redirect: thank_45you_45pagensUAr9uEsFMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagensUAr9uEsFMeta?.name ?? "thank-you-page___fr",
    path: thank_45you_45pagensUAr9uEsFMeta?.path ?? "/fr/thank-you-page",
    meta: thank_45you_45pagensUAr9uEsFMeta || {},
    alias: thank_45you_45pagensUAr9uEsFMeta?.alias || [],
    redirect: thank_45you_45pagensUAr9uEsFMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagensUAr9uEsFMeta?.name ?? "thank-you-page___fr-ca",
    path: thank_45you_45pagensUAr9uEsFMeta?.path ?? "/fr-ca/thank-you-page",
    meta: thank_45you_45pagensUAr9uEsFMeta || {},
    alias: thank_45you_45pagensUAr9uEsFMeta?.alias || [],
    redirect: thank_45you_45pagensUAr9uEsFMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitinggPXFvzTEgRMeta?.name ?? "thankyou-recruiting___en-US",
    path: thankyou_45recruitinggPXFvzTEgRMeta?.path ?? "/thankyou-recruiting",
    meta: thankyou_45recruitinggPXFvzTEgRMeta || {},
    alias: thankyou_45recruitinggPXFvzTEgRMeta?.alias || [],
    redirect: thankyou_45recruitinggPXFvzTEgRMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitinggPXFvzTEgRMeta?.name ?? "thankyou-recruiting___ca",
    path: thankyou_45recruitinggPXFvzTEgRMeta?.path ?? "/ca/thankyou-recruiting",
    meta: thankyou_45recruitinggPXFvzTEgRMeta || {},
    alias: thankyou_45recruitinggPXFvzTEgRMeta?.alias || [],
    redirect: thankyou_45recruitinggPXFvzTEgRMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitinggPXFvzTEgRMeta?.name ?? "thankyou-recruiting___nl",
    path: thankyou_45recruitinggPXFvzTEgRMeta?.path ?? "/nl/thankyou-recruiting",
    meta: thankyou_45recruitinggPXFvzTEgRMeta || {},
    alias: thankyou_45recruitinggPXFvzTEgRMeta?.alias || [],
    redirect: thankyou_45recruitinggPXFvzTEgRMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitinggPXFvzTEgRMeta?.name ?? "thankyou-recruiting___uk",
    path: thankyou_45recruitinggPXFvzTEgRMeta?.path ?? "/uk/thankyou-recruiting",
    meta: thankyou_45recruitinggPXFvzTEgRMeta || {},
    alias: thankyou_45recruitinggPXFvzTEgRMeta?.alias || [],
    redirect: thankyou_45recruitinggPXFvzTEgRMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitinggPXFvzTEgRMeta?.name ?? "thankyou-recruiting___de",
    path: thankyou_45recruitinggPXFvzTEgRMeta?.path ?? "/de/thankyou-recruiting",
    meta: thankyou_45recruitinggPXFvzTEgRMeta || {},
    alias: thankyou_45recruitinggPXFvzTEgRMeta?.alias || [],
    redirect: thankyou_45recruitinggPXFvzTEgRMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitinggPXFvzTEgRMeta?.name ?? "thankyou-recruiting___mx",
    path: thankyou_45recruitinggPXFvzTEgRMeta?.path ?? "/mx/thankyou-recruiting",
    meta: thankyou_45recruitinggPXFvzTEgRMeta || {},
    alias: thankyou_45recruitinggPXFvzTEgRMeta?.alias || [],
    redirect: thankyou_45recruitinggPXFvzTEgRMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitinggPXFvzTEgRMeta?.name ?? "thankyou-recruiting___fr",
    path: thankyou_45recruitinggPXFvzTEgRMeta?.path ?? "/fr/thankyou-recruiting",
    meta: thankyou_45recruitinggPXFvzTEgRMeta || {},
    alias: thankyou_45recruitinggPXFvzTEgRMeta?.alias || [],
    redirect: thankyou_45recruitinggPXFvzTEgRMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitinggPXFvzTEgRMeta?.name ?? "thankyou-recruiting___fr-ca",
    path: thankyou_45recruitinggPXFvzTEgRMeta?.path ?? "/fr-ca/thankyou-recruiting",
    meta: thankyou_45recruitinggPXFvzTEgRMeta || {},
    alias: thankyou_45recruitinggPXFvzTEgRMeta?.alias || [],
    redirect: thankyou_45recruitinggPXFvzTEgRMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralKXkdOVYLAHMeta?.name ?? "thankyou-referral___en-US",
    path: thankyou_45referralKXkdOVYLAHMeta?.path ?? "/thankyou-referral",
    meta: thankyou_45referralKXkdOVYLAHMeta || {},
    alias: thankyou_45referralKXkdOVYLAHMeta?.alias || [],
    redirect: thankyou_45referralKXkdOVYLAHMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralKXkdOVYLAHMeta?.name ?? "thankyou-referral___ca",
    path: thankyou_45referralKXkdOVYLAHMeta?.path ?? "/ca/thankyou-referral",
    meta: thankyou_45referralKXkdOVYLAHMeta || {},
    alias: thankyou_45referralKXkdOVYLAHMeta?.alias || [],
    redirect: thankyou_45referralKXkdOVYLAHMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralKXkdOVYLAHMeta?.name ?? "thankyou-referral___nl",
    path: thankyou_45referralKXkdOVYLAHMeta?.path ?? "/nl/thankyou-referral",
    meta: thankyou_45referralKXkdOVYLAHMeta || {},
    alias: thankyou_45referralKXkdOVYLAHMeta?.alias || [],
    redirect: thankyou_45referralKXkdOVYLAHMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralKXkdOVYLAHMeta?.name ?? "thankyou-referral___uk",
    path: thankyou_45referralKXkdOVYLAHMeta?.path ?? "/uk/thankyou-referral",
    meta: thankyou_45referralKXkdOVYLAHMeta || {},
    alias: thankyou_45referralKXkdOVYLAHMeta?.alias || [],
    redirect: thankyou_45referralKXkdOVYLAHMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralKXkdOVYLAHMeta?.name ?? "thankyou-referral___de",
    path: thankyou_45referralKXkdOVYLAHMeta?.path ?? "/de/thankyou-referral",
    meta: thankyou_45referralKXkdOVYLAHMeta || {},
    alias: thankyou_45referralKXkdOVYLAHMeta?.alias || [],
    redirect: thankyou_45referralKXkdOVYLAHMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralKXkdOVYLAHMeta?.name ?? "thankyou-referral___mx",
    path: thankyou_45referralKXkdOVYLAHMeta?.path ?? "/mx/thankyou-referral",
    meta: thankyou_45referralKXkdOVYLAHMeta || {},
    alias: thankyou_45referralKXkdOVYLAHMeta?.alias || [],
    redirect: thankyou_45referralKXkdOVYLAHMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralKXkdOVYLAHMeta?.name ?? "thankyou-referral___fr",
    path: thankyou_45referralKXkdOVYLAHMeta?.path ?? "/fr/thankyou-referral",
    meta: thankyou_45referralKXkdOVYLAHMeta || {},
    alias: thankyou_45referralKXkdOVYLAHMeta?.alias || [],
    redirect: thankyou_45referralKXkdOVYLAHMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralKXkdOVYLAHMeta?.name ?? "thankyou-referral___fr-ca",
    path: thankyou_45referralKXkdOVYLAHMeta?.path ?? "/fr-ca/thankyou-referral",
    meta: thankyou_45referralKXkdOVYLAHMeta || {},
    alias: thankyou_45referralKXkdOVYLAHMeta?.alias || [],
    redirect: thankyou_45referralKXkdOVYLAHMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyouF4fZx7KasyMeta?.name ?? "thankyou___en-US",
    path: thankyouF4fZx7KasyMeta?.path ?? "/thankyou",
    meta: thankyouF4fZx7KasyMeta || {},
    alias: thankyouF4fZx7KasyMeta?.alias || [],
    redirect: thankyouF4fZx7KasyMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouF4fZx7KasyMeta?.name ?? "thankyou___ca",
    path: thankyouF4fZx7KasyMeta?.path ?? "/ca/thankyou",
    meta: thankyouF4fZx7KasyMeta || {},
    alias: thankyouF4fZx7KasyMeta?.alias || [],
    redirect: thankyouF4fZx7KasyMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouF4fZx7KasyMeta?.name ?? "thankyou___nl",
    path: thankyouF4fZx7KasyMeta?.path ?? "/nl/thankyou",
    meta: thankyouF4fZx7KasyMeta || {},
    alias: thankyouF4fZx7KasyMeta?.alias || [],
    redirect: thankyouF4fZx7KasyMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouF4fZx7KasyMeta?.name ?? "thankyou___uk",
    path: thankyouF4fZx7KasyMeta?.path ?? "/uk/thankyou",
    meta: thankyouF4fZx7KasyMeta || {},
    alias: thankyouF4fZx7KasyMeta?.alias || [],
    redirect: thankyouF4fZx7KasyMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouF4fZx7KasyMeta?.name ?? "thankyou___de",
    path: thankyouF4fZx7KasyMeta?.path ?? "/de/thankyou",
    meta: thankyouF4fZx7KasyMeta || {},
    alias: thankyouF4fZx7KasyMeta?.alias || [],
    redirect: thankyouF4fZx7KasyMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouF4fZx7KasyMeta?.name ?? "thankyou___mx",
    path: thankyouF4fZx7KasyMeta?.path ?? "/mx/thankyou",
    meta: thankyouF4fZx7KasyMeta || {},
    alias: thankyouF4fZx7KasyMeta?.alias || [],
    redirect: thankyouF4fZx7KasyMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouF4fZx7KasyMeta?.name ?? "thankyou___fr",
    path: thankyouF4fZx7KasyMeta?.path ?? "/fr/thankyou",
    meta: thankyouF4fZx7KasyMeta || {},
    alias: thankyouF4fZx7KasyMeta?.alias || [],
    redirect: thankyouF4fZx7KasyMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouF4fZx7KasyMeta?.name ?? "thankyou___fr-ca",
    path: thankyouF4fZx7KasyMeta?.path ?? "/fr-ca/thankyou",
    meta: thankyouF4fZx7KasyMeta || {},
    alias: thankyouF4fZx7KasyMeta?.alias || [],
    redirect: thankyouF4fZx7KasyMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: elitejilMufutQPMeta?.name ?? "tiers-elite___uk",
    path: elitejilMufutQPMeta?.path ?? "/uk/tiers/elite",
    meta: elitejilMufutQPMeta || {},
    alias: elitejilMufutQPMeta?.alias || [],
    redirect: elitejilMufutQPMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/tiers/elite.vue").then(m => m.default || m)
  },
  {
    name: elitejilMufutQPMeta?.name ?? "tiers-elite___en-US",
    path: elitejilMufutQPMeta?.path ?? "/tiers/elite",
    meta: elitejilMufutQPMeta || {},
    alias: elitejilMufutQPMeta?.alias || [],
    redirect: elitejilMufutQPMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/tiers/elite.vue").then(m => m.default || m)
  },
  {
    name: elitejilMufutQPMeta?.name ?? "tiers-elite___ca",
    path: elitejilMufutQPMeta?.path ?? "/ca/tiers/elite",
    meta: elitejilMufutQPMeta || {},
    alias: elitejilMufutQPMeta?.alias || [],
    redirect: elitejilMufutQPMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/tiers/elite.vue").then(m => m.default || m)
  },
  {
    name: plusVbSStCmBOcMeta?.name ?? "tiers-plus___uk",
    path: plusVbSStCmBOcMeta?.path ?? "/uk/tiers/plus",
    meta: plusVbSStCmBOcMeta || {},
    alias: plusVbSStCmBOcMeta?.alias || [],
    redirect: plusVbSStCmBOcMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/tiers/plus.vue").then(m => m.default || m)
  },
  {
    name: plusVbSStCmBOcMeta?.name ?? "tiers-plus___en-US",
    path: plusVbSStCmBOcMeta?.path ?? "/tiers/plus",
    meta: plusVbSStCmBOcMeta || {},
    alias: plusVbSStCmBOcMeta?.alias || [],
    redirect: plusVbSStCmBOcMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/tiers/plus.vue").then(m => m.default || m)
  },
  {
    name: plusVbSStCmBOcMeta?.name ?? "tiers-plus___ca",
    path: plusVbSStCmBOcMeta?.path ?? "/ca/tiers/plus",
    meta: plusVbSStCmBOcMeta || {},
    alias: plusVbSStCmBOcMeta?.alias || [],
    redirect: plusVbSStCmBOcMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/tiers/plus.vue").then(m => m.default || m)
  },
  {
    name: premierlbT2V8Hv5FMeta?.name ?? "tiers-premier___uk",
    path: premierlbT2V8Hv5FMeta?.path ?? "/uk/tiers/premier",
    meta: premierlbT2V8Hv5FMeta || {},
    alias: premierlbT2V8Hv5FMeta?.alias || [],
    redirect: premierlbT2V8Hv5FMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/tiers/premier.vue").then(m => m.default || m)
  },
  {
    name: premierlbT2V8Hv5FMeta?.name ?? "tiers-premier___en-US",
    path: premierlbT2V8Hv5FMeta?.path ?? "/tiers/premier",
    meta: premierlbT2V8Hv5FMeta || {},
    alias: premierlbT2V8Hv5FMeta?.alias || [],
    redirect: premierlbT2V8Hv5FMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/tiers/premier.vue").then(m => m.default || m)
  },
  {
    name: premierlbT2V8Hv5FMeta?.name ?? "tiers-premier___ca",
    path: premierlbT2V8Hv5FMeta?.path ?? "/ca/tiers/premier",
    meta: premierlbT2V8Hv5FMeta || {},
    alias: premierlbT2V8Hv5FMeta?.alias || [],
    redirect: premierlbT2V8Hv5FMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/tiers/premier.vue").then(m => m.default || m)
  },
  {
    name: starter4c4mJaQyhjMeta?.name ?? "tiers-starter___uk",
    path: starter4c4mJaQyhjMeta?.path ?? "/uk/tiers/starter",
    meta: starter4c4mJaQyhjMeta || {},
    alias: starter4c4mJaQyhjMeta?.alias || [],
    redirect: starter4c4mJaQyhjMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/tiers/starter.vue").then(m => m.default || m)
  },
  {
    name: starter4c4mJaQyhjMeta?.name ?? "tiers-starter___en-US",
    path: starter4c4mJaQyhjMeta?.path ?? "/tiers/starter",
    meta: starter4c4mJaQyhjMeta || {},
    alias: starter4c4mJaQyhjMeta?.alias || [],
    redirect: starter4c4mJaQyhjMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/tiers/starter.vue").then(m => m.default || m)
  },
  {
    name: starter4c4mJaQyhjMeta?.name ?? "tiers-starter___ca",
    path: starter4c4mJaQyhjMeta?.path ?? "/ca/tiers/starter",
    meta: starter4c4mJaQyhjMeta || {},
    alias: starter4c4mJaQyhjMeta?.alias || [],
    redirect: starter4c4mJaQyhjMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/tiers/starter.vue").then(m => m.default || m)
  },
  {
    name: processklUdMwEI3BMeta?.name ?? "trial-process___en-US",
    path: processklUdMwEI3BMeta?.path ?? "/trial/process",
    meta: processklUdMwEI3BMeta || {},
    alias: processklUdMwEI3BMeta?.alias || [],
    redirect: processklUdMwEI3BMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processklUdMwEI3BMeta?.name ?? "trial-process___ca",
    path: processklUdMwEI3BMeta?.path ?? "/ca/trial/process",
    meta: processklUdMwEI3BMeta || {},
    alias: processklUdMwEI3BMeta?.alias || [],
    redirect: processklUdMwEI3BMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processklUdMwEI3BMeta?.name ?? "trial-process___nl",
    path: processklUdMwEI3BMeta?.path ?? "/nl/trial/process",
    meta: processklUdMwEI3BMeta || {},
    alias: processklUdMwEI3BMeta?.alias || [],
    redirect: processklUdMwEI3BMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processklUdMwEI3BMeta?.name ?? "trial-process___uk",
    path: processklUdMwEI3BMeta?.path ?? "/uk/trial/process",
    meta: processklUdMwEI3BMeta || {},
    alias: processklUdMwEI3BMeta?.alias || [],
    redirect: processklUdMwEI3BMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processklUdMwEI3BMeta?.name ?? "trial-process___de",
    path: processklUdMwEI3BMeta?.path ?? "/de/trial/process",
    meta: processklUdMwEI3BMeta || {},
    alias: processklUdMwEI3BMeta?.alias || [],
    redirect: processklUdMwEI3BMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processklUdMwEI3BMeta?.name ?? "trial-process___mx",
    path: processklUdMwEI3BMeta?.path ?? "/mx/trial/process",
    meta: processklUdMwEI3BMeta || {},
    alias: processklUdMwEI3BMeta?.alias || [],
    redirect: processklUdMwEI3BMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processklUdMwEI3BMeta?.name ?? "trial-process___fr",
    path: processklUdMwEI3BMeta?.path ?? "/fr/trial/process",
    meta: processklUdMwEI3BMeta || {},
    alias: processklUdMwEI3BMeta?.alias || [],
    redirect: processklUdMwEI3BMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processklUdMwEI3BMeta?.name ?? "trial-process___fr-ca",
    path: processklUdMwEI3BMeta?.path ?? "/fr-ca/trial/process",
    meta: processklUdMwEI3BMeta || {},
    alias: processklUdMwEI3BMeta?.alias || [],
    redirect: processklUdMwEI3BMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: supportb055D007icMeta?.name ?? "warranty-support___uk",
    path: supportb055D007icMeta?.path ?? "/uk/warranty/support",
    meta: supportb055D007icMeta || {},
    alias: supportb055D007icMeta?.alias || [],
    redirect: supportb055D007icMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: supportb055D007icMeta?.name ?? "warranty-support___en-US",
    path: supportb055D007icMeta?.path ?? "/warranty/support",
    meta: supportb055D007icMeta || {},
    alias: supportb055D007icMeta?.alias || [],
    redirect: supportb055D007icMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: supportb055D007icMeta?.name ?? "warranty-support___ca",
    path: supportb055D007icMeta?.path ?? "/ca/warranty/support",
    meta: supportb055D007icMeta || {},
    alias: supportb055D007icMeta?.alias || [],
    redirect: supportb055D007icMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: supportb055D007icMeta?.name ?? "warranty-support___ie",
    path: supportb055D007icMeta?.path ?? "/ie/warranty/support",
    meta: supportb055D007icMeta || {},
    alias: supportb055D007icMeta?.alias || [],
    redirect: supportb055D007icMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ghmeDDZsJNMeta?.name ?? "webinars-slug___en-US",
    path: _91slug_93ghmeDDZsJNMeta?.path ?? "/webinars/:slug()",
    meta: _91slug_93ghmeDDZsJNMeta || {},
    alias: _91slug_93ghmeDDZsJNMeta?.alias || [],
    redirect: _91slug_93ghmeDDZsJNMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ghmeDDZsJNMeta?.name ?? "webinars-slug___ca",
    path: _91slug_93ghmeDDZsJNMeta?.path ?? "/ca/webinars/:slug()",
    meta: _91slug_93ghmeDDZsJNMeta || {},
    alias: _91slug_93ghmeDDZsJNMeta?.alias || [],
    redirect: _91slug_93ghmeDDZsJNMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ghmeDDZsJNMeta?.name ?? "webinars-slug___nl",
    path: _91slug_93ghmeDDZsJNMeta?.path ?? "/nl/webinars/:slug()",
    meta: _91slug_93ghmeDDZsJNMeta || {},
    alias: _91slug_93ghmeDDZsJNMeta?.alias || [],
    redirect: _91slug_93ghmeDDZsJNMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ghmeDDZsJNMeta?.name ?? "webinars-slug___uk",
    path: _91slug_93ghmeDDZsJNMeta?.path ?? "/uk/webinars/:slug()",
    meta: _91slug_93ghmeDDZsJNMeta || {},
    alias: _91slug_93ghmeDDZsJNMeta?.alias || [],
    redirect: _91slug_93ghmeDDZsJNMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ghmeDDZsJNMeta?.name ?? "webinars-slug___de",
    path: _91slug_93ghmeDDZsJNMeta?.path ?? "/de/webinars/:slug()",
    meta: _91slug_93ghmeDDZsJNMeta || {},
    alias: _91slug_93ghmeDDZsJNMeta?.alias || [],
    redirect: _91slug_93ghmeDDZsJNMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ghmeDDZsJNMeta?.name ?? "webinars-slug___mx",
    path: _91slug_93ghmeDDZsJNMeta?.path ?? "/mx/webinars/:slug()",
    meta: _91slug_93ghmeDDZsJNMeta || {},
    alias: _91slug_93ghmeDDZsJNMeta?.alias || [],
    redirect: _91slug_93ghmeDDZsJNMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ghmeDDZsJNMeta?.name ?? "webinars-slug___fr",
    path: _91slug_93ghmeDDZsJNMeta?.path ?? "/fr/webinars/:slug()",
    meta: _91slug_93ghmeDDZsJNMeta || {},
    alias: _91slug_93ghmeDDZsJNMeta?.alias || [],
    redirect: _91slug_93ghmeDDZsJNMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ghmeDDZsJNMeta?.name ?? "webinars-slug___fr-ca",
    path: _91slug_93ghmeDDZsJNMeta?.path ?? "/fr-ca/webinars/:slug()",
    meta: _91slug_93ghmeDDZsJNMeta || {},
    alias: _91slug_93ghmeDDZsJNMeta?.alias || [],
    redirect: _91slug_93ghmeDDZsJNMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexjl5eGSP63DMeta?.name ?? "webinars___en-US",
    path: indexjl5eGSP63DMeta?.path ?? "/webinars",
    meta: indexjl5eGSP63DMeta || {},
    alias: indexjl5eGSP63DMeta?.alias || [],
    redirect: indexjl5eGSP63DMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexjl5eGSP63DMeta?.name ?? "webinars___ca",
    path: indexjl5eGSP63DMeta?.path ?? "/ca/webinars",
    meta: indexjl5eGSP63DMeta || {},
    alias: indexjl5eGSP63DMeta?.alias || [],
    redirect: indexjl5eGSP63DMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexjl5eGSP63DMeta?.name ?? "webinars___nl",
    path: indexjl5eGSP63DMeta?.path ?? "/nl/webinars",
    meta: indexjl5eGSP63DMeta || {},
    alias: indexjl5eGSP63DMeta?.alias || [],
    redirect: indexjl5eGSP63DMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexjl5eGSP63DMeta?.name ?? "webinars___uk",
    path: indexjl5eGSP63DMeta?.path ?? "/uk/webinars",
    meta: indexjl5eGSP63DMeta || {},
    alias: indexjl5eGSP63DMeta?.alias || [],
    redirect: indexjl5eGSP63DMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexjl5eGSP63DMeta?.name ?? "webinars___de",
    path: indexjl5eGSP63DMeta?.path ?? "/de/webinars",
    meta: indexjl5eGSP63DMeta || {},
    alias: indexjl5eGSP63DMeta?.alias || [],
    redirect: indexjl5eGSP63DMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexjl5eGSP63DMeta?.name ?? "webinars___mx",
    path: indexjl5eGSP63DMeta?.path ?? "/mx/webinars",
    meta: indexjl5eGSP63DMeta || {},
    alias: indexjl5eGSP63DMeta?.alias || [],
    redirect: indexjl5eGSP63DMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexjl5eGSP63DMeta?.name ?? "webinars___fr",
    path: indexjl5eGSP63DMeta?.path ?? "/fr/webinars",
    meta: indexjl5eGSP63DMeta || {},
    alias: indexjl5eGSP63DMeta?.alias || [],
    redirect: indexjl5eGSP63DMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexjl5eGSP63DMeta?.name ?? "webinars___fr-ca",
    path: indexjl5eGSP63DMeta?.path ?? "/fr-ca/webinars",
    meta: indexjl5eGSP63DMeta || {},
    alias: indexjl5eGSP63DMeta?.alias || [],
    redirect: indexjl5eGSP63DMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_9393pan9yVxZMeta?.name ?? "webinars-thank-you-slug___en-US",
    path: _91slug_9393pan9yVxZMeta?.path ?? "/webinars/thank-you/:slug()",
    meta: _91slug_9393pan9yVxZMeta || {},
    alias: _91slug_9393pan9yVxZMeta?.alias || [],
    redirect: _91slug_9393pan9yVxZMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9393pan9yVxZMeta?.name ?? "webinars-thank-you-slug___ca",
    path: _91slug_9393pan9yVxZMeta?.path ?? "/ca/webinars/thank-you/:slug()",
    meta: _91slug_9393pan9yVxZMeta || {},
    alias: _91slug_9393pan9yVxZMeta?.alias || [],
    redirect: _91slug_9393pan9yVxZMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9393pan9yVxZMeta?.name ?? "webinars-thank-you-slug___nl",
    path: _91slug_9393pan9yVxZMeta?.path ?? "/nl/webinars/thank-you/:slug()",
    meta: _91slug_9393pan9yVxZMeta || {},
    alias: _91slug_9393pan9yVxZMeta?.alias || [],
    redirect: _91slug_9393pan9yVxZMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9393pan9yVxZMeta?.name ?? "webinars-thank-you-slug___uk",
    path: _91slug_9393pan9yVxZMeta?.path ?? "/uk/webinars/thank-you/:slug()",
    meta: _91slug_9393pan9yVxZMeta || {},
    alias: _91slug_9393pan9yVxZMeta?.alias || [],
    redirect: _91slug_9393pan9yVxZMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9393pan9yVxZMeta?.name ?? "webinars-thank-you-slug___de",
    path: _91slug_9393pan9yVxZMeta?.path ?? "/de/webinars/thank-you/:slug()",
    meta: _91slug_9393pan9yVxZMeta || {},
    alias: _91slug_9393pan9yVxZMeta?.alias || [],
    redirect: _91slug_9393pan9yVxZMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9393pan9yVxZMeta?.name ?? "webinars-thank-you-slug___mx",
    path: _91slug_9393pan9yVxZMeta?.path ?? "/mx/webinars/thank-you/:slug()",
    meta: _91slug_9393pan9yVxZMeta || {},
    alias: _91slug_9393pan9yVxZMeta?.alias || [],
    redirect: _91slug_9393pan9yVxZMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9393pan9yVxZMeta?.name ?? "webinars-thank-you-slug___fr",
    path: _91slug_9393pan9yVxZMeta?.path ?? "/fr/webinars/thank-you/:slug()",
    meta: _91slug_9393pan9yVxZMeta || {},
    alias: _91slug_9393pan9yVxZMeta?.alias || [],
    redirect: _91slug_9393pan9yVxZMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9393pan9yVxZMeta?.name ?? "webinars-thank-you-slug___fr-ca",
    path: _91slug_9393pan9yVxZMeta?.path ?? "/fr-ca/webinars/thank-you/:slug()",
    meta: _91slug_9393pan9yVxZMeta || {},
    alias: _91slug_9393pan9yVxZMeta?.alias || [],
    redirect: _91slug_9393pan9yVxZMeta?.redirect,
    component: () => import("/codebuild/output/src2964691665/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  }
]